import React, { useEffect } from 'react'

import './styles.scss'
import { BiBookBookmark, BiChat, BiHeadphone } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

const Support = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='support section_main' style={{ marginBottom: '60px' }}>
            <div className='top_padding_container'>
                <h1 className='header_1'>Help & Support</h1>
            </div>

            <div className='support__cards'>
                <div className='card_row'>
                    <div className='support_card'
                        onClick={() => navigate('/support/email')}
                    >
                        <div className='icon'>
                            <BiChat />
                        </div>
                        <h2 className='header_2'>Email</h2>
                        <p>
                            Email us at <a href='mailto:info@visitpearl.com.au'>info@visitpearl.com.au</a> and we will get back to you as soon as possible.
                        </p>
                    </div>

                    <div className='support_card'
                        onClick={() => navigate('/support/faq')}
                    >
                        <div className='icon'>
                            <BiBookBookmark />
                        </div>
                        <h2 className='header_2'>FAQ</h2>
                        <p>
                            Check out our frequently asked questions for instant help.
                        </p>
                    </div>

                    <div className='support_card'
                        onClick={() => navigate('/support/call')}
                    >
                        <div className='icon'>
                            <BiHeadphone />
                        </div>
                        <h2 className='header_2'>Call</h2>
                        <p>
                            Request a call back from our support team. We will get back to you as soon as possible.
                        </p>
                    </div>
                </div>

                <div className='card_row'>
                    <div className='support_card'
                        onClick={() => navigate('/support/support-requests')}
                    >
                        <div className='icon'>
                            <BiChat />
                        </div>
                        <h2 className='header_2'>Support Request</h2>
                        <p>
                            Submit a support request for any issues you are facing. We will get back to you as soon as possible.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Support