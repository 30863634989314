import { HomeOutlined, LoadingOutlined, MenuUnfoldOutlined, SearchOutlined } from '@ant-design/icons'
import { AutoComplete, Breadcrumb, Button, Divider, Drawer, Empty, Form, Input, Pagination, Radio, Rate, Select, Skeleton, Slider, Tag, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Row } from 'react-bootstrap'
import { DEFAULT_THUMBNAIL } from '../../constants/otherConstsnts'
import ReactQuill from 'react-quill'
import { capitalizeFisrtLetterEachWord, resizeString } from '../../helpers/nameRender'
import { convertCurrency } from '../../helpers/currencyConverter'
import usePageSettings from '../../hooks/usePageSettings'
import useCurrencyRates from '../../hooks/useCurrencyRates'

import './styles.scss'
import { cities } from '../../constants/cities'
import { accommodationTypes, hotelRoomTypes, hotelTags } from '../../constants/itemTypes'
import { getAllAccommodationPackages, searchAccommodationPackage } from '../../../redux/api/accommodations.api'
import { TbCactus } from 'react-icons/tb'
import { FaHotel, FaLocationDot } from 'react-icons/fa6'

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const accommodationTypeOptions = Object.keys(accommodationTypes).map(key => {
    return {
        label: getOptionName(accommodationTypes[key]),
        value: key
    };
});

const hotelTagsOptions = Object.keys(hotelTags).map(key => {
    return {
        label: getOptionName(hotelTags[key]),
        value: key
    };
});

const accommodationRoomTypes = Object.keys(hotelRoomTypes).map(key => {
    return {
        label: getOptionName(hotelRoomTypes[key]),
        value: key
    };
});

const Accommodations = () => {
    const [searchResults, setSearchResults] = useState([])
    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const navigate = useNavigate()
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)

    const currencyRates = useCurrencyRates()?.currencyRates
    const selectedCurrency = usePageSettings()?.selectedCurrency;

    const [accommodations, setAccommodations] = useState([])
    const [loading, setLoading] = useState(false)

    const [page, setPage] = useState(1)
    const [minPrice, setMinPrice] = useState(null)
    const [maxPrice, setMaxPrice] = useState(null)
    const [selectedTags, setSelectedTags] = useState([])
    const [selectedAccommodationType, setSelectedAccommodationType] = useState(null)
    const [packageRoomType, setPackageRoomType] = useState(null)
    const [selectedCity, setSelectedCity] = useState(null)
    const [selectedRating, setSelectedRating] = useState(null)
    const [form] = Form.useForm();

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchAccommodations({ page: 1 })
    }, [])

    const onChangeSearch = async (value) => {
        if (value?.length > 2) {
            await handleSearch(value)
        } else {
            setSearchResults([])
        }

        if (value?.length === 0) {
            setSearchResults([])
        }
    }

    const handleSearch = async (keyword) => {
        setIsSearchLoading(true)
        const response = await searchAccommodationPackage(keyword)
        if (response && response.data) {
            addSearchResults(response.data)
        }
        setIsSearchLoading(false)
    }

    const addSearchResults = (items) => {
        const results = items.map(item => ({
            value: item.id,
            label:
                <div className='result-row-activities'
                    onClick={() => navigate(`/accommodations/${item.id}`)}
                >
                    <div className='left'>
                        <LazyLoadImage
                            alt={item.packageName}
                            effect="blur"
                            wrapperProps={{
                                style: { transitionDelay: "0.1s" },
                            }}
                            src={item.packageImages?.split(',')[0] || DEFAULT_THUMBNAIL}
                        />
                    </div>
                    <div className='right'>
                        <h3>{item.packageName}</h3>
                        <p>{item.accommodationName}</p>
                    </div>
                </div>
        }))

        if (results?.length === 0) {
            results.push({
                value: 'no-results',
                label: <div className='result-row no_result'>
                    <div className='empty'>
                        <TbCactus /> <p>No Results Found</p>
                    </div>
                </div>
            })
        }

        setSearchResults(results)
    }

    // Filter clear functions
    const clearCity = () => {
        form.resetFields(['city'])
        setSelectedCity(null)
    }
    const clearAccommodationType = () => {
        form.resetFields(['accommodationType'])
        setSelectedAccommodationType(null)
    }
    const clearPackageRoomType = () => {
        form.resetFields(['packageRoomType'])
        setPackageRoomType(null)
    }
    const clearRating = () => {
        form.resetFields(['starRating'])
        setSelectedRating(null)
    }
    const clearPrice = () => {
        form.resetFields(['price'])
        setMinPrice(null)
        setMaxPrice(null)
    }
    const clearTags = () => {
        form.resetFields(['tags'])
        setSelectedTags([])
    }

    const handleOnFilterFinish = async (values) => {
        values = {
            page: 1,
            pageSize: 10,
            minPrice: minPrice || null,
            maxPrice: maxPrice || null,
            accommodationTags: selectedTags?.join(',') || null,
            accommodationType: selectedAccommodationType || null,
            packageRoomType: packageRoomType || null,
            accommodationCity: selectedCity || null,
            accommodationRating: selectedRating || null,
        }

        await fetchAccommodations(values)

        setIsFilterDrawerOpen(false)
    }

    const handleFetchFilteredTourPackages = async (page) => {
        const values = {
            page: page,
            pageSize: 10,
            minPrice: minPrice || null,
            maxPrice: maxPrice || null,
            accommodationTags: selectedTags?.join(',') || null,
            accommodationType: selectedAccommodationType || null,
            packageRoomType: packageRoomType || null,
            accommodationCity: selectedCity || null,
            accommodationRating: selectedRating || null,
        }

        await fetchAccommodations(values)
    }

    const fetchAccommodations = async (values) => {
        setLoading(true)
        const response = await getAllAccommodationPackages(values)
        if (response && response.data) {
            setAccommodations(response.data)
        }
        setLoading(false)
    }

    return (
        <div className='main__contaier tours_main'>
            <div className='hero section_main'>
                <div className='hero__background__overlay'></div>
                <div className='hero__background'>
                    <LazyLoadImage
                        alt='background-image'
                        effect="blur"
                        wrapperProps={{
                            style: { transitionDelay: "0.1s" },
                        }}
                        src='https://visitpearlsharedblob.blob.core.windows.net/appassets/accommodations_bg.webp'
                    />
                </div>

                <div className='hero__content'>
                    <h1 data-aos="fade-up" data-aos-delay="300" className='section_title'>
                        Accommodations
                    </h1>

                    <div className='hero__search__button' data-aos="fade-up" data-aos-delay="600">
                        <AutoComplete
                            popupClassName='certain-category-search-popup'
                            options={searchResults}
                        >
                            <Input
                                className='search__input'
                                prefix={isSearchLoading ? <LoadingOutlined /> : <SearchOutlined />}
                                placeholder='Search for hotels, guest houses, etc...'
                                onChange={(e) => onChangeSearch(e.target.value)}
                            />
                        </AutoComplete>
                        <Button
                            type='primary'
                            onClick={() => handleSearch()}
                            className='serach_btn'
                            loading={isSearchLoading}
                            icon={<SearchOutlined />}
                        >
                            Search
                        </Button>
                    </div>
                </div>
            </div>

            <div className="custom-shape-divider-top-1724082403">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                </svg>
            </div>
            <div className='destinations__content section_main'>
                <div className='breadcrumb__section'>
                    <Breadcrumb
                        className='breadcrumb_pages'
                        items={[
                            {
                                title: <div className='breadcrumb__item' onClick={() => navigate('/')}><HomeOutlined /> Home</div>,
                            },
                            {
                                title: <div className='breadcrumb__item last'>Accommodations</div>,
                            },
                        ]}
                    />

                    <Button
                        type='primary'
                        className='filter__btn'
                        onClick={() => setIsFilterDrawerOpen(true)}
                    >
                        <MenuUnfoldOutlined />
                    </Button>
                </div>

                <div className='main_items__list'>
                    <div className='filter__section'>
                        <Form
                            layout='vertical'
                            form={form}
                            onFinish={handleOnFilterFinish}
                        >
                            <div className='header'>
                                <h2>Filters</h2>
                                <Button type='primary' htmlType='submit'>Apply</Button>
                            </div>

                            <Form.Item
                                label={
                                    <div className='form_label'>
                                        <p>City</p>
                                        <Button type='link' onClick={clearCity}>Clear</Button>
                                    </div>
                                }
                                name='city'
                            >
                                <Select
                                    placeholder='Select City'
                                    allowClear
                                    showSearch
                                    onChange={(value) => setSelectedCity(value)}
                                >
                                    {
                                        cities.map((city, idx) => (
                                            <Select.Option key={idx} value={city}>{city}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>

                            <Divider />

                            <Form.Item
                                label={
                                    <div className='form_label'>
                                        <p>Accommodation Type</p>
                                        <Button type='link' onClick={clearAccommodationType}>Clear</Button>
                                    </div>
                                }
                                name='accommodationType'
                            >
                                <Radio.Group
                                    onChange={(e) => setSelectedAccommodationType(e.target.value)}
                                    options={accommodationTypeOptions}
                                />
                            </Form.Item>

                            <Divider />

                            <Form.Item
                                label={
                                    <div className='form_label'>
                                        <p>Room Type</p>
                                        <Button type='link' onClick={clearPackageRoomType}>Clear</Button>
                                    </div>
                                }
                                name='packageRoomType'
                            >
                                <Radio.Group
                                    onChange={(e) => setPackageRoomType(e.target.value)}
                                    options={accommodationRoomTypes}
                                />
                            </Form.Item>

                            <Divider />

                            <Form.Item
                                label={
                                    <div className='form_label'>
                                        <p>Rating</p>
                                        <Button type='link' onClick={clearRating}>Clear</Button>
                                    </div>
                                }
                                name='starRating'
                            >
                                <Rate
                                    defaultValue={0}
                                    onChange={(value) => setSelectedRating(value)}
                                />
                            </Form.Item>

                            <Divider />

                            <Form.Item
                                label={
                                    <div className='form_label'>
                                        <p>Price</p>
                                        <Button type='link' onClick={clearPrice}>Clear</Button>
                                    </div>
                                }
                                name='price'
                            >
                                <Slider
                                    className='slider'
                                    min={50}
                                    max={1000}
                                    range
                                    onChange={(value) => {
                                        setMinPrice(value[0]);
                                        setMaxPrice(value[1]);
                                    }}
                                    value={[minPrice, maxPrice]}
                                    defaultValue={[50, 1000]}
                                />
                                <p>Price Range: {minPrice || 50} AUD - {maxPrice || 1000} AUD</p>
                            </Form.Item>

                            <Form.Item
                                label={
                                    <div className='form_label'>
                                        <p>More</p>
                                        <Button type='link' onClick={clearTags}>Clear</Button>
                                    </div>
                                }
                                name='tags'
                            >
                                <Select
                                    mode='multiple'
                                    placeholder='Select Tags'
                                    allowClear
                                    showSearch
                                    onChange={(value) => setSelectedTags(value)}
                                >
                                    {
                                        hotelTagsOptions.map((tag, idx) => (
                                            <Select.Option key={idx} value={tag.value}>{tag.label}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Form>
                    </div>

                    <div className='items__list'>
                        <div className='added_filters'>
                        </div>
                        <div className='section__all__destinations__content'>
                            <Row xs={1} md={1} lg={1} xl={1} className="g-4">
                                {!loading && accommodations?.map((item, idx) => (
                                    <Col key={idx}>
                                        <Card
                                            className='tour__packages__main_card'
                                            data-aos="fade-up"
                                            data-aos-delay={`${idx * 50}`}
                                            onClick={() => navigate(`/accommodations/${item?.id}`)}
                                        >
                                            <Card.Body
                                                className='destination__card__content'
                                            >
                                                <div className='left'>
                                                    <LazyLoadImage
                                                        alt={item?.packageName}
                                                        effect="blur"
                                                        wrapperProps={{
                                                            style: { transitionDelay: "0.1s" },
                                                        }}
                                                        src={item?.packageImages?.split(',')[0] || DEFAULT_THUMBNAIL}
                                                    />
                                                </div>

                                                <div className='right'>
                                                    <h1>
                                                        {item?.packageName}
                                                    </h1>

                                                    {
                                                        item?.accommodationRating &&
                                                        <Rate
                                                            disabled
                                                            value={item?.accommodationRating}
                                                        />
                                                    }


                                                    <ReactQuill
                                                        value={item && resizeString(item?.packageDescription, 200)}
                                                        readOnly={true}
                                                        theme='bubble'
                                                    />
                                                    <div className='tags'>
                                                        {
                                                            item?.packageFacilities && (() => {
                                                                const tags = item.packageFacilities.split(',');
                                                                const visibleTags = tags.slice(0, 3);
                                                                const remainingCount = tags.length - 3;

                                                                return (
                                                                    <>
                                                                        {visibleTags.map((tag, index) => (
                                                                            <Tag key={index}>
                                                                                {tag ? capitalizeFisrtLetterEachWord(tag) : 'N/A'}
                                                                            </Tag>
                                                                        ))}
                                                                        {remainingCount > 0 && (
                                                                            <Tooltip title={tags.slice(3).map(tag => capitalizeFisrtLetterEachWord(tag)).join(', ')}>
                                                                                <Tag>+{remainingCount} more</Tag>
                                                                            </Tooltip>
                                                                        )}
                                                                    </>
                                                                );
                                                            })()
                                                        }
                                                    </div>

                                                    <div className='card_footer'>
                                                        <div className='details max_details'>
                                                            <div className='item'>
                                                                <h3>Accommodation Type: </h3> <p>{item?.accommodationType
                                                                    ? capitalizeFisrtLetterEachWord(item?.accommodationType) : 'N/A'}</p>
                                                            </div>

                                                            <div className='item'>
                                                                <h3>Room Type: </h3> <p>{item?.packageRooms ?
                                                                    capitalizeFisrtLetterEachWord(item?.packageRooms) : 'N/A'}</p>
                                                            </div>
                                                            <div className='item hotel'>
                                                                <FaHotel />
                                                                <p>{item?.accommodationName}</p>
                                                            </div>
                                                            <div className='item location'>
                                                                <FaLocationDot />
                                                                <p>{item?.accommodationCity}</p>
                                                            </div>
                                                        </div>
                                                        <div className='price'>
                                                            <h2>
                                                                <span>From </span>
                                                                {item?.packagePrice ? convertCurrency(item?.packagePrice, selectedCurrency, currencyRates) : 'N/A'} {selectedCurrency}
                                                            </h2>

                                                            <Button
                                                                type='primary'
                                                                onClick={() => navigate(`/tours/${item?.id}`)}
                                                            >
                                                                View Package
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}

                                {
                                    loading && <>
                                        {Array.from({ length: 4 }).map((_, idx) => (
                                            <Col key={idx}>
                                                <Card className='tour__card_loading'>
                                                    <Card.Body
                                                        className='card_body'
                                                    >
                                                        <div className='left'>
                                                            <Skeleton.Image active />
                                                        </div>
                                                        <div className='right'>
                                                            <Skeleton.Button active />
                                                            <Skeleton active />
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))}
                                    </>
                                }
                            </Row>
                            {
                                loading === false && accommodations?.length === 0 && (
                                    <div className='empty_cards'>
                                        <Empty
                                            description={<span>No found</span>}
                                        />
                                    </div>
                                )
                            }
                            {(!loading && accommodations?.length !== 0) &&
                                <Pagination
                                    className='pagination'
                                    defaultCurrent={page}
                                    total={accommodations[0]?.filteredPackagesCount}
                                    onChange={(page) => {
                                        setPage(page);
                                        handleFetchFilteredTourPackages(page);
                                    }}
                                    showSizeChanger={false}
                                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                />}
                        </div>
                    </div>
                </div>
            </div>

            <Drawer
                title="Basic Drawer"
                className='filter__drawer'
                placement='bottom'
                closable={false}
                open={isFilterDrawerOpen}
                onClose={() => setIsFilterDrawerOpen(false)}
                height='70vh'
                headerStyle={{ display: 'none' }}
            >
                <Form
                    layout='vertical'
                    form={form}
                    onFinish={handleOnFilterFinish}
                >
                    <div className='header'>
                        <h2>Filters</h2>
                        <Button type='primary' htmlType='submit'>Apply</Button>
                    </div>

                    <Form.Item
                        label={
                            <div className='form_label'>
                                <p>City</p>
                                <Button type='link' onClick={clearCity}>Clear</Button>
                            </div>
                        }
                        name='city'
                    >
                        <Select
                            placeholder='Select City'
                            allowClear
                            showSearch
                            onChange={(value) => setSelectedCity(value)}
                        >
                            {
                                cities.map((city, idx) => (
                                    <Select.Option key={idx} value={city}>{city}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>

                    <Divider />

                    <Form.Item
                        label={
                            <div className='form_label'>
                                <p>Accommodation Type</p>
                                <Button type='link' onClick={clearAccommodationType}>Clear</Button>
                            </div>
                        }
                        name='accommodationType'
                    >
                        <Radio.Group
                            onChange={(e) => setSelectedAccommodationType(e.target.value)}
                            options={accommodationTypeOptions}
                        />
                    </Form.Item>

                    <Divider />

                    <Form.Item
                        label={
                            <div className='form_label'>
                                <p>Room Type</p>
                                <Button type='link' onClick={clearPackageRoomType}>Clear</Button>
                            </div>
                        }
                        name='packageRoomType'
                    >
                        <Radio.Group
                            onChange={(e) => setPackageRoomType(e.target.value)}
                            options={accommodationRoomTypes}
                        />
                    </Form.Item>

                    <Divider />

                    <Form.Item
                        label={
                            <div className='form_label'>
                                <p>Rating</p>
                                <Button type='link' onClick={clearRating}>Clear</Button>
                            </div>
                        }
                        name='starRating'
                    >
                        <Rate
                            defaultValue={0}
                            onChange={(value) => setSelectedRating(value)}
                        />
                    </Form.Item>

                    <Divider />

                    <Form.Item
                        label={
                            <div className='form_label'>
                                <p>Price</p>
                                <Button type='link' onClick={clearPrice}>Clear</Button>
                            </div>
                        }
                        name='price'
                    >
                        <Slider
                            className='slider'
                            min={50}
                            max={1000}
                            range
                            onChange={(value) => {
                                setMinPrice(value[0]);
                                setMaxPrice(value[1]);
                            }}
                            value={[minPrice, maxPrice]}
                            defaultValue={[50, 1000]}
                        />
                        <p>Price Range: {minPrice || 50} AUD - {maxPrice || 1000} AUD</p>
                    </Form.Item>

                    <Form.Item
                        label={
                            <div className='form_label'>
                                <p>More</p>
                                <Button type='link' onClick={clearTags}>Clear</Button>
                            </div>
                        }
                        name='tags'
                    >
                        <Select
                            mode='multiple'
                            placeholder='Select Tags'
                            allowClear
                            showSearch
                            onChange={(value) => setSelectedTags(value)}
                        >
                            {
                                hotelTagsOptions.map((tag, idx) => (
                                    <Select.Option key={idx} value={tag.value}>{tag.label}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    )
}

export default Accommodations