import React, { useEffect, useState } from 'react'
import { CheckCircleOutlined } from '@ant-design/icons'

import './styles.scss'
import { Button, Checkbox, Divider, Form, Input, Modal, Select, Steps, Tabs } from 'antd'
import { TAX_PERCENTAGE, TAX_RATE } from '../../../../../constants/finance'
import { capitalizeFisrtLetterEachWord } from '../../../../../helpers/nameRender'
import useMyProfile from '../../../../../hooks/useMyProfile'
import { PENDING_PAYMENT } from '../../../../../constants/tourStatus'
import { createUserTour } from '../../../../../../redux/api/userTour.api'
import { useNavigate } from 'react-router-dom'
import usePageSettings from '../../../../../hooks/usePageSettings'

const TourBookingPayment = ({
    userTourData,
    setUserTourData,
    tourPackage,
    messageApi,
    prev,
    next,
    calculateTotal,
    calculateSubTotal,
    calculateDiscount,
    calculateTax,
    current,
}) => {
    const [paymentWarning, setPaymentWarning] = useState(null)
    const [paymentOption, setPaymentOption] = useState(null)
    const myProfile = useMyProfile().userProfile;
    const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false)
    const [isInstalmentModelOpen, setIsInstalmentModelOpen] = useState(false)
    const [isBookingConfirmLoading, setIsBookingConfirmLoading] = useState(false)
    const selectedCurrency = usePageSettings()?.selectedCurrency;
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const onFinish = async (values) => {
        runCheck()
        if (paymentOption === 'BANK_TRANSFER') {
            handleSaveUserTour(values, 'BANK_TRANSFER')
        } else if (paymentOption === 'Monthly') {
            handleSaveUserTour(values, 'Monthly')
        }
    }

    const handleSaveUserTour = async (values, paymentOption) => {
        setIsBookingConfirmLoading(true)

        let travellersInfromation = {
            adults: userTourData?.adults > 0 ?
                Array.from({ length: userTourData.adults }, (_, index) => index).map((index) => ({
                    firstName: userTourData?.userData[`adults[${index}].firstName`],
                    lastName: userTourData?.userData[`adults[${index}].lastName`],
                    email: userTourData?.userData[`adults[${index}].email`],
                    phone: userTourData?.userData[`adults[${index}].phone`],
                    country: userTourData?.userData[`adults[${index}].country`],
                    passportNumber: userTourData?.userData[`adults[${index}].passportNumber`],
                    allergies: userTourData?.userData[`adults[${index}].allergies`],
                })) : [],
            children: userTourData?.children > 0 ?
                Array.from({ length: userTourData.children }, (_, index) => index).map((index) => ({
                    firstName: userTourData?.userData[`children[${index}].firstName`],
                    lastName: userTourData?.userData[`children[${index}].lastName`],
                    email: userTourData?.userData[`children[${index}].email`],
                    phone: userTourData?.userData[`children[${index}].phone`],
                    country: userTourData?.userData[`children[${index}].country`],
                    passportNumber: userTourData?.userData[`children[${index}].passportNumber`],
                    allergies: userTourData?.userData[`children[${index}].allergies`],
                })) : [],
            infants: userTourData?.infants > 0 ?
                Array.from({ length: userTourData.infants }, (_, index) => index).map((index) => ({
                    firstName: userTourData?.userData[`infants[${index}].firstName`],
                    lastName: userTourData?.userData[`infants[${index}].lastName`],
                    email: userTourData?.userData[`infants[${index}].email`],
                    phone: userTourData?.userData[`infants[${index}].phone`],
                    country: userTourData?.userData[`infants[${index}].country`],
                    passportNumber: userTourData?.userData[`infants[${index}].passportNumber`],
                    allergies: userTourData?.userData[`infants[${index}].allergies`],
                })) : [],
        }

        let travellersInfromationJSON = JSON.stringify(travellersInfromation)
        let flightJSON = JSON.stringify(userTourData?.filghtDetails)

        const data = {
            userId: myProfile.id,
            packageId: tourPackage.id,
            bookingStatus: PENDING_PAYMENT,
            transportOption: userTourData.vehicleType,
            accommodationOption: userTourData.roomType,
            specialNoteByCustomer: values.notes,
            isConfirmedByAdmin: false,
            userAggreed: values.terms_and_conditions,
            paymentStatus: PENDING_PAYMENT,
            paymentMethod: paymentOption,
            totalAmount: calculateSubTotal(),
            discountAmount: tourPackage.discount,
            tax: TAX_PERCENTAGE,
            finalAmount: calculateTotal(),
            installmentId: paymentOption === 'Monthly' ? (tourPackage?.instalmentPlans ? tourPackage?.instalmentPlans[0].id : null) : null,
            emergencyContactName: userTourData?.userData?.contactFirstName + ' ' + userTourData?.userData?.contactGivenName,
            emergencyContactNumber: userTourData?.userData?.contactPhone,
            emergencyEmail: userTourData?.userData?.contactEmail,
            travellersInfromation: travellersInfromationJSON,
            tourStartDate: userTourData.startDate,
            flightDetails: flightJSON,
        }

        try {
            const response = await createUserTour(data)
            if (response.status === 201) {
                messageApi.open({
                    type: 'success',
                    message: 'Booking Confirmed',
                    description: 'Your booking is confirmed. Please make the payment to the following bank account to complete the booking process.',
                })
                setUserTourData({})
                if (paymentOption === 'BANK_TRANSFER') {
                    setIsTransactionModalOpen(true)
                } else if (paymentOption === 'Monthly') {
                    setIsInstalmentModelOpen(true)
                }
            } else {
                console.log('ERROR SAVING USER TOUR', response)
                messageApi.open({
                    type: 'error',
                    message: 'Error',
                    description: 'An error occurred while saving the booking. Please try again.',
                })
            }

        } catch (error) {
            console.log('ERROR SAVING USER TOUR', error)
            messageApi.open({
                type: 'error',
                message: 'Error',
                description: 'An error occurred while saving the booking. Please try again.',
            })
        }

        setIsBookingConfirmLoading(false)
    }

    const calculateInstallment = (total, months) => {
        let installment = total / months
        return Math.round(installment * 100) / 100
    }

    const checkInstallmentPlanEligibility = (selectedPlan) => {
        if (userTourData.startDate) {
            const installmentPlans = tourPackage?.instalmentPlans
            const plan = installmentPlans.find(plan => plan.installmentType === selectedPlan)
            const months = plan.months
            const today = new Date()
            const lastInstallmentDate = new Date(today.setMonth(today.getMonth() + months))
            const tourStartDate = new Date(userTourData.startDate)
            if (lastInstallmentDate < tourStartDate) {
                setPaymentWarning(null)
            } else {
                setPaymentWarning(`The last installment date should be at least 2 weeks away from the tour start date (${lastInstallmentDate.toDateString()}).`)
            }
        }
    }

    const runCheck = () => {
        if (!userTourData.startDate) {
            messageApi.open({
                type: 'error',
                message: 'Start Date is required',
                description: 'Please select a start date for the tour.',
            })
            return
        }
        if (!userTourData.roomType) {
            messageApi.open({
                type: 'error',
                message: 'Room Type is required',
                description: 'Please select a room type for the tour.',
            })
            return
        }
        if (!userTourData.vehicleType) {
            messageApi.open({
                type: 'error',
                message: 'Vehicle Type is required',
                description: 'Please select a vehicle type for the tour.',
            })
            return
        }
        if (userTourData.adults === 0 && userTourData.children === 0 && userTourData.infants === 0) {
            messageApi.open({
                type: 'error',
                message: 'No members added',
                description: 'Please add at least one member to the tour.',
            })
            return
        }
    }

    return (
        <>
            <div className='contents'>
                <section className='booking_input_section'>
                    <h2 className='heder_2'>
                        Your Package Pricing
                    </h2>
                    <div className='payments_wrapper'>
                        <div className='pricing_item date'>
                            <div><strong> Start Date: </strong>{userTourData?.startDate || <span style={{ color: '#F45713' }}>Please Select</span>}</div>
                            <div><strong>Room Type: </strong>{userTourData?.roomType || <span style={{ color: '#F45713' }}>Please Select</span>}</div>
                            <div><strong>Vehicle Type: </strong>{userTourData?.vehicleType || <span style={{ color: '#F45713' }}>Please Select</span>}</div>
                        </div>

                        <Divider />

                        <div className='pricing_item quentityies'>
                            <div className='quentity'><h5>Adults: </h5> <p> &#215; {userTourData?.adults}</p></div>
                            <div className='quentity'><h5>Children: </h5> <p> &#215; {userTourData?.children}</p></div>
                            <div className='quentity'><h5>Infants: </h5> <p> &#215; {userTourData?.infants}</p></div>
                        </div>

                        <Divider />

                        <div className='total'>
                            <div>
                                <h4>
                                    Sub Total:
                                </h4>
                                <p>{calculateSubTotal()} {selectedCurrency}</p>
                            </div>
                            {
                                tourPackage?.discount &&
                                <div>
                                    <h4>
                                        Discount: {tourPackage?.discount} %
                                    </h4>
                                    <p>
                                        {calculateDiscount()} {selectedCurrency}
                                    </p>
                                </div>
                            }
                            <div>
                                <h4>
                                    Service Charge: {TAX_PERCENTAGE}%
                                </h4>
                                <p>{calculateTax()} {selectedCurrency}</p>
                            </div>

                            <Divider />

                            <div className='grand_total'>
                                <h5>Total: </h5>
                                <h6>
                                    {
                                        calculateTotal()
                                    } {selectedCurrency}
                                </h6>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='booking_input_section sim_services'>
                    <h2 className='heder_2'>
                        Payment Options
                    </h2>
                    <p className='note'>
                        We accept the following payment options for your convenience.
                    </p>
                    <div className='service_wrapper'>
                        {
                            tourPackage?.paymentOptions?.split(',').map((option, index) => (
                                <div className='service' key={index}>
                                    <CheckCircleOutlined />
                                    <p>{capitalizeFisrtLetterEachWord(option)}</p>
                                </div>
                            ))
                        }
                    </div>

                    <h2 className='heder_2' style={{ marginTop: '15px' }}>
                        Installment Plans
                    </h2>
                    <p className='note'>
                        &#x2022; {
                            tourPackage?.instalmentPlans && tourPackage?.instalmentPlans.length > 0 ?
                                `We offer the following installment plans for your convenience.` :
                                'There are no installment plans available for this package.'
                        }
                    </p>
                    <p className='note'>
                        &#x2022; If you are a installment plan, your tour start date should be at least 2 weeks away from the last installment date.
                    </p>
                    {tourPackage?.instalmentPlans && tourPackage?.instalmentPlans.length > 0 &&
                        <div className='service_wrapper tour_book_instalments' style={{ marginTop: '0px' }}>
                            <p className='note'>
                                &#x2022; This is a free service provided by us to make your booking experience easier. No additional charges or interests are applied. <a href='/terms-and-conditions' target='_blank' rel='noreferrer'>T&C Apply</a>
                            </p>
                            {
                                tourPackage?.instalmentPlans && tourPackage?.instalmentPlans.map((plan, index) => (
                                    <div className='installment' key={index}>
                                        <div className='service' >
                                            <CheckCircleOutlined />
                                            <h4>{plan.installmentType} Plan: </h4>
                                            <p>{plan.months} {plan.installmentType === 'Monthly' ? 'months' : 'weeks'}</p>
                                        </div>
                                        <div className='price'>
                                            <h4>Plan: </h4>
                                            <p>{calculateInstallment(calculateTotal(), plan.months)} {selectedCurrency}
                                                &#215; {plan.months} {plan.installmentType === 'Monthly' ? 'months' : 'weeks'}
                                            </p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    }
                </section>
            </div>

            <div className='booking_pricing'>
                <Form
                    name="booking_pricing"
                    layout="vertical"
                    onFinish={onFinish}
                    style={{ width: '100%' }}
                >
                    <div className='payment_option'>
                        <Form.Item
                            name="payment_option"
                            label="Payment Option"
                            rules={[
                                { required: true, message: 'Please select a payment option' },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        const installmentPlans = tourPackage?.instalmentPlans
                                        if (installmentPlans && installmentPlans.length > 0 && installmentPlans.map(plan => plan.installmentType).includes(value)) {
                                            if (paymentWarning) {
                                                return Promise.reject(paymentWarning)
                                            }
                                        }
                                        return Promise.resolve()
                                    }
                                })
                            ]}
                            help={paymentWarning}
                            validateStatus={paymentWarning ? 'error' : 'success'}
                        >
                            <Select
                                placeholder="Select a payment option"
                                onChange={(value) => {
                                    const installmentPlans = tourPackage?.instalmentPlans
                                    setPaymentOption(value)
                                    if (installmentPlans && installmentPlans.length > 0 && installmentPlans.map(plan => plan.installmentType).includes(value)) {
                                        checkInstallmentPlanEligibility(value)
                                    } else {
                                        setPaymentWarning(null)
                                    }
                                }}
                            >
                                {
                                    tourPackage?.paymentOptions?.split(',').map((option, index) => (
                                        <Select.Option key={index} value={option}>{capitalizeFisrtLetterEachWord(option)}</Select.Option>
                                    ))
                                }
                                {
                                    tourPackage?.instalmentPlans && tourPackage?.instalmentPlans.length > 0 &&
                                    tourPackage?.instalmentPlans.map((plan, index) => (
                                        <Select.Option key={index} value={plan.installmentType}>{plan.installmentType} Plan</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name='notes'
                            label='Notes'
                        >
                            <Input.TextArea
                                placeholder='Add any notes here...'
                                rows={4}
                                maxLength={200}
                                showCount
                            />
                        </Form.Item>

                        <Form.Item
                            name="terms_and_conditions"
                            valuePropName="checked"
                            rules={[{ required: true, message: 'Please accept the terms and conditions' }]}
                        >
                            <div className='terms_and_conditions'>
                                <Checkbox onChange={(e) => setUserTourData({ ...userTourData, termsAndConditions: e.target.checked })}
                                >
                                    I have read and accept the <a className='default_anchor' href='/terms-and-conditions' target='_blank' rel='noreferrer'>Terms & Conditions</a>
                                </Checkbox>
                            </div>
                        </Form.Item>
                    </div>

                    <div className='actions'>
                        {current < 5 - 1 && (
                            <Button type="primary" className='next'>
                                Next
                            </Button>
                        )}
                        {current === 5 - 1 && (
                            <Button type="primary" className='next' htmlType='submit' loading={isBookingConfirmLoading}>
                                {
                                    paymentOption === 'BANK_TRANSFER' || paymentOption === 'Monthly'
                                        ? 'Confirm Booking' : 'Pay Now'
                                }
                            </Button>
                        )}
                        {current > 0 && (
                            <Button onClick={() => prev()} className='prev'>
                                Previous
                            </Button>
                        )}
                    </div>
                </Form>
            </div>

            <Modal
                title="Confirm Booking"
                rootClassName='tour_booking_modal'
                open={isTransactionModalOpen}
                closable={false}
                onOk={() => setIsTransactionModalOpen(false)}
                footer={[
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => {
                            setIsTransactionModalOpen(false)
                            navigate('/dashboard/my-bookings')
                        }}
                    >
                        Ok
                    </Button>,
                ]}
            >
                <p>
                    Your booking is confirmed. Please make the payment to the following bank account to complete the booking process.
                </p>
                <Steps
                    direction="vertical"
                    current={0}
                    items={[
                        {
                            title: 'Confirm Booking',
                            description: 'Your booking is confirmed. And this will save to your booking history.',
                        },
                        {
                            title: 'Make Payment',
                            description: 'Please make the payment to the following bank account to complete the booking process.',
                        },
                        {
                            title: 'Payment Confirmation',
                            description: 'After making the payment, please confirm the payment to complete the booking process. You can submit the payment receipt from the bookings page.',
                        },
                        {
                            title: 'Booking Confirmation',
                            description: 'After confirming the payment, your booking will be confirmed. You can view the booking details from the bookings page.',
                        }
                    ]}
                />
                <Divider>Bank Account Details</Divider>
                <div className='back_details'>
                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                key: '1',
                                label: 'AUD',
                                children: <div className='bank_details'>
                                    <p>Account Name: <strong>VISIPEARL.COM</strong></p>
                                    <p>BSB: <strong>064-437</strong></p>
                                    <p>Account Number: <strong>1011 6377</strong></p>
                                    <p>Reference: <strong>Booking ID (Can Find in My Bookings)</strong></p>
                                </div>
                            },
                            {
                                key: '2',
                                label: 'USD',
                                children: <div className='bank_details'>
                                    <p>Account Name: <strong>VISIPEARL.COM</strong></p>
                                    <p>BSB: <strong>064-437</strong></p>
                                    <p>Account Number: <strong>1011 6385</strong></p>
                                    <p>Reference: <strong>Booking ID (Can Find in My Bookings)</strong></p>
                                </div>
                            }
                        ]}
                    />
                </div>

            </Modal>


            <Modal
                title="Confirm Booking"
                rootClassName='tour_booking_modal'
                open={isInstalmentModelOpen}
                closable={false}
                onOk={() => setIsInstalmentModelOpen(false)}
                footer={[
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => {
                            setIsInstalmentModelOpen(false)
                            navigate('/dashboard/my-bookings')
                        }}
                    >
                        Ok
                    </Button>,
                ]}
            >
                <p>
                    Your booking is confirmed. Please make the 1st installment payment to the following bank account to complete the booking process.
                </p>
                <Steps
                    direction="vertical"
                    current={0}
                    items={[
                        {
                            title: 'Confirm Booking',
                            description: 'Your booking is confirmed. And this will save to your booking history.',
                        },
                        {
                            title: 'Make Payment',
                            description: 'Please make the 1st installment payment to the following bank account to complete the booking process.',
                        },
                        {
                            title: 'Payment Confirmation',
                            description: 'After making the payment, please confirm the payment to complete the booking process. You can submit the payment receipt from the bookings page.',
                        },
                        {
                            title: 'Booking Confirmation',
                            description: 'After confirming the payment, your booking will be confirmed. You can view the booking details from the bookings page.',
                        }
                    ]}
                />
                <Divider>Bank Account Details</Divider>
                <div className='back_details'>
                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                key: '1',
                                label: 'AUD',
                                children: <div className='bank_details'>
                                    <p>Account Name: <strong>VISIPEARL.COM</strong></p>
                                    <p>BSB: <strong>064-437</strong></p>
                                    <p>Account Number: <strong>1011 6377</strong></p>
                                    <p>Reference: <strong>Booking ID (Can Find in My Bookings)</strong></p>
                                </div>
                            },
                            {
                                key: '2',
                                label: 'USD',
                                children: <div className='bank_details'>
                                    <p>Account Name: <strong>VISIPEARL.COM</strong></p>
                                    <p>BSB: <strong>064-437</strong></p>
                                    <p>Account Number: <strong>1011 6385</strong></p>
                                    <p>Reference: <strong>Booking ID (Can Find in My Bookings)</strong></p>
                                </div>
                            }
                        ]}
                    />
                </div>

            </Modal>
        </>
    )
}

export default TourBookingPayment