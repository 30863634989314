import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { AutoComplete, Breadcrumb, Button, Divider, Drawer, Empty, Form, Input, Pagination, Radio, Skeleton } from 'antd'
import { HomeOutlined, LoadingOutlined, MenuUnfoldOutlined, SearchOutlined } from '@ant-design/icons'
import { Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import { searchDestinations } from '../../../redux/api/destinations.api'
import { DEFAULT_THUMBNAIL } from '../../constants/otherConstsnts'
import { TbCactus, TbMapPin } from 'react-icons/tb'
import { destinationTypes, districtList } from '../../constants/itemTypes'
import useDestinations from '../../hooks/useDestinations'
import { useDispatch } from 'react-redux'
import { fetchDestinationsAC } from '../../../redux/actions/destinations/destinations.ac'

import './styles.scss'

const Destinations = () => {
    const [searchResults, setSearchResults] = useState([])
    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const [showAllCities, setShowAllCities] = useState(false)
    const [selectedType, setSelectedType] = useState(null)
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)
    const [page, setPage] = useState(1)
    const [form] = Form.useForm();
    const destinations = useDestinations();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
        handleFetchDestinations({ page: 1 })
    }, [])

    const onChangeSearch = async (value) => {
        if (value.length > 2) {
            await handleSearch(value)
        } else {
            setSearchResults([])
        }

        if (value.length === 0) {
            setSearchResults([])
        }
    }

    const handleSearch = async (keyword) => {
        setIsSearchLoading(true)

        const response = await searchDestinations(keyword)
        if (response && response.data) {
            addSearchResults(response.data)
        }

        setIsSearchLoading(false)
    }

    const addSearchResults = (items) => {
        const results = items.map(item => ({
            value: item.id,
            label:
                <div className='result-row-activities'
                    onClick={() => navigate(`/destinations/${item.id}`)}
                >
                    <div className='left'>
                        <LazyLoadImage
                            alt={item.destinationName}
                            effect="blur"
                            wrapperProps={{
                                style: { transitionDelay: "0.1s" },
                            }}
                            src={item.thumbnailUrl || DEFAULT_THUMBNAIL}
                        />
                    </div>
                    <div className='right'>
                        <h3>{item.destinationName}</h3>
                        <p>{item.destinationCity}</p>
                    </div>
                </div>
        }))

        if (results.length === 0) {
            results.push({
                value: 'no-results',
                label: <div className='result-row no_result'>
                    <div className='empty'>
                        <TbCactus /> <p>No Results Found</p>
                    </div>
                </div>
            })
        }

        setSearchResults(results)
    }

    const handleRenderCities = () => {
        let cities = districtList
        if (!showAllCities) {
            cities = districtList.slice(0, 5)
        }

        return cities.map((city, index) => (
            <Radio key={index} value={city}>{city}</Radio>
        ))
    }

    const clearTypes = () => {
        form.resetFields(['destinationType']);
        setSelectedType(null);
    };

    const clearLocations = () => {
        form.resetFields(['destinationLocation']);
        setSelectedLocation(null);
    };

    const handleOnFilterFinish = async (values) => {
        values = {
            page: 1,
            destinationType: selectedType,
            destinationCity: selectedLocation,
        }

        handleFetchDestinations(values)

        setIsFilterDrawerOpen(false)
    }

    const handleFetchDestinations = (values) => {
        values.pageSize = 9
        dispatch(fetchDestinationsAC(values))
    }

    const handleFetchFilteredDestinations = (page) => {
        const data = {
            page,
            destinationType: selectedType || null,
            destinationCity: selectedLocation || null,
        }

        handleFetchDestinations(data)
    }


    return (
        <div className='main__contaier destinations_main'>
            <div className='hero section_main'>
                <div className='hero__background__overlay'></div>
                <div className='hero__background'>
                    <LazyLoadImage
                        alt='background-image'
                        effect="blur"
                        wrapperProps={{
                            style: { transitionDelay: "0.1s" },
                        }}
                        src='https://visitpearlsharedblob.blob.core.windows.net/appassets/destinationsBg.webp'
                    />
                </div>

                <div className='hero__content'>
                    <h1 data-aos="fade-up" data-aos-delay="300" className='section_title'>Destinations</h1>

                    <div className='hero__search__button' data-aos="fade-up" data-aos-delay="600">
                        <AutoComplete
                            popupClassName='certain-category-search-popup'
                            options={searchResults}
                        >
                            <Input
                                className='search__input'
                                prefix={isSearchLoading ? <LoadingOutlined /> : <SearchOutlined />}
                                placeholder='Search for destinations'
                                onChange={(e) => onChangeSearch(e.target.value)}
                            />
                        </AutoComplete>
                        <Button
                            type='primary'
                            onClick={() => handleSearch()}
                            className='serach_btn'
                            loading={isSearchLoading}
                            icon={<SearchOutlined />}
                        >
                            Search
                        </Button>
                    </div>
                </div>
            </div>

            <div className="custom-shape-divider-top-1724082403">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                </svg>
            </div>
            <div className='destinations__content section_main'>
                <div className='breadcrumb__section'>
                    <Breadcrumb
                        className='breadcrumb_pages'
                        items={[
                            {
                                title: <div className='breadcrumb__item' onClick={() => navigate('/')}><HomeOutlined /> Home</div>,
                            },
                            {
                                title: <div className='breadcrumb__item last'>Destinations</div>,
                            },
                        ]}
                    />

                    <Button
                        type='primary'
                        className='filter__btn'
                        onClick={() => setIsFilterDrawerOpen(true)}
                    >
                        <MenuUnfoldOutlined />
                    </Button>
                </div>

                <div className='main_items__list'>
                    <div className='filter__section'>
                        <Form
                            layout='vertical'
                            form={form}
                            onFinish={handleOnFilterFinish}
                        >
                            <div className='header'>
                                <h2>Filters</h2>
                                <Button type='primary' htmlType='submit'>Apply</Button>
                            </div>

                            <Form.Item
                                label={
                                    <div className='form_label'>
                                        <p>Destination Type</p>
                                        <Button type='link' onClick={clearTypes} >Clear</Button>
                                    </div>
                                }
                                name='destinationType'
                            >
                                <Radio.Group
                                    value={selectedType}
                                    onChange={(e) => setSelectedType(e.target.value)}
                                >
                                    <Radio value={destinationTypes.CITY}>City</Radio>
                                    <Radio value={destinationTypes.WILDLIFE_PARK}>Wildlife Park</Radio>
                                    <Radio value={destinationTypes.MOUNTAIN}>Mountains</Radio>
                                    <Radio value={destinationTypes.BEACH}>Beach</Radio>
                                    <Radio value={destinationTypes.WATER_PARK}>Water Park</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Divider />

                            <Form.Item
                                label={
                                    <div className='form_label'>
                                        <p>Location</p>
                                        <Button type='link' onClick={clearLocations} >Clear</Button>
                                    </div>
                                }
                                name='destinationLocation'
                            >
                                <Radio.Group
                                    value={selectedLocation}
                                    onChange={(e) => {
                                        setSelectedLocation(e.target.value);
                                        form.setFieldsValue({ destinationLocation: e.target.value });
                                    }}
                                >
                                    {handleRenderCities()}
                                </Radio.Group>
                                <Button className='show_all_btn' type='link' onClick={() => setShowAllCities(!showAllCities)}>
                                    {showAllCities ? 'Hide' : 'Show All'}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>

                    <div className='items__list'>
                        <div className='section__all__destinations__content'>
                            <Row xs={1} md={2} lg={3} xl={3} className="g-4">
                                {!destinations?.loading && destinations?.destinations?.map((item, idx) => (
                                    <Col key={idx}>
                                        <Card
                                            className='destination__card'
                                            style={{
                                                backgroundImage: `url('${item?.thumbnailUrl}')`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                            }}
                                            data-aos="fade-up"
                                            data-aos-delay={`${idx * 50}`}
                                            onClick={() => navigate(`/destinations/${item?.id}`)}
                                        >
                                            <div className='background__overlay'></div>
                                            <Card.Body
                                                className='destination__card__content'
                                            >
                                                <h2>{item?.destinationName}</h2>
                                                {/* <p><TbMapPin /> {item?.destinationCity}</p> */}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}

                                {
                                    destinations?.loading && <>
                                        {Array.from({ length: 6 }).map((_, idx) => (
                                            <Col key={idx}>
                                                <Card className='destination__card_loading'>
                                                    <Card.Body>
                                                        <Skeleton active />
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))}
                                    </>
                                }
                            </Row>
                            {
                                destinations?.loading === false && destinations?.destinations?.length === 0 && (
                                    <div className='empty_cards'>
                                        <Empty
                                            description={<span>No found</span>}
                                        />
                                    </div>
                                )
                            }
                            {!destinations?.loading &&
                                <Pagination
                                    className='pagination'
                                    defaultCurrent={page}
                                    total={destinations?.destinations[0]?.filteredDestinationCount}
                                    onChange={(page) => {
                                        setPage(page);
                                        handleFetchFilteredDestinations(page);
                                    }}
                                    pageSize={9}
                                    showSizeChanger={false}
                                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                />}
                        </div>
                    </div>
                </div>

                <div className='section_main_video'>
                    <iframe src="https://www.youtube.com/embed/q0mbKsKG-ng?si=au0SyFOJbEE8WQZH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>

            <Drawer
                title="Basic Drawer"
                className='filter__drawer'
                placement='bottom'
                closable={false}
                open={isFilterDrawerOpen}
                onClose={() => setIsFilterDrawerOpen(false)}
                height='70vh'
                headerStyle={{ display: 'none' }}
            >
                <Form
                    layout='vertical'
                    form={form}
                    onFinish={handleOnFilterFinish}
                >
                    <div className='header'>
                        <h2>Filters</h2>
                        <div className='actions'>
                            <Button className='btnn-close' type='link' onClick={() => setIsFilterDrawerOpen(false)}>Close</Button>
                            <Button className='btn-apply' type='primary' htmlType='submit'>Apply</Button>
                        </div>
                    </div>

                    <Form.Item
                        label={
                            <div className='form_label'>
                                <p>Destination Type</p>
                                <Button type='link' onClick={clearTypes} >Clear</Button>
                            </div>
                        }
                        name='destinationType'
                    >
                        <Radio.Group
                            value={selectedType}
                            onChange={(e) => setSelectedType(e.target.value)}
                        >
                            <Radio value={destinationTypes.CITY}>City</Radio>
                            <Radio value={destinationTypes.WILDLIFE_PARK}>Wildlife Park</Radio>
                            <Radio value={destinationTypes.MOUNTAIN}>Mountains</Radio>
                            <Radio value={destinationTypes.BEACH}>Beach</Radio>
                            <Radio value={destinationTypes.WATER_PARK}>Water Park</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Divider />

                    <Form.Item
                        label={
                            <div className='form_label'>
                                <p>Location</p>
                                <Button type='link' onClick={clearLocations} >Clear</Button>
                            </div>
                        }
                        name='destinationLocation'
                    >
                        <Radio.Group
                            value={selectedLocation}
                            onChange={(e) => {
                                setSelectedLocation(e.target.value);
                                form.setFieldsValue({ destinationLocation: e.target.value });
                            }}
                        >
                            {handleRenderCities()}
                        </Radio.Group>
                        <Button className='show_all_btn' type='link' onClick={() => setShowAllCities(!showAllCities)}>
                            {showAllCities ? 'Hide' : 'Show All'}
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </div >
    )
}

export default Destinations;
