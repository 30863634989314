import React, { useEffect, useState } from 'react'
import useMyProfile from '../../../../hooks/useMyProfile';

import './styles.scss'
import { Empty, Skeleton } from 'antd';
import { getPaymentsByUser } from '../../../../../redux/api/userProfile.api';
import { TOUR_PACKAGE_PAYMENT } from '../../../../constants/finance';
import { capitalizeFisrtLetterEachWord } from '../../../../helpers/nameRender';
import moment from 'moment';
import { Card } from 'react-bootstrap';
import useCurrencyRates from '../../../../hooks/useCurrencyRates';
import usePageSettings from '../../../../hooks/usePageSettings';
import { convertCurrency } from '../../../../helpers/currencyConverter';

const PaymentInformation = () => {
    const myProfile = useMyProfile().userProfile;
    const [loading, setLoading] = useState(false);
    const [payments, setPayments] = useState([]);
    const selectedCurrency = usePageSettings()?.selectedCurrency;
    const currencyRates = useCurrencyRates()?.currencyRates

    useEffect(() => {
        if (myProfile) {
            handleGetPayments();
        }
    }, [myProfile]);

    const handleGetPayments = async () => {
        setLoading(true);
        try {
            const response = await getPaymentsByUser(myProfile.id);
            setPayments(response?.data);
        } catch (error) {
            console.log('ERROR GETTING PAYMENTS', error);
        }
        setLoading(false);
    }

    const calculateDue = (date, days) => {
        const newDate = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSZ').add(days, 'days');
        return <span>{newDate.format('MMM D YYYY h:mma')}</span>
    }

    return (
        <div className='personal_details'>
            <h1 className='heder_2'>
                Payments & Transactions Information
            </h1>
            <p className='about'>
                Your payment and transaction information is securely stored and managed by our payment gateway provider. We do not store any payment information on our servers.
            </p>

            <div className='personal_details__container'>
                {!loading && payments?.length === 0 &&
                    <div className='empty'>
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description='You have not done any transactions yet.'
                        />
                    </div>
                }
                {
                    !loading && payments?.length > 0 &&
                    payments.map((payment, index) => (
                        <div className='payment' key={index}>
                            <p><span>Payment ID: </span>{payment?.id}</p>
                            {payment?.paymentType === TOUR_PACKAGE_PAYMENT &&
                                <>
                                    <p><span>Paid For: </span>{payment?.tourPackage?.packageName}</p>
                                </>
                            }
                            <p><span>Amount: </span>{payment?.paymentAmount && convertCurrency(payment?.paymentAmount, selectedCurrency, currencyRates)} {selectedCurrency}</p>
                            <p><span>Payment Status: </span>{payment?.paymentStatus ? capitalizeFisrtLetterEachWord(payment?.paymentStatus) : 'Not Available. Contact Us'}</p>
                            <p><span>Reference: </span>{payment?.paymentReference}</p>
                            <p><span>Paid At: </span>{calculateDue(payment?.createdAt, 0)}</p>
                        </div>
                    ))
                }
                {
                    loading &&
                    <div className='loading'>
                        {
                            Array(3).fill(0).map((_, index) => (
                                <Card key={index} className='paymentLoading'>
                                    <Skeleton active />
                                </Card>
                            ))
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default PaymentInformation