import { EditOutlined, HomeOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import { AutoComplete, Breadcrumb, Button, Empty, Input, Pagination, Skeleton, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useNavigate } from 'react-router-dom'

import './styles.scss'
import { TbCactus } from 'react-icons/tb'
import { DEFAULT_PROFILE_IMAGE, DEFAULT_PROFILE_IMAGE_VISIT_PEARL, DEFAULT_THUMBNAIL } from '../../constants/otherConstsnts'
import { getAllBlogs, searchBlogs } from '../../../redux/api/blogs.api'
import { Card, Col, Row } from 'react-bootstrap'
import { resizeString } from '../../helpers/nameRender'
import moment from 'moment'

const Blogs = () => {
    const [searchResults, setSearchResults] = useState([])
    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const [blogs, setBlogs] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
        handleFetchBlogs(page)
    }, [])

    const onChangeSearch = async (value) => {
        if (value.length > 2) {
            await handleSearch(value)
        } else {
            setSearchResults([])
        }

        if (value.length === 0) {
            setSearchResults([])
        }
    }

    const handleSearch = async (keyword) => {
        setIsSearchLoading(true)

        const response = await searchBlogs(keyword)
        if (response && response.data) {
            addSearchResults(response.data)
        }

        setIsSearchLoading(false)
    }

    const handleFetchBlogs = async (page) => {
        const data = {
            page
        }
        setLoading(true)
        const response = await getAllBlogs(data)
        if (response.status === 200) {
            setBlogs(response.data)
        }
        setLoading(false)
    }

    const addSearchResults = (items) => {
        const results = items.map(item => ({
            value: item.id,
            label:
                <div className='result-row-activities'
                    onClick={() => navigate(`/blogs/${item.id}`)}
                >
                    <div className='left'>
                        <LazyLoadImage
                            alt={item.blogTitle}
                            effect="blur"
                            wrapperProps={{
                                style: { transitionDelay: "0.1s" },
                            }}
                            src={item.blogImages?.split(',')[0] || DEFAULT_THUMBNAIL}
                        />
                    </div>
                    <div className='right'>
                        <h3>{item.blogTitle}</h3>
                    </div>
                </div>
        }))

        if (results.length === 0) {
            results.push({
                value: 'no-results',
                label: <div className='result-row no_result'>
                    <div className='empty'>
                        <TbCactus /> <p>No Results Found</p>
                    </div>
                </div>
            })
        }

        setSearchResults(results)
    }

    return (
        <div className='main__contaier blogs_main'>
            <div className='hero section_main'>
                <div className='hero__background__overlay'></div>
                <div className='hero__background'>
                    <LazyLoadImage
                        alt='background-image'
                        effect="blur"
                        wrapperProps={{
                            style: { transitionDelay: "0.1s" },
                        }}
                        src='https://visitpearlsharedblob.blob.core.windows.net/appassets/blogs_bg.webp'
                    />
                </div>

                <div className='hero__content'>
                    <h1 data-aos="fade-up" data-aos-delay="300" className='section_title'>
                        Blogs & News
                    </h1>

                    <div className='hero__search__button' data-aos="fade-up" data-aos-delay="600">
                        <AutoComplete
                            popupClassName='certain-category-search-popup'
                            options={searchResults}
                        >
                            <Input
                                className='search__input'
                                prefix={isSearchLoading ? <LoadingOutlined /> : <SearchOutlined />}
                                placeholder='Search for blogs'
                                onChange={(e) => onChangeSearch(e.target.value)}
                            />
                        </AutoComplete>
                        <Button
                            type='primary'
                            onClick={() => handleSearch()}
                            className='serach_btn'
                            loading={isSearchLoading}
                            icon={<SearchOutlined />}
                        >
                            Search
                        </Button>
                    </div>
                    <Button
                        type='primary'
                        className='custom_primary_btn_large_dark'
                        style={{ marginTop: '20px' }}
                        onClick={() => navigate('/blogs/write')}
                    >
                        <EditOutlined />
                        Write a Blog
                    </Button>
                </div>
            </div>

            <div className="custom-shape-divider-top-1724082403">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                </svg>
            </div>
            <div className='destinations__content section_main blogs_main'>
                <div className='breadcrumb__section'>
                    <Breadcrumb
                        className='breadcrumb_pages'
                        items={[
                            {
                                title: <div className='breadcrumb__item' onClick={() => navigate('/')}><HomeOutlined /> Home</div>,
                            },
                            {
                                title: <div className='breadcrumb__item last'>
                                    Blogs & News
                                </div>,
                            },
                        ]}
                    />
                </div>

                <div className='items__list' style={{ marginTop: 20 }}>
                    <div className='section__all__destinations__content'>
                        <Row xs={1} md={2} lg={3} xl={3} className="g-4">
                            {!loading && blogs?.map((item, idx) => (
                                <Col key={idx}>
                                    <Card
                                        data-aos="fade-up"
                                        data-aos-delay={`${idx * 100}`}
                                        className='package__card'
                                        style={{
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                        }}
                                        onClick={() => navigate(`/blogs/${item?.id}`)}
                                    >
                                        <LazyLoadImage
                                            src={item?.blogImages?.split(',')[0] || DEFAULT_THUMBNAIL}
                                            alt={item?.packageName}
                                            effect='blur'
                                        />

                                        <Card.Body
                                            className='destination__card__content'
                                        >
                                            <h2>{item?.blogTitle}</h2>
                                            <div className='tags'>
                                                {
                                                    item?.blogTags?.split(',').map((tag, idx) => (
                                                        <Tag > {tag} </Tag>
                                                    ))
                                                }
                                            </div>
                                            <p>{item?.blogDescription ? resizeString(item?.blogDescription, 100) : 'No Description'}</p>

                                            {item?.blogType === 'admin' ?
                                                <div className='author'>
                                                    <div className='author_image'>
                                                        <LazyLoadImage
                                                            alt='author'
                                                            src={DEFAULT_PROFILE_IMAGE_VISIT_PEARL}
                                                            effect='blur'
                                                        />
                                                    </div>
                                                    <div className='author_name'>
                                                        <h3>Visit Pearl</h3>
                                                        <p>{moment(item?.createdAt, 'MMM D YYYY h:mma').format('MMMM Do YYYY')}</p>
                                                    </div>
                                                </div>
                                                :
                                                <div className='author'>
                                                    <div className='author_image'>
                                                        <LazyLoadImage
                                                            alt='author'
                                                            src={item?.profilePicUrl || DEFAULT_PROFILE_IMAGE}
                                                            effect='blur'
                                                        />
                                                    </div>
                                                    <div className='author_name'>
                                                        <h3>{item?.userName}</h3>
                                                        <p>{moment(item?.createdAt, 'MMM D YYYY h:mma').format('MMMM Do YYYY')}</p>
                                                    </div>
                                                </div>
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}

                            {
                                loading && <>
                                    {Array.from({ length: 6 }).map((_, idx) => (
                                        <Col key={idx}>
                                            <Card className='destination__card_loading'>
                                                <Card.Body>
                                                    <Skeleton active />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </>
                            }
                        </Row>
                        {
                            loading === false && blogs?.length === 0 && (
                                <div className='empty_cards'>
                                    <Empty
                                        description={<span>No Blogs Found</span>}
                                    />
                                </div>
                            )
                        }
                        {!loading && blogs?.length > 0 &&
                            <Pagination
                                className='pagination'
                                defaultCurrent={page}
                                total={blogs[0]?.filteredBlogCount}
                                onChange={(page) => {
                                    setPage(page);
                                    handleFetchBlogs(page);
                                }}
                                pageSize={9}
                                showSizeChanger={false}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                style={{ marginTop: '20px' }}
                            />}
                    </div>
                </div>
            </div>


        </div>

    )
}

export default Blogs