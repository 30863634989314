import React, { useEffect, useRef, useState } from 'react'

import './styles.scss'
import { addCustomTourMessage, deleteCustomTourChatMessage, getCustomTourChatMessages } from '../../../../../../../redux/api/userTour.api'
import ReactQuill from 'react-quill'
import { Button, Empty, Form, Popconfirm, Upload } from 'antd'
import { DeleteOutlined, PaperClipOutlined, SendOutlined } from '@ant-design/icons'
import { handleDeleteBlob, handleImageUpload, SUPPORT_CONTAINER } from '../../../../../../azure/blob'

const CustomTourChat = ({ userTour, messageApi }) => {
    const [messages, setMessages] = useState([])
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const formRef = useRef()
    const chatMessagesRef = useRef()
    const [fileList, setFileList] = useState([])

    useEffect(() => {
        handleGetMessages()
    }, [])

    useEffect(() => {
        if (chatMessagesRef.current) {
            chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight
        }
    }, [messages])

    const handleGetMessages = async () => {
        setLoading(true)
        const response = await getCustomTourChatMessages(userTour.id)
        if (response.status === 200) {
            setMessages(response.data)
        }
        setLoading(false)
    }

    const handlesendMessage = async (values) => {
        setSending(true)

        if (!values.message) return
        if (values.message === '<p><br></p>') {
            messageApi.error({
                message: 'Please type a message'
            })
            return
        }
        let imageUrls = [];
        const name = 'customTourChat' + userTour.id

        if (fileList.length > 0) {
            const totalSize = fileList.reduce((acc, file) => acc + file.size, 0)
            if (totalSize > 10485760) {
                messageApi.error({
                    message: 'Total size of files should not exceed 10MB'
                })
                setSending(false)
                return
            }

            const imageUploadPromises = fileList.map((file, index) => {
                return handleImageUpload(
                    SUPPORT_CONTAINER,
                    file.originFileObj,
                    file.size,
                    `${name}_${file.name}`
                ).then(blobRes => {
                    imageUrls.push(blobRes.blockBlobClient.url);
                });
            });
            await Promise.all(imageUploadPromises);
        }

        if (imageUrls.length > 0) {
            imageUrls = imageUrls.map(url => sanitizeBlobUrls(url));
            values.attachments = imageUrls.join(',');
        }

        values.sender = 'customer'
        values.customTourId = userTour.id

        const response = await addCustomTourMessage(values)
        if (response.status === 200) {
            formRef.current.resetFields()
            setFileList([])
            setMessages([...messages, response.data])
        }
        setSending(false)
    }

    const handleDeleMessage = async (messageId) => {
        const attachments = messages.find(message => message.id === messageId).attachments?.split(',')

        if (attachments && attachments.length > 0) {
            const imageDeletePromises = attachments.map(async (image, index) => {
                const blobName = getBlobName(image);
                return handleDeleteBlob(SUPPORT_CONTAINER, blobName);
            });
            await Promise.all(imageDeletePromises);
        }

        const response = await deleteCustomTourChatMessage(messageId)
        if (response.status === 200) {
            setMessages(messages.filter(message => message.id !== messageId))
        }
    }

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }
    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl.split('/')
        return splitUrl[splitUrl.length - 1]
    }

    return (
        <div className='custom_tour_chat'>
            <h1>Custom Tour Chat</h1>
            <div className='chat_body'>
                <div className='chat_messages' ref={chatMessagesRef}>
                    {
                        loading && <div className='loading'>Loading...</div>
                    }
                    {
                        messages.map((message, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`chat_message ${message?.sender === 'admin' ? 'admin_chat_message' : 'user_chat_message'}`}
                                >
                                    <div className='chat_message_sender'>
                                        <h4>{message?.sender === 'admin' ? 'Admin' : 'You'}</h4>
                                        <p>{message?.createdAt}</p>
                                        {message?.sender === 'admin' &&
                                            <Popconfirm
                                                title='Are you sure you want to delete this message?'
                                                onConfirm={() => handleDeleMessage(message.id)}
                                                okText='Yes'
                                                cancelText='No'
                                            >
                                                <Button
                                                    type='text'
                                                    danger
                                                >
                                                    <DeleteOutlined />
                                                </Button>
                                            </Popconfirm>}
                                    </div>
                                    <ReactQuill
                                        value={message.message}
                                        readOnly={true}
                                        theme='bubble'
                                    />
                                    {message?.attachments &&
                                        <div className='attachments'>
                                            <p>Attachments: </p>
                                            {
                                                message.attachments?.split(',')?.map((attachment, index) => (
                                                    <div key={index} className='attachment'>
                                                        <a href={attachment} target='_blank' rel='noreferrer'>
                                                            attachment {index + 1}
                                                        </a>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                    {
                        messages.length === 0 && !loading &&
                        <div className='no_messages'>
                            <Empty
                                description='No messages'
                            />
                        </div>
                    }
                </div>
                <Form
                    onFinish={handlesendMessage}
                    ref={formRef}
                >
                    <div className='chat_message_input'>
                        <div style={{ width: '100%' }}>
                            <Form.Item
                                name='message'
                                style={{ width: '100%' }}
                                rules={[{ required: true, message: 'Please type a message' }]}
                            >
                                <ReactQuill
                                    placeholder='Type a message...'
                                    theme='bubble'
                                />
                            </Form.Item>

                            <Upload
                                fileList={fileList}
                                onChange={handleChange}
                                multiple={true}
                                maxCount={10}
                                beforeUpload={(file) => {
                                    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/gif' || file.type === 'image/webp' || file.type === 'application/pdf';
                                    if (!isJpgOrPng) {
                                        messageApi.error({
                                            message: 'Unsupported image format!',
                                            description: 'Please upload a valid image format (jpeg, jpg, png, gif, webp, pdf)',
                                        });
                                    }
                                    const isSizeValid = file.size / 1024 / 1024 < 2;
                                    if (!isSizeValid) {
                                        messageApi.error({
                                            message: 'Image must be smaller than 2MB!',
                                        });
                                    }

                                    const isVaid = isJpgOrPng && isSizeValid;

                                    return isVaid ? false : Upload.LIST_IGNORE;
                                }}
                            >
                                {fileList.length < 3 && <Button type='link'>
                                    <PaperClipOutlined /> Attach Files
                                </Button>}
                            </Upload>
                        </div>

                        <Button
                            type='primary'
                            icon={<SendOutlined />}
                            loading={sending}
                            htmlType='submit'
                        >
                            Send
                            <SendOutlined />
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default CustomTourChat