import React, { useEffect, useState } from 'react'

import { CloseCircleOutlined, DollarOutlined, MenuUnfoldOutlined, SafetyCertificateOutlined, SettingOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Drawer, Spin } from 'antd'
import { Route, Routes, useNavigate } from 'react-router-dom'

import PersonalDetails from './PersonalDetails'
import PaymentInformation from './PaymentInformation'

import './styles.scss'
import useMyProfile from '../../../hooks/useMyProfile'
import DashboardSecurity from './DashboardSecurity'
import DashboardGroups from './DashboardGroups'
import DashboardSettings from './DashboardSettings'

const items = [
    {
        key: '1',
        label: 'Personal Information',
        icon: <UserOutlined />,
        link: '/personal-information',
    },
    {
        key: '2',
        label: 'Payment Information',
        icon: <DollarOutlined />,
        link: '/payment-information',
    },
    {
        key: '3',
        label: 'Security',
        icon: <SafetyCertificateOutlined />,
        link: '/security',
    },
    {
        key: '4',
        label: 'Settings',
        icon: < SettingOutlined />,
        link: '/settings',
    },
    {
        key: '5',
        label: 'My Groups',
        icon: < TeamOutlined />,
        link: '/my-groups',
    },
]

const DashboardHome = () => {
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState('1');
    const [isNavDrawerOpen, setIsNavDraweOpen] = useState(false);
    const myProfile = useMyProfile();
    const url = window.location.pathname;

    useEffect(() => {
        if (url.includes('personal-information')) {
            setActiveItem('1');
            navigate('/dashboard/home/personal-information');
        } else if (url.includes('payment-information')) {
            setActiveItem('2');
            navigate('/dashboard/home/payment-information');
        } else if (url.includes('security')) {
            setActiveItem('3');
            navigate('/dashboard/home/security');
        } else if (url.includes('settings')) {
            setActiveItem('4');
            navigate('/dashboard/home/settings');
        } else if (url.includes('my-groups')) {
            setActiveItem('5');
            navigate('/dashboard/home/my-groups');
        } else {
            navigate('/dashboard/home/personal-information');
            setActiveItem('1');
        }
    }, []);


    return (
        <div className='dashboard__Home'>
            <div className='dash_home_nav'>
                {items.map((item) => (
                    <div
                        key={item.key}
                        className={`dash_home_nav__container__items__item ${activeItem === item.key ? 'active' : ''}`}
                        onClick={() => {
                            navigate(`/dashboard/home${item.link}`);
                            setActiveItem(item.key);
                        }}
                    >
                        {item.icon}
                        {item.label}
                    </div>
                ))}
            </div>

            <div className='mobile_nav'>
                <Button onClick={() => setIsNavDraweOpen(true)}><MenuUnfoldOutlined /> Open Menu</Button>
            </div>

            <div className='dash_home_content'>
                <Spin spinning={myProfile.loading}>
                    <Routes>
                        <Route path='/personal-information' element={<PersonalDetails />} />
                        <Route path='/payment-information' element={<PaymentInformation />} />
                        <Route path='/security' element={<DashboardSecurity />} />
                        <Route path='/settings' element={<DashboardSettings />} />
                        <Route path='/my-groups' element={<DashboardGroups />} />
                    </Routes>
                </Spin>
            </div>

            <Drawer
                title="Basic Drawer"
                className='home__nav__drawer'
                rootClassName='home__nav__drawer_body'
                placement='bottom'
                closable={false}
                open={isNavDrawerOpen}
                onClose={() => setIsNavDraweOpen(false)}
                height='70vh'
                headerStyle={{ display: 'none' }}
            >
                <div className='drawer__content_home_nav'>
                    {items.map((item) => (
                        <div
                            key={item.key}
                            className={`drawer__content__items ${activeItem === item.key ? 'active' : ''}`}
                            onClick={() => {
                                navigate(`/dashboard/home${item.link}`);
                                setActiveItem(item.key);
                                setIsNavDraweOpen(false);
                            }}
                        >
                            {item.icon}
                            {item.label}
                        </div>
                    ))}
                    <div className='drawer__content__items close' onClick={() => setIsNavDraweOpen(false)}>
                        <CloseCircleOutlined />
                        Close
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default DashboardHome