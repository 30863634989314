import React, { useState } from 'react'
import { capitalizeFisrtLetterEachWord } from '../../../../../../helpers/nameRender'
import { InboxOutlined } from '@ant-design/icons'
import { Button, Divider, Drawer, Empty, notification, Tabs, Upload } from 'antd';

import './styles.scss'
import moment from 'moment';
import { handleImageUpload, PAYMENTS_CONTAINER } from '../../../../../../azure/blob';
import { addPayment, editCustomTourCutsomer, editUserTourCustomer } from '../../../../../../../redux/api/userTour.api';
import useMyProfile from '../../../../../../hooks/useMyProfile';
import { BANK_TRANSFER, INSTALMENT, PAID, PENDING_PAYMENT, PENDING_VERIFICATION, TAX_PERCENTAGE, TOUR_PACKAGE_PAYMENT } from '../../../../../../constants/finance';
import { useNavigate } from 'react-router-dom';
import useCurrencyRates from '../../../../../../hooks/useCurrencyRates';
import usePageSettings from '../../../../../../hooks/usePageSettings';
import { convertCurrency } from '../../../../../../helpers/currencyConverter';

const { Dragger } = Upload;

const BookingPayments = ({ userTour }) => {
    const [fileList, setFileList] = useState([]);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [openBankTransferDrawer, setOpenBankTransferDrawer] = useState(false);
    const [selectedInstalment, setSelectedInstalment] = useState(null);
    const myProfile = useMyProfile().userProfile;
    const [api, contextHolder] = notification.useNotification();
    const selectedCurrency = usePageSettings()?.selectedCurrency;
    const currencyRates = useCurrencyRates()?.currencyRates
    const navigate = useNavigate()

    const calculateDueDate = (date, days) => {
        const newDate = moment(date, 'MMM D YYYY h:mma').add(days, 'days');
        return <span style={{ color: newDate.isBefore(moment()) ? 'red' : 'green' }}>{newDate.format('MMM D YYYY h:mma')}</span>
    }
    const calculateDue = (date, days) => {
        const newDate = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSZ').add(days, 'days');
        return <span style={{ color: newDate.isBefore(moment()) ? 'red' : 'green' }}>{newDate.format('MMM D YYYY h:mma')}</span>
    }

    const handleCreatePayment = async () => {
        setLoadingSubmit(true);
        if (fileList.length < 1) {
            api.error({
                message: 'No file selected',
                description: 'Please select a file to upload'
            })
            setLoadingSubmit(false);
            return;
        } else {
            const blobName = `${userTour?.id}_${fileList[0].originFileObj?.name}`;

            const totalSize = fileList.reduce((acc, file) => acc + file.size, 0)
            if (totalSize > 10485760) {
                api.error({
                    message: 'Total size of files should not exceed 10MB'
                })
                setLoadingSubmit(false);
                return
            }

            const blobRes = await handleImageUpload(
                PAYMENTS_CONTAINER,
                fileList[0].originFileObj,
                fileList[0].size,
                blobName
            )
            if (blobRes?.blockBlobClient.url) {
                const paymentValues = {
                    userId: myProfile?.id,
                    paidFor: userTour?.id,
                    paymentType: TOUR_PACKAGE_PAYMENT,
                    paymentMethod: 'BANK_TRANSFER',
                    paymentStatus: PENDING_VERIFICATION,
                    paymentAmount: userTour?.finalAmount.toString(),
                    paymentCurrency: 'AUD',
                    paymentDate: moment().format('MMM D YYYY h:mma'),
                    paymentTime: moment().format('h:mma'),
                    proof: blobRes?.blockBlobClient.url,
                    paymentReference: `Booking ID (${userTour?.id})`

                }

                const response = await addPayment(paymentValues)
                if (response?.status === 200) {
                    const userTourValues = {
                        id: userTour?.id,
                        paymentId: response?.data?.id,
                        paidAt: moment().format('MMM D YYYY h:mma'),
                        paymentStatus: PENDING_VERIFICATION,
                        bookingStatus: PENDING_VERIFICATION,
                        paymentMethod: BANK_TRANSFER
                    }

                    const updateResponse = await editCustomTourCutsomer(userTourValues)

                    if (updateResponse?.status === 200) {
                        api.success({
                            message: 'Payment Slip Submitted',
                            description: 'Your payment slip has been submitted successfully. We will verify your payment and confirm your booking.'
                        })
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000)
                    } else {
                        console.log('ERROR UPDATING BOOKING', updateResponse)
                        api.error({
                            message: 'Error Updating Booking',
                            description: 'Your payment slip has been submitted successfully. However, there was an error updating your booking. Please contact us.'
                        })
                    }
                } else {
                    console.log('ERROR ADDING PAYMENT', response)
                    api.error({
                        message: 'Error Submitting Payment Slip',
                        description: 'There was an error submitting your payment slip. Please try again later.'
                    })
                }
            } else {
                api.error({
                    message: 'Error Uploading Payment Slip',
                    description: 'There was an error uploading your payment slip. Please try again later.'
                })
            }
        }

        setLoadingSubmit(false);
    }

    const handleCreatePaymentInstalment = async () => {
        setLoadingSubmit(true);
        if (fileList.length < 1) {
            api.error({
                message: 'No file selected',
                description: 'Please select a file to upload'
            })
            setLoadingSubmit(false);
            return;
        } else {
            const blobName = `${userTour?.id}_${fileList[0].originFileObj?.name}`;
            const blobRes = await handleImageUpload(
                PAYMENTS_CONTAINER,
                fileList[0].originFileObj,
                fileList[0].size,
                blobName
            )
            if (blobRes?.blockBlobClient.url) {
                const paymentValues = {
                    userId: myProfile?.id,
                    paidFor: userTour?.id + '#' + selectedInstalment?.instalmentId,
                    paymentType: TOUR_PACKAGE_PAYMENT,
                    paymentMethod: 'BANK_TRANSFER',
                    paymentStatus: PENDING_VERIFICATION,
                    paymentAmount: userTour?.finalAmount.toString(),
                    paymentCurrency: 'AUD',
                    paymentDate: moment().format('MMM D YYYY h:mma'),
                    paymentTime: moment().format('h:mma'),
                    proof: blobRes?.blockBlobClient.url,
                    paymentReference: `Booking ID (${userTour?.id}) - Instalment ${selectedInstalment?.instalmentId}`
                }

                const response = await addPayment(paymentValues)
                if (response?.status === 200) {
                    let paymentPlan = userTour?.instalmentPayments.map(instalment => {
                        if (instalment?.id === selectedInstalment?.id) {
                            return {
                                ...instalment,
                                paymentId: response?.data?.id,
                                isPaid: true
                            }
                        } else {
                            return instalment
                        }
                    })
                    const userTourValues = {
                        id: userTour?.id,
                        instalmentPayments: JSON.stringify(paymentPlan),
                    }

                    const updateResponse = await editUserTourCustomer(userTourValues)

                    if (updateResponse?.status === 200) {
                        api.success({
                            message: 'Payment Slip Submitted',
                            description: 'Your payment slip has been submitted successfully. We will verify your payment and confirm your booking.'
                        })
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000)
                    } else {
                        console.log('ERROR UPDATING BOOKING', updateResponse)
                        api.error({
                            message: 'Error Updating Booking',
                            description: 'Your payment slip has been submitted successfully. However, there was an error updating your booking. Please contact us.'
                        })
                    }
                } else {
                    console.log('ERROR ADDING PAYMENT', response)
                    api.error({
                        message: 'Error Submitting Payment Slip',
                        description: 'There was an error submitting your payment slip. Please try again later.'
                    })
                }
            } else {
                api.error({
                    message: 'Error Uploading Payment Slip',
                    description: 'There was an error uploading your payment slip. Please try again later.'
                })
            }
        }

        setLoadingSubmit(false);
    }

    return (
        <div className='booking_payments'>
            {contextHolder}
            {userTour?.paymentStatus ?
                <>
                    <h3 className='header_3' style={{ marginBottom: '10px' }}>Booking Payments</h3>
                    <div className='p_container'>
                        <p><span>Payment Method: </span>{userTour?.paymentMethod ? capitalizeFisrtLetterEachWord(userTour?.paymentMethod) : 'Not selected yet'}
                        </p>
                        <p><span>Payment Status: </span>{userTour?.paymentStatus ? capitalizeFisrtLetterEachWord(userTour?.paymentStatus) : 'Contact Us'}</p>
                    </div>

                    <h4 className='header_4' style={{ margin: '15px 0 10px 0' }}>
                        Your Charges
                    </h4>
                    <div className='p_container'>
                        <p><span>Sub Amount: </span>{userTour?.totalAmount && convertCurrency(userTour?.totalAmount, selectedCurrency, currencyRates)} {selectedCurrency}</p>
                        {userTour?.discountAmount && <p><span>Discount: </span>{userTour?.discountAmount} %</p>}
                        <p><span>Service Charge: </span>{userTour?.tax && convertCurrency(userTour?.tax, selectedCurrency, currencyRates)} {selectedCurrency}</p>
                        <p><span>Total: </span>{userTour?.finalAmount && convertCurrency(userTour?.finalAmount, selectedCurrency, currencyRates)} {selectedCurrency}</p>
                    </div>
                </>
                :
                <div className='payment_empty'>
                    <Empty
                        description='Payment details will be available once you have completed your booking.'
                    />
                </div>
            }


            {userTour?.paymentStatus === PENDING_PAYMENT &&
                <>
                    <Divider style={{ marginBottom: 5 }} />
                    <Tabs
                        defaultActiveKey="1"
                        type="card"
                        items={[
                            {
                                key: '1',
                                label: 'Bank Transfer',
                                children:
                                    <>
                                        <div className='payment_splip_submit'>
                                            <h4 className='header_4'>Submit Payment Slip</h4>
                                            <p>
                                                You have selected to pay via bank transfer. Please submit your payment slip here before the due date. We will verify your payment and confirm your booking.
                                            </p>
                                            <h5 className='payment_due_date'>
                                                Payment Due: {calculateDueDate(userTour?.createdAt, 3)}
                                            </h5>
                                            <h5 className='payment_due_date'>
                                                Amount: {userTour?.finalAmount && convertCurrency(userTour?.finalAmount, selectedCurrency, currencyRates)} {selectedCurrency}
                                            </h5>
                                            <Dragger
                                                fileList={fileList}
                                                onChange={({ fileList }) => setFileList(fileList)}
                                                beforeUpload={
                                                    (file) => {
                                                        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
                                                        if (!isJpgOrPng) {
                                                            setFileList([]);
                                                            api.error({
                                                                message: 'Unsupported file format',
                                                                description: 'Please upload only JPG, PNG or PDF files'
                                                            })
                                                            return Upload.LIST_IGNORE;
                                                        } else {
                                                            return false;
                                                        }
                                                    }}
                                                maxCount={1}
                                            >
                                                <p className="ant-upload-drag-icon">
                                                    <InboxOutlined />
                                                </p>
                                                <p className="ant-upload-text">
                                                    Click or drag payment slip to this area to upload
                                                </p>
                                                <p className="ant-upload-hint">
                                                    Please upload a clear image of your payment slip. We accept JPG, PNG, PDF files. Upload only one file.
                                                </p>
                                            </Dragger>
                                            <Button
                                                type='primary'
                                                onClick={handleCreatePayment}
                                                loading={loadingSubmit}
                                            >
                                                Submit Payment Slip
                                            </Button>
                                        </div>

                                        <Divider />
                                        <h3 className='header_3' style={{ marginBottom: '10px' }}>Bank Details</h3>
                                        <div className='bank_details_container'>
                                            <Tabs
                                                defaultActiveKey="1"
                                                items={[
                                                    {
                                                        key: '1',
                                                        label: 'AUD',
                                                        children: <div className='bank_details'>
                                                            <p>Account Name: <strong>VISIPEARL.COM</strong></p>
                                                            <p>BSB: <strong>064-437</strong></p>
                                                            <p>Account Number: <strong>1011 6377</strong></p>
                                                            <p>Reference: <strong>Booking ID ({userTour?.id})</strong></p>
                                                        </div>
                                                    },
                                                    {
                                                        key: '2',
                                                        label: 'USD',
                                                        children: <div className='bank_details'>
                                                            <p>Account Name: <strong>VISIPEARL.COM</strong></p>
                                                            <p>BSB: <strong>064-437</strong></p>
                                                            <p>Account Number: <strong>1011 6385</strong></p>
                                                            <p>Reference: <strong>Booking ID ({userTour?.id})</strong></p>
                                                        </div>
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </>
                            },
                            {
                                key: '2',
                                label: 'Online Payment',
                                children: <div className='payment_empty'>
                                    <Empty
                                        description='Online payment is not available yet. Please contact us for more information.'
                                    />
                                </div>
                            }

                        ]}
                    />
                </>
            }
            {userTour?.paymentMethod === BANK_TRANSFER && userTour?.paymentStatus === PENDING_VERIFICATION &&
                <>
                    <Divider />
                    <div className='payment_splip_submit'>
                        <h4 className='header_4'>
                            Payment Slip Submitted
                        </h4>
                        <p>
                            Your payment slip has been submitted. We will verify your payment and confirm your booking. Please check back later for updates.
                        </p>
                        <h5 className='payment_due_date'>
                            Payment Status: <p style={{ color: '#ffae1f' }}>Pending Verification</p>
                        </h5>
                        <h5 className='payment_due_date'>
                            Payment Id: {userTour?.paymentId}
                        </h5>
                        <h5 className='payment_due_date'>
                            Amount: {userTour?.finalAmount && convertCurrency(userTour?.finalAmount, selectedCurrency, currencyRates)} {selectedCurrency}
                        </h5>
                    </div>
                </>
            }
            {userTour?.paymentMethod === BANK_TRANSFER && userTour?.paymentStatus === PAID &&
                <>
                    <Divider />
                    <div className='payment_splip_submit'>
                        <h4 className='header_4'>
                            Your Payment has been Verified
                        </h4>
                        <p>
                            Your payment has been verified. Your booking has been confirmed. Please check back later for updates.
                        </p>
                        <h5 className='payment_due_date'>
                            Payment Status: <p style={{ color: '#52C41A' }}>Paid</p>
                        </h5>
                        <h5 className='payment_due_date'>
                            Payment Id: {userTour?.paymentId}
                        </h5>
                        <h5 className='payment_due_date'>
                            Amount: {userTour?.finalAmount && convertCurrency(userTour?.finalAmount, selectedCurrency, currencyRates)} {selectedCurrency}
                        </h5>
                    </div>
                </>
            }
            {userTour?.paymentMethod === INSTALMENT && userTour?.paymentStatus === PENDING_PAYMENT &&
                <>
                    <div className='payment_splip_submit'>
                        <h4 className='header_4'>
                            Your Monthly Instalment
                        </h4>
                        <p>
                            You have selected to pay via monthly instalments. Please submit your payment slip here before the due date. We will verify your payment and confirm your booking.
                        </p>
                        <div className='instalment_plan_container'>
                            {
                                userTour?.instalmentPayments?.map((instalment, index) => (
                                    <div key={index} className='instalment_plan'>
                                        <h5>Instalment {index + 1}</h5>
                                        <div className='details_container'>
                                            <div className='left'>
                                                <p>Amount: {instalment?.amount} AUD</p>
                                                <p>Payment Date: {calculateDue(instalment?.paymentDate, 0)}</p>
                                                <p>Due Date: {calculateDue(instalment?.dueDate, 0)}</p>
                                                <p>Status: {instalment?.isPaid ? 'Paid' : 'Pending'}</p>
                                                <>
                                                    <Divider style={{ margin: '5px 0' }} />
                                                    <p>Payment ID: {instalment?.payment?.id}</p>
                                                    <p><span>Payment Status: </span>{instalment?.payment?.paymentStatus ? capitalizeFisrtLetterEachWord(instalment?.payment?.paymentStatus) : 'Contact Us'}</p>
                                                </>
                                            </div>
                                            <div className='action'>
                                                {!instalment?.paymentId ? <>
                                                    <Button
                                                        onClick={() => {
                                                            setOpenBankTransferDrawer(true);
                                                            setSelectedInstalment(instalment);
                                                        }}
                                                    >
                                                        Bank Transfer
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            api.info({
                                                                message: 'Online Payment',
                                                                description: 'Online payment is not available yet. Please contact us for more information.'
                                                            })
                                                        }}
                                                    >
                                                        Online Payment
                                                    </Button>
                                                </>
                                                    :
                                                    <Button
                                                        onClick={() => {
                                                            navigate(`/dashboard/home/payment-information/${instalment?.payment?.id}`);
                                                        }}
                                                        type='link'>View My Payment</Button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </>
            }
            <Drawer
                title="Bank Transfer"
                width={720}
                onClose={() => setOpenBankTransferDrawer(false)}
                open={openBankTransferDrawer}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                rootClassName='instalment_pay_drawer'
            >
                <div className='instalment_submit'>
                    <h4 className='header_3'>Submit Payment Slip</h4>
                    <p>
                        You have selected to pay via bank transfer. Please submit your payment slip here before the due date. We will verify your payment and confirm your booking.
                    </p>
                    <p><starong>Instalment Id: </starong>{selectedInstalment?.instalmentId}</p>
                    <h5 className='payment_due_date'>
                        Payment Date: {calculateDue(selectedInstalment?.paymentDate, 0)}
                    </h5>
                    <h5 className='payment_due_date'>
                        Due Date: {calculateDue(selectedInstalment?.dueDate, 0)}
                    </h5>
                    <h5 className='payment_due_date'>
                        Amount: {selectedInstalment?.amount && convertCurrency(userTour?.amount, selectedCurrency, currencyRates)} {selectedCurrency}
                    </h5>
                    <Divider />
                    <Dragger
                        fileList={fileList}
                        onChange={({ fileList }) => setFileList(fileList)}
                        beforeUpload={
                            (file) => {
                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';

                                const isSizeValid = file.size / 1024 / 1024 < 2;

                                if (!isSizeValid) {
                                    setFileList([]);
                                    api.error({
                                        message: 'File too large',
                                        description: 'Please upload a file less than 2MB'
                                    })
                                    return Upload.LIST_IGNORE;
                                } else if (!isJpgOrPng) {
                                    setFileList([]);
                                    api.error({
                                        message: 'Unsupported file format',
                                        description: 'Please upload only JPG, PNG or PDF files'
                                    })
                                    return Upload.LIST_IGNORE;
                                } else {
                                    return false;
                                }
                            }}
                        maxCount={1}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            Click or drag payment slip to this area to upload
                        </p>
                        <p className="ant-upload-hint">
                            Please upload a clear image of your payment slip. We accept JPG, PNG, PDF files. Upload only one file.
                        </p>
                    </Dragger>
                    <Button
                        type='primary'
                        onClick={handleCreatePaymentInstalment}
                        loading={loadingSubmit}
                        style={{
                            marginTop: '10px',
                            width: '100%'
                        }}
                    >
                        Submit Payment Slip
                    </Button>

                    <Divider />
                    <h3 className='header_3' style={{ marginBottom: '10px' }}>Bank Details</h3>
                    <div className='bank_details_container'>
                        <Tabs
                            defaultActiveKey="1"
                            items={[
                                {
                                    key: '1',
                                    label: 'AUD',
                                    children: <div className='bank_details'>
                                        <p>Account Name: <strong>VISIPEARL.COM</strong></p>
                                        <p>BSB: <strong>064-437</strong></p>
                                        <p>Account Number: <strong>1011 6377</strong></p>
                                        <p>Reference: <strong>Booking ID ({userTour?.id})</strong></p>
                                    </div>
                                },
                                {
                                    key: '2',
                                    label: 'USD',
                                    children: <div className='bank_details'>
                                        <p>Account Name: <strong>VISIPEARL.COM</strong></p>
                                        <p>BSB: <strong>064-437</strong></p>
                                        <p>Account Number: <strong>1011 6385</strong></p>
                                        <p>Reference: <strong>Booking ID ({userTour?.id})</strong></p>
                                    </div>
                                }
                            ]}
                        />
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default BookingPayments