import { Button, DatePicker, Divider, Form, InputNumber, Radio } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import './styles.scss'
import moment from 'moment'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import usePageSettings from '../../../../../hooks/usePageSettings'
import { TAX_PERCENTAGE } from '../../../../../constants/finance'

const DateAndQuentity = ({
    userTourData,
    setUserTourData,
    tourPackage,
    messageApi,
    prev,
    next,
    calculateTotal,
    calculateSubTotal,
    calculateDiscount,
    calculateTax,
    current,
}) => {
    const [availableVehicleOptions, setAvailableVehicleOptions] = useState([])
    const formRef = useRef(null)
    const selectedCurrency = usePageSettings()?.selectedCurrency;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const totalMembers = userTourData?.adults + userTourData?.children + userTourData?.infants;
        if (totalMembers > 0 && totalMembers < 3) {
            setAvailableVehicleOptions(vehicleOpetions.slice(0, 4))
        } else if (totalMembers > 2 && totalMembers < 7) {
            setAvailableVehicleOptions(vehicleOpetions[3])
        } else if (totalMembers > 6 && totalMembers < 13) {
            setAvailableVehicleOptions(vehicleOpetions[4])
        }
    }, [userTourData])


    const onDateChange = (date) => {
        date = date ? date.format('YYYY-MM-DD') : null
        setUserTourData({ ...userTourData, startDate: date })
    }

    const getMinDate = () => {
        const today = moment()
        const minDate = today.add(2, 'weeks')
        return minDate
    }

    const onQuentityChange = (value, type) => {
        const currentAllQuentity = userTourData.adults + userTourData.children + userTourData.infants
        const maxGroupSize = tourPackage?.maxGroupSize
        const newQuentity = currentAllQuentity

        formRef.current.resetFields()

        if (newQuentity > maxGroupSize) {
            messageApi.open({
                type: 'error',
                message: 'Maximum group size exceeded',
                description: `You can only book a maximum of ${maxGroupSize} people.`,
            })
            return
        }

        if (type === 'adults') {
            setUserTourData({ ...userTourData, adults: value, vehicleType: null })
        } else if (type === 'children') {
            setUserTourData({ ...userTourData, children: value, vehicleType: null })
        } else if (type === 'infants') {
            setUserTourData({ ...userTourData, infants: value, vehicleType: null })
        }
    }

    const handleGoNext = () => {
        // check if all the fields are filled
        if (!userTourData.startDate) {
            messageApi.open({
                type: 'error',
                message: 'Start Date is required',
                description: 'Please select a start date for the tour.',
            })
            return
        }
        if (!userTourData.roomType) {
            messageApi.open({
                type: 'error',
                message: 'Room Type is required',
                description: 'Please select a room type for the tour.',
            })
            return
        }
        if (!userTourData.vehicleType) {
            messageApi.open({
                type: 'error',
                message: 'Vehicle Type is required',
                description: 'Please select a vehicle type for the tour.',
            })
            return
        }
        if (userTourData.adults === 0 && userTourData.children === 0 && userTourData.infants === 0) {
            messageApi.open({
                type: 'error',
                message: 'No members added',
                description: 'Please add at least one member to the tour.',
            })
            return
        }

        next()
    }

    const vehicleOpetions = [
        <Radio
            className={`room_type`}
            value='Hatchback'
        >
            <div className='flex'>
                <div className='image'>
                    <LazyLoadImage
                        src='https://visitpearlsharedblob.blob.core.windows.net/appassets/vehicleTypeHatchback.webp'
                        alt='vehicle'
                        effect='blur'
                    />
                </div>
                <div className='texts'>
                    <h5>
                        Hatchback (2 persons)
                    </h5>
                    <p>
                        Hatchback is a small car with 3 passenger seats and 1 driver seat. It is suitable for a small group of 2 with 2 luggage. <br />
                        <strong>
                            Toyota Aqua, Suzuki Swift, Honda Fit, etc.
                        </strong>
                    </p>
                </div>
            </div>
            {/* <div className='right'>
                <h4>{tourPackage?.vehicleHatchback} AUD &#215; 1</h4>
            </div> */}
        </Radio>,

        <Radio
            className={`room_type`}
            value='Sedan'
        >
            <div className='flex'>
                <div className='image'>
                    <LazyLoadImage
                        src='https://visitpearlsharedblob.blob.core.windows.net/appassets/vehicleTypeSedan.webp'
                        alt='vehicle'
                        effect='blur'
                    />
                </div>
                <div className='texts'>
                    <h5>
                        Sedan (2 persons)
                    </h5>
                    <p>
                        Sedan is a large car with 3 passenger seats and 1 driver seat. It is suitable for a small group of 2 with 2 luggage.
                        <br />
                        <strong>
                            Toyota Axio, Toyota Allion, Toyota Premio, etc.
                        </strong>
                    </p>
                </div>
            </div>
            {/* <div className='right'>
                <h4>{tourPackage?.vehicleSedan} AUD &#215; 1</h4>
            </div> */}
        </Radio>,

        <Radio
            className={`room_type`}
            value='Suv'
        >
            <div className='flex'>
                <div className='image'>
                    <LazyLoadImage
                        src='https://visitpearlsharedblob.blob.core.windows.net/appassets/vehicleTypeSuv.webp'
                        alt='vehicle'
                        effect='blur'
                    />
                </div>
                <div className='texts'>
                    <h5>
                        SUV (2 persons)
                    </h5>
                    <p>
                        SUV is a large sizer four wheel drive vehicle with 3 passenger seats and 1 driver seat. It is suitable for a small group of 2 with 3 luggage and a child.
                        <br />
                        <strong>
                            Toyota Rav4, Nissan X-Trail, Honda Vezel, etc.
                        </strong>
                    </p>
                </div>
            </div>
            {/* <div className='right'>
                <h4>{tourPackage?.vehicleSuv} AUD &#215; 1</h4>
            </div> */}
        </Radio>,

        <Radio
            className={`room_type`}
            value='Van'
        >
            <div className='flex'>
                <div className='image'>
                    <LazyLoadImage
                        src='https://visitpearlsharedblob.blob.core.windows.net/appassets/vehicleTypeVan.webp'
                        alt='vehicle'
                        effect='blur'
                    />
                </div>
                <div className='texts'>
                    <h5>
                        Van (6 persons)
                    </h5>
                    <p>
                        Van is a large vehicle with 6 passenger seats and 1 driver seat. It is suitable for a group of 6 with 6 luggage.
                        <br />
                        <strong>
                            Toyota KDH, Nissan Caravan, Toyota Hiace, etc.
                        </strong>
                    </p>
                </div>
            </div>

            {/* <div className='right'>
                <h4>{tourPackage?.vehicleVan} AUD &#215; 1</h4>
            </div> */}
        </Radio>,

        <Radio
            className={`room_type`}
            value='Coster'
        >
            <div className='flex'>
                <div className='image'>
                    <LazyLoadImage
                        src='https://visitpearlsharedblob.blob.core.windows.net/appassets/vehicleTypeCoster.webp'
                        alt='vehicle'
                        effect='blur'
                    />
                </div>
                <div className='texts'>
                    <h5>
                        Coaster (12 persons)
                    </h5>
                    <p>
                        Coaster is a large vehicle with 12 passenger seats and 1 driver seat. It is suitable for a group of 12 with 12 luggage.
                        <br />
                        <strong>
                            Toyota Coaster, Nissan Civilian, etc.
                        </strong>
                    </p>
                </div>
            </div>

            {/* <div className='right'>
                <h4>{tourPackage?.VehicleCoster} AUD &#215; 1</h4>
            </div> */}
        </Radio>
    ]

    return (
        <>
            <div className='contents'>
                <section className='booking_input_section'>
                    <h2 className='heder_2'>
                        Select tour start date
                    </h2>
                    <div className='calander_wrapper'>
                        <p className='note'>
                            <span>Note: </span>
                            Select a date at least <strong>2 weeks</strong> from today.
                        </p>
                        <DatePicker
                            style={{ width: '200px' }}
                            onChange={onDateChange}
                            disabledDate={(current) => current && current < getMinDate()}
                            defaultValue={userTourData?.startDate && moment(userTourData?.startDate)}
                            showNow={false}
                        />
                    </div>

                    <Divider />

                    <h2 className='heder_2'>
                        How many people are traveling?
                    </h2>
                    <div className='note_Sub'>Maximum Group Size: {tourPackage?.maxGroupSize}</div>
                    <div className='wrapper'>
                        <div className='item'>
                            <span>
                                <h3>Adults</h3>
                                <h4>Age 12 +</h4>
                            </span>
                            <InputNumber
                                min={1}
                                max={tourPackage?.maxGroupSize}
                                defaultValue={userTourData?.adults}
                                onChange={(value) => { onQuentityChange(value, 'adults') }}
                            />
                        </div>

                        <div className='item'>
                            <span>
                                <h3>Children</h3>
                                <h4>Age 2 - 11</h4>
                            </span>
                            <InputNumber
                                min={0}
                                max={tourPackage?.maxGroupSize}
                                defaultValue={userTourData?.children}
                                onChange={(value) => onQuentityChange(value, 'children')}
                            />
                        </div>

                        <div className='item'>
                            <span>
                                <h3>Infants</h3>
                                <h4>Age 1 & below</h4>
                            </span>
                            <InputNumber
                                min={0}
                                max={tourPackage?.maxGroupSize}
                                defaultValue={userTourData?.infants}
                                onChange={(value) => onQuentityChange(value, 'infants')}
                            />
                        </div>
                    </div>

                    <Divider />

                    <h2 className='heder_2'>
                        Select Preferred Room Type
                    </h2>

                    <div className='wrapper'>
                        <Radio.Group
                            onChange={(e) => {
                                setUserTourData({ ...userTourData, roomType: e.target.value })
                            }}
                            value={userTourData.roomType}
                        >
                            <Radio className='room_type' value='Single'>
                                <div className='inner_type'>
                                    <div className='left'>
                                        <h5>Single Room (1 person)</h5>
                                        <p>This room is for 1 person only. If you are traveling alone, this room is for you.</p>
                                    </div>
                                    {/* <div className='right'>
                                        <h4>{tourPackage?.roomSingle} AUD &#215; 1</h4>
                                    </div> */}
                                </div>
                            </Radio>

                            <Radio className='room_type' value='Double'>
                                <div className='left'>
                                    <h5>
                                        Standard double room (2 persons)
                                    </h5>
                                    <p>
                                        This room is for 2 persons. If you are traveling with a partner, or if you are traveling with a child, this room is for you.
                                    </p>
                                </div>
                                {/* <div className='right'>
                                    <h4>{tourPackage?.roomDouble} AUD &#215; 1</h4>
                                </div> */}
                            </Radio>

                            <Radio className='room_type' value='Triple'>
                                <div className='left'>
                                    <h5>
                                        Standard triple room (3 persons)
                                    </h5>
                                    <p>
                                        This room is for 3 persons. If you are traveling with a group of 3, this room is for you.
                                    </p>
                                </div>
                                {/* <div className='right'>
                                    <h4>{tourPackage?.roomTriple} AUD &#215; 1</h4>
                                </div> */}
                            </Radio>

                            <Radio className='room_type' value='Family'>
                                <div className='left'>
                                    <h5>
                                        Family Room (4 persons)
                                    </h5>
                                    <p>
                                        This room is for a family of 4. If you are traveling with a family of 4, this room is for you.
                                    </p>
                                </div>
                                {/* <div className='right'>
                                    <h4>{tourPackage?.roomFamily} AUD &#215; 1</h4>
                                </div> */}
                            </Radio>
                        </Radio.Group>
                    </div>

                    <Divider />

                    <Form
                        hideRequiredMark
                        ref={formRef}
                    >
                        <h2 className='heder_2'>
                            Select Prefered Vehicle Type
                        </h2>

                        <div className='wrapper'>
                            <Form.Item
                                name='vehicleType'
                            >
                                <Radio.Group
                                    onChange={(e) => {
                                        setUserTourData({ ...userTourData, vehicleType: e.target.value })
                                    }}
                                    defaultValue={userTourData?.vehicleType}
                                >
                                    {availableVehicleOptions}
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </Form>

                </section>
            </div>

            <div className='booking_pricing'>
                <h2 className='heder_2'>Package Details</h2>

                <div className='pricing_item date'>
                    <div><strong> Start Date: </strong>{userTourData?.startDate || <span style={{ color: '#F45713' }}>Please Select</span>}</div>
                    <div><strong>Room Type: </strong>{userTourData?.roomType || <span style={{ color: '#F45713' }}>Please Select</span>}</div>
                    <div><strong>Vehicle Type: </strong>{userTourData?.vehicleType || <span style={{ color: '#F45713' }}>Please Select</span>}</div>
                </div>

                <Divider />

                <div className='pricing_item quentity'>
                    <div className='quentity'><h5>Adults: </h5> <p> &#215; {userTourData?.adults}</p></div>
                    <div className='quentity'><h5>Children: </h5> <p> &#215; {userTourData?.children}</p></div>
                    <div className='quentity'><h5>Infants: </h5> <p> &#215; {userTourData?.infants}</p></div>
                </div>

                <Divider />

                <div className='total'>
                    <div>
                        <h4>
                            Sub Total:
                        </h4>
                        <p>{calculateSubTotal()} {selectedCurrency}</p>
                    </div>
                    {
                        tourPackage?.discount &&
                        <div>
                            <h4>
                                Discount:
                            </h4>
                            <p>
                                {tourPackage?.discount}%
                            </p>
                        </div>
                    }
                    <div>
                        <h4>
                            Service Charge:
                        </h4>
                        <p>{TAX_PERCENTAGE}%</p>
                    </div>

                    <Divider />

                    <div><h5>Total: </h5>
                        <h6>
                            {
                                calculateTotal()
                            } {selectedCurrency}
                        </h6>
                    </div>
                </div>

                <div className='actions'>
                    {current < 5 - 1 && (
                        <Button type="primary" className='next' onClick={handleGoNext}>
                            Next
                        </Button>
                    )}
                    {current === 5 - 1 && (
                        <Button type="primary" className='next'>
                            Done
                        </Button>
                    )}
                    {current > 0 && (
                        <Button onClick={() => prev()} className='prev'>
                            Previous
                        </Button>
                    )}
                </div>
            </div>
        </>
    )
}

export default DateAndQuentity