import React from 'react'

const ServiceGuarantee = () => {
    return (
        <div className='support section_main' style={{ marginBottom: '60px' }}>
            <div className='top_padding_container'>
                <h1 className='header_1'>
                    Service Guarantee
                </h1>
            </div>
        </div>
    )
}

export default ServiceGuarantee