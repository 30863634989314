export const PAYMENT_POLICIES = {
    description: 'We aim to provide a transparent and fair pricing structure for our tours, ensuring that you have all the necessary information before booking. Please review our charges and cancellation policies below to understand the terms and conditions that apply to your booking.',
    charges: [
        {
            title: 'Tour Package Price',
            description: 'The price of the tour package is inclusive of all taxes and charges. The price does not include any additional services or activities that are not mentioned in the itinerary.',
        },
        {
            title: 'Single Supplement',
            description: 'A single supplement is applicable for solo travelers who wish to have a private room. The single supplement charge is in addition to the tour package price.',
        },
        {
            title: 'Optional Add-ons',
            description: 'Optional add-ons such as extra activities, excursions, or services are available at an additional cost. Please contact us for more information on optional add-ons.',
        },
        {
            title: 'Airport Transfers',
            description: 'Airport transfers are included in the tour package price. Please provide us with your flight details at least 48 hours before your arrival to ensure a smooth transfer process.',
        },
        {
            title: 'Travel Insurance',
            description: 'Travel insurance is not included in the tour package price. We recommend that you purchase travel insurance to protect yourself against unforeseen circumstances such as trip cancellations, delays, or medical emergencies.',
        },
        {
            title: 'Booking',
            description: 'To confirm your booking, a non-refundable deposit is required. The remaining balance is due 30 days before the tour departure date. If you book within 30 days of the departure date, the full payment is required at the time of booking.',
        }
    ]
}

export const GENERAL_POLICIES = {
    description: 'We are committed to providing you with a safe and enjoyable travel experience. Please review our general policies below to ensure that you have all the necessary information before booking.',
    policies: [
        {
            title: 'Child Policy',
            description: 'Children under the age of 12 are welcome on our tours. Please contact us for more information on child rates and availability.',
        },
        {
            title: 'Pet Policy',
            description: 'Pets are not allowed on our tours. Service animals are welcome with prior approval.',
        },
        {
            title: 'Smoking Policy',
            description: 'Smoking is not allowed on our tours. Smoking is only permitted in designated areas during rest stops.',
        },
        {
            title: 'Alcohol Policy',
            description: 'Alcohol consumption is allowed on our tours. Please drink responsibly and be considerate of other passengers.',
        },
        {
            title: 'Photography Policy',
            description: 'Photography is allowed on our tours. Please respect the privacy of other passengers and ask for permission before taking photos of them.',
        },
        {
            title: 'Lost and Found Policy',
            description: 'If you lose any personal belongings during the tour, please inform the tour guide immediately. We will do our best to locate and return your lost items to you.',
        },
        {
            title: 'Changes to Itinerary',
            description: 'We reserve the right to make changes to the itinerary due to unforeseen circumstances such as weather conditions, road closures, or other factors beyond our control. We will do our best to inform you of any changes as soon as possible.',
        },
        {
            title: 'Travel Documents',
            description: 'Please ensure that you have all the necessary travel documents such as passports, visas, and travel insurance before your departure. It is your responsibility to ensure that you have the correct documents for your trip.',
        },
        {
            title: 'Health and Safety',
            description: 'Your health and safety are our top priorities. Please inform us of any medical conditions, allergies, or dietary requirements before your departure. We will do our best to accommodate your needs during the tour.',
        },
        {
            title: 'Responsibility',
            description: 'We are not responsible for any loss, damage, or injury that may occur during the tour. We recommend that you take out travel insurance to protect yourself against unforeseen circumstances.',
        }
    ]
}

export const CANCELLATION_POLICIES = {
    description: 'We understand that plans can change, and you may need to cancel your booking. Please review our cancellation policies below to understand the terms and conditions that apply to cancellations and refunds.',
    policies: [
        {
            title: 'Cancellation by You',
            description: 'If you need to cancel your booking, please inform us as soon as possible. The following cancellation charges will apply:',
            charges: [
                {
                    title: 'More than 30 days before departure',
                    description: 'Deposit is non-refundable',
                },
                {
                    title: '30-15 days before departure',
                    description: '50% of the total tour package price',
                },
                {
                    title: 'Less than 15 days before departure',
                    description: '100% of the total tour package price',
                }
            ]
        },
        {
            title: 'Cancellation by Us',
            description: 'We reserve the right to cancel the tour due to unforeseen circumstances such as weather conditions, road closures, or other factors beyond our control. In the event of a cancellation by us, you will receive a full refund of the tour package price.',
        },
        {
            title: 'Refunds',
            description: 'Refunds will be processed within 14 days of receiving your cancellation request. Refunds will be issued to the original payment method used for the booking.',
        }
    ]
}

export const SAFETY_AND_TRUST = {
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam purus velit, laoreet at justo ut, pretium rhoncus velit. Donec vel justo nisi. Curabitur vel tortor sit amet sem dignissim fringilla ac sed dolor. Praesent finibus, felis quis bibendum sollicitudin, diam sapien auctor urna, sed faucibus sapien est sed tortor. Maecenas bibendum, felis nec blandit hendrerit, metus velit sodales sapien, non pellentesque metus lorem eu dui. Fusce sit amet mattis purus, ut luctus augue. Morbi dictum quis mi pretium hendrerit. Mauris pellentesque, mi ut gravida cursus, ipsum nunc volutpat ex, quis tincidunt sapien sapien sit amet sem. ',
    policies: [
        {
            title: 'Health and Safety',
            description: 'Your health and safety are our top priorities. We follow all local health and safety guidelines to ensure that you have a safe and enjoyable travel experience.',
        },
        {
            title: 'Insurance',
            description: 'We recommend that you purchase travel insurance to protect yourself against unforeseen circumstances such as trip cancellations, delays, or medical emergencies.',
        },
        {
            title: 'Security',
            description: 'We take the security of our passengers seriously. We work with local authorities and security experts to ensure that you have a safe and secure travel experience.',
        },
        {
            title: 'Privacy',
            description: 'We respect your privacy and will not share your personal information with third parties without your consent. Please review our privacy policy for more information on how we collect and use your personal information.',
        },
        {
            title: 'Trust',
            description: 'We are committed to building trust with our passengers. We have a team of experienced tour guides and staff who are dedicated to providing you with a memorable travel experience.',
        }
    ]
}

export const FAQ_TOUR = {
    title: 'Frequently Asked Questions',
    questions: [
        {
            question: 'What is included in the tour package price?',
            answer: 'The tour package price includes accommodation, meals, transportation, and guided tours as specified in the itinerary. Please review the tour details for a complete list of inclusions.'
        },
        {
            question: 'What is the single supplement charge?',
            answer: 'The single supplement charge is applicable for solo travelers who wish to have a private room. The single supplement charge is in addition to the tour package price.'
        },
        {
            question: 'Are airport transfers included in the tour package price?',
            answer: 'Airport transfers are included in the tour package price. Please provide us with your flight details at least 48 hours before your arrival to ensure a smooth transfer process.'
        },
        {
            question: 'Is travel insurance included in the tour package price?',
            answer: 'Travel insurance is not included in the tour package price. We recommend that you purchase travel insurance to protect yourself against unforeseen circumstances such as trip cancellations, delays, or medical emergencies.'
        },
        {
            question: 'What is the booking process?',
            answer: 'To confirm your booking, a non-refundable deposit is required. The remaining balance is due 30 days before the tour departure date. If you book within 30 days of the departure date, the full payment is required at the time of booking.'
        }
    ]
}

export const FREE_SERVICES = [
    'Cras volutpat condimentum metus at malesuada. Morbi aliquet elit quis felis facilisis egestas. Nulla metus est, fringilla quis odio facilisis, malesuada imperdiet velit.',
    'Cras volutpat felis facilisis egestas. Nulla metus est, fringilla quis odio facilisis, malesuada imperdiet velit.',
    'Cras volutpat condimentum metus at malesuada. Morbi aliquet elit quis felis facilisis egestas. Nulla metus est, fringilla quis  velit.',
    'Cras volutpat condimentum facilisis egestas. Nulla metus est, fringilla quis odio facilisis, malesuada imperdiet velit.',
    'Cras volutpat condimentum metus at malesuada. Morbi aliquet elit quis felis facilisis egestas. Nulla esuada imperdiet velit.',
]