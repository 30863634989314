import React, { useState } from 'react'
import useMyProfile from '../../../../hooks/useMyProfile';
import { Button, Divider, Modal, Popconfirm } from 'antd';

import './styles.scss'
import { useMsal } from '@azure/msal-react';
import { deleteUserPermanently } from '../../../../../redux/api/userProfile.api';
import { useNavigate } from 'react-router-dom';
import { PASSWORD_RESET_REQUEST } from '../../../../azure/authConfig';

const DashboardSecurity = () => {
    const myProfile = useMyProfile();
    const { instance } = useMsal();
    const [isDeleteUserModalVisible, setIsDeleteUserModalVisible] = useState(false)
    const [deleteUserLoading, setDeleteUserLoading] = useState(false)
    const navigate = useNavigate();

    const handleLogout = async () => {
        instance.handleRedirectPromise()
            .then(() => {
                instance.logout();
            });
    };

    const deleteUser = async () => {
        setDeleteUserLoading(true)
        try {
            const res = await deleteUserPermanently(myProfile?.userProfile?.id)
            if (res?.status === 200) {
                setIsDeleteUserModalVisible(false)
                handleLogout()
                navigate('/')
            } else {
            }
        } catch (error) {
            console.error('Error deleting user', error)
        }
        setDeleteUserLoading(false)
    }

    const handlePasswordReset = () => {
        instance.loginRedirect(PASSWORD_RESET_REQUEST).catch(e => {
            console.error("Password reset failed:", e);
        });
    };

    return (
        <div className='personal_details'>
            <h1 className='heder_2'>Security</h1>
            <p className='about'>
                Manage your account security settings. You can change your password, sign out of all devices, or delete your account.
            </p>

            <div className='personal_details__container'>

                <Divider />

                <div className='personal_details__item'>
                    <div className='left'>
                        <h2 className='label'>Password</h2>
                        <h3 className='value'>
                            Reset your password regularly to keep your account secure.
                        </h3>
                    </div>
                    <Button
                        className='security_desktopEdit'
                        type='link'
                        onClick={handlePasswordReset}
                    >
                        Reset
                    </Button>
                </div>

                <Divider />

                <div className='personal_details__item'>
                    <div className='left'>
                        <h2 className='label'>Sign out</h2>
                        <h3 className='value'>
                            This will sign you out of all devices including the one you are currently using.
                        </h3>
                    </div>
                    <Popconfirm
                        title="Are you sure you want to sign out?"
                        description="This will sign you out of all devices including the one you are currently using."
                        onConfirm={handleLogout}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            className='security_desktopEdit'
                            type='link'
                        >
                            Sign out
                        </Button>
                    </Popconfirm>
                </div>

                <Divider />

                <div className='personal_details__item'>
                    <div className='left'>
                        <h2 className='label'>Delete Account</h2>
                        <h3 className='value'>
                            Permanately delete your account. This action cannot be undone.
                        </h3>
                    </div>
                    <Button
                        className='security_desktopEdit'
                        type='link'
                        onClick={() => setIsDeleteUserModalVisible(true)}
                        danger
                    >
                        Delete Account
                    </Button>
                </div>

                <Divider />
            </div>

            <Modal
                title="Are you sure you want to delete your account?"
                open={isDeleteUserModalVisible}
                onCancel={() => setIsDeleteUserModalVisible(false)}
                footer={null}
            >
                <p style={{
                    color: 'red',
                    fontSize: '18px',
                    marginBottom: '10px'
                }}>
                    This action is irreversible!
                </p>
                <p style={{
                    fontSize: '14px',
                    marginBottom: '10px'
                }}>
                    Please be sure before proceeding. Your account will be deleted permanently. If you have any ongoing bookings, they will be deletd as well (Not Cancelled). <strong>You will not be able to recover your account or bookings.</strong>
                </p>
                <div
                    className='user__delete__actions'
                    style={{
                        display: 'flex',
                        gap: '10px',
                    }}
                >
                    <Button
                        type='default'
                        onClick={() => setIsDeleteUserModalVisible(false)}
                    >
                        Cancel
                    </Button>

                    <Button type='primary' danger onClick={deleteUser} loading={deleteUserLoading}>
                        Delete User
                    </Button>
                </div>
            </Modal>
        </div>
    )
}

export default DashboardSecurity