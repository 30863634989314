import React, { useEffect } from 'react'
import { FREE_SERVICES } from '../../../../../constants/policies'
import { CheckCircleOutlined } from '@ant-design/icons'

import './styles.scss'
import { Button, Divider } from 'antd'
import { TAX_PERCENTAGE, TAX_RATE } from '../../../../../constants/finance'
import usePageSettings from '../../../../../hooks/usePageSettings'

const OtherServices = ({
    userTourData,
    setUserTourData,
    tourPackage,
    messageApi,
    prev,
    next,
    calculateTotal,
    calculateSubTotal,
    calculateDiscount,
    calculateTax,
    current,
}) => {
    const selectedCurrency = usePageSettings()?.selectedCurrency;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleGoNext = () => {
        // check if all the fields are filled
        if (!userTourData.startDate) {
            messageApi.open({
                type: 'error',
                message: 'Start Date is required',
                description: 'Please select a start date for the tour.',
            })
            return
        }
        if (!userTourData.roomType) {
            messageApi.open({
                type: 'error',
                message: 'Room Type is required',
                description: 'Please select a room type for the tour.',
            })
            return
        }
        if (!userTourData.vehicleType) {
            messageApi.open({
                type: 'error',
                message: 'Vehicle Type is required',
                description: 'Please select a vehicle type for the tour.',
            })
            return
        }
        if (userTourData.adults === 0 && userTourData.children === 0 && userTourData.infants === 0) {
            messageApi.open({
                type: 'error',
                message: 'No members added',
                description: 'Please add at least one member to the tour.',
            })
            return
        }
        next()
    }

    return (
        <>
            <div className='contents'>
                <section className='booking_input_section'>
                    <h2 className='heder_2'>
                        Free Services
                    </h2>
                    <div className='service_wrapper'>
                        {
                            FREE_SERVICES?.map((service, index) => (
                                <div key={index} className='service'>
                                    <CheckCircleOutlined />
                                    <p>{service}</p>
                                </div>
                            ))
                        }
                    </div>
                </section>

                <section className='booking_input_section sim_services'>
                    <h2 className='heder_2'>
                        Sim Card
                    </h2>
                    <p className='note'>
                        We provide a free sim card for every traveler. The sim card includes 5GB of data and 100 minutes of local calls.
                    </p>
                    <div className='service_wrapper'>
                        <div className='service'>
                            <CheckCircleOutlined />
                            <p>5GB of data</p>
                        </div>
                        <div className='service'>
                            <CheckCircleOutlined />
                            <p>100 minutes of local calls</p>
                        </div>
                    </div>
                </section>

                <section className='booking_input_section sim_services'>
                    <h2 className='heder_2'>
                        Traveler Insurance
                    </h2>
                    <p className='note'>
                        Traveler insurance is not available at the moment. We are working on it and will be available soon.
                    </p>
                    <p className='note red'>
                        Traveler insurance is not compulsory but we recommend you to have it.
                    </p>
                </section>
            </div>

            <div className='booking_pricing'>
                <h2 className='heder_2'>Package Details</h2>

                <div className='pricing_item date'>
                    <div><strong> Start Date: </strong>{userTourData?.startDate || <span style={{ color: '#F45713' }}>Please Select</span>}</div>
                    <div><strong>Room Type: </strong>{userTourData?.roomType || <span style={{ color: '#F45713' }}>Please Select</span>}</div>
                    <div><strong>Vehicle Type: </strong>{userTourData?.vehicleType || <span style={{ color: '#F45713' }}>Please Select</span>}</div>
                </div>

                <Divider />

                <div className='pricing_item quentity'>
                    <div className='quentity'><h5>Adults: </h5> <p> &#215; {userTourData?.adults}</p></div>
                    <div className='quentity'><h5>Children: </h5> <p> &#215; {userTourData?.children}</p></div>
                    <div className='quentity'><h5>Infants: </h5> <p> &#215; {userTourData?.infants}</p></div>
                </div>

                <Divider />

                <div className='total'>
                    <div>
                        <h4>
                            Sub Total:
                        </h4>
                        <p>{calculateSubTotal()} {selectedCurrency}</p>
                    </div>
                    {
                        tourPackage?.discount &&
                        <div>
                            <h4>
                                Discount:
                            </h4>
                            <p>
                                {tourPackage?.discount}%
                            </p>
                        </div>
                    }
                    <div>
                        <h4>
                            Service Charge:
                        </h4>
                        <p>{TAX_PERCENTAGE}%</p>
                    </div>

                    <Divider />

                    <div><h5>Total: </h5>
                        <h6>
                            {
                                calculateTotal()
                            } {selectedCurrency}
                        </h6>
                    </div>
                </div>

                <div className='actions'>
                    {current < 5 - 1 && (
                        <Button type="primary" className='next' onClick={handleGoNext}>
                            Next
                        </Button>
                    )}
                    {current === 5 - 1 && (
                        <Button type="primary" className='next'>
                            Done
                        </Button>
                    )}
                    {current > 0 && (
                        <Button onClick={() => prev()} className='prev'>
                            Previous
                        </Button>
                    )}
                </div>
            </div>
        </>
    )
}

export default OtherServices