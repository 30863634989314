import { Breadcrumb, Button, Collapse, message, notification, Rate, Tag } from 'antd'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAccommodationPackageById } from '../../../../redux/api/accommodations.api'
import { useMsal } from '@azure/msal-react'
import useMyProfile from '../../../hooks/useMyProfile'
import LoadingComp from '../../../components/LoadingComp'
import { CaretRightOutlined, HeartOutlined, HeartTwoTone, HomeFilled, HomeOutlined, ShareAltOutlined } from '@ant-design/icons'
import { addToFavourites, deleteFavourite, getFavouriteById } from '../../../../redux/api/favourite.api'
import { FAV_ACCOMMODATION } from '../../../constants/favTypes'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { DEFAULT_THUMBNAIL } from '../../../constants/otherConstsnts'
import ReactQuill from 'react-quill'
import { capitalizeFisrtLetterEachWord } from '../../../helpers/nameRender'
import { FaBed, FaHotel, FaSignInAlt, FaSignOutAlt, FaUtensils } from 'react-icons/fa'

import './styles.scss'
import { FaLocationDot } from 'react-icons/fa6'
import { Card } from 'react-bootstrap'
import { getFacilityIcon } from '../../../helpers/iconsRender';
import usePageSettings from '../../../hooks/usePageSettings';
import useCurrencyRates from '../../../hooks/useCurrencyRates';
import { convertCurrency } from '../../../helpers/currencyConverter';
import { BiCart } from 'react-icons/bi';
import { LOGIN_REQUEST } from '../../../azure/authConfig';

const { Panel } = Collapse;

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};


const AccommodationPackage = () => {
    const url = window.location.pathname
    const packageId = url.split('/').pop()
    const [loading, setLoading] = useState(true)
    const selectedCurrency = usePageSettings()?.selectedCurrency;
    const currencyRates = useCurrencyRates()?.currencyRates
    const [accommodationPackage, steAccommodationPackage] = useState()
    const [redMoreOpen, setReadMoreOpen] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const [loadingFavourite, setLoadingFavourite] = useState(false)
    const [isFavourite, setIsFavourite] = useState(null)
    const navigate = useNavigate()
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const myProfile = useMyProfile();
    const [loadingBook, setLoadingBook] = useState(false)

    const location = useLocation();

    useEffect(() => {
        console.log('Current location:', location);
    }, [location]);


    useEffect(() => {
        window.scrollTo(0, 0)
        if (!packageId) {
            navigate('/accommodations')
        }

        handleGetAccommodationPackage()
    }, [])

    const handleGetAccommodationPackage = async () => {
        setLoading(true)
        const response = await getAccommodationPackageById(packageId)
        if (response.status === 200) {
            steAccommodationPackage(response.data)
        } else {
            messageApi.error('Failed to fetch accommodation package')
        }
        setLoading(false)
    }

    const copyLinkToClipboard = () => {
        const url = window.location.href
        navigator.clipboard.writeText(url)
        messageApi.open({
            type: 'success',
            content: 'Link copied to clipboard',
            duration: 2,
        });
    }

    const handleOnClikFavourite = async () => {
        setLoadingFavourite(true)
        if (isFavourite) {
            await deleteFavourite(isFavourite.id)
            setIsFavourite(null)
            messageApi.open({
                type: 'success',
                content: 'Removed from favourites',
                duration: 2,
            });
        } else {
            await addToFavourites({
                userId: myProfile?.userProfile?.id,
                favouriteId: packageId,
                favouriteType: FAV_ACCOMMODATION
            })
            await handleCheckIsFavourite()
            messageApi.open({
                type: 'success',
                content: 'Added to favourites',
                duration: 2,
            });
        }
        setLoadingFavourite(false)
    }

    const handleCheckIsFavourite = async () => {
        setLoadingFavourite(true)
        try {
            const response = await getFavouriteById(packageId)
            setIsFavourite(response.data)
        } catch (error) {
            console.error('Error checking favourite', error)
        }
        setLoadingFavourite(false)
    }

    const handleBookNow = () => {
        if (!activeAccount) {
            setLoadingBook(true)
            handleLoginRedirect()
        } else {
            navigate(`/accommodations/${packageId}/book`)
        }
    }

    const handleLoginRedirect = () => {
        instance.handleRedirectPromise()
            .then((response) => {
                if (response) {
                    instance.setActiveAccount(response.account);
                    navigate(`/accommodations/${packageId}/book`)
                } else {
                    instance.loginRedirect(LOGIN_REQUEST)
                        .catch((e) => {
                            console.error('User Login Error', e);
                        });
                }
            })
            .catch((error) => {
                console.error('Redirect Handling Error', error);
            });
    }

    const renderImages = (images) => {
        if (images?.length > 2) {
            const moreImages = images.slice(2)
            return (
                <div className='images_container more_than_two'>
                    <PhotoView src={images[0]}>
                        <LazyLoadImage
                            alt='image'
                            effect="blur"
                            wrapperProps={{
                                style: { transitionDelay: "0.1s" },
                            }}
                            src={images[0]}
                        />
                    </PhotoView>
                    <PhotoView src={images[1]}>
                        <LazyLoadImage
                            alt='image'
                            effect="blur"
                            wrapperProps={{
                                style: { transitionDelay: "0.1s" },
                            }}
                            src={images[1]}
                        />
                    </PhotoView>
                    <div className='more_images'>
                        {
                            moreImages.map((image, index) => (
                                <PhotoView key={index} src={image}>
                                    <LazyLoadImage
                                        alt='image'
                                        effect="blur"
                                        wrapperProps={{
                                            style: { transitionDelay: "0.1s" },
                                        }}
                                        src={image}
                                    />
                                </PhotoView>
                            ))
                        }
                    </div>
                </div>
            )
        } else {
            return (
                <div className='images_container'>
                    {
                        images?.map((image, index) => (
                            <PhotoView key={index} src={image}>
                                <LazyLoadImage
                                    alt='image'
                                    effect="blur"
                                    wrapperProps={{
                                        style: { transitionDelay: "0.1s" },
                                    }}
                                    src={image}
                                />
                            </PhotoView>
                        ))
                    }
                </div>
            )
        }
    }

    const descriptionCount = (description) => {
        let count = window.innerWidth > 1024 ? 1000 : 300
        return !redMoreOpen ? (description?.length > 200 ? description.slice(0, count) + '...' : description) : description
    }

    return (
        <>
            {
                !loading ?
                    <div className='main__contaier accommodationPackage section_main'>
                        {contextHolder}
                        <div className='hero top_margin_main'>
                            <Breadcrumb
                                className='breadcrumb_pages'
                                items={[
                                    {
                                        title: <div className='breadcrumb__item' onClick={() => navigate('/')}><HomeOutlined /> Home</div>,
                                    },
                                    {
                                        title: <div className='breadcrumb__item' onClick={() => navigate('/accommodations')} >Accommodations</div>,
                                    },
                                    {
                                        title: <div className='breadcrumb__item last' >
                                            {loading ? 'Loading...' : accommodationPackage?.accommodationPackage?.packageName}
                                        </div>,
                                    },
                                ]}
                            />
                            <div className='hero__content_header'>
                                <h1 data-aos="fade-up" data-aos-delay="300" className='header_1 align_start'>
                                    {loading ? 'Loading...' : accommodationPackage?.accommodationPackage?.packageName}
                                </h1>

                                <div className='actions'>
                                    <Button onClick={copyLinkToClipboard} className='button dark' type='link'><ShareAltOutlined /></Button>
                                    <Button
                                        className='button'
                                        type='link'
                                        loading={loadingFavourite}
                                        onClick={handleOnClikFavourite}
                                    >
                                        {isFavourite
                                            ?
                                            <HeartTwoTone twoToneColor="#eb2f96" />
                                            :
                                            <HeartOutlined />}
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className='image_gallery'>
                            <PhotoProvider>
                                <div className='left'>
                                    <PhotoView src={accommodationPackage?.accommodation?.thumbnailUrl}>
                                        <LazyLoadImage
                                            alt='image'
                                            effect="blur"
                                            wrapperProps={{
                                                style: { transitionDelay: "0.1s" },
                                            }}
                                            src={accommodationPackage && (accommodationPackage?.accommodation?.thumbnailUrl || DEFAULT_THUMBNAIL)}
                                        />
                                    </PhotoView>
                                </div>
                                <div className='right'>
                                    {
                                        renderImages(accommodationPackage?.accommodationPackage?.packageImages?.split(','))
                                    }
                                </div>
                                <div className='total'>
                                    <span>{accommodationPackage?.accommodationPackage?.packageImages?.split(',').length + (accommodationPackage?.accommodationPackage?.thumbnailUrl ? 1 : 0)} photos</span>
                                </div>
                            </PhotoProvider>
                        </div>

                        <div className='tour_package_content_body'>
                            <div className='content__container'>
                                <section className='sec__1'>
                                    <div className='tags'>
                                        {
                                            accommodationPackage?.accommodationPackage?.accommodationTags?.split(',').map((tag, index) => (
                                                <Tag key={index} className='tag'>{tag}</Tag>
                                            ))
                                        }
                                    </div>

                                    <div className='short_details'>
                                        <div className='row_cus'>
                                            <div className='col_cus'>
                                                <div className='title'><FaHotel /> Hotel Type:</div>
                                                <div className='value'>{accommodationPackage?.accommodationPackage?.accommodationType &&
                                                    capitalizeFisrtLetterEachWord(accommodationPackage?.accommodationPackage?.accommodationType)} </div>
                                            </div>

                                            <div className='col_cus'>
                                                <div className='title'>< HomeFilled /> Room Type:</div>
                                                <div className='value'>{accommodationPackage?.accommodationPackage?.packageRooms &&
                                                    capitalizeFisrtLetterEachWord(accommodationPackage?.accommodationPackage?.packageRooms)} </div>
                                            </div>

                                            <div className='col_cus'>
                                                <div className='title'>< FaLocationDot /> City:</div>
                                                <div className='value'>{accommodationPackage?.accommodationPackage?.accommodationCity &&
                                                    capitalizeFisrtLetterEachWord(accommodationPackage?.accommodationPackage?.accommodationCity)} </div>
                                            </div>
                                        </div>

                                        <div className='row_cus'>
                                            <div className='col_cus prem'>
                                                <div className='value'>{accommodationPackage?.accommodationPackage?.packageType &&
                                                    capitalizeFisrtLetterEachWord(accommodationPackage?.accommodationPackage?.packageType)} </div>
                                            </div>

                                            <div className='col_cus'>
                                                <Rate
                                                    disabled
                                                    allowHalf
                                                    defaultValue={accommodationPackage?.accommodationPackage?.accommodationRating}
                                                /> <span className='rating'>{accommodationPackage?.accommodationPackage?.accommodationRating} Star Rating</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='about'>
                                        <h2 className='heder_2'>
                                            About this package
                                        </h2>
                                        <ReactQuill
                                            value={accommodationPackage?.accommodationPackage?.packageDescription}
                                            readOnly={true}
                                            theme='bubble'
                                        />
                                    </div>

                                    <div className='highlights'>
                                        <h2 className='heder_2'>
                                            Facilities
                                        </h2>
                                        <div className='list'>
                                            {
                                                accommodationPackage?.accommodationPackage?.packageFacilities?.split(',')?.map((highlight, index) => (
                                                    <p key={index}>{
                                                        highlight && capitalizeFisrtLetterEachWord(highlight)
                                                    }</p>
                                                ))
                                            }
                                        </div>
                                    </div>

                                    {accommodationPackage?.accommodationPackage?.packageMeals &&
                                        <div className='highlights meals'>
                                            <h2 className='heder_2'>
                                                Meals Included
                                            </h2>
                                            <div className='list'>
                                                {
                                                    accommodationPackage?.accommodationPackage?.packageMeals?.split(',')?.map((highlight, index) => (
                                                        <p key={index}>{
                                                            highlight && capitalizeFisrtLetterEachWord(highlight)
                                                        }</p>
                                                    ))
                                                }
                                            </div>
                                        </div>}
                                </section>

                                <section className='sec__2'>
                                    <h2 className='heder_2'>
                                        About {accommodationPackage?.accommodation?.accommodationName}
                                    </h2>
                                    <div className='slider'>
                                        <Carousel
                                            swipeable={true}
                                            draggable={false}
                                            showDots={true}
                                            responsive={responsive}
                                            ssr={true}
                                            infinite={false}
                                            autoPlaySpeed={1000}
                                            keyBoardControl={true}
                                            customTransition="all .5"
                                            transitionDuration={500}
                                            containerClass="carousel-container"
                                            dotListClass="custom-dot-list-style"
                                            itemClass="carousel-item-padding-40-px"
                                        >
                                            {
                                                accommodationPackage?.accommodation?.accommodationImages?.split(',')?.map((acc, index) => (
                                                    <Card key={index} className='destination_card'>
                                                        <Card.Img variant="top" src={acc} className='card_image' />
                                                    </Card>
                                                ))
                                            }
                                        </Carousel>
                                    </div>

                                    <div className='more_about_destination__content' >
                                        <ReactQuill
                                            className='day_description'
                                            value={descriptionCount(accommodationPackage?.accommodation?.accommodationDescription)}
                                            readOnly={true}
                                            theme='bubble'
                                        />
                                        <Button onClick={() => setReadMoreOpen(!redMoreOpen)} className='button less' type='link'>
                                            {
                                                redMoreOpen ? 'Read Less' : 'Read More...'
                                            }
                                        </Button>
                                    </div>

                                    <div className='accommodationFacilities'>
                                        <h2 className='heder_2'>
                                            More Facilities
                                        </h2>
                                        <div className='facilities'>
                                            {
                                                accommodationPackage?.accommodationFacilities?.map((faci, index) => (
                                                    <div key={index} className='facility'>
                                                        <div className='icon'>{faci.facilityType && getFacilityIcon(faci.facilityType)}</div>
                                                        <div className='title'>{faci.facilityName}</div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </section>

                                <section className='sec__3'>
                                    <h2 className='heder_2'>
                                        Your Favourite Meals & Drinks
                                    </h2>

                                    <div className='meals'>
                                        {
                                            accommodationPackage?.accommodationMeals?.map((meal, index) => (
                                                <div key={index} className='meal'>
                                                    <div className='meal_info'>
                                                        <div className='title'>{meal.mealName}</div>
                                                        <div className='icon'>{meal.mealType && capitalizeFisrtLetterEachWord(meal.mealType)}</div>
                                                        <div className='descriptoin'>{meal.mealDescription}</div>
                                                    </div>

                                                    <div className='price'>
                                                        <h2>{meal.mealPrice ? convertCurrency(meal?.mealPrice, selectedCurrency, currencyRates) : 'N/A'}</h2>
                                                        <p>
                                                            {selectedCurrency || 'AUD'}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </section>

                                <section className='sec__4'>
                                    <h2 className='heder_2'>House Rules</h2>
                                    <div className='house-rules'>
                                        <div className='check-times'>
                                            <div className='check-time'>
                                                <FaSignInAlt />
                                                <div>
                                                    <h4>Check-in Time</h4>
                                                    <p>{accommodationPackage?.accommodationHouseRules[0]?.checkInTime} P.M</p>
                                                </div>
                                            </div>
                                            <div className='check-time'>
                                                <FaSignOutAlt />
                                                <div>
                                                    <h4>Check-out Time</h4>
                                                    <p>{accommodationPackage?.accommodationHouseRules[0]?.checkOutTime} A.M</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='policies'>
                                            <Collapse
                                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                            >
                                                <Panel header="Cancellation Policy" key="1">
                                                    <p>{accommodationPackage?.accommodationHouseRules[0]?.cancellationPolicy}</p>
                                                </Panel>
                                                <Panel header="Payment Policy" key="2">
                                                    <p>{accommodationPackage?.accommodationHouseRules[0]?.paymentPolicy}</p>
                                                </Panel>
                                                <Panel header="Child Policy" key="3">
                                                    <p>{accommodationPackage?.accommodationHouseRules[0]?.childPolicy}</p>
                                                </Panel>
                                                <Panel header="Pet Policy" key="4">
                                                    <p>{accommodationPackage?.accommodationHouseRules[0]?.petPolicy}</p>
                                                </Panel>
                                                <Panel header="Smoking Policy" key="5">
                                                    <p>{accommodationPackage?.accommodationHouseRules[0]?.smokingPolicy}</p>
                                                </Panel>
                                                <Panel header="Other Policies" key="6">
                                                    <p>{accommodationPackage?.accommodationHouseRules[0]?.otherPolicy}</p>
                                                </Panel>
                                            </Collapse>
                                        </div>
                                    </div>
                                </section>

                                <section className='sec__5'>
                                    <h2 className='heder_2'>Other Available Packages</h2>
                                    <div className='other-packages'>
                                        {accommodationPackage?.accommodationOtherPackages?.map((pack, index) => (
                                            <div className='package-card' key={index}
                                                onClick={() => {
                                                    const currentId = window.location.pathname.split('/').pop();
                                                    if (currentId !== pack.id) {
                                                        window.location.href = `/accommodations/${pack.id}`;
                                                    }
                                                }}
                                            >
                                                <div className='package-image'>
                                                    <LazyLoadImage
                                                        alt={pack.packageName}
                                                        effect="blur"
                                                        src={pack.packageImages.split(',')[0]}
                                                    />
                                                    <span className='package-type'>{pack.packageType}</span>
                                                </div>
                                                <div className='package-content'>
                                                    <h3>{pack.packageName}</h3>
                                                    <div className='package-details'>
                                                        <p className='price'>${pack.packagePrice} <span>per night</span></p>
                                                        <div className='details'>
                                                            <p><FaBed /> {pack.packageRooms.replace('_', ' ')}</p>
                                                            <p><FaUtensils /> {pack.packageMeals.split(',').join(', ')}</p>
                                                        </div>
                                                    </div>
                                                    <div className='facilities'>
                                                        {pack.packageFacilities.split(',').slice(0, 3).map((facility, idx) => (
                                                            <span key={idx}>{facility.replace('_', ' ')}</span>
                                                        ))}
                                                        {pack.packageFacilities.split(',').length > 3 &&
                                                            <span>+{pack.packageFacilities.split(',').length - 3} more</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </section>
                            </div>

                            <div className='buy_now'>
                                <h2 className='heder_2'>
                                    Book Accommodation
                                </h2>
                                <p>
                                    You can place your booking for this accommodation package here. You can also inquire about changes to the package.
                                </p>
                                <h2 className='price'>
                                    {accommodationPackage?.accommodationPackage.packagePrice ? convertCurrency(accommodationPackage?.accommodationPackage.packagePrice, selectedCurrency, currencyRates) : 'N/A'} {selectedCurrency || 'AUD'}
                                    <span> Per Person </span>
                                </h2>
                                <Button
                                    type='primary'
                                    className='button_p dark'
                                    onClick={handleBookNow}
                                    loading={loadingBook}
                                >
                                    <BiCart /> Check Availability
                                </Button>

                                {/* <Popover
                                    content={
                                        <div className='inquire_content'
                                            style={{
                                                width: '300px'
                                            }}
                                        >
                                            <p>
                                                Customize this accommodation package to suit your needs. You can inquire about changes to the package.
                                            </p>
                                        </div>
                                    }
                                >
                                    <Button
                                        type='default'
                                        className='button_p inquire'
                                        onClick={() => {
                                            const element = document.getElementById('enquire_form')
                                            element.scrollIntoView({ behavior: 'smooth' })
                                        }}
                                    >
                                        <BiExtension />Inquire Changes
                                    </Button>
                                </Popover> */}
                            </div>
                        </div>
                    </div>
                    :
                    <div className='loading_page'>
                        <LoadingComp />
                    </div>
            }
        </>
    )
}

export default AccommodationPackage