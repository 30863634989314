import { Breadcrumb, Button, Col, Collapse, Divider, Form, Input, message, notification, Popover, Radio, Rate, Result, Row, Spin, Steps, Tag } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { addTourEnquiry, getAccommodationsByTourPackage, getItinerariesByTourPackage, getTourPackageById } from '../../../../redux/api/tourPackages.api'
import LoadingComp from '../../../components/LoadingComp'
import { CheckCircleOutlined, CheckCircleTwoTone, CloseCircleTwoTone, HeartOutlined, HeartTwoTone, HomeOutlined, ShareAltOutlined } from '@ant-design/icons'

import './styles.scss'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { DEFAULT_PROFILE_IMAGE, DEFAULT_PROFILE_IMAGE_VISIT_PEARL, DEFAULT_THUMBNAIL } from '../../../constants/otherConstsnts'
import { BiCalendarEvent, BiCart, BiDownload, BiExtension, BiGroup, BiTrip, BiUserPlus } from 'react-icons/bi'
import { captilizeFirstLetter, resizeString, trimHTML } from '../../../helpers/nameRender'
import ReactQuill from 'react-quill'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card } from 'react-bootstrap'
import { CANCELLATION_POLICIES, FAQ_TOUR, GENERAL_POLICIES, PAYMENT_POLICIES, SAFETY_AND_TRUST } from '../../../constants/policies'
import { supporRequestTypes } from '../../../constants/itemTypes'
import { UN_AUTH_USER } from '../../../constants/roles'
import useMyProfile from '../../../hooks/useMyProfile'
import { useMsal } from '@azure/msal-react'
import { LOGIN_REQUEST } from '../../../azure/authConfig'
import { addToFavourites, deleteFavourite, getFavouriteById } from '../../../../redux/api/favourite.api'
import { FAV_TOUR_PACKAGE } from '../../../constants/favTypes'
import usePageSettings from '../../../hooks/usePageSettings'
import useCurrencyRates from '../../../hooks/useCurrencyRates'
import { convertCurrency } from '../../../helpers/currencyConverter'
import { getBlogByRelatedId } from '../../../../redux/api/blogs.api'
import moment from 'moment'
import { getReviewsByItem } from '../../../../redux/api/reviews.api'

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

const TourPackage = () => {
  const url = window.location.pathname
  const tourPackageId = url.split('/').pop()
  const [loading, setLoading] = useState(true)
  const [tourPackage, setTourPackage] = useState(null)
  const [messageApi, contextHolder] = message.useMessage();
  const [api, contextHolderNoti] = notification.useNotification();
  const [itinerary, setItinerary] = useState(null)
  const [loadingItinerary, setLoadingItinerary] = useState(true)
  const [accommodations, setAccommodations] = useState(null)
  const [loadingBook, setLoadingBook] = useState(false)
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const myProfile = useMyProfile();
  const [fromLoading, setFormLoading] = useState(false)
  const formRef = useRef()
  const [isFavourite, setIsFavourite] = useState(null)
  const [loadingFavourite, setLoadingFavourite] = useState(false)
  const currencyRates = useCurrencyRates()?.currencyRates
  const selectedCurrency = usePageSettings()?.selectedCurrency;
  const [relatedBlogs, setRelatedBlogs] = useState([])
  const [reviews, setReviews] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!tourPackageId) {
      navigate('/tours')
    }

    handleGetTourPackage(tourPackageId)
    handleGetItinerary(tourPackageId)
    handleGetAccommodations(tourPackageId)
    handleGetReviews()
    handleGetRelatedBlogs()
  }, [])

  useEffect(() => {
    if (myProfile?.userProfile) {
      handleCheckIsFavourite()
    }
  }, [myProfile])

  const handleGetRelatedBlogs = async () => {
    try {
      const response = await getBlogByRelatedId(tourPackageId, 'tourPackage')
      setRelatedBlogs(response.data)
    } catch (error) {
      console.error('Error fetching related blogs', error)
    }
  }

  const handleGetReviews = async () => {
    try {
      const response = await getReviewsByItem(tourPackageId)
      setReviews(response.data)
    } catch (error) {
      console.error('Error fetching reviews', error)
    }
  }

  const handleCheckIsFavourite = async () => {
    setLoadingFavourite(true)
    try {
      const response = await getFavouriteById(tourPackageId)
      setIsFavourite(response.data)
    } catch (error) {
      console.error('Error checking favourite', error)
    }
    setLoadingFavourite(false)
  }

  const handleOnClikFavourite = async () => {
    setLoadingFavourite(true)
    if (isFavourite) {
      await deleteFavourite(isFavourite.id)
      setIsFavourite(null)
      messageApi.open({
        type: 'success',
        content: 'Removed from favourites',
        duration: 2,
      });
    } else {
      await addToFavourites({
        userId: myProfile?.userProfile?.id,
        favouriteId: tourPackageId,
        favouriteType: FAV_TOUR_PACKAGE
      })
      await handleCheckIsFavourite()
      messageApi.open({
        type: 'success',
        content: 'Added to favourites',
        duration: 2,
      });
    }
    setLoadingFavourite(false)
  }

  const handleGetItinerary = async (id) => {
    setLoadingItinerary(true)
    try {
      const itineraries = await getItinerariesByTourPackage(id)
      setItinerary(itineraries.data)
    } catch (error) {
      console.error('Error fetching itineraries', error)
    }
    setLoadingItinerary(false)
  }

  const handleGetTourPackage = async (id) => {
    setLoading(true)
    try {
      const tour = await getTourPackageById(id)
      setTourPackage(tour.data[0])
    } catch (error) {
      console.error('Error fetching destination', error)
    }
    setLoading(false)
  }

  const handleGetAccommodations = async (id) => {
    try {
      const accommodations = await getAccommodationsByTourPackage(id)
      setAccommodations(accommodations.data)
    } catch (error) {
      console.error('Error fetching accommodations', error)
    }
  }


  const copyLinkToClipboard = () => {
    const url = window.location.href
    navigator.clipboard.writeText(url)
    messageApi.open({
      type: 'success',
      content: 'Link copied to clipboard',
      duration: 2,
    });
  }

  const renderImages = (images) => {
    if (images?.length > 2) {
      const moreImages = images.slice(2)
      return (
        <div className='images_container more_than_two'>
          <PhotoView src={images[0]}>
            <LazyLoadImage
              alt='image'
              effect="blur"
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
              src={images[0]}
            />
          </PhotoView>
          <PhotoView src={images[1]}>
            <LazyLoadImage
              alt='image'
              effect="blur"
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
              src={images[1]}
            />
          </PhotoView>
          <div className='more_images'>
            {
              moreImages.map((image, index) => (
                <PhotoView key={index} src={image}>
                  <LazyLoadImage
                    alt='image'
                    effect="blur"
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                    src={image}
                  />
                </PhotoView>
              ))
            }
          </div>
        </div>
      )
    } else {
      return (
        <div className='images_container'>
          {
            images?.map((image, index) => (
              <PhotoView key={index} src={image}>
                <LazyLoadImage
                  alt='image'
                  effect="blur"
                  wrapperProps={{
                    style: { transitionDelay: "0.1s" },
                  }}
                  src={image}
                />
              </PhotoView>
            ))
          }
        </div>
      )
    }
  }

  const handleFormSubmit = async (values) => {
    setFormLoading(true)

    values.userType = myProfile?.userProfile?.id || UN_AUTH_USER;
    values.origin = 'TOUR_PACKAGE_PAGE'
    values.tourPackageId = tourPackageId
    values.userName = values.firstName + ' ' + values.lastName

    if (myProfile?.userProfile?.id) {
      values.userId = myProfile?.userProfile?.id
    }

    delete values.firstName
    delete values.lastName

    try {
      const response = await addTourEnquiry(values)
      if (response.status === 200) {
        api.success({
          message: 'Enquiry Sent',
          description: 'Your enquiry has been sent. We will get back to you soon.'
        })
        formRef.current.resetFields()
      } else {
        api.error({
          message: 'Failed to send enquiry',
          description: 'Please try again later.'
        })
      }
    }
    catch (error) {
      console.error(error)
      api.error({
        message: 'Failed to send enquiry',
        description: 'Please try again later.'
      })
    }

    setFormLoading(false)
  }

  const handleBookNow = () => {
    if (!activeAccount) {
      setLoadingBook(true)
      handleLoginRedirect()
    } else {
      navigate(`/tours/${tourPackageId}/book`)
    }
  }

  const handleLoginRedirect = () => {
    instance.handleRedirectPromise()
      .then((response) => {
        if (response) {
          instance.setActiveAccount(response.account);
          navigate(`/tours/${tourPackageId}/book`)
        } else {
          instance.loginRedirect(LOGIN_REQUEST)
            .catch((e) => {
              console.error('User Login Error', e);
            });
        }
      })
      .catch((error) => {
        console.error('Redirect Handling Error', error);
      });
  }

  return (
    <>
      {
        !loading && tourPackage ?
          <div className='main__contaier tourPackage section_main'>
            {contextHolder}
            {contextHolderNoti}
            <div className='hero top_margin_main'>
              <Breadcrumb
                className='breadcrumb_pages'
                items={[
                  {
                    title: <div className='breadcrumb__item' onClick={() => navigate('/')}><HomeOutlined /> Home</div>,
                  },
                  {
                    title: <div className='breadcrumb__item' onClick={() => navigate('/tours')} >Tour Packages</div>,
                  },
                  {
                    title: <div className='breadcrumb__item last' >
                      {loading ? 'Loading...' : tourPackage?.packageName}
                    </div>,
                  },
                ]}
              />
              <div className='hero__content_header'>
                <h1 data-aos="fade-up" data-aos-delay="300" className='header_1 align_start'>
                  {loading ? 'Loading...' : tourPackage?.packageName}
                </h1>

                <div className='actions'>
                  <Button onClick={copyLinkToClipboard} className='button dark' type='link'><ShareAltOutlined /></Button>
                  <Button
                    className='button'
                    type='link'
                    loading={loadingFavourite}
                    onClick={handleOnClikFavourite}
                  >
                    {isFavourite
                      ?
                      <HeartTwoTone twoToneColor="#eb2f96" />
                      :
                      <HeartOutlined />}
                  </Button>
                </div>
              </div>
            </div>

            <div className='image_gallery'>
              <PhotoProvider>
                <div className='left'>
                  <PhotoView src={tourPackage?.thumbnailUrl}>
                    <LazyLoadImage
                      alt='image'
                      effect="blur"
                      wrapperProps={{
                        style: { transitionDelay: "0.1s" },
                      }}
                      src={tourPackage && (tourPackage?.thumbnailUrl || DEFAULT_THUMBNAIL)}
                    />
                  </PhotoView>
                </div>
                <div className='right'>
                  {
                    renderImages(tourPackage?.packageImages?.split(','))
                  }
                </div>
                <div className='total'>
                  <span>{tourPackage?.packageImages?.split(',').length + (tourPackage?.thumbnailUrl ? 1 : 0)} photos</span>
                </div>
              </PhotoProvider>
            </div>

            <div className='tour_package_content_body'>
              <div className='content__container'>
                <section className='sec__1'>
                  <div className='tags'>
                    {
                      tourPackage?.tags?.split(',').map((tag, index) => (
                        <Tag key={index} className='tag'>{tag}</Tag>
                      ))
                    }
                  </div>

                  <div className='short_details'>
                    <div className='row_cus'>
                      <div className='col_cus'>
                        <div className='title'><BiCalendarEvent /> Duration:</div>
                        <div className='value'>{tourPackage?.durationDays} Days / {tourPackage?.durationNights} Nights</div>
                      </div>

                      <div className='col_cus'>
                        <div className='title'><BiTrip /> Tour Type:</div>
                        <div className='value'>{tourPackage?.tourType && captilizeFirstLetter(tourPackage?.tourType)}</div>
                      </div>
                    </div>

                    <div className='row_cus'>
                      <div className='col_cus'>
                        <div className='title'><BiGroup /> Age Range:</div>
                        <div className='value'>{tourPackage?.minAge} Years to {tourPackage?.maxAge} Years</div>
                      </div>

                      <div className='col_cus'>
                        <div className='title'><BiUserPlus /> Max Group Size:</div>
                        <div className='value'>{tourPackage?.maxGroupSize}</div>
                      </div>
                    </div>
                  </div>

                  <div className='about'>
                    <h2 className='heder_2'>
                      About this tour
                    </h2>
                    <ReactQuill
                      value={tourPackage?.shotDescription}
                      readOnly={true}
                      theme='bubble'
                    />
                  </div>

                  <div className='highlights'>
                    <h2 className='heder_2'>
                      Highlights
                    </h2>
                    <div className='list'>
                      {
                        tourPackage?.highlights?.map((highlight, index) => (
                          <span><CheckCircleOutlined /> <p key={index}>{highlight.highlight}</p></span>
                        ))
                      }
                    </div>
                  </div>
                </section>


                <section className='sec__2'>
                  <h2 className='heder_2'>
                    Where you will go
                  </h2>
                  <div className='slider'>
                    <Carousel
                      swipeable={true}
                      draggable={false}
                      showDots={true}
                      responsive={responsive}
                      ssr={true}
                      infinite={false}
                      autoPlaySpeed={1000}
                      keyBoardControl={true}
                      customTransition="all .5"
                      transitionDuration={500}
                      containerClass="carousel-container"
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                    >
                      {
                        tourPackage?.destinations?.map((destination, index) => (
                          <Card key={index} className='destination_card'>
                            <Card.Img variant="top" src={destination.thumbnailUrl} className='card_image' />
                            <Card.Body className='card_body'>
                              <Card.Title className='card_title'>{destination.destinationName}</Card.Title>
                            </Card.Body>
                          </Card>
                        ))
                      }
                    </Carousel>
                  </div>
                  <h2 className='heder_2'>
                    This is your route
                  </h2>

                  <div className='route'>
                    <iframe
                      src={tourPackage?.routeMap}
                      title="Route Map"
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </section>

                <section className='sec__3'>
                  <h2 className='heder_2'>
                    Itinerary
                  </h2>

                  <div className='itinerary'>
                    <Steps
                      direction="vertical"
                      progressDot
                      loading={loadingItinerary}
                    >
                      {
                        itinerary?.map((itinerary, index) => (
                          <Steps.Step
                            className='itinerary_step'
                            key={index}
                            title={
                              <div>
                                <Collapse
                                  bordered={false}
                                  expandIconPosition='right'
                                  ghost
                                >
                                  <Collapse.Panel
                                    header={<span>
                                      <h2>Day {itinerary?.dayNumber}</h2>
                                      <p>{itinerary.dayTitle}</p>
                                    </span>}
                                    key={index}
                                    className='itinerary_collapse'
                                  >
                                    <ReactQuill
                                      className='day_description'
                                      value={itinerary.dayDescription}
                                      readOnly={true}
                                      theme='bubble'
                                    />
                                    <div className='main__destination'>
                                      <div className='left'>
                                        <LazyLoadImage
                                          alt='image'
                                          effect="blur"
                                          wrapperProps={{
                                            style: { transitionDelay: "0.1s" },
                                          }}
                                          src={itinerary?.mainDestination?.thumbnailUrl}
                                          onClick={() => navigate(`/destinations/${itinerary?.mainDestination?.id}`)}
                                        />
                                      </div>
                                      <div className='right'>
                                        <h2>Main Destination</h2>
                                        <h3
                                          onClick={() => navigate(`/destinations/${itinerary?.mainDestination?.id}`)}
                                        >{itinerary?.mainDestination?.destinationName}</h3>
                                        <ReactQuill
                                          value={itinerary?.mainDestination?.destinationDescriptionShort ? trimHTML(itinerary?.mainDestination?.destinationDescriptionShort,
                                            window.innerWidth > 1366 ? 120 : 60
                                          ) : ''}
                                          readOnly={true}
                                          theme='bubble'
                                        />
                                      </div>
                                    </div>

                                    <Divider />

                                    <div className='experience'>
                                      <h2>You can experience</h2>
                                      <div className='itemss'>
                                        {
                                          itinerary?.items?.sort((a, b) => a.position - b.position)?.map((item, index) => (
                                            <div className='item_iti_dis'>
                                              <div className='left'>
                                                <LazyLoadImage
                                                  alt='image'
                                                  effect="blur"
                                                  wrapperProps={{
                                                    style: { transitionDelay: "0.1s" },
                                                  }}
                                                  src={item?.thumbnailUrl || DEFAULT_THUMBNAIL}
                                                />
                                              </div>

                                              <div className='right'>
                                                <h4>{item?.eventName || item?.activityName || item?.attractionName || item?.destinationName || 'N/A'}</h4>
                                                <p>
                                                  <strong>Type: </strong> {item?.type ? captilizeFirstLetter(item?.type) : 'N/A'}
                                                </p>
                                                <p>
                                                  <strong>Short Description: </strong>
                                                  <ReactQuill
                                                    value={
                                                      item?.eventDescriptionShort ?
                                                        trimHTML(item?.eventDescriptionShort, 120) :
                                                        item?.activityDescriptionShort ? trimHTML(item?.activityDescriptionShort, 120) :
                                                          item?.attractionDescriptionShort ? trimHTML(item?.attractionDescriptionShort, 120) :
                                                            item?.destinationDescriptionShort ? trimHTML(item?.destinationDescriptionShort, 120) :
                                                              'N/A'
                                                    }
                                                    readOnly={true}
                                                    theme='bubble'
                                                  />
                                                </p>
                                              </div>
                                            </div>
                                          ))
                                        }
                                      </div>
                                    </div>

                                    <Divider />

                                    <div className='experience'>
                                      <h2>You will Stay at</h2>
                                      <p className='note'>
                                        <span>Please Note: </span> The following are the hotels that you will stay at during the tour. The hotels are subject to change and will be confirmed 4 weeks before the tour.
                                      </p>
                                      <div className='itemss'>
                                        <div className='item_iti_dis'>
                                          {/* <div className='left'>
                                            <LazyLoadImage
                                              alt='image'
                                              effect="blur"
                                              wrapperProps={{
                                                style: { transitionDelay: "0.1s" },
                                              }}
                                              src={itinerary?.stay?.thumbnailUrl || DEFAULT_THUMBNAIL}
                                              onClick={() => navigate(`/accommodations/${itinerary?.stay?.id}`)}
                                            />
                                          </div> */}

                                          <div className='right'>
                                            <p className='header_4'
                                            // onClick={() => navigate(`/accommodations/${itinerary?.stay?.id}`)}
                                            >{itinerary?.stay?.accommodationName} or Similar</p>
                                            {/* <p>
                                              <strong>Rating: </strong> {itinerary?.stay?.starRating + ' Stars' || itinerary?.stay?.grade + ' Grade'}
                                            </p>
                                            <p>
                                              <strong>Short Description: </strong>
                                              <ReactQuill
                                                value={itinerary?.stay?.accommodationDescriptionShort && trimHTML(itinerary?.stay?.accommodationDescriptionShort, 120)}
                                                readOnly={true}
                                                theme='bubble'
                                              />
                                            </p> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </Collapse.Panel>
                                </Collapse>
                              </div>
                            }
                          />
                        ))
                      }
                    </Steps>
                  </div>
                </section>

                <section className='sec__6'>
                  <h2 className='heder_2'>
                    You will stay at
                  </h2>

                  <p className='note'>
                    <span>Please Note: </span> The following are the hotels that you will stay at during the tour. The hotels are subject to change and will be confirmed <strong>4 weeks</strong> before the tour.
                  </p>
                  <div className='list'>
                    {
                      accommodations?.sort((a, b) => a.dayNumber - b.dayNumber)?.map((item, index) => (
                        <div className='itemss'>
                          <div className='item_iti_dis'>
                            {/* <div className='left'>
                              <LazyLoadImage
                                alt='image'
                                effect="blur"
                                wrapperProps={{
                                  style: { transitionDelay: "0.1s" },
                                }}
                                src={item?.accommodation?.thumbnailUrl || DEFAULT_THUMBNAIL}
                              // onClick={() => navigate(`/accommodations/${itinerary?.stay?.id}`)}
                              />
                            </div> */}

                            <div className='right'>
                              <h3
                              // onClick={() => navigate(`/accommodations/${itinerary?.stay?.id}`)}
                              >Day {item?.dayNumber} : {item?.accommodation?.accommodationName} or Similar</h3>
                              {/* <p>
                                <strong>Rating: </strong> {item?.accommodation?.starRating + ' Stars' || item?.accommodation?.grade + ' Grade'}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </section>

                <section className='sec__4'>
                  <h2 className='heder_2'>
                    This package includes
                  </h2>
                  <div className='slider'>
                    <div className='list'>
                      {
                        tourPackage?.includes?.map((item, index) => (
                          <div className='include'>
                            <span><CheckCircleTwoTone twoToneColor='#52C41A'
                            /> <strong>{item?.includesType}</strong> <p key={index}>{item.includesName}</p></span>

                            <ReactQuill
                              value={item?.includesDescription}
                              readOnly={true}
                              theme='bubble'
                            />
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </section>

                <section className='sec__4'>
                  <h2 className='heder_2'>
                    This package excludes
                  </h2>
                  <div className='slider'>
                    <div className='list'>
                      {
                        tourPackage?.excludes?.map((item, index) => (
                          <div className='include'>
                            <span><CloseCircleTwoTone twoToneColor='#FF4D4F'
                            /><p key={index}>{item.exclude}</p></span>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </section>

                <section className='sec__5'>
                  <h2 className='heder_2'>
                    How it works
                  </h2>
                  <div className='cont'>
                    <ReactQuill
                      value={tourPackage?.howItWorks}
                      readOnly={true}
                      theme='bubble'
                    />
                  </div>
                </section>

                <section className='sec__5'>
                  <h2 className='heder_2'>
                    Special Notes
                  </h2>
                  <div className='cont'>
                    <ReactQuill
                      value={tourPackage?.notes}
                      readOnly={true}
                      theme='bubble'
                    />
                  </div>
                </section>

                <section className='sec__5'>
                  <h2 className='heder_2'>
                    Payment Policies
                  </h2>
                  <div className='cont'>
                    <p className='decrip_main'>
                      {PAYMENT_POLICIES.description}
                    </p>

                    <div className='list'>
                      {
                        PAYMENT_POLICIES.charges.map((charge, index) => (
                          <div className='charge'>
                            <p><strong>{charge.title} : </strong> {charge.description}</p>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <a className='default_anchor' href='/terms-and-conditions/#payment' target='_blank'>
                    Learn more about our payment policies
                  </a>
                </section>

                <section className='sec__5'>
                  <h2 className='heder_2'>
                    Cancellation Policies
                  </h2>
                  <div className='cont'>
                    <p className='decrip_main'>
                      {CANCELLATION_POLICIES.description}
                    </p>

                    <div className='list'>
                      {
                        CANCELLATION_POLICIES.policies.map((charge, index) => (
                          <div className='charge'>
                            <p><strong>{charge.title} : </strong> {charge.description}</p>
                            {
                              charge?.charges?.map((charge, index) => (
                                <div className='sub_charge'>
                                  <p><strong>{charge.title} : </strong> {charge.description}</p>
                                </div>
                              ))
                            }
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <a className='default_anchor' href='/terms-and-conditions/#tours' target='_blank'>
                    Learn more about our cancellation policies
                  </a>
                </section>

                <section className='sec__5'>
                  <h2 className='heder_2'>
                    Other Policies
                  </h2>
                  <div className='cont'>
                    <p className='decrip_main'>
                      {GENERAL_POLICIES.description}
                    </p>

                    <div className='list'>
                      {
                        GENERAL_POLICIES.policies.map((charge, index) => (
                          <div className='charge'>
                            <p><strong>{charge.title} : </strong> {charge.description}</p>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <a className='default_anchor' href='/terms-and-conditions' target='_blank'>
                    Terms and Conditions
                  </a>
                </section>

                <section className='sec__5'>
                  <h2 className='heder_2'>
                    Safety & Trust
                  </h2>
                  <div className='cont'>
                    <div className='header'>
                      <LazyLoadImage
                        alt='image'
                        effect="blur"
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                        src='https://visitpearlsharedblob.blob.core.windows.net/appassets/safety_logo.webp'
                      />

                      <p className='decrip_main'>
                        {SAFETY_AND_TRUST.description}
                      </p>
                    </div>

                    <div className='list'>
                      {
                        SAFETY_AND_TRUST.policies.map((charge, index) => (
                          <div className='charge'>
                            <p><strong>{charge.title} : </strong> {charge.description}</p>
                            {
                              charge?.charges?.map((charge, index) => (
                                <div className='sub_charge'>
                                  <p><strong>{charge.title} : </strong> {charge.description}</p>
                                </div>
                              ))
                            }
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <a className='default_anchor' href='/terms-and-conditions/#safty-and-trust' target='_blank'>
                    Learn More
                  </a>
                </section>

                <section className='sec__5'>
                  <h2 className='heder_2'>
                    What our customers ask about this tour
                  </h2>
                  <div className='cont'>
                    <p className='decrip_main'>
                      {FAQ_TOUR.description}
                    </p>

                    <Collapse
                      bordered={false}
                      expandIconPosition='right'
                      ghost
                    >
                      {
                        FAQ_TOUR.questions.map((question, index) => (
                          <Collapse.Panel
                            header={<span>
                              <h6>{question.question}</h6>
                            </span>}
                            key={index}
                            className='itinerary_collapse'
                          >
                            <p>{question.answer}</p>
                          </Collapse.Panel>
                        ))
                      }
                    </Collapse>
                  </div>
                </section>

                <section className='sec__5'>
                  <h2 className='heder_2'>
                    Videos
                  </h2>

                  {tourPackage?.packageVideos && <div className='videos section'
                    style={{
                      backgroundImage: `url(${tourPackage?.thumbnailUrl || DEFAULT_THUMBNAIL})`,
                    }}
                  >
                    <Carousel
                      arrows
                      responsive={{
                        superLargeDesktop: {
                          breakpoint: { max: 4000, min: 3000 },
                          items: 1
                        },
                        desktop: {
                          breakpoint: { max: 3000, min: 1024 },
                          items: 1
                        },
                        tablet: {
                          breakpoint: { max: 1024, min: 464 },
                          items: 1
                        },
                        mobile: {
                          breakpoint: { max: 464, min: 0 },
                          items: 1
                        }
                      }}
                    >
                      {
                        tourPackage?.packageVideos && tourPackage?.packageVideos?.split(',').map((video, index) => (
                          <div key={index}>
                            <video controls>
                              <source src={video} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        ))
                      }
                    </Carousel>
                  </div>}
                </section>

                <section className='sec__7'>
                  <h2 className='heder_2'>
                    Send your custom request for this tour
                  </h2>

                  <div className='enquire_form' id='enquire_form'>
                    <Spin spinning={fromLoading} tip="Sending...">
                      <Form
                        name='contact-us'
                        layout='vertical'
                        requiredMark={false}
                        onFinish={handleFormSubmit}
                        ref={formRef}
                        data-aos="fade-down"
                        data-aos-delay="300"
                      >
                        <div className='row'>
                          <div className='col'>
                            <Form.Item
                              name='firstName'
                              label='First Name'
                              rules={[{ required: true, message: 'Please input your first name!' }]}
                            >
                              <Input maxLength={20} />
                            </Form.Item>
                          </div>
                          <div className='col'>
                            <Form.Item
                              name='lastName'
                              label='Last Name'
                              rules={[{ required: true, message: 'Please input your last name!' }]}
                            >
                              <Input maxLength={20} />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col'>
                            <Form.Item
                              name='email'
                              label='Email'
                              rules={
                                [
                                  { required: true, message: 'Please input your email!' },
                                  { type: 'email', message: 'Please input a valid email!' }
                                ]
                              }
                            >
                              <Input maxLength={100} />
                            </Form.Item>
                          </div>
                          <div className='col'>
                            <Form.Item
                              name='contactNo'
                              label='Phone Number'
                              rules={
                                [
                                  { required: true, message: 'Please input your phone number!' },
                                  { pattern: /^[0-9]+$/, message: 'Please input a valid phone number!' }
                                ]
                              }
                            >
                              <Input maxLength={20} />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='row subject'>
                          <div className='col'>
                            <h3>Select Subject</h3>
                            <Form.Item
                              name='subject'
                              rules={[{ required: true, message: 'Please select a subject!' }]}
                            >
                              <Radio.Group>
                                <Radio value={supporRequestTypes.GENERAL}>General Inquiry</Radio>
                                <Radio value={supporRequestTypes.BOOKING}>Booking Inquiry</Radio>
                                <Radio value={supporRequestTypes.FEEDBACK}>Feedback</Radio>
                                <Radio value={supporRequestTypes.REQUEST_CHANGE}>Customize Tour</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </div>
                        </div>

                        <Row gutter={16}>
                          <Col span={24}>
                            <Form.Item
                              name='message'
                              label='Message'
                              rules={[{ required: true, message: 'Please input your message!' }]}
                            >
                              <Input.TextArea maxLength={500} placeholder='Wite your message...' />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Form.Item className='submit_btn'>
                          <Button
                            loading={loading}
                            className='custom_primary_btn'
                            htmlType='submit'
                          >
                            {
                              loading ? 'Sending...'
                                : 'Send Enquiry'
                            }
                          </Button>
                        </Form.Item>
                      </Form>
                    </Spin>
                  </div>
                </section>
              </div>

              <div className='buy_now'>
                <h2 className='heder_2'>I need this tour</h2>
                <p>
                  You can book this tour now. You can also inquire about changes to the tour.
                </p>
                <h2 className='price'>
                  <span>From </span>
                  {tourPackage?.startingPrice ? convertCurrency(tourPackage?.startingPrice, selectedCurrency, currencyRates) : 'N/A'} {selectedCurrency || 'AUD'}
                </h2>
                <Button
                  type='primary'
                  className='button_p dark'
                  onClick={handleBookNow}
                  loading={loadingBook}
                >
                  <BiCart /> Book Tour Now
                </Button>

                <Popover
                  content={
                    <div className='inquire_content'
                      style={{
                        width: '300px'
                      }}
                    >
                      <p>
                        Customize this tour may change the price. You can inquire about changes to the tour.
                      </p>
                    </div>
                  }
                >
                  <Button
                    type='default'
                    className='button_p inquire'
                    onClick={() => {
                      formRef.current.setFieldsValue({
                        subject: supporRequestTypes.REQUEST_CHANGE
                      })

                      const element = document.getElementById('enquire_form')
                      element.scrollIntoView({ behavior: 'smooth' })
                    }}
                  >
                    <BiExtension />Inquire Changes
                  </Button>
                </Popover>

                <Divider />

                <div className='more'>
                  <Button
                    type='link'
                    className='button link'
                    onClick={() => {
                      window.open(tourPackage?.itineraryDownloadUrl, '_blank')
                    }}
                  >
                    <BiDownload /> Download Brochure
                  </Button>
                  {/* <Button type='link' className='button link'
                    onClick={() => navigate('/contact-us')}
                  >
                    <BiMessageSquareAdd /> Ask a Question
                  </Button> */}
                </div>
              </div>
            </div>

            {reviews?.length > 0 &&
              <div className='blogs section'>
                <h2 className='header_1'>
                  What our travellers say about this Package
                </h2>

                <div className='review_cards'
                  style={{
                    marginTop: 20
                  }}
                >
                  {reviews?.map((item, idx) => (
                    <div
                      className='review_card'
                    >
                      <div className='review_header'>
                        <div className='left'>
                          <LazyLoadImage
                            alt='image'
                            effect="blur"
                            wrapperProps={{
                              style: { transitionDelay: "0.1s" },
                            }}
                            src={item?.profilePicUrl || DEFAULT_PROFILE_IMAGE}
                          />
                        </div>
                        <div className='right'>
                          <h2>{item?.userName}</h2>
                          <p>{moment(item?.createdAt, 'MMM D YYYY h:mma').format('MMMM Do YYYY')}</p>
                        </div>
                      </div>
                      <Rate className='rate' disabled value={item?.ratingValue} />

                      <div className='review_content'>
                        <p>
                          {item?.ratingDescription}
                        </p>
                      </div>
                      <div className='review_images'>
                        <PhotoProvider>
                          {
                            item?.images?.split(',').length > 0 && item.images.split(',').map((image, index) => (
                              <PhotoView src={image} key={index}>
                                <LazyLoadImage
                                  alt='image'
                                  effect="blur"
                                  wrapperProps={{
                                    style: { transitionDelay: "0.1s" },
                                  }}
                                  src={image}
                                />
                              </PhotoView>
                            ))
                          }
                        </PhotoProvider>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            }

            {relatedBlogs?.length > 0 &&
              <div className='blogs section'>
                <h2 className='header_1'>
                  Read our Blogs
                </h2>

                <div className='items__list' style={{ marginTop: 20 }}>
                  <div className='section__all__destinations__content'>
                    <Row xs={1} md={3} lg={4} xl={4} className="g-4" gutter={16}>
                      {!loading && relatedBlogs?.map((item, idx) => (
                        <Col key={idx}>
                          <Card
                            data-aos="fade-up"
                            data-aos-delay={`${idx * 100}`}
                            className='package__card'
                            style={{
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                            }}
                            onClick={() => navigate(`/blogs/${item?.id}`)}
                          >
                            <LazyLoadImage
                              src={item?.blogImages?.split(',')[0] || DEFAULT_THUMBNAIL}
                              alt={item?.packageName}
                              effect='blur'
                            />

                            <Card.Body
                              className='destination__card__content'
                            >
                              <h2>{item?.blogTitle}</h2>
                              <div className='tags'>
                                {
                                  item?.blogTags?.split(',').map((tag, idx) => (
                                    <Tag > {tag} </Tag>
                                  ))
                                }
                              </div>
                              <p>{item?.blogDescription ? resizeString(item?.blogDescription, 100) : 'No Description'}</p>

                              {item?.blogType === 'admin' ?
                                <div className='author'>
                                  <div className='author_image'>
                                    <LazyLoadImage
                                      alt='author'
                                      src={DEFAULT_PROFILE_IMAGE_VISIT_PEARL}
                                      effect='blur'
                                    />
                                  </div>
                                  <div className='author_name'>
                                    <h3>Visit Pearl</h3>
                                    <p>{moment(item?.createdAt, 'MMM D YYYY h:mma').format('MMMM Do YYYY')}</p>
                                  </div>
                                </div>
                                :
                                <div className='author'>
                                  <div className='author_image'>
                                    <LazyLoadImage
                                      alt='author'
                                      src={item?.profilePicUrl || DEFAULT_PROFILE_IMAGE}
                                      effect='blur'
                                    />
                                  </div>
                                  <div className='author_name'>
                                    <h3>{item?.userName}</h3>
                                    <p>{moment(item?.createdAt, 'MMM D YYYY h:mma').format('MMMM Do YYYY')}</p>
                                  </div>
                                </div>
                              }
                            </Card.Body>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </div>
              </div>}
          </div>
          :
          <>
            {
              loading ?
                <div className='loading_page'>
                  <LoadingComp />
                </div>
                :
                <div className='not_found'
                  style={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                    extra={<Button type="primary" onClick={() => navigate('/')}>Back Home</Button>}
                  />
                </div>
            }
          </>
      }
    </>
  )
}

export default TourPackage