import React, { useState } from 'react'

import './styles.scss'
import { Button, Tabs } from 'antd'
import { FacebookFilled, InstagramFilled, TwitterCircleFilled, YoutubeFilled } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const tabItems = [
    {
        key: '1',
        label: 'About Us',
        content: <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque non facilisis ante. Integer sed magna pellentesque ante sollicitudin gravida. Etiam laoreet lectus nibh, eget consequat velit eleifend eget. Ut consectetur venenatis malesuada. Mauris id facilisis mauris. Nullam placerat massa tincidunt ante pharetra, sit amet tempor nisl ornare. Donec vel elit tincidunt, interdum metus sit amet, mattis est. Aliquam erat volutpat. Donec porttitor velit eu libero tincidunt gravida.
        </p>
    },
    {
        key: '2',
        label: 'Our Vision & Mission',
        content: <p>
            Halo, consectetur adipiscing elit. Quisque non facilisis ante. Integer sed magna pellentesque ante sollicitudin gravida. Etiam laoreet lectus nibh, eget consequat velit eleifend eget. Ut consectetur venenatis malesuada. Mauris id facilisis mauris. Nullam placerat massa tincidunt ante pharetra, sit amet tempor nisl ornare. Donec vel elit tincidunt, interdum metus sit amet, mattis est. Aliquam erat volutpat. Donec porttitor velit eu libero tincidunt gravida.
        </p>
    },
]

const SecAboutUs = () => {
    const [activeTab, setActiveTab] = useState('1')
    const navigate = useNavigate()

    return (
        <div className='section_main about_section'>
            <div className='left'>
                <h1 className='section_title' data-aos="fade-right" >Why visit pearl?</h1>

                <Tabs
                    defaultActiveKey="1"
                    items={tabItems}
                    onChange={(key) => setActiveTab(key)}
                    data-aos="fade-right"
                    data-aos-delay="100"
                />

                <p
                    data-aos="fade-right"
                    data-aos-delay="200"
                >
                    {
                        tabItems.map((item) => {
                            if (item.key === activeTab) {
                                return item.content;
                            }
                            return null;
                        })
                    }
                </p>

                <div className='btns'>
                    <Button
                        onClick={() => navigate('/about-us')}
                        data-aos="fade-right" data-aos-delay="300" className='custom_primary_btn' type='primary'>Learn More...</Button>
                    <div className='socials' data-aos="fade-right" data-aos-delay="400">
                        <a href='https://www.facebook.com/profile.php?id=61560704055422' target='_blank' rel='noreferrer'><FacebookFilled /></a>
                        <a href='https://www.instagram.com/visit.pearl' target='_blank' rel='noreferrer'><InstagramFilled /></a>
                        {/* <a href='/'><TwitterCircleFilled /></a> */}
                        <a href='https://www.youtube.com/@VisitPearl' target='_blank' rel='noreferrer'><YoutubeFilled /></a>
                    </div>
                </div>
            </div>

            <div className='right'>
                <img
                    src='https://visitpearlsharedblob.blob.core.windows.net/appassets/bird_logo.webp'
                    alt='about us'
                    loading='lazy'
                    data-aos="fade-left"
                />
            </div>
        </div>
    )
}

export default SecAboutUs