export const countries = {
    UNITEDSTATES: { name: "United States", code: "us", currency: "USD", phone: "+1", lang: "en" },
    UNITEDKINGDOM: { name: "United Kingdom", code: "gb", currency: "GBP", phone: "+44", lang: "en" },
    AUSTRALIA: { name: "Australia", code: "au", currency: "AUD", phone: "+61", lang: "en" },
    CANADA: { name: "Canada", code: "ca", currency: "CAD", phone: "+1", lang: "en" },
    FRANCE: { name: "France", code: "fr", currency: "EUR", phone: "+33", lang: "fr" },
    GERMANY: { name: "Germany", code: "de", currency: "EUR", phone: "+49", lang: "de" },
    JAPAN: { name: "Japan", code: "jp", currency: "JPY", phone: "+81", lang: "ja" },
    RUSSIA: { name: "Russia", code: "ru", currency: "RUB", phone: "+7", lang: "ru" },
    CHINA: { name: "China", code: "cn", currency: "CNY", phone: "+86", lang: "zh" },
    ITALY: { name: "Italy", code: "it", currency: "EUR", phone: "+39", lang: "it" },
    SPAIN: { name: "Spain", code: "es", currency: "EUR", phone: "+34", lang: "es" },
    NETHERLANDS: { name: "Netherlands", code: "nl", currency: "EUR", phone: "+31", lang: "nl" },
    SWEDEN: { name: "Sweden", code: "se", currency: "SEK", phone: "+46", lang: "sv" },
    NORWAY: { name: "Norway", code: "no", currency: "NOK", phone: "+47", lang: "no" },
}

export const currencies = [
    { name: "USD", symbol: "$" },
    { name: "GBP", symbol: "£" },
    { name: "AUD", symbol: "A$" },
    { name: "CAD", symbol: "C$" },
    { name: "INR", symbol: "₹" },
    { name: "EUR", symbol: "€" },
    { name: "JPY", symbol: "¥" },
    { name: "RUB", symbol: "₽" },
    { name: "CNY", symbol: "¥" },
    { name: "SEK", symbol: "kr" },
    { name: "NOK", symbol: "kr" },
]

export const countriesArray = Object.values(countries)