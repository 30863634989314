import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { LOGIN_REQUEST } from '../../azure/authConfig';
import PleaseWait from '../PleaseWait';
import { Navigate } from 'react-router-dom';
import { InteractionStatus, BrowserAuthError } from '@azure/msal-browser';

const ProtectedRoute = ({ element: Component }) => {
    const { instance, inProgress } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authError, setAuthError] = useState(null);

    useEffect(() => {
        if (activeAccount) {
            autherizeUser();
        } else if (inProgress === InteractionStatus.None) {
            handleLoginRedirect();
        }
    }, [instance, activeAccount, inProgress]);

    const autherizeUser = () => {
        instance.acquireTokenSilent({
            ...LOGIN_REQUEST,
        })
            .then((response) => {
                if (response) {
                    setIsAuthenticated(true);
                }
            })
            .catch((e) => {
                if (e instanceof BrowserAuthError && e.errorCode === 'interaction_in_progress') {
                    console.log('Interaction in progress, waiting...');
                } else {
                    console.error('User Authorization Error', e);
                }
            });
    };

    const handleLoginRedirect = () => {
        instance.handleRedirectPromise()
            .then((response) => {
                if (response) {
                    instance.setActiveAccount(response.account);
                } else if (inProgress === InteractionStatus.None) {
                    instance.loginRedirect(LOGIN_REQUEST)
                        .catch((e) => {
                            if (e instanceof BrowserAuthError && e.errorCode === 'interaction_in_progress') {
                                console.log('Interaction in progress, waiting...');
                            } else {
                                console.error('User Login Error', e);
                            }
                        });
                }
            })
            .catch((error) => {
                console.error('Redirect Handling Error', error);
                if (error.toString().includes('AADB2C99002')) {
                    setAuthError('not-allowed');
                }
            });
    };

    if (authError === 'not-allowed') {
        return <Navigate to="/not-allowed" />;
    }

    return (
        <>
            <AuthenticatedTemplate>
                {isAuthenticated ? <Component /> : <PleaseWait />}
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <PleaseWait />
            </UnauthenticatedTemplate>
        </>
    );
};

export default ProtectedRoute;
