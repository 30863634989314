import React, { useEffect, useRef, useState } from 'react'
import useDestinations from '../../../hooks/useDestinations'
import { getDestinationById } from '../../../../redux/api/destinations.api'
import { useNavigate } from 'react-router-dom'
import { CloudOutlined, EnvironmentOutlined, FireOutlined, HeartOutlined, HeartTwoTone, HomeOutlined, ShareAltOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Carousel, Empty, message, Pagination, Skeleton, Tag } from 'antd'

import './styles.scss'
import { DEFAULT_PROFILE_IMAGE, DEFAULT_PROFILE_IMAGE_VISIT_PEARL, DEFAULT_THUMBNAIL } from '../../../constants/otherConstsnts'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ReactQuill from 'react-quill'
import { captilizeEachWord, resizeString } from '../../../helpers/nameRender'
import { fetchAllAttractions } from '../../../../redux/api/attractions.api'
import { Card, Col, Row } from 'react-bootstrap'
import { fetchAllActivities } from '../../../../redux/api/activities.api'
import { fetchAllEvents } from '../../../../redux/api/events.api'
import LoadingComp from '../../../components/LoadingComp'
import useMyProfile from '../../../hooks/useMyProfile'
import { addToFavourites, deleteFavourite, getFavouriteById } from '../../../../redux/api/favourite.api'
import { FAV_DESTINATION } from '../../../constants/favTypes'
import { getBlogByRelatedId } from '../../../../redux/api/blogs.api'
import moment from 'moment'

const Destination = () => {
    const destinations = useDestinations().destinations
    const url = window.location.pathname
    const navigate = useNavigate()
    const destinationId = url.split('/').pop()
    const [loading, setLoading] = useState(true)
    const [destination, setDestination] = useState(null)
    const [messageApi, contextHolder] = message.useMessage();
    const [redMoreOpen, setReadMoreOpen] = useState(false)
    const moreDetainsRef = useRef()
    const myProfile = useMyProfile().userProfile

    const [attractions, setAttractions] = useState([])
    const [loadingAttractions, setLoadingAttractions] = useState(true)
    const [attractionsPage, setAttractionsPage] = useState(1)

    const [activities, setActivities] = useState([])
    const [loadingActivities, setLoadingActivities] = useState(true)
    const [activitiesPage, setActivitiesPage] = useState(1)

    const [events, setEvents] = useState([])
    const [loadingEvents, setLoadingEvents] = useState(true)
    const [eventsPage, setEventsPage] = useState(1)

    const [isFavourite, setIsFavourite] = useState(null)
    const [loadingFavourite, setLoadingFavourite] = useState(false)

    const [relatedBlogs, setRelatedBlogs] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (destination) {
            handleGetAttractions(1)
            handleGetActivities(1)
            handleGetEvents(1)
            handleGetRelatedBlogs()
        }
    }, [destination])

    useEffect(() => {
        if (myProfile) {
            handleCheckIsFavourite()
        }
    }, [myProfile])

    const handleGetRelatedBlogs = async () => {
        try {
            const response = await getBlogByRelatedId(destinationId, 'destination')
            setRelatedBlogs(response.data)
        } catch (error) {
            console.error('Error fetching related blogs', error)
        }
    }

    const handleCheckIsFavourite = async () => {
        setLoadingFavourite(true)
        try {
            const response = await getFavouriteById(destinationId)
            setIsFavourite(response.data)
        } catch (error) {
            console.error('Error checking favourite', error)
        }
        setLoadingFavourite(false)
    }

    const handleOnClikFavourite = async () => {
        setLoadingFavourite(true)
        if (isFavourite) {
            await deleteFavourite(isFavourite.id)
            setIsFavourite(null)
            messageApi.open({
                type: 'success',
                content: 'Removed from favourites',
                duration: 2,
            });
        } else {
            await addToFavourites({
                userId: myProfile?.id,
                favouriteId: destinationId,
                favouriteType: FAV_DESTINATION
            })
            await handleCheckIsFavourite()
            messageApi.open({
                type: 'success',
                content: 'Added to favourites',
                duration: 2,
            });
        }
        setLoadingFavourite(false)
    }


    useEffect(() => {
        if (!destinationId) {
            navigate('/destinations')
        }

        if (destinations?.length > 0) {
            const destination = destinations.find(destination => destination.id === destinationId)
            if (destination) {
                setDestination(destination)
                setLoading(false)
            } else {
                handleGetDestination(destinationId)
            }
        } else {
            handleGetDestination(destinationId)
        }
    }, [])

    const handleGetDestination = async (id) => {
        setLoading(true)
        try {
            const destination = await getDestinationById(id)
            setDestination(destination.data[0])
        } catch (error) {
            console.error('Error fetching destination', error)
        }
        setLoading(false)
    }

    const copyLinkToClipboard = () => {
        const url = window.location.href
        navigator.clipboard.writeText(url)
        messageApi.open({
            type: 'success',
            content: 'Link copied to clipboard',
            duration: 2,
        });
    }

    const descriptionCount = (description) => {
        let count = window.innerWidth > 1024 ? 1200 : 500
        return !redMoreOpen ? (description?.length > 200 ? description.slice(0, count) + '...' : description) : description
    }

    const handleGetAttractions = async (page) => {
        setLoadingAttractions(true)
        try {
            let pageSize = 4;
            if (window.innerWidth < 1024) {
                pageSize = 3;
            } else if (window.innerWidth < 768) {
                pageSize = 2
            }

            const values = {
                page: page || attractionsPage,
                destinationId: destinationId,
                pageSize: pageSize
            }
            const attractions = await fetchAllAttractions(values)
            setAttractions(attractions.data)
        } catch (error) {
            console.error('Error fetching attractions', error)
        }
        setLoadingAttractions(false)
    }

    const handleGetActivities = async (page) => {
        setLoadingActivities(true)
        try {
            let pageSize = 4;
            if (window.innerWidth < 1024) {
                pageSize = 3;
            } else if (window.innerWidth < 768) {
                pageSize = 2
            }

            const values = {
                page: page || activitiesPage,
                destinationId: destinationId,
                pageSize: pageSize
            }
            const activities = await fetchAllActivities(values)
            setActivities(activities.data)
        } catch (error) {
            console.error('Error fetching activities', error)
        }
        setLoadingActivities(false)
    }

    const handleGetEvents = async (page) => {
        setLoadingEvents(true)
        try {
            let pageSize = 4;
            if (window.innerWidth < 1024) {
                pageSize = 3;
            } else if (window.innerWidth < 768) {
                pageSize = 2
            }

            const values = {
                page: page || eventsPage,
                destinationId: destinationId,
                pageSize: pageSize
            }
            const events = await fetchAllEvents(values)
            setEvents(events.data)
        } catch (error) {
            console.error('Error fetching events', error)
        }
        setLoadingEvents(false)
    }

    const renderImages = (images) => {
        if (images?.length > 2) {
            const moreImages = images.slice(2)
            return (
                <div className='images_container more_than_two'>
                    <PhotoView src={images[0]}>
                        <LazyLoadImage
                            alt='image'
                            effect="blur"
                            wrapperProps={{
                                style: { transitionDelay: "0.1s" },
                            }}
                            src={images[0]}
                        />
                    </PhotoView>
                    <PhotoView src={images[1]}>
                        <LazyLoadImage
                            alt='image'
                            effect="blur"
                            wrapperProps={{
                                style: { transitionDelay: "0.1s" },
                            }}
                            src={images[1]}
                        />
                    </PhotoView>
                    <div className='more_images'>
                        {
                            moreImages.map((image, index) => (
                                <PhotoView key={index} src={image}>
                                    <LazyLoadImage
                                        alt='image'
                                        effect="blur"
                                        wrapperProps={{
                                            style: { transitionDelay: "0.1s" },
                                        }}
                                        src={image}
                                    />
                                </PhotoView>
                            ))
                        }
                    </div>
                </div>
            )
        } else {
            return (
                <div className='images_container'>
                    {
                        images?.map((image, index) => (
                            <PhotoView key={index} src={image}>
                                <LazyLoadImage
                                    alt='image'
                                    effect="blur"
                                    wrapperProps={{
                                        style: { transitionDelay: "0.1s" },
                                    }}
                                    src={image}
                                />
                            </PhotoView>
                        ))
                    }
                </div>
            )
        }
    }

    return (
        <>
            {
                !loading ?
                    <div className='main__contaier destination section_main' >
                        {contextHolder}
                        <div className='hero top_margin_main'>
                            <Breadcrumb
                                className='breadcrumb_pages'
                                items={[
                                    {
                                        title: <div className='breadcrumb__item' onClick={() => navigate('/')}><HomeOutlined /> Home</div>,
                                    },
                                    {
                                        title: <div className='breadcrumb__item' onClick={() => navigate('/destinations')} >Destinations</div>,
                                    },
                                    {
                                        title: <div className='breadcrumb__item last' >
                                            {loading ? 'Loading...' : destination?.destinationName}
                                        </div>,
                                    },
                                ]}
                            />
                            <div className='hero__content_header'>
                                <h1 data-aos="fade-up" data-aos-delay="300" className='header_1 align_start'>
                                    {loading ? 'Loading...' : destination?.destinationName}
                                </h1>

                                <div className='actions'>
                                    <Button onClick={copyLinkToClipboard} className='button dark' type='link'><ShareAltOutlined /></Button>
                                    <Button
                                        className='button'
                                        type='link'
                                        loading={loadingFavourite}
                                        onClick={handleOnClikFavourite}
                                    >
                                        {isFavourite
                                            ?
                                            <HeartTwoTone twoToneColor="#eb2f96" />
                                            :
                                            <HeartOutlined />}
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className='image_gallery'>
                            <PhotoProvider>
                                <div className='left'>
                                    <PhotoView src={destination?.thumbnailUrl}>
                                        <LazyLoadImage
                                            alt='image'
                                            effect="blur"
                                            wrapperProps={{
                                                style: { transitionDelay: "0.1s" },
                                            }}
                                            src={destination && (destination?.thumbnailUrl || DEFAULT_THUMBNAIL)}
                                        />
                                    </PhotoView>
                                </div>
                                <div className='right'>
                                    {
                                        renderImages(destination?.destinationImages?.split(','))
                                    }
                                </div>
                                <div className='total'>
                                    <span>{destination?.destinationImages?.split(',').length + (destination?.thumbnailUrl ? 1 : 0)} photos</span>
                                </div>
                            </PhotoProvider>
                        </div>

                        <div className='about_destination section'>
                            <div className='left'>
                                <h2 className='heder_2'>
                                    About {destination?.destinationName}
                                </h2>

                                <div className='description_short'>
                                    <ReactQuill
                                        value={destination?.destinationDescriptionShort}
                                        readOnly={true}
                                        theme='bubble'
                                    />
                                    <span className='read_more' onClick={() => {
                                        moreDetainsRef.current.scrollIntoView({ behavior: 'smooth' })
                                    }}>Read More...</span>
                                </div>


                                <div className='weather_district'>
                                    <div className='item'>
                                        <EnvironmentOutlined />
                                        <p>{destination?.destinationCity}</p>
                                    </div>
                                    <div className='item'>
                                        <CloudOutlined />
                                        <p>
                                            {destination?.weather}
                                        </p>
                                    </div>
                                </div>

                                <div className='tags'>
                                    {
                                        destination?.tags?.split(',').map((tag, index) => (
                                            <Tag key={index}
                                                className={`tag ${tag === 'Must Visit' ? 'must_visit' : ''}`}
                                            >
                                                <FireOutlined />
                                                {captilizeEachWord(tag)}
                                            </Tag>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className='right'>
                                <iframe
                                    title='map'
                                    src={destination?.mapLocation}
                                    allowfullscreen='true'
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                >
                                </iframe>
                            </div>
                        </div>

                        <div className='more_about_destination section'>
                            <h2 className='header_1'>
                                Read More about <span className='light'>{destination?.destinationName}</span>
                            </h2>

                            <div className='more_about_destination__content' ref={moreDetainsRef}>
                                <div
                                    className={`overlay ${redMoreOpen ? 'open' : ''}`}
                                >
                                    <Button onClick={() => setReadMoreOpen(!redMoreOpen)} className='button more' type='link'>
                                        Read More...
                                    </Button>
                                </div>
                                <ReactQuill
                                    value={descriptionCount(destination?.destinationDescription)}
                                    readOnly={true}
                                    theme='bubble'
                                />
                                {
                                    redMoreOpen &&
                                    <Button onClick={() => setReadMoreOpen(!redMoreOpen)} className='button less' type='link'>
                                        Read Less...
                                    </Button>
                                }
                            </div>
                        </div>

                        <div className='attractions section'>
                            <h2 className='header_1'>
                                The Best of {destination?.destinationName}<span className='light'> Attractions & Experiences</span>
                            </h2>

                            <div className='attractions__content'>
                                <Row xs={1} md={2} lg={4} xl={4} className="g-4">
                                    {!loadingAttractions && attractions?.map((item, idx) => (
                                        <Col key={idx}>
                                            <Card
                                                onClick={() => navigate(`/destinations/${destinationId}/attractions/${item?.id}`)}
                                                data-aos="fade-up"
                                                data-aos-delay={`${idx * 100}`}
                                                className='attraction__card'
                                                style={{
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                }}
                                            >
                                                <img
                                                    src={item?.thumbnailUrl || DEFAULT_THUMBNAIL}
                                                    alt={item?.attractionName}
                                                    loading='lazy'
                                                />

                                                <Card.Body
                                                    className='destination__card__content'
                                                >
                                                    <h2 className='header_3'>{item?.attractionName}</h2>
                                                    <div className='tags'>
                                                        {
                                                            item?.tags?.split(',').map((tag, idx) => (
                                                                <Tag > {tag} </Tag>
                                                            ))
                                                        }
                                                    </div>
                                                    <ReactQuill
                                                        value={item && resizeString(item?.attractionDescriptionShort, 140)}
                                                        readOnly={true}
                                                        theme='bubble'
                                                    />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}

                                    {
                                        loadingAttractions && <>
                                            {Array.from({
                                                length: window.innerWidth > 1024 ? 4 : window.innerWidth > 768 ? 3 : 2
                                            }).map((_, idx) => (
                                                <Col key={idx}>
                                                    <Card className='attraction__card_loading'>
                                                        <Card.Body>
                                                            <Skeleton.Image active />
                                                            <Skeleton.Button shape='square' active />
                                                            <Skeleton active />
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </>
                                    }
                                </Row>
                                {
                                    loadingAttractions === false && attractions?.length === 0 && (
                                        <div className='empty_cards'>
                                            <Empty
                                                description={<span>No found</span>}
                                            />
                                        </div>
                                    )
                                }
                                {!loadingAttractions &&
                                    <div className='pagination_container'>
                                        <Pagination
                                            className='pagination'
                                            defaultCurrent={attractionsPage}
                                            total={attractions[0]?.filteredAttractionCount}
                                            onChange={(page) => {
                                                setAttractionsPage(page)
                                                handleGetAttractions(page)
                                            }}
                                            pageSize={4}
                                            showSizeChanger={false}
                                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                        />
                                    </div>
                                }
                            </div>
                        </div>


                        {activities && activities.length > 0 &&
                            <div className='activities section'>
                                <h2 className='header_1'>
                                    What to do in <span className='light'>{destination?.destinationName}</span>
                                </h2>

                                <div className='attractions__content'>
                                    <Row xs={1} md={2} lg={4} xl={4} className="g-4">
                                        {!loadingActivities && activities?.map((item, idx) => (
                                            <Col key={idx}>
                                                <Card
                                                    onClick={() => navigate(`/destinations/${destinationId}/activities/${item?.id}`)}
                                                    data-aos="fade-up"
                                                    data-aos-delay={`${idx * 100}`}
                                                    className='attraction__card'
                                                    style={{
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center',
                                                    }}
                                                >
                                                    <img
                                                        src={item?.thumbnailUrl || DEFAULT_THUMBNAIL}
                                                        alt={item?.activityName}
                                                        loading='lazy'
                                                    />

                                                    <Card.Body
                                                        className='destination__card__content'
                                                    >
                                                        <h2 className='header_3'>{item?.activityName}</h2>
                                                        <div className='tags'>
                                                            {
                                                                item?.tags?.split(',').map((tag, idx) => (
                                                                    <Tag > {tag} </Tag>
                                                                ))
                                                            }
                                                        </div>
                                                        <ReactQuill
                                                            value={item && resizeString(item?.activityDescriptionShort, 100)}
                                                            readOnly={true}
                                                            theme='bubble'
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))}

                                        {
                                            loadingActivities && <>
                                                {Array.from({
                                                    length: window.innerWidth > 1024 ? 4 : window.innerWidth > 768 ? 3 : 2
                                                }).map((_, idx) => (
                                                    <Col key={idx}>
                                                        <Card className='attraction__card_loading'>
                                                            <Card.Body>
                                                                <Skeleton.Image active />
                                                                <Skeleton.Button shape='square' active />
                                                                <Skeleton active />
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </>
                                        }
                                    </Row>
                                    {
                                        loadingActivities === false && activities?.length === 0 && (
                                            <div className='empty_cards'>
                                                <Empty
                                                    description={<span>No found</span>}
                                                />
                                            </div>
                                        )
                                    }
                                    {!loadingActivities &&
                                        <div className='pagination_container'>
                                            <Pagination
                                                className='pagination'
                                                defaultCurrent={activitiesPage}
                                                total={activities[0]?.filteredActivityCount}
                                                onChange={(page) => {
                                                    setActivitiesPage(page)
                                                    handleGetActivities(page)
                                                }}
                                                pageSize={4}
                                                showSizeChanger={false}
                                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>}

                        {destination?.destinationVideos && <div className='videos section'
                            style={{
                                backgroundImage: `url(${destination?.thumbnailUrl || DEFAULT_THUMBNAIL})`,
                            }}
                        >
                            <Carousel arrows>
                                {
                                    destination?.destinationVideos && destination?.destinationVideos.split(',').map((video, index) => (
                                        <div key={index}>
                                            <video controls>
                                                <source src={video} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    ))
                                }
                            </Carousel>
                        </div>}

                        {events && events.length > 0 &&
                            <div className='events section'>
                                <h2 className='header_1'>
                                    Events in <span className='light'>{destination?.destinationName}</span>
                                </h2>

                                <div className='attractions__content'>
                                    <Row xs={1} md={2} lg={4} xl={4} className="g-4">
                                        {!loadingEvents && events?.map((item, idx) => (
                                            <Col key={idx}>
                                                <Card
                                                    onClick={() => navigate(`/destinations/${destinationId}/events/${item?.id}`)}
                                                    data-aos="fade-up"
                                                    data-aos-delay={`${idx * 100}`}
                                                    className='attraction__card'
                                                    style={{
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center',
                                                    }}
                                                >
                                                    <img
                                                        src={item?.thumbnailUrl || DEFAULT_THUMBNAIL}
                                                        alt={item?.eventName}
                                                        loading='lazy'
                                                    />

                                                    <Card.Body
                                                        className='destination__card__content'
                                                    >
                                                        <h2 className='header_3'>{item?.eventName}</h2>
                                                        <div className='tags'>
                                                            {
                                                                item?.tags?.split(',').map((tag, idx) => (
                                                                    <Tag > {tag} </Tag>
                                                                ))
                                                            }
                                                        </div>
                                                        <ReactQuill
                                                            value={item && resizeString(item?.eventDescriptionShort, 100)}
                                                            readOnly={true}
                                                            theme='bubble'
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))}

                                        {
                                            loadingEvents && <>
                                                {Array.from({
                                                    length: window.innerWidth > 1024 ? 4 : window.innerWidth > 768 ? 3 : 2
                                                }).map((_, idx) => (
                                                    <Col key={idx}>
                                                        <Card className='attraction__card_loading'>
                                                            <Card.Body>
                                                                <Skeleton.Image active />
                                                                <Skeleton.Button shape='square' active />
                                                                <Skeleton active />
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </>
                                        }
                                    </Row>
                                    {
                                        loadingEvents === false && events?.length === 0 && (
                                            <div className='empty_cards'>
                                                <Empty
                                                    description={<span>No found</span>}
                                                />
                                            </div>
                                        )
                                    }
                                    {!loadingEvents &&
                                        <div className='pagination_container'>
                                            <Pagination
                                                className='pagination'
                                                defaultCurrent={eventsPage}
                                                total={events[0]?.filteredEventsCount}
                                                onChange={(page) => {
                                                    setEventsPage(page)
                                                    handleGetEvents(page)
                                                }}
                                                pageSize={4}
                                                showSizeChanger={false}
                                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>}

                        {relatedBlogs?.length > 0 &&
                            <div className='blogs section'>
                                <h2 className='header_1'>
                                    Read our Blogs
                                </h2>

                                <div className='items__list' style={{ marginTop: 20 }}>
                                    <div className='section__all__destinations__content'>
                                        <Row xs={1} md={2} lg={3} xl={4} className="g-4">
                                            {!loading && relatedBlogs?.map((item, idx) => (
                                                <Col key={idx}>
                                                    <Card
                                                        data-aos="fade-up"
                                                        data-aos-delay={`${idx * 100}`}
                                                        className='package__card'
                                                        style={{
                                                            backgroundSize: 'cover',
                                                            backgroundPosition: 'center',
                                                        }}
                                                        onClick={() => navigate(`/blogs/${item?.id}`)}
                                                    >
                                                        <LazyLoadImage
                                                            src={item?.blogImages?.split(',')[0] || DEFAULT_THUMBNAIL}
                                                            alt={item?.packageName}
                                                            effect='blur'
                                                        />

                                                        <Card.Body
                                                            className='destination__card__content'
                                                        >
                                                            <h2>{item?.blogTitle}</h2>
                                                            <div className='tags'>
                                                                {
                                                                    item?.blogTags?.split(',').map((tag, idx) => (
                                                                        <Tag > {tag} </Tag>
                                                                    ))
                                                                }
                                                            </div>
                                                            <p>{item?.blogDescription ? resizeString(item?.blogDescription, 100) : 'No Description'}</p>

                                                            {item?.blogType === 'admin' ?
                                                                <div className='author'>
                                                                    <div className='author_image'>
                                                                        <LazyLoadImage
                                                                            alt='author'
                                                                            src={DEFAULT_PROFILE_IMAGE_VISIT_PEARL}
                                                                            effect='blur'
                                                                        />
                                                                    </div>
                                                                    <div className='author_name'>
                                                                        <h3>Visit Pearl</h3>
                                                                        <p>{moment(item?.createdAt, 'MMM D YYYY h:mma').format('MMMM Do YYYY')}</p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='author'>
                                                                    <div className='author_image'>
                                                                        <LazyLoadImage
                                                                            alt='author'
                                                                            src={item?.profilePicUrl || DEFAULT_PROFILE_IMAGE}
                                                                            effect='blur'
                                                                        />
                                                                    </div>
                                                                    <div className='author_name'>
                                                                        <h3>{item?.userName}</h3>
                                                                        <p>{moment(item?.createdAt, 'MMM D YYYY h:mma').format('MMMM Do YYYY')}</p>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                </div>
                            </div>}
                    </div >
                    :
                    <div className='loading_page'>
                        <LoadingComp />
                    </div>
            }
        </>
    )
}

export default Destination