import { combineReducers } from "redux";

import destinationsReducer from "./destinations/destinations.reducer";
import { tourPackagesReducer } from "./tourPackages/tourPackages.reducer";
import { pageSettingsReducer } from "./pageSettings/pageSettings.reducer";
import { myProfileReducer } from "./myProfile/myProfile.reducer";
import { notificationsReducer } from "./notifications/notifications.reducer";
import { currenciesReducer } from "./currencies/currencies.reducer";

const rootReducer = combineReducers({
    destinations: destinationsReducer,
    tourPackages: tourPackagesReducer,
    pageSettings: pageSettingsReducer,
    myProfile: myProfileReducer,
    newNotificationsCount: notificationsReducer,
    currencyRates: currenciesReducer
});

export default rootReducer;