import { Button, Empty, Skeleton, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { destinationTypes } from '../../../constants/itemTypes'
import { useDispatch } from 'react-redux'
import { fetchDestinationsAC } from '../../../../redux/actions/destinations/destinations.ac'
import useDestinations from '../../../hooks/useDestinations'
import { Row, Col, Card } from 'react-bootstrap';

import './styles.scss'
import { TbMapPin } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

const tabItems = [
    {
        key: 'all',
        label: 'All Destinations'
    },
    {
        key: destinationTypes.BEACH,
        label: 'Beaches'
    },
    {
        key: destinationTypes.MOUNTAIN,
        label: 'Mountains'
    },
    {
        key: destinationTypes.HISTORICAL_SITE,
        label: 'Historical Places'
    },
    {
        key: destinationTypes.WILDLIFE_PARK,
        label: 'Wild Life'
    },
    {
        key: destinationTypes.WATERFALL,
        label: 'Waterfalls'
    }
]


const SecAllDestinations = () => {
    const [activeTab, setActiveTab] = useState('all')
    const dispatch = useDispatch()
    const destinations = useDestinations()
    const navigate = useNavigate()

    useEffect(() => {
        handleFetchdestinations(1)
    }, [])

    const handleTabChange = async (key) => {
        if (destinations?.loading) return
        await handleFetchdestinations(1, key)
        setActiveTab(key)
    }

    const handleFetchdestinations = async (page, key) => {
        let values = key !== 'all' ? { destinationType: key } : {}
        values.page = page
        values.pageSize = 8
        await dispatch(fetchDestinationsAC(values))
    }

    const getCamelCase = (str) => {
        return str
            .replace(/_/g, ' ')
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
    }

    return (
        <div className='section_main section__all__destinations'>
            <Tabs
                defaultActiveKey="all"
                items={tabItems}
                activeTab={activeTab}
                onChange={(key) => handleTabChange(key)}
                aria-disabled
                data-aos="fade-up"
            />

            <div className='section__all__destinations__content'>
                <Row xs={1} md={2} lg={3} xl={4} className="g-4">
                    {!destinations?.loading && destinations?.destinations?.map((item, idx) => (
                        <Col key={idx}>
                            <Card
                                className='destination__card'
                                style={{
                                    backgroundImage: `url('${item?.thumbnailUrl}')`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                                data-aos="fade-up"
                                data-aos-delay={`${idx * 100}`}
                                onClick={() => navigate(`/destinations/${item?.id}`)}
                            >
                                <div className='background__overlay'></div>
                                <Card.Body
                                    className='destination__card__content'
                                >
                                    <h2
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px'
                                        }}
                                    ><TbMapPin /> {item?.destinationName}</h2>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}

                    {
                        destinations?.loading && <>
                            {Array.from({ length: 4 }).map((_, idx) => (
                                <Col key={idx}>
                                    <Card className='destination__card_loading'>
                                        <Card.Body>
                                            <Skeleton active />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </>
                    }
                </Row>
                {
                    destinations?.loading === false && destinations?.destinations?.length === 0 && (
                        <div className='empty_cards'>
                            <Empty
                                description={<span>No {getCamelCase(activeTab)} found</span>}
                            />
                        </div>
                    )
                }
                <Button
                    data-aos="fade-up"
                    data-aos-delay="500"
                    className='load_more_btn primary_btn_default'
                    loading={destinations?.loading}
                    onClick={() => {
                        navigate('/destinations')
                    }}
                >
                    View All...
                </Button>
            </div>
        </div>
    )
}

export default SecAllDestinations