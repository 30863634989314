import { fetchAllTourPackages } from '../../api/tourPackages.api';
import {
    FETCH_TOUR_PACKAGES,
    FETCH_TOUR_PACKAGES_SUCCESS,
    FETCH_TOUR_PACKAGES_FAIL,

} from '../../types';

export const fetchTourPackagesAC = (values) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_TOUR_PACKAGES
        })
        try {
            const tourPackagesResponse = await fetchAllTourPackages(values)
            console.log('tourPackagesResponse', tourPackagesResponse)
            dispatch({
                type: FETCH_TOUR_PACKAGES_SUCCESS,
                payload: tourPackagesResponse.data
            })
        } catch (error) {
            dispatch({
                type: FETCH_TOUR_PACKAGES_FAIL,
                payload: error.message
            })
        }
    }
}