import { CloudOutlined, EnvironmentOutlined, FireOutlined, HeartOutlined, HeartTwoTone, HomeOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Carousel, message, Tag } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { useNavigate } from 'react-router-dom'
import { DEFAULT_THUMBNAIL } from '../../../../constants/otherConstsnts';
import { captilizeEachWord } from '../../../../helpers/nameRender';
import ReactQuill from 'react-quill';
import LoadingComp from '../../../../components/LoadingComp';
import { getActivityById } from '../../../../../redux/api/activities.api';
import useMyProfile from '../../../../hooks/useMyProfile';
import { addToFavourites, deleteFavourite, getFavouriteById } from '../../../../../redux/api/favourite.api';
import { FAV_ACTIVITY } from '../../../../constants/favTypes';

const Activity = () => {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [redMoreOpen, setReadMoreOpen] = useState(false)
    const url = window.location.pathname
    const activityId = url.split('/')[4]
    const destinationId = url.split('/')[2]
    const [loading, setLoading] = useState(true)
    const [activity, setActivity] = useState(null)
    const moreDetainsRef = useRef()

    const myProfile = useMyProfile().userProfile
    const [isFavourite, setIsFavourite] = useState(null)
    const [loadingFavourite, setLoadingFavourite] = useState(false)


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (activityId) {
            handleGetAttraction(activityId)
        }
    }, [activityId])

    useEffect(() => {
        if (myProfile) {
            handleCheckIsFavourite()
        }
    }, [myProfile])

    const handleCheckIsFavourite = async () => {
        setLoadingFavourite(true)
        try {
            const response = await getFavouriteById(activityId)
            setIsFavourite(response.data)
        } catch (error) {
            console.error('Error checking favourite', error)
        }
        setLoadingFavourite(false)
    }

    const handleOnClikFavourite = async () => {
        setLoadingFavourite(true)
        if (isFavourite) {
            await deleteFavourite(isFavourite.id)
            setIsFavourite(null)
            messageApi.open({
                type: 'success',
                content: 'Removed from favourites',
                duration: 2,
            });
        } else {
            await addToFavourites({
                userId: myProfile?.id,
                favouriteId: activityId,
                favouriteType: FAV_ACTIVITY
            })
            await handleCheckIsFavourite()
            messageApi.open({
                type: 'success',
                content: 'Added to favourites',
                duration: 2,
            });
        }
        setLoadingFavourite(false)
    }

    const handleGetAttraction = async (id) => {
        setLoading(true)
        try {
            const item = await getActivityById(id)
            setActivity(item.data[0])
        } catch (error) {
            console.error('Error fetching destination', error)
        }
        setLoading(false)
    }

    const renderImages = (images) => {
        if (images?.length > 2) {
            const moreImages = images.slice(2)
            return (
                <div className='images_container more_than_two'>
                    <PhotoView src={images[0]}>
                        <LazyLoadImage
                            alt='image'
                            effect="blur"
                            wrapperProps={{
                                style: { transitionDelay: "0.1s" },
                            }}
                            src={images[0]}
                        />
                    </PhotoView>
                    <PhotoView src={images[1]}>
                        <LazyLoadImage
                            alt='image'
                            effect="blur"
                            wrapperProps={{
                                style: { transitionDelay: "0.1s" },
                            }}
                            src={images[1]}
                        />
                    </PhotoView>
                    <div className='more_images'>
                        {
                            moreImages.map((image, index) => (
                                <PhotoView key={index} src={image}>
                                    <LazyLoadImage
                                        alt='image'
                                        effect="blur"
                                        wrapperProps={{
                                            style: { transitionDelay: "0.1s" },
                                        }}
                                        src={image}
                                    />
                                </PhotoView>
                            ))
                        }
                    </div>
                </div>
            )
        } else {
            return (
                <div className='images_container'>
                    {
                        images?.map((image, index) => (
                            <PhotoView key={index} src={image}>
                                <LazyLoadImage
                                    alt='image'
                                    effect="blur"
                                    wrapperProps={{
                                        style: { transitionDelay: "0.1s" },
                                    }}
                                    src={image}
                                />
                            </PhotoView>
                        ))
                    }
                </div>
            )
        }
    }

    const descriptionCount = (description) => {
        let count = window.innerWidth > 1024 ? 1200 : 500
        return !redMoreOpen ? (description?.length > 200 ? description.slice(0, count) + '...' : description) : description
    }

    const copyLinkToClipboard = () => {
        const url = window.location.href
        navigator.clipboard.writeText(url)
        messageApi.open({
            type: 'success',
            content: 'Link copied to clipboard',
            duration: 2,
        });
    }

    return (
        <>
            {
                !loading ?
                    <div className='main__contaier destination section_main' >
                        {contextHolder}
                        <div className='hero top_margin_main'>
                            <Breadcrumb
                                className='breadcrumb_pages'
                                items={[
                                    {
                                        title: <div className='breadcrumb__item' onClick={() => navigate('/')}><HomeOutlined /> Home</div>,
                                    },
                                    {
                                        title: <div className='breadcrumb__item' onClick={() => navigate('/destinations')} >Destinations</div>,
                                    },
                                    {
                                        title: <div className='breadcrumb__item'
                                            onClick={() => navigate(`/destinations/${destinationId}`)}
                                        >
                                            {loading ? 'Loading...' : activity?.destinationName}
                                        </div>,
                                    },
                                    {
                                        title: <div className='breadcrumb__item last'>Activity: {loading ? 'Loading...' : activity?.activityName}</div>,
                                    }
                                ]}
                            />
                            <div className='hero__content_header'>
                                <h1 data-aos="fade-up" data-aos-delay="300" className='header_1 align_start'>
                                    {loading ? 'Loading...' : activity?.activityName}
                                </h1>

                                <div className='actions'>
                                    <Button onClick={copyLinkToClipboard} className='button dark' type='link'><ShareAltOutlined /></Button>
                                    <Button
                                        className='button'
                                        type='link'
                                        loading={loadingFavourite}
                                        onClick={handleOnClikFavourite}
                                    >
                                        {isFavourite
                                            ?
                                            <HeartTwoTone twoToneColor="#eb2f96" />
                                            :
                                            <HeartOutlined />}
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className='image_gallery'>
                            <PhotoProvider>
                                <div className='left'>
                                    <PhotoView src={activity?.thumbnailUrl}>
                                        <LazyLoadImage
                                            alt='image'
                                            effect="blur"
                                            wrapperProps={{
                                                style: { transitionDelay: "0.1s" },
                                            }}
                                            src={activity && (activity?.thumbnailUrl || DEFAULT_THUMBNAIL)}
                                        />
                                    </PhotoView>
                                </div>
                                <div className='right'>
                                    {
                                        renderImages(activity?.activityImages?.split(','))
                                    }
                                </div>
                                <div className='total'>
                                    <span>{activity?.activityImages?.split(',').length + (activity?.thumbnailUrl ? 1 : 0)} photos</span>
                                </div>
                            </PhotoProvider>
                        </div>

                        <div className='about_destination section'>
                            <div className='left'>
                                <h2 className='heder_2'>
                                    About {activity?.activityName}
                                </h2>

                                <div className='description_short'>
                                    <ReactQuill
                                        value={activity?.activityDescriptionShort}
                                        readOnly={true}
                                        theme='bubble'
                                    />
                                    <span
                                        onClick={() => {
                                            moreDetainsRef.current.scrollIntoView({ behavior: 'smooth' })
                                        }}
                                        className='read_more'>Read More...</span>
                                </div>


                                <div className='weather_district'>
                                    <div className='item'>
                                        <EnvironmentOutlined />
                                        <p>{activity?.activityCity}</p>
                                    </div>
                                    <div className='item'>
                                        <CloudOutlined />
                                        <p>
                                            {activity?.weather}
                                        </p>
                                    </div>
                                </div>

                                <div className='tags'>
                                    {
                                        activity?.tags?.split(',').map((tag, index) => (
                                            <Tag key={index}
                                                className={`tag ${tag === 'Must Visit' ? 'must_visit' : ''}`}
                                            >
                                                <FireOutlined />
                                                {captilizeEachWord(tag)}
                                            </Tag>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className='right'>
                                <iframe
                                    title='map'
                                    src={activity?.mapLocation}
                                    allowfullscreen='true'
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                >
                                </iframe>
                            </div>
                        </div>

                        <div className='more_about_destination section'>
                            <h2 className='header_1'>
                                Read More about <span className='light'>{activity?.activityName}</span>
                            </h2>

                            <div
                                className='more_about_destination__content'
                                ref={moreDetainsRef}
                            >
                                <div
                                    className={`overlay ${redMoreOpen ? 'open' : ''}`}
                                >
                                    <Button onClick={() => setReadMoreOpen(!redMoreOpen)} className='button more' type='link'>
                                        Read More...
                                    </Button>
                                </div>
                                <ReactQuill
                                    value={descriptionCount(activity?.activityDescription)}
                                    readOnly={true}
                                    theme='bubble'
                                />
                                {
                                    redMoreOpen &&
                                    <Button onClick={() => setReadMoreOpen(!redMoreOpen)} className='button less' type='link'>
                                        Read Less...
                                    </Button>
                                }
                            </div>
                        </div>

                        {activity?.activityVideos && <div className='videos section'
                            style={{
                                backgroundImage: `url(${activity?.thumbnailUrl || DEFAULT_THUMBNAIL})`,
                            }}
                        >
                            <Carousel arrows>
                                {
                                    activity?.activityVideos && activity?.activityVideos?.split(',').map((video, index) => (
                                        <div key={index}>
                                            <video controls>
                                                <source src={video} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    ))
                                }
                            </Carousel>
                        </div>}

                    </div>
                    :
                    <div className='loading_page'>
                        <LoadingComp />
                    </div>
            }
        </>
    )
}

export default Activity