import React, { useEffect, useState } from 'react'
import useMyProfile from '../../../hooks/useMyProfile';
import { Button, Skeleton, Tooltip } from 'antd';
import { CheckCircleFilled, EditOutlined } from '@ant-design/icons';

import './styles.scss'
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getBlogsByUser } from '../../../../redux/api/blogs.api';

const MyBlogs = () => {
    const myProfile = useMyProfile();
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (myProfile?.userProfile) {
            handlefetchBlogs();
        }
    }, [myProfile]);

    const handlefetchBlogs = async () => {
        setLoading(true);
        const response = await getBlogsByUser(myProfile?.userProfile?.id);
        if (response.status === 200) {
            setBlogs(response.data);
        }
        setLoading(false);
    }

    return (
        <div className='main__contaier my_reviews'>
            <div className='header'>
                <h2 className='header_1' style={{ marginBottom: '20px' }}>Your Blogs</h2>
                <Button
                    className='custom_primary_btn'
                    onClick={() => navigate('/blogs/write')}
                ><EditOutlined /> Write Blog</Button>
            </div>

            <div className='reviews'>
                {
                    loading &&
                    Array(3).fill(0).map((_, index) => (
                        <Card key={index} className='paymentLoading' style={{
                            marginBottom: '10px',
                            border: 'none',
                            padding: '20px',
                        }}>
                            <Skeleton active />
                        </Card>
                    ))
                }
                {
                    !loading && blogs.length > 0 &&
                    blogs.map((blog, index) => (
                        <Card
                            key={index}
                            className='blog_card'
                            onClick={() => navigate(`/dashboard/my-blogs/${blog?.id}`)}
                        >
                            <div className='review_card__header'>
                                <h3>{blog?.blogTitle}</h3>
                                <Tooltip
                                    title={blog?.blogStatus !== 'PENDING_APPROVAL'
                                        ?
                                        'Published'
                                        :
                                        'Pending Approval'
                                    }>
                                    <CheckCircleFilled style={{ fontSize: '20px', color: blog?.blogStatus !== 'PENDING_APPROVAL' ? '#52C41A' : 'gray' }} />
                                </Tooltip>
                            </div>
                            <div className='review_card__body'>
                                <p>{blog?.blogDescription}</p>
                                <div className='images'
                                    style={{
                                        display: 'flex',
                                        gap: '10px',
                                        marginTop: '10px',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                </div>
                            </div>
                        </Card>
                    ))
                }
            </div>
        </div>
    )
}

export default MyBlogs