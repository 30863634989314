import { CarOutlined, StarOutlined, WifiOutlined } from "@ant-design/icons"
import { BiDumbbell, BiFoodMenu, BiSpa, BiSwim } from "react-icons/bi";
import { CiCircleCheck } from "react-icons/ci";
import { FiWind } from "react-icons/fi";
import { TbBottle } from "react-icons/tb";

export const getFacilityIcon = (facility) => {
    let icon = <StarOutlined />
    switch (facility) {
        case 'WIFI':
            icon = <WifiOutlined />
            break;
        case 'PARKING':
            icon = <CarOutlined />
            break;
        case 'POOL':
            icon = <BiSwim />
            break;
        case 'AIR_CONDITIONING':
            icon = <FiWind />
            break;
        case 'RESTAURANT':
            icon = <BiFoodMenu />
            break;
        case 'BAR':
            icon = <TbBottle />
            break;
        case 'SPA':
            icon = <BiSpa />
            break;
        case 'FITNESS_CENTER':
            icon = <BiDumbbell />
            break;
        default:
            icon = <CiCircleCheck />
            break;
    }

    return icon;
}