import React, { useEffect } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import './styles.scss'
import { Button, Divider } from 'antd'

const AboutSriLanka = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='about_srilanka section_main' style={{ marginBottom: '60px' }}>
            <div className='top_padding_container'>
                <div className='hero'>
                    <div className='left'>
                        <h3 className='heder_2'>Welcome to Sri Lanka</h3>
                        <h2 className='header_1'>Pearl of the Indian Ocen</h2>

                        <p className='paragraph'>
                            Sri Lanka, often described as the Pearl of the Indian ocean, is a breathtaking island country located in the Indian Ocean, just off the southeastern coast of India. Despite its relatively small size, Sri Lanka is incredibly diverse, boasting a rich history, vibrant culture, and extraordinary landscapes that range from lush rainforests and tea plantations to golden beaches and bustling cities.
                            <br /><br />
                            In Sri Lanka, you’ll discover a unique blend of ancient traditions, natural beauty, and modern conveniences, making it one of the most rewarding destinations for travelers from around the globe. Whether you're drawn to the country's historical landmarks, wildlife, food, or serene coastal towns, Sri Lanka promises an unforgettable journey of discovery.
                        </p>
                    </div>
                    <div className='right'>
                        <LazyLoadImage
                            src={require('../../Assets/images/sir_lanka_hero.webp')}
                            alt='srilanka'
                            effect='blur'
                        />
                    </div>
                </div>

                <Divider />

                <div className='content'>
                    <div className='left'>
                        <h3 className='heder_2'>A Journey Through Sri Lanka’s Rich History</h3>
                        <p className='paragraph'>
                            Sri Lanka's history dates back over 2,500 years, with early civilizations flourishing in the fertile northern plains. The island has seen the rise and fall of powerful kingdoms, each leaving behind remarkable monuments, religious sites, and architectural masterpieces that continue to captivate modern-day visitors.
                        </p>
                        <h3 className='header_4'>Ancient Cities and Kingdoms</h3>
                        <p className='paragraph'>
                            One of the highlights of Sri Lanka’s ancient history is the Cultural Triangle, which includes the cities of Anuradhapura, Polonnaruwa, and Kandy. These cities are home to UNESCO World Heritage Sites, where travelers can explore ancient stupas, royal palaces, and intricate stone carvings.
                        </p>
                        <ul>
                            <li><strong>Anuradhapura:</strong> The first capital of Sri Lanka and a key site of Buddhism, known for its towering dagobas (stupas), monasteries, and the sacred Bodhi Tree, under which Lord Buddha attained enlightenment.</li>
                            <li>
                                <strong>Polonnaruwa:</strong> This medieval city was a center of commerce and religion, filled with ancient temples, statues of Buddha, and well-preserved ruins of palaces. The Gal Vihara, with its colossal rock carvings of Buddha, is a must-see.
                            </li>
                            <li>
                                <strong>Sigiriya:</strong> Known as the "Lion Rock," Sigiriya is an ancient rock fortress and a symbol of Sri Lanka’s engineering brilliance. The climb to the top offers stunning views, and the site is adorned with beautiful frescoes and elaborate water gardens.
                            </li>
                            <li>
                                <strong>Kandy:</strong> The spiritual heart of Sri Lanka, Kandy is home to the Temple of the Sacred Tooth Relic, one of the most venerated places of worship for Buddhists worldwide. The city is also the venue for the annual Esala Perahera festival, where the tooth relic is paraded through the streets with a spectacular procession of dancers, elephants, and musicians.
                            </li>
                        </ul>
                    </div>
                    <div className='right'>
                        <LazyLoadImage
                            src={require('../../Assets/images/ancient.webp')}
                            alt='srilanka'
                            effect='blur'
                        />
                    </div>
                </div>

                <Divider />

                <div className='content'>
                    <div className='right'>
                        <LazyLoadImage
                            src={require('../../Assets/images/beaches.webp')}
                            alt='srilanka'
                            effect='blur'
                        />
                    </div>

                    <div className='left'>
                        <h3 className='heder_2'>Nature’s Wonderland: Sri Lanka’s Diverse Landscapes</h3>
                        <p className='paragraph'>
                            Sri Lanka may be a small island, but it offers an astonishing variety of landscapes. From palm-fringed beaches and rolling tea plantations to dense jungles teeming with wildlife, the island’s natural beauty is unparalleled.
                        </p>
                        <h3 className='header_4'>Beaches and Coastal Treasures</h3>
                        <p className='paragraph'>
                            The country’s 1,340 kilometers of coastline is home to some of the world’s most beautiful beaches. Whether you’re looking for a secluded hideaway or a bustling beach town, Sri Lanka has something for every type of traveler.
                        </p>
                        <ul>
                            <li><strong>South Coast:</strong> Home to popular beach destinations like Galle, Unawatuna, Mirissa, and Tangalle, the south coast offers a blend of golden beaches, charming coastal towns, and historical sites. Galle, a fortified city built by the Portuguese and later developed by the Dutch, is a UNESCO World Heritage Site known for its colonial architecture and vibrant markets.</li>
                            <li>
                                <strong>East Coast:</strong> The quieter east coast, including Trincomalee and Arugam Bay, is ideal for surfing, snorkeling, and whale watching. Arugam Bay is a world-famous surf spot, while the calm waters of Nilaveli Beach near Trincomalee are perfect for underwater exploration.
                            </li>
                            <li>
                                <strong>West Coast:</strong> The west coast is home to the vibrant capital city of Colombo, a bustling hub of commerce, culture, and entertainment. This region also offers serene beaches like Negombo and Kalpitiya, where kite surfing and dolphin watching are popular.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='content'>
                    <div className='left'>
                        <h3 className='header_4'>Mountains, Waterfalls, and Tea Plantations</h3>
                        <p className='paragraph'>
                            Sri Lanka’s Hill Country is a land of lush green mountains, cool climate, and scenic beauty. The region is world-renowned for its Ceylon tea plantations, waterfalls, and picturesque towns.
                        </p>
                        <ul>
                            <li><strong>Ella:</strong> Famous for its stunning views, trekking paths, and the iconic Nine Arches Bridge, Ella offers visitors the chance to immerse themselves in the natural beauty of the island’s central highlands.</li>
                            <li>
                                <strong>Nuwara Eliya:</strong> Known as “Little England,” this charming town is surrounded by tea plantations, misty hills, and colonial-era bungalows. Visitors can enjoy a cup of freshly brewed Ceylon tea while taking in the scenic beauty.
                            </li>
                            <li>
                                <strong>Horton Plains National Park:</strong> A protected area and a UNESCO World Heritage Site, Horton Plains is home to World’s End, a dramatic escarpment with a sheer drop of about 870 meters, offering spectacular views over the surrounding countryside.
                            </li>
                        </ul>
                    </div>

                    <div className='right'>
                        <LazyLoadImage
                            src={require('../../Assets/images/waterfall.webp')}
                            alt='srilanka'
                            effect='blur'
                        />
                    </div>
                </div>

                <div className='content'>
                    <div className='right'>
                        <LazyLoadImage
                            src={require('../../Assets/images/wildlife.webp')}
                            alt='srilanka'
                            effect='blur'
                        />
                    </div>
                    <div className='left'>
                        <h3 className='header_4'>National Parks and Wildlife</h3>
                        <p className='paragraph'>
                            Sri Lanka’s wildlife is one of its most enchanting draws. The island is home to a rich array of species, from elephants and leopards to birds and marine life.
                        </p>
                        <ul>
                            <li><strong>Yala National Park:</strong> Located in the southeastern region of Sri Lanka, Yala is the country's most famous wildlife park. It is home to a large population of leopards, making it one of the best places in the world to spot these elusive big cats. In addition to leopards, Yala is home to elephants, sloth bears, crocodiles, and a wide variety of bird species.</li>
                            <li>
                                <strong>Udawalawe National Park:</strong> If elephants are your passion, Udawalawe is the place to be. The park is home to hundreds of wild elephants, and visitors are almost guaranteed to see herds of these gentle giants during a jeep safari. Udawalawe is also a sanctuary for birdwatchers, with numerous species of endemic and migratory birds.
                            </li>
                            <li>
                                <strong>Wilpattu National Park:</strong> Located in the northwest, Wilpattu is the largest national park in Sri Lanka. It is less visited than Yala but offers equally rewarding wildlife encounters, including leopards, sloth bears, and a wide variety of birds.
                            </li>
                            <li>
                                <strong>Sinharaja Forest Reserve:</strong> This <strong><i>UNESCO</i></strong> World Heritage Site is Sri Lanka’s last undisturbed area of tropical rainforest. It is home to more than half of the country’s endemic species of mammals, birds, butterflies, and trees. Trekking through Sinharaja is an immersive experience, with dense foliage, the
                            </li>
                        </ul>
                    </div>
                </div>

                <Divider />

                <div className='content'>
                    <div className='left'>
                        <h3 className='heder_2'>Cultural Melting Pot: Festivals, Art, and Spirituality</h3>
                        <h3 className='header_4' style={{ marginTop: 10 }}>Buddhism, Hinduism, and Colonial Influences</h3>
                        <p className='paragraph'>
                            Sri Lanka is predominantly Buddhist, with over 70% of the population practicing Theravada Buddhism. However, Hinduism, Christianity, and Islam are also deeply ingrained in the island’s cultural fabric. The harmonious coexistence of these religions is reflected in the vibrant mix of temples, churches, and mosques found across the country.
                        </p>
                        <ul>
                            <li><strong>Buddhist Sites:</strong> Aside from the famous Temple of the Tooth Relic in Kandy, the Dambulla Cave Temple is another significant religious site. With its towering golden Buddha statue and intricate cave paintings, Dambulla is a living testament to Sri Lanka’s Buddhist heritage.</li>
                            <li>
                                <strong>Hindu Temples:</strong> In the north and east of the island, Hinduism is the dominant religion. Temples such as the Nallur Kandaswamy Kovil in Jaffna and the Munneswaram Temple near Chilaw are important pilgrimage sites for Tamil Hindus.
                            </li>
                            <li>
                                <strong>Colonial Architecture:</strong> Sri Lanka was colonized by the Portuguese, Dutch, and British, and their influence is evident in the country’s architecture. The city of Galle is famous for its well-preserved Dutch Fort, while Colombo is home to grand colonial-era buildings, such as the Old Parliament Building and the National Museum.
                            </li>
                        </ul>
                    </div>

                    <div className='right'>
                        <LazyLoadImage
                            src={require('../../Assets/images/buddhist.webp')}
                            alt='srilanka'
                            effect='blur'
                        />
                    </div>
                </div>

                <div className='content'>
                    <div className='right'>
                        <LazyLoadImage
                            src={require('../../Assets/images/perahara.webp')}
                            alt='srilanka'
                            effect='blur'
                        />
                    </div>
                    <div className='left'>
                        <h3 className='header_4'>Colorful Festivals</h3>
                        <p className='paragraph'>
                            Sri Lanka’s festivals are a vibrant celebration of the country’s religious and cultural diversity. Every month of the year offers a chance to witness or participate in one of these colorful events.
                        </p>
                        <ul>
                            <li><strong>Esala Perahera:</strong> Held in July or August, this is Sri Lanka’s most famous festival. Over 10 days, the sacred tooth relic of the Buddha is paraded through the streets of Kandy, accompanied by dancers, drummers, and caparisoned elephants. The spectacle draws thousands of visitors, both local and international, who come to witness this spiritual event.</li>
                            <li>
                                <strong>Vesak:</strong> Celebrated in May, Vesak marks the birth, enlightenment, and passing of the Buddha. Streets are adorned with colorful lanterns and illuminated displays, and temples hold special services and alms-giving ceremonies.
                            </li>
                            <li>
                                <strong>Sinhala and Tamil New Year:</strong> The Sri Lankan New Year, celebrated in April, is a time of renewal, family gatherings, and traditional games. Homes are filled with the aroma of kiribath (milk rice), and communities come together to celebrate with food, music, and dance.
                            </li>
                        </ul>
                    </div>
                </div>

                <Divider />

                <div className='content'>
                    <div className='left'>
                        <h3 className='heder_2'>A Culinary Adventure: Taste the Flavors of Sri Lanka</h3>
                        <p className='paragraph'>
                            Sri Lanka’s food is a reflection of its cultural diversity, with influences from India, Malaysia, the Netherlands, and Portugal. The island’s cuisine is a delightful fusion of bold flavors, fresh ingredients, and aromatic spices.
                        </p>
                        <h3 className='header_4' style={{ marginBottom: 10 }}>Signature Dishes</h3>
                        <ul>
                            <li><strong>Rice and Curry:</strong> The cornerstone of Sri Lankan cuisine, rice and curry is a meal that consists of rice accompanied by a variety of meat, fish, and vegetable curries. Sri Lankan curries are known for their complex spice mixes, often featuring ingredients such as cinnamon, cardamom, coriander, and chili. Each region of the country has its own variation of this dish, making it a must-try wherever you go.</li>
                            <li>
                                <strong>Hoppers:</strong> These crispy, bowl-shaped pancakes are made from fermented rice flour and coconut milk. They are a popular street food and come in a variety of forms. Egg hoppers, topped with a fried egg, are a favorite for breakfast, while sweet hoppers are filled with coconut and jaggery.
                            </li>
                            <li>
                                <strong>Kottu Roti:</strong> Often referred to as the ultimate Sri Lankan street food, kottu is made by stir-frying chopped roti bread with vegetables, meat (usually chicken or beef), eggs, and a selection of spices. The rhythmic clanging of metal blades chopping up the roti on hot iron griddles is a familiar sound in Sri Lankan night markets.
                            </li>
                            <li>
                                <strong>Sri Lankan Seafood:</strong> Sri Lanka’s coastline provides an abundance of fresh seafood. Curry crab, grilled lobster, and coconut shrimp are just a few of the mouthwatering dishes that can be found in seaside restaurants across the island.
                            </li>
                            <li>
                                <strong>Fruit and Sweets:</strong> Sri Lanka is a tropical paradise when it comes to fruits. Mangoes, bananas, pineapples, and papayas are abundant, while more exotic offerings such as rambutan, mangosteen, and durian can be found in local markets. For dessert, try watalappam, a creamy coconut custard dessert with jaggery and spices.
                            </li>
                        </ul>
                    </div>
                    <div className='right'>
                        <LazyLoadImage
                            src={require('../../Assets/images/food.webp')}
                            alt='srilanka'
                            effect='blur'
                        />
                    </div>
                </div>

                <Divider />

                <div className='content'>
                    <div className='right'>
                        <LazyLoadImage
                            src={require('../../Assets/images/people.webp')}
                            alt='srilanka'
                            effect='blur'
                        />
                    </div>
                    <div className='left'>
                        <h3 className='heder_2'>People and Hospitality</h3>
                        <p className='paragraph'>
                            Sri Lanka is often referred to as the "Island of Smiles" for good reason. The people of Sri Lanka are known for their warmth, hospitality, and genuine kindness. Travelers to Sri Lanka often remark on the friendliness of the locals, who are always eager to share their culture, stories, and traditions with visitors.
                        </p>
                        <ul>
                            <li><strong>Family-oriented culture:</strong> Family plays a central role in Sri Lankan life. Visitors can often see multi-generational families living together, and festivals or events are seen as times for family reunions.</li>
                            <li>
                                <strong>Ayubowan:</strong> The traditional Sri Lankan greeting, "Ayubowan," means "may you live long." It is accompanied by the gesture of placing palms together, bowing slightly, and smiling—a reflection of the gentle and polite nature of the people.
                            </li>
                        </ul>
                        <p className='paragraph'>
                            Whether you're staying in a luxury hotel or a small homestay, you’ll be treated with the utmost care and respect, ensuring that your time in Sri Lanka is as welcoming and comfortable as possible.
                        </p>
                    </div>
                </div>

                <Divider />

                <div className='content'>
                    <div className='left'>
                        <h3 className='heder_2'>Why Visit Sri Lanka?</h3>
                        <p className='paragraph'>
                            Sri Lanka offers an irresistible combination of natural beauty, cultural depth, and authentic experiences. Whether you seek adventure in the wild jungles, relaxation on idyllic beaches, or exploration of ancient ruins, this small but diverse island has something for everyone. With its delicious cuisine, vibrant festivals, and the warm hospitality of its people, Sri Lanka is a destination that will stay in your heart long after you leave.
                        </p>

                        <p className='paragraph'>
                            Plan your trip to Sri Lanka today and discover why it’s known as the Pearl of the Indian Ocean!
                        </p>

                        <Button
                            type='primary'
                            style={{ marginTop: 20 }}
                            className='custom_primary_btn_2'
                            onClick={() => window.location.href = '/tours'}
                        >
                            Explore Sri Lanka
                        </Button>
                    </div>

                    <div className='right'>
                        <LazyLoadImage
                            src={require('../../Assets/images/visitsrilanka.webp')}
                            alt='srilanka'
                            effect='blur'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSriLanka