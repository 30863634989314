import {
    SET_SELECTED_CURRENCY,
    SET_SELECTED_CURRENCY_SUCCESS,
    SET_SELECTED_CURRENCY_FAIL,

    SET_SELECTED_LANGUAGE,
    SET_SELECTED_LANGUAGE_SUCCESS,
    SET_SELECTED_LANGUAGE_FAIL,
} from '../../types';

const initialState = {
    loading: false,
    error: null,
    selectedCurrency: 'AUD',
    selectedLanguage: null
};

export const pageSettingsReducer = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case SET_SELECTED_CURRENCY:
            return {
                ...state,
                loading: true
            };
        case SET_SELECTED_CURRENCY_SUCCESS:
            return {
                ...state,
                loading: false,
                selectedCurrency: action.payload,
                error: null
            };
        case SET_SELECTED_CURRENCY_FAIL:
            return {
                ...state,
                loading: false,
                selectedCurrency: null,
                error: action.payload
            };
        case SET_SELECTED_LANGUAGE:
            return {
                ...state,
                loading: true
            };
        case SET_SELECTED_LANGUAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                selectedLanguage: action.payload,
                error: null
            };
        case SET_SELECTED_LANGUAGE_FAIL:
            return {
                ...state,
                loading: false,
                selectedLanguage: null,
                error: action.payload
            };
        default:
            return state;
    }
}
