import React from 'react'
import LoadingComp from '../LoadingComp'

const PleaseWait = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh',
                top: 0,
                left: 0,
                zIndex: 9999,
            }}
        >
            <LoadingComp />
        </div>
    )
}

export default PleaseWait