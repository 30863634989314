import axios from 'axios';

import { API_BASE_URL, API_BASE_URL_OPEN } from "../../App/azure/api";
import { getAccessToken } from '../../App/azure/auth';
import { msalInstance } from '../../App/azure/authConfig';

export const searchTourPackages = async (searchTerm) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL_OPEN}/searchTourPackages?keyword=${searchTerm}`,
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}


export const fetchAllTourPackages = async (values) => {
    const params = new URLSearchParams();

    if (values.page !== undefined && values.page !== null) params.append('page', values.page);
    params.append('pageSize', 5);
    if (values.durationDays) params.append('durationDays', values.durationDays);
    if (values.maxAge) params.append('maxAge', values.maxAge);
    if (values.minAge) params.append('minAge', values.minAge);
    if (values.maxPrice) params.append('maxPrice', values.maxPrice);
    if (values.minPrice) params.append('minPrice', values.minPrice);
    if (values.tourType) params.append('tourType', values.tourType);

    const url = `${API_BASE_URL_OPEN}/getAllTourPackages?${params.toString()}`;

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: url,
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}


export const getTourPackageById = async (id) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL_OPEN}/getTourPackageById?packageId=${id}`,
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getItinerariesByTourPackage = async (id) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL_OPEN}/getItinerariesByTourPackage?packageId=${id}`,
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}


export const getAccommodationsByTourPackage = async (id) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL_OPEN}/getAccommodationsByTourPackage?id=${id}`,
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}
export const addTourEnquiry = async (values) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL_OPEN}/addTourEnquiry`,
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getAllTourPackagsNameAndIds = async () => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getAllTourPackagsNameAndIds`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}
