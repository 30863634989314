import {
    SET_SELECTED_CURRENCY,
    SET_SELECTED_CURRENCY_SUCCESS,
    SET_SELECTED_CURRENCY_FAIL,

    SET_SELECTED_LANGUAGE,
    SET_SELECTED_LANGUAGE_SUCCESS,
    SET_SELECTED_LANGUAGE_FAIL,
} from '../../types';

export const setSelectedCurrencyAC = (values) => {
    return async (dispatch) => {
        dispatch({
            type: SET_SELECTED_CURRENCY
        })
        try {
            dispatch({
                type: SET_SELECTED_CURRENCY_SUCCESS,
                payload: values
            })
        } catch (error) {
            dispatch({
                type: SET_SELECTED_CURRENCY_FAIL,
                payload: error.message
            })
        }
    }
}

export const setSelectedLanguageAC = (values) => {
    return async (dispatch) => {
        dispatch({
            type: SET_SELECTED_LANGUAGE
        })
        try {
            dispatch({
                type: SET_SELECTED_LANGUAGE_SUCCESS,
                payload: values
            })
        } catch (error) {
            dispatch({
                type: SET_SELECTED_LANGUAGE_FAIL,
                payload: error.message
            })
        }
    }
}