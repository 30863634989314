import React, { useEffect, useRef, useState } from 'react'
import useMyProfile from '../../../../hooks/useMyProfile';
import { Button, Form, notification, Select, Spin } from 'antd';

import { editUserPreferences } from '../../../../../redux/api/userProfile.api';
import { useDispatch } from 'react-redux';
import { fetchProfileAC } from '../../../../../redux/actions/myProfile/myProfile.ac';

import './styles.scss'
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

const DashboardSettings = () => {
    const myProfile = useMyProfile();
    const dispatch = useDispatch();
    const [anythingChanged, setAnythingChanged] = useState(false)
    const formRef = useRef();
    const [api, contextHolder] = notification.useNotification();
    const [preferences, setPreferences] = useState();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (myProfile?.userProfile) {
            setPreferences(myProfile.userProfile.preferences)
        }
    }, [myProfile])

    useEffect(() => {
        if (preferences) {
            fillForm()
        }
    }, [preferences])

    const fillForm = () => {
        formRef?.current?.setFieldsValue({
            appNotification: preferences.appNotification ? 'true' : 'false',
            emailNotification: preferences.emailNotification ? 'true' : 'false',
            smsNotification: preferences.smsNotification ? 'true' : 'false',
            appPromotion: preferences.appPromotion ? 'true' : 'false',
            emailPromotion: preferences.emailPromotion ? 'true' : 'false',
            country: preferences.country,
            cuurrency: preferences.currency,
            language: preferences.language,
            locationTracking: preferences.locationTracking ? 'true' : 'false',
        })
    }

    const resetForm = () => {
        formRef?.current?.resetFields()
        setAnythingChanged(false)
        fillForm()
    }

    const handleSubmit = async (values) => {
        setLoading(true)

        if (values.appNotification === 'true') {
            values.appNotification = true
        } else {
            values.appNotification = false
        }
        if (values.emailNotification === 'true') {
            values.emailNotification = true
        } else {
            values.emailNotification = false
        }
        if (values.smsNotification === 'true') {
            values.smsNotification = true
        } else {
            values.smsNotification = false
        }
        if (values.appPromotion === 'true') {
            values.appPromotion = true
        } else {
            values.appPromotion = false
        }
        if (values.emailPromotion === 'true') {
            values.emailPromotion = true
        } else {
            values.emailPromotion = false
        }

        if (values.locationTracking === 'true') {
            values.locationTracking = true
        } else {
            values.locationTracking = false
        }

        try {
            values.userId = myProfile.userProfile.id
            const res = await editUserPreferences(values)
            if (res.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'User preferences updated successfully'
                })
                dispatch(fetchProfileAC())
                setAnythingChanged(false)
            } else {
                api.error({
                    message: 'Error',
                    description: 'An error occured while updating user preferences'
                })
            }

        } catch (error) {
            api.error({
                message: 'Error',
                description: 'An error occured while updating user preferences'
            })
        }
        setLoading(false)
    }

    return (
        <div className='personal_details'>
            {contextHolder}
            <h1 className='heder_2'>Settings</h1>
            <p className='about'>
                Manage your account settings and set preferences.
            </p>

            <div className='personal_details__container'>
                <Spin spinning={loading} size='large' tip='Please wait...'>
                    <Form
                        name='basic'
                        onFinish={handleSubmit}
                        ref={formRef}
                        onChange={() => setAnythingChanged(true)}
                    >
                        {/* <Form.Item
                            name='appNotification'
                            label='App Notifications'
                        >
                            <Select mode='single' allowClear style={{ width: '200px' }} onChange={() => setAnythingChanged(true)}>
                                <Select.Option value='true'>Allow</Select.Option>
                                <Select.Option value='false'>Not Allow</Select.Option>
                            </Select>
                        </Form.Item> */}
                        <Form.Item
                            name='emailNotification'
                            label='Email Notifications'
                        >
                            <Select mode='single' allowClear style={{ width: '200px' }} onChange={() => setAnythingChanged(true)}>
                                <Select.Option value='true'>Allow</Select.Option>
                                <Select.Option value='false'>Not Allow</Select.Option>
                            </Select>
                        </Form.Item>
                        {/* <Form.Item
                            name='smsNotification'
                            label='SMS Notifications'
                        >
                            <Select mode='single' allowClear style={{ width: '200px' }} onChange={() => setAnythingChanged(true)}>
                                <Select.Option value='true'>Allow</Select.Option>
                                <Select.Option value='false'>Not Allow</Select.Option>
                            </Select>
                        </Form.Item> */}
                        {/* <Form.Item
                            name='appPromotion'
                            label='App Promotions'
                        >
                            <Select mode='single' allowClear style={{ width: '200px' }} onChange={() => setAnythingChanged(true)}>
                                <Select.Option value='true'>Allow</Select.Option>
                                <Select.Option value='false'>Not Allow</Select.Option>
                            </Select>
                        </Form.Item> */}
                        <Form.Item
                            name='emailPromotion'
                            label='Email Promotions'
                        >
                            <Select mode='single' allowClear style={{ width: '200px' }} onChange={() => setAnythingChanged(true)}>
                                <Select.Option value='true'>Allow</Select.Option>
                                <Select.Option value='false'>Not Allow</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name='language'
                            label='Language'
                        >
                            <Select
                                mode='single'
                                allowClear
                                style={{
                                    width: '200px',
                                }}
                                placeholder="Please select language"
                                options={[
                                    { label: 'English', value: 'EN' },
                                    { label: 'French', value: 'FR' },
                                    { label: 'Spanish', value: 'ES' },
                                    { label: 'German', value: 'DE' },
                                    { label: 'Italian', value: 'IT' },
                                    { label: 'Russian', value: 'RU' },
                                    { label: 'Chinese', value: 'ZH' },
                                    { label: 'Japanese', value: 'JA' },
                                ]}
                                onChange={() => setAnythingChanged(true)}
                            />
                        </Form.Item>
                        {/* <Form.Item
                            name='currency'
                            label='Currency'
                        >
                            <Select
                                mode='single'
                                allowClear
                                style={{
                                    width: '200px',
                                }}
                                placeholder="Please select currency"
                                options={[
                                    { label: 'USD', value: 'USD' },
                                    { label: 'EUR', value: 'EUR' },
                                    { label: 'GBP', value: 'GBP' },
                                    { label: 'JPY', value: 'JPY' },
                                    { label: 'AUD', value: 'AUD' },
                                    { label: 'CAD', value: 'CAD' },
                                    { label: 'CHF', value: 'CHF' },
                                    { label: 'CNY', value: 'CNY' },
                                    { label: 'SEK', value: 'SEK' },
                                    { label: 'NZD', value: 'NZD' },
                                ]}
                                onChange={() => setAnythingChanged(true)}
                            />
                        </Form.Item> */}
                        <Form.Item
                            name='locationTracking'
                            label='Location Tracking'
                        >
                            <Select mode='single' allowClear style={{ width: '200px' }} onChange={() => setAnythingChanged(true)}>
                                <Select.Option value='true'>Allow</Select.Option>
                                <Select.Option value='false'>Not Allow</Select.Option>
                            </Select>
                        </Form.Item>
                        <div className='edit__user__header__actions'
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '20px',
                                gap: '10px'
                            }}
                        >
                            <Button type='default' icon={<CloseOutlined />} onClick={resetForm}>Discard</Button>
                            <Button
                                type='primary'
                                icon={<SaveOutlined />}
                                htmlType='submit'
                                disabled={!anythingChanged}
                                loading={loading}
                            >
                                Update
                            </Button>
                        </div>
                    </Form>
                </Spin>
            </div>
        </div>
    )
}

export default DashboardSettings