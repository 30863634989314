import axios from 'axios';
import { API_BASE_URL, API_BASE_URL_OPEN } from '../../App/azure/api';
import { getAccessToken } from '../../App/azure/auth';
import { msalInstance } from '../../App/azure/authConfig';

export const searchDestinations = async (searchTerm) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL_OPEN}/searchDestination?keyword=${searchTerm}`,
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const fetchAllDestinations = async (values) => {
    const params = new URLSearchParams();

    if (values.page !== undefined && values.page !== null) params.append('page', values.page);
    if (values.pageSize !== undefined && values.pageSize !== null) {
        params.append('pageSize', values.pageSize);
    } else {
        params.append('pageSize', 10);
    }
    if (values.destinationType) params.append('destinationType', values.destinationType);
    if (values.destinationLocation) params.append('destinationLocation', values.destinationLocation);
    if (values.destinationCity) params.append('destinationCity', values.destinationCity);
    if (values.weather) params.append('weather', values.weather);
    if (values.month) params.append('month', values.month);

    const url = `${API_BASE_URL_OPEN}/getAllDestinations?${params.toString()}`;

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: url,
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getDestinationById = async (id) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL_OPEN}/getDestinationById?destinationId=${id}`,
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const fetchDestinationNames = async () => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getAllDestinationNamesAndIds`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}