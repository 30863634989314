import React, { useEffect } from 'react'

import './styles.scss'
import { Breadcrumb } from 'antd'
import { useNavigate } from 'react-router-dom'
import { CustomerServiceOutlined } from '@ant-design/icons'
import RequestCall from './RequestCall'

const Call = () => {
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='support section_main' >
            <div className='top_padding_container'>
                <Breadcrumb
                    className='breadcrumb_pages'
                    style={{ marginBottom: '10px' }}
                    items={[
                        {
                            title: <div className='breadcrumb__item' onClick={() => navigate('/support')}><CustomerServiceOutlined /> Help & Support</div>,
                        },
                        {
                            title: <div className='breadcrumb__item last'>Call</div>,
                        },
                    ]}
                />
            </div>

            <div className='heading'>
                <h1 className='header_1'>
                    Contact Us
                </h1>
                <p className='paragraph'>
                    Charges may apply when making international calls. Please check with your phone provider for more information.
                </p>
            </div>

            <RequestCall />
        </div>
    )
}

export default Call