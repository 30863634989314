import React, { useEffect, useState } from 'react'
import { getHotelsByUserTour } from '../../../../../../../redux/api/userTour.api'
import { Empty, Rate } from 'antd'
import ReactQuill from 'react-quill'

import './styles.scss'
import LoadingComp from '../../../../../../components/LoadingComp'

const AccommodationsBooking = ({ userTour }) => {
    const [loading, setLoading] = useState(true)
    const [accommodation, setAccommodation] = useState(null)

    useEffect(() => {
        handleGetAccommodations(userTour?.id)
    }, [])

    const handleGetAccommodations = async (id) => {
        setLoading(true)
        try {
            const response = await getHotelsByUserTour(id)
            setAccommodation(response.data)
        } catch (error) {
            console.error('Error fetching itineraries', error)
        }
        setLoading(false)
    }

    return (
        <div className='user_toru_accommodations'>
            {
                loading ?
                    <div className='booking__loading_container'>
                        <LoadingComp />
                    </div>
                    :
                    <div className='booking__accommodations'>
                        <h3 className='header_3' style={{ marginBottom: '10px' }}>Accommodations</h3>
                        {
                            accommodation?.length > 0 ?
                                accommodation.map((hotel) => (
                                    <div className='booking__accommodations__card'>
                                        <h4 className='header_4'>{hotel?.title}</h4>
                                        <p><span>Day: </span>{hotel?.dayNumber}</p>
                                        {hotel?.starGrade && <p><span>Star Rating: </span>
                                            <Rate disabled defaultValue={hotel?.starGrade} />
                                        </p>}
                                        <ReactQuill
                                            value={hotel?.description}
                                            readOnly={true}
                                            theme='bubble'
                                        />
                                    </div>
                                ))
                                :
                                <div>
                                    <Empty>
                                        <p>
                                            Accommodations are mostly the same as the ones in the tour package. But in some cases, we may have to change the accommodation due to unforeseen circumstances., <strong>We'll inform your exact accommodation details 3 weeks before the tour starts.</strong>
                                        </p>
                                    </Empty>
                                </div>
                        }
                    </div>

            }
        </div>
    )
}

export default AccommodationsBooking