import React, { useEffect, useState } from 'react'
import DatesAndQty from './DatesAndQty';
import { notification, Steps } from 'antd';

import './styles.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SelectDestinations from './SelectDestinations';
import TransportAndAccommodation from './TransportAndAccommodation';
import OtherServices from './OtherServices';
import ReviewAndConf from './ReviewAndConf';

const PlanTour = () => {
    const [current, setCurrent] = useState(0);
    const [userTourData, setUserTourData] = useState({});
    const [api, contextHolderNoti] = notification.useNotification();

    useEffect(() => {
        setUserTourData({
            favourites: {
                beaches: 0,
                wildlife: 0,
                historialSites: 0,
                adventure: 0,
                relaxation: 0,
                cultural: 0,
                wellnessAndSpa: 0,
                festivalAndEvents: 0,
            },
            startDate: null,
            duration: 0,
            travellers: {
                adults: 0,
                children: 0,
                infants: 0,
            },
            accommodationType: null,
            favoriteDestinations: [],
            destinationSpecifics: null,
            accommodationStayType: null,
            accommodationStarRating: null,
            vehicleType: null,
            transportSpecifics: null,
            preferencesSpecifics: null,
            tourGuideLanguage: 'English',
            insuarenceId: null,
        });
    }, []);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const steps = [
        {
            title: 'Start Planning Your Tour',
            content: <DatesAndQty
                next={next}
                prev={prev}
                setUserTourData={setUserTourData}
                userTourData={userTourData}
                notificationApi={api}
                current={current}
            />
        },
        {
            title: 'Your Travel Preferences',
            content: <SelectDestinations
                next={next}
                prev={prev}
                setUserTourData={setUserTourData}
                userTourData={userTourData}
                notificationApi={api}
                current={current}
            />
        },
        {
            title: 'Transport & Accommodation',
            content: <TransportAndAccommodation
                next={next}
                prev={prev}
                setUserTourData={setUserTourData}
                userTourData={userTourData}
                notificationApi={api}
                current={current}
            />
        },
        {
            title: 'Other Services & Preferences',
            content: <OtherServices
                next={next}
                prev={prev}
                setUserTourData={setUserTourData}
                userTourData={userTourData}
                notificationApi={api}
                current={current}
            />
        },
        {
            title: 'Review & Submit',
            content: <ReviewAndConf
                next={next}
                prev={prev}
                setUserTourData={setUserTourData}
                userTourData={userTourData}
                notificationApi={api}
                current={current}
            />
        }
    ]

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    return (
        <div className='main__contaier custom_tour_plan_main'>
            {contextHolderNoti}
            <div className='hero section_main'>
                <div className='hero__background__overlay'></div>
                <div className='hero__background'>
                    <LazyLoadImage
                        alt='background-image'
                        effect="blur"
                        wrapperProps={{
                            style: { transitionDelay: "0.1s" },
                        }}
                        src='https://visitpearlsharedblob.blob.core.windows.net/appassets/destinationsBg.webp'
                    />
                </div>
                <div className='steps_container'>
                    <Steps
                        current={current}
                        items={items}
                        progressDot={window.innerWidth > 768 ? false : true}
                        labelPlacement="vertical"
                    />
                </div>
            </div>

            <div className='content_body_main section_main'>
                {steps[current].content}
            </div>
        </div>
    )
}

export default PlanTour