import React, { useEffect, useRef, useState } from 'react'
import { reviewTypes } from '../../../../constants/reviewTypes';
import { Button, Col, Drawer, Form, Image, Input, notification, Rate, Row, Select, Spin, Upload } from 'antd';
import { getAllTourPackagsNameAndIds } from '../../../../../redux/api/tourPackages.api';
import { fetchDestinationNames } from '../../../../../redux/api/destinations.api';
import { fetchAttractionsNames } from '../../../../../redux/api/attractions.api';
import { fetchActivityNames } from '../../../../../redux/api/activities.api';
import { fetchEventsNames } from '../../../../../redux/api/events.api';
import { handleImageUpload, USER_POSTS } from '../../../../azure/blob';
import { addReview } from '../../../../../redux/api/reviews.api';
import useMyProfile from '../../../../hooks/useMyProfile';

const getOptionName = (value) => {
    value = value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
    return value.replace('Rev', '');
};

const reviewOptions = Object.keys(reviewTypes).map(key => {
    return {
        label: getOptionName(reviewTypes[key]),
        value: key
    };
})

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const AddReview = ({
    isOpen,
    setOpen,
    handleGetReviews,
    ratingType,
    ratedFor
}) => {
    const formRef = useRef();
    const [ratedForOptions, setRatedForOptions] = useState([]);
    const [loadingRatedForOptions, setLoadingRatedForOptions] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [loadingAddReview, setLoadingAddReview] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const myProfile = useMyProfile().userProfile;

    useEffect(() => {
        if (isOpen && formRef.current && ratingType && ratedFor) {
            formRef.current.setFieldsValue({
                ratingType: ratingType,
                ratedFor: ratedFor
            });
        }
    }, [isOpen]);

    const handleGetRatedForOptions = async (value) => {
        setLoadingRatedForOptions(true);
        setLoadingText('Loading places...');
        if (value === reviewTypes.REV_TOUR_PACKAGE) {
            const res = await getAllTourPackagsNameAndIds();
            setRatedForOptions(
                res.data.map(item => {
                    return {
                        label: item.packageName,
                        value: item.id
                    }
                })
            );
        } else if (value === reviewTypes.REV_DESTINATION) {
            const res = await fetchDestinationNames();
            setRatedForOptions(
                res.data.map(item => {
                    return {
                        label: item.destinationName,
                        value: item.id
                    }
                })
            );
        } else if (value === reviewTypes.REV_ATTRACTION) {
            const res = await fetchAttractionsNames();
            setRatedForOptions(
                res.data.map(item => {
                    return {
                        label: item.attractionName,
                        value: item.id
                    }
                })
            );
        } else if (value === reviewTypes.REV_ACTIVITY) {
            const res = await fetchActivityNames();
            setRatedForOptions(
                res.data.map(item => {
                    return {
                        label: item.activityName,
                        value: item.id
                    }
                })
            );
        } else if (value === reviewTypes.REV_EVENT) {
            const res = await fetchEventsNames();
            setRatedForOptions(
                res.data.map(item => {
                    return {
                        label: item.eventName,
                        value: item.id
                    }
                })
            );
        }

        setLoadingRatedForOptions(false)
        setLoadingText('');
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const handleOnFinsih = async (values) => {
        setLoadingAddReview(true);

        let imageUrls = [];
        if (fileList.length > 0) {
            setLoadingText('Uploading Images...');
            const name = `${values.ratingType}_${values.ratedFor}`;
            const imageUploadPromises = fileList.map((file, index) => {
                setLoadingText(`Uploading image ${index + 1}...`);
                return handleImageUpload(
                    USER_POSTS,
                    file.originFileObj,
                    file.size,
                    `${name}_${file.name}`
                ).then(blobRes => {
                    imageUrls.push(blobRes.blockBlobClient.url);
                    setLoadingText(`Uploaded image ${index + 1}...`);
                });
            });
            await Promise.all(imageUploadPromises);
            delete values.images;
        }
        if (imageUrls.length > 0) {
            imageUrls = imageUrls.map(url => sanitizeBlobUrls(url));
            values.images = imageUrls.join(',');
        }

        values.userId = myProfile?.id;
        setLoadingText('Adding Review...');
        const response = await addReview(values);
        if (response.status === 200) {
            api.success({
                message: 'Review Added Successfully',
                description: 'You have successfully added a new review'
            });

            handleGetReviews();
            setOpen(false);
        } else {
            api.error({
                message: 'Error Adding Review',
                description: 'There was an error adding the review. Please try again'
            });
        }


        setLoadingAddReview(false);
        setLoadingText('');
    }

    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }

    return (
        <Drawer
            title="Add Review"
            placement="right"
            closable={true}
            onClose={() => setOpen(false)}
            open={isOpen}
            width={720}
        >
            {contextHolder}
            <Spin spinning={loadingRatedForOptions || loadingAddReview} tip={loadingText}>
                <Form
                    layout='vertical'
                    ref={formRef}
                    onFinish={handleOnFinsih}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Review Type"
                                name="ratingType"
                                rules={[{ required: true, message: 'Please input your review type!' }]}
                            >
                                <Select
                                    placeholder="Select a review type"
                                    allowClear
                                    onChange={(value) => handleGetRatedForOptions(value)}
                                    loading={loadingRatedForOptions}
                                    disabled={loadingRatedForOptions}
                                >
                                    {reviewOptions.map(option => (
                                        <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Review Place"
                                name="ratedFor"
                                rules={[{ required: true, message: 'Please input your title!' }]}
                            >
                                <Select
                                    placeholder="Select a review type"
                                    allowClear
                                    showSearch
                                    loading={loadingRatedForOptions}
                                    disabled={loadingRatedForOptions}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {ratedForOptions.map(option => (
                                        <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Your Review"
                                name="ratingDescription"
                                rules={[{ required: true, message: 'Please add your review!' }]}
                            >
                                <Input.TextArea
                                    placeholder="Your review"
                                    autoSize={{ minRows: 5, maxRows: 8 }}
                                    maxLength={1000}
                                    showCount
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Your Rate (1-5)"
                                name="ratingValue"
                                rules={[{ required: true, message: 'Please add your rate!' }]}
                            >
                                <Rate
                                    count={5}
                                    defaultValue={0}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Your Images (Max 10)"
                                name="images"
                            >
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    multiple={true}
                                    maxCount={10}
                                    beforeUpload={(file) => {
                                        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/gif' || file.type === 'image/webp';
                                        if (!isJpgOrPng) {
                                            api.error({
                                                message: 'Unsupported image format!',
                                                description: 'Please upload a valid image format (jpeg, jpg, png, gif, webp)',
                                            });
                                        }
                                        const isSizeValid = file.size / 1024 / 1024 < 2;
                                        if (!isSizeValid) {
                                            api.error({
                                                message: 'Image must be smaller than 2MB!',
                                            });
                                        }

                                        const isVaid = isJpgOrPng && isSizeValid;

                                        return isVaid ? false : Upload.LIST_IGNORE;
                                    }}
                                >
                                    {fileList.length <= 10 && '+ Upload'}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loadingAddReview}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </Drawer>
    )
}

export default AddReview