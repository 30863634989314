import React, { useEffect, useRef } from 'react'

import './styles.scss'
import { Button, Col, Divider, Form, Input, Row, Select } from 'antd'
import { TAX_PERCENTAGE, TAX_RATE } from '../../../../../constants/finance'
import { countryList } from '../../../../../constants/itemTypes'
import usePageSettings from '../../../../../hooks/usePageSettings'

const TravelerDetils = ({
    userTourData,
    setUserTourData,
    tourPackage,
    messageApi,
    prev,
    next,
    calculateTotal,
    calculateSubTotal,
    calculateDiscount,
    calculateTax,
    current,
}) => {
    const formRef = useRef();
    const selectedCurrency = usePageSettings()?.selectedCurrency;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (userTourData.userData) {
            formRef?.current?.setFieldsValue(userTourData.userData)
        }
    }, [userTourData.userData])

    const handleGoNext = (values) => {
        if (!userTourData.startDate) {
            messageApi.open({
                type: 'error',
                message: 'Start Date is required',
                description: 'Please select a start date for the tour.',
            })
            return
        }
        if (!userTourData.roomType) {
            messageApi.open({
                type: 'error',
                message: 'Room Type is required',
                description: 'Please select a room type for the tour.',
            })
            return
        }
        if (!userTourData.vehicleType) {
            messageApi.open({
                type: 'error',
                message: 'Vehicle Type is required',
                description: 'Please select a vehicle type for the tour.',
            })
            return
        }
        if (userTourData.adults === 0 && userTourData.children === 0 && userTourData.infants === 0) {
            messageApi.open({
                type: 'error',
                message: 'No members added',
                description: 'Please add at least one member to the tour.',
            })
            return
        }

        setUserTourData({
            ...userTourData,
            userData: values
        })

        next()
    }

    return (
        <Form
            onFinish={handleGoNext}
            className='booking_form_customer_data'
            layout='vertical'
            ref={formRef}
            scrollToFirstError
        >
            <div className='contents'>
                <section className='booking_input_section'>
                    <h2 className='heder_2'>
                        Travelers Information
                    </h2>
                    <div className='travelers_wrapper'>
                        {userTourData?.adults > 0 && <Divider
                            orientation='left'
                        >
                            Adults Information
                        </Divider>}
                        {
                            userTourData?.adults > 0 && Array.from({ length: userTourData?.adults }).map((_, index) => (
                                <div className='traveler'>
                                    <h4>Traveler {index + 1} Details - Adult</h4>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='First Name'
                                                    name={`adults[${index}].firstName`}
                                                    rules={[
                                                        { required: true, message: 'Please input your first name!' }
                                                    ]}
                                                >
                                                    <Input type='text' placeholder='John' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Last Name'
                                                    name={`adults[${index}].lastName`}
                                                    rules={[{ required: true, message: 'Please input your last name!' }]}
                                                >
                                                    <Input type='text' placeholder='Doe' />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Email'
                                                    name={`adults[${index}].email`}
                                                    rules={[{ required: true, message: 'Please input your email!' }, { type: 'email' }]}
                                                >
                                                    <Input placeholder='example@example.com' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Phone'
                                                    name={`adults[${index}].phone`}
                                                    rules={[
                                                        { required: true, message: 'Please input your phone!' },
                                                        { pattern: /^[0-9]{10}$/, message: 'Please input valid phone number!' },
                                                    ]}
                                                >
                                                    <Input type='tel' placeholder='0400000000' />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Country'
                                                    name={`adults[${index}].country`}
                                                    rules={[{ required: true, message: 'Please input your country!' }]}
                                                >
                                                    <Select
                                                        placeholder='Select your country'
                                                        allowClear
                                                    >
                                                        {
                                                            countryList.map((country) => (
                                                                <Select.Option value={country}>{country}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Passport Number'
                                                    name={`adults[${index}].passportNumber`}
                                                    rules={
                                                        [
                                                            {
                                                                required: true,
                                                                message: 'Please input your passport number!',
                                                            },
                                                            {
                                                                pattern: /^[A-Z0-9]{9}$/,
                                                                message: 'Please input valid passport number!',
                                                            },
                                                        ]
                                                    }
                                                >
                                                    <Input placeholder='A12345678' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Form.Item
                                                    label='Allergies & Dietary Requirements'
                                                    name={`adults[${index}].allergies`}
                                                    rules={[{ required: false }]}
                                                >
                                                    <Input.TextArea placeholder='Allergies & Dietary Requirements' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <div className='travelers_wrapper'>
                        {userTourData?.children > 0 && <Divider
                            orientation='left'
                        >
                            Children Information
                        </Divider>}
                        {
                            userTourData?.children > 0 && Array.from({ length: userTourData?.children }).map((_, index) => (
                                <div className='traveler'>
                                    <h4>Traveler {index + 1} Details - Children</h4>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='First Name'
                                                    name={`children[${index}].firstName`}
                                                    rules={[
                                                        { required: true, message: 'Please input your first name!' }
                                                    ]}
                                                >
                                                    <Input type='text' placeholder='John' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Last Name'
                                                    name={`children[${index}].lastName`}
                                                    rules={[{ required: true, message: 'Please input your last name!' }]}
                                                >
                                                    <Input type='text' placeholder='Doe' />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Email'
                                                    name={`children[${index}].email`}
                                                    rules={[{ required: true, message: 'Please input your email!' }, { type: 'email' }]}
                                                >
                                                    <Input type='email' placeholder='example@example.com' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Phone'
                                                    name={`children[${index}].phone`}
                                                    rules={[
                                                        { required: true, message: 'Please input your phone!' },
                                                        { pattern: /^[0-9]{10}$/, message: 'Please input valid phone number!' },
                                                    ]}
                                                >
                                                    <Input type='tel' placeholder='0400000000' />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Country'
                                                    name={`children[${index}].country`}
                                                    rules={[{ required: true, message: 'Please input your country!' }]}
                                                >
                                                    <Select
                                                        placeholder='Select your country'
                                                        allowClear
                                                    >
                                                        {
                                                            countryList.map((country) => (
                                                                <Select.Option value={country}>{country}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Passport Number'
                                                    name={`children[${index}].passportNumber`}
                                                    rules={
                                                        [
                                                            {
                                                                required: true,
                                                                message: 'Please input your passport number!',
                                                            },
                                                            {
                                                                pattern: /^[A-Z0-9]{9}$/,
                                                                message: 'Please input valid passport number!',
                                                            },
                                                        ]
                                                    }
                                                >
                                                    <Input placeholder='A12345678' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Form.Item
                                                    label='Allergies & Dietary Requirements'
                                                    name={`children[${index}].allergies`}
                                                    rules={[{ required: false }]}
                                                >
                                                    <Input.TextArea placeholder='Allergies & Dietary Requirements' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <div className='travelers_wrapper'>
                        {userTourData?.infants > 0 && <Divider
                            orientation='left'
                        >
                            Infants Information
                        </Divider>}
                        {
                            userTourData?.infants > 0 && Array.from({ length: userTourData?.infants }).map((_, index) => (
                                <div className='traveler'>
                                    <h4>Traveler {index + 1} Details - Infant</h4>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='First Name'
                                                    name={`infants[${index}].firstName`}
                                                    rules={[
                                                        { required: true, message: 'Please input your first name!' }
                                                    ]}
                                                >
                                                    <Input type='text' placeholder='John' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Last Name'
                                                    name={`infants[${index}].lastName`}
                                                    rules={[{ required: true, message: 'Please input your last name!' }]}
                                                >
                                                    <Input type='text' placeholder='Doe' />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Email (Parent)'
                                                    name={`infants[${index}].email`}
                                                    rules={[{ required: true, message: 'Please input your email!' }, { type: 'email' }]}
                                                >
                                                    <Input type='email' placeholder='example@example.com' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Phone (Parent)'
                                                    name={`infants[${index}].phone`}
                                                    rules={[
                                                        { required: true, message: 'Please input your phone!' },
                                                        { pattern: /^[0-9]{10}$/, message: 'Please input valid phone number!' },
                                                    ]}
                                                >
                                                    <Input type='tel' placeholder='0400000000' />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Country'
                                                    name={`infants[${index}].country`}
                                                    rules={[{ required: true, message: 'Please input your email!' }]}
                                                >
                                                    <Select
                                                        placeholder='Select your country'
                                                        allowClear
                                                    >
                                                        {
                                                            countryList.map((country) => (
                                                                <Select.Option value={country}>{country}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Passport Number (Parent)'
                                                    name={`infants[${index}].passportNumber`}
                                                    rules={
                                                        [
                                                            {
                                                                required: true,
                                                                message: 'Please input your passport number!',
                                                            },
                                                            {
                                                                pattern: /^[A-Z0-9]{9}$/,
                                                                message: 'Please input valid passport number!',
                                                            },
                                                        ]
                                                    }
                                                >
                                                    <Input placeholder='A12345678' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Form.Item
                                                    label='Allergies & Dietary Requirements'
                                                    name={`infants[${index}].allergies`}
                                                    rules={[{ required: false }]}
                                                >
                                                    <Input.TextArea placeholder='Allergies & Dietary Requirements' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </section>

                <section className='booking_input_section sim_services'>
                    <h2 className='heder_2'>
                        Contact Information
                    </h2>
                    <p className='note'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut libero dapibus, posuere enim in, laoreet nunc. Maecenas eget fringilla mi.
                    </p>
                    <div className='service_wrapper'>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label='Surname / Last Name (In English)'
                                        name='contactFirstName'
                                        rules={[
                                            { required: true, message: 'Please input your surname!' }
                                        ]}
                                    >
                                        <Input type='text' placeholder='John' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label='Given Name (In English)'
                                        name='contactGivenName'
                                        rules={[{ required: true, message: 'Please input your give name!' }]}
                                    >
                                        <Input type='text' placeholder='Doe' />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label='Email'
                                        name='contactEmail'
                                        layout='vertical'
                                        rules={[{ required: true, message: 'Please input your email!' }]}
                                    >
                                        <Input type='email' placeholder='example@example.com' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label='Phone'
                                        name='contactPhone'
                                        rules={[
                                            { required: true, message: 'Please input your phone!' },
                                            { pattern: /^[0-9]{10}$/, message: 'Please input valid phone number!' },
                                        ]}
                                    >
                                        <Input type='tel' placeholder='0400000000' />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </section>
            </div>

            <div className='booking_pricing'>
                <h2 className='heder_2'>Package Details</h2>

                <div className='pricing_item date'>
                    <div><strong> Start Date: </strong>{userTourData?.startDate || <span style={{ color: '#F45713' }}>Please Select</span>}</div>
                    <div><strong>Room Type: </strong>{userTourData?.roomType || <span style={{ color: '#F45713' }}>Please Select</span>}</div>
                    <div><strong>Vehicle Type: </strong>{userTourData?.vehicleType || <span style={{ color: '#F45713' }}>Please Select</span>}</div>
                </div>

                <Divider />

                <div className='pricing_item quentity'>
                    <div className='quentity'><h5>Adults: </h5> <p> &#215; {userTourData?.adults}</p></div>
                    <div className='quentity'><h5>Children: </h5> <p> &#215; {userTourData?.children}</p></div>
                    <div className='quentity'><h5>Infants: </h5> <p> &#215; {userTourData?.infants}</p></div>
                </div>

                <Divider />

                <div className='total'>
                    <div>
                        <h4>
                            Sub Total:
                        </h4>
                        <p>{calculateSubTotal()} {selectedCurrency}</p>
                    </div>
                    {
                        tourPackage?.discount &&
                        <div>
                            <h4>
                                Discount:
                            </h4>
                            <p>
                                {tourPackage?.discount}%
                            </p>
                        </div>
                    }
                    <div>
                        <h4>
                            Service Charge:
                        </h4>
                        <p>{TAX_PERCENTAGE}%</p>
                    </div>

                    <Divider />

                    <div><h5>Total: </h5>
                        <h6>
                            {
                                calculateTotal()
                            } {selectedCurrency}
                        </h6>
                    </div>
                </div>

                <div className='actions'>
                    {current < 5 - 1 && (
                        <Button type="primary" className='next' htmlType='submit'>
                            Next
                        </Button>
                    )}
                    {current === 5 - 1 && (
                        <Button type="primary" className='next'>
                            Done
                        </Button>
                    )}
                    {current > 0 && (
                        <Button onClick={() => prev()} className='prev'>
                            Previous
                        </Button>
                    )}
                </div>
            </div>
        </Form>
    )
}

export default TravelerDetils