import { Button, Col, ConfigProvider, DatePicker, Divider, Form, Input, InputNumber, Modal, notification, Radio, Rate, Row, Select, Spin, TimePicker, Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import useMyProfile from '../../../hooks/useMyProfile';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { accommodationTypes, customEventsTypes } from '../../../constants/itemTypes';

import './styles.scss';
import moment from 'moment';
import { capitalizeFisrtLetterEachWord } from '../../../helpers/nameRender';
import { createCustomEvent } from '../../../../redux/api/userTour.api';

const quillModules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
    ]
};

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const accommodationOptions = Object.keys(accommodationTypes).map(key => {
    return {
        label: getOptionName(accommodationTypes[key]),
        value: key
    };
});


const PlanEvent = () => {
    const navigate = useNavigate()
    const myProfile = useMyProfile();
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSubmit = async (values) => {
        setLoading(true);
        window.scrollTo(0, 0);

        const data = {
            userId: myProfile?.userProfile?.id,
            packageName: `${myProfile?.userProfile?.userName}'s custom ${values?.eventType && capitalizeFisrtLetterEachWord(values?.eventType)} event`,
            eventRequirement: values?.message,
            eventType: values?.eventType,
            accommodationType: values?.accommodationStayType,
            accommodationRating: values?.accommodationStarRating,
            roomType: values?.accommodationType,
            vehicleType: values?.vehicleType,
            startDate: moment(values.tourStartDate.$d).format('YYYY-MM-DD'),
            durationNights: values?.tourDuration,
        }

        const response = await createCustomEvent(data);
        if (response.status === 200) {
            setOpenModal(true);
        } else {
            api.error({
                message: 'Error',
                description: 'Something went wrong. Please try again later.',
            });
        }

        setLoading(false);
    }

    const getMinDate = () => {
        const today = moment();
        const minDate = today.add(2, 'weeks');
        return minDate;
    };

    return (
        <div className='main__contaier custom_tour_plan_main evenet_plan'>
            {contextHolder}
            <div className='hero section_main'>
                <div className='hero__background__overlay'></div>
                <div className='hero__background'>
                    <LazyLoadImage
                        alt='background-image'
                        effect="blur"
                        wrapperProps={{
                            style: { transitionDelay: "0.1s" },
                        }}
                        src='https://visitpearlsharedblob.blob.core.windows.net/appassets/destinationsBg.webp'
                    />
                </div>
            </div>

            <div className='content_body_main section_main'>
                <h1 className='header_1'>
                    Plan Your Dream Event
                </h1>

                <div className='content_body_custom_event'>
                    <p>
                        We are here to help you plan your dream event. Whether it is a wedding, a birthday party, a corporate event, or any other event, we can help you plan it. We have a team of experts who can help you plan your event from start to finish.
                    </p>
                    <p>
                        We can help you with everything from finding the perfect venue to choosing the right decorations. We can also help you with catering, entertainment, and more. So if you are looking to plan an event, contact us today and let us help you make your dream event a reality.
                    </p>
                </div>

                <Divider />

                <div className='support__container'>
                    <Spin spinning={loading} >
                        {!myProfile?.loading ?
                            <Form
                                layout='vertical'
                                onFinish={handleSubmit}
                            >
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <h2 className='header_3'>
                                            Select Event Type
                                        </h2>
                                        <Form.Item
                                            name='eventType'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select event type.',
                                                },
                                            ]}
                                        >
                                            <Radio.Group className='radio_group_event_type'>
                                                <Radio
                                                    className={`room_type`}
                                                    value={customEventsTypes.WEDDING}
                                                >
                                                    <div className='flex'>
                                                        <div className='image'>
                                                            <LazyLoadImage
                                                                src='https://visitpearlsharedblob.blob.core.windows.net/appassets/wedding.webp'
                                                                alt='vehicle'
                                                                effect='blur'
                                                            />
                                                        </div>
                                                        <div className='texts'>
                                                            <h5>
                                                                Wedding
                                                            </h5>
                                                            <p>
                                                                We can help you plan your dream wedding. Celebrate your special day in style with our help.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Radio>,

                                                <Radio
                                                    className={`room_type`}
                                                    value={customEventsTypes.BIRTHDAY}
                                                >
                                                    <div className='flex'>
                                                        <div className='image'>
                                                            <LazyLoadImage
                                                                src='https://visitpearlsharedblob.blob.core.windows.net/appassets/birthday.webp'
                                                                alt='vehicle'
                                                                effect='blur'
                                                            />
                                                        </div>
                                                        <div className='texts'>
                                                            <h5>
                                                                Birthday Party
                                                            </h5>
                                                            <p>
                                                                We can help you plan the perfect birthday party. Your birthday is a special day, and we want to help you celebrate it in style.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Radio>,

                                                <Radio
                                                    className={`room_type`}
                                                    value={customEventsTypes.ANNIVERSARY}
                                                >
                                                    <div className='flex'>
                                                        <div className='image'>
                                                            <LazyLoadImage
                                                                src='https://visitpearlsharedblob.blob.core.windows.net/appassets/anniversary.webp'
                                                                alt='vehicle'
                                                                effect='blur'
                                                            />
                                                        </div>
                                                        <div className='texts'>
                                                            <h5>
                                                                Anniversary
                                                            </h5>
                                                            <p>
                                                                We can help you plan the perfect anniversary celebration. Suprise your loved one with unforgettable memories.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Radio>,

                                                <Radio
                                                    className={`room_type`}
                                                    value={customEventsTypes.CONFERENCE}
                                                >
                                                    <div className='flex'>
                                                        <div className='image'>
                                                            <LazyLoadImage
                                                                src='https://visitpearlsharedblob.blob.core.windows.net/appassets/conference.webp'
                                                                alt='vehicle'
                                                                effect='blur'
                                                            />
                                                        </div>
                                                        <div className='texts'>
                                                            <h5>
                                                                Corporate Event
                                                            </h5>
                                                            <p>
                                                                We can help you plan the perfect corporate event. Plan your conference, team building event, or holiday party with us.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Radio>,

                                                <Radio
                                                    className={`room_type`}
                                                    value={customEventsTypes.HONEYMOON}
                                                >
                                                    <div className='flex'>
                                                        <div className='image'>
                                                            <LazyLoadImage
                                                                src='https://visitpearlsharedblob.blob.core.windows.net/appassets/honeymoon.webp'
                                                                alt='vehicle'
                                                                effect='blur'
                                                            />
                                                        </div>
                                                        <div className='texts'>
                                                            <h5>
                                                                Honeymoon
                                                            </h5>
                                                            <p>
                                                                We can help you plan the perfect honeymoon. Get unforgettable memories with your loved one.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Radio>

                                                <Radio
                                                    className={`room_type`}
                                                    value={customEventsTypes.ENGAGEMENT_PARTY}
                                                >
                                                    <div className='flex'>
                                                        <div className='image'>
                                                            <LazyLoadImage
                                                                src='https://visitpearlsharedblob.blob.core.windows.net/appassets/engagement.webp'
                                                                alt='vehicle'
                                                                effect='blur'
                                                            />
                                                        </div>
                                                        <div className='texts'>
                                                            <h5>
                                                                Engagement Party
                                                            </h5>
                                                            <p>
                                                                We can help you plan the perfect engagement party. Celebrate your love with a party that reflects your style and personality.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Radio>

                                                <Radio
                                                    className={`room_type`}
                                                    value={customEventsTypes.ROMANTIC_PROPOSAL}
                                                >
                                                    <div className='flex'>
                                                        <div className='image'>
                                                            <LazyLoadImage
                                                                src='https://visitpearlsharedblob.blob.core.windows.net/appassets/proposal.webp'
                                                                alt='vehicle'
                                                                effect='blur'
                                                            />
                                                        </div>
                                                        <div className='texts'>
                                                            <h5>
                                                                Romantic Proposal
                                                            </h5>
                                                            <p>
                                                                We can help you plan the perfect romantic proposal. Make your proposal unforgettable with your loved one.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Radio>

                                                <Radio
                                                    className={`room_type`}
                                                    value={customEventsTypes.OTHER}
                                                >
                                                    <div className='flex'>
                                                        <div className='image'>
                                                            <LazyLoadImage
                                                                src='https://visitpearlsharedblob.blob.core.windows.net/appassets/otherevents.webp'
                                                                alt='vehicle'
                                                                effect='blur'
                                                            />
                                                        </div>
                                                        <div className='texts'>
                                                            <h5>
                                                                Other Events
                                                            </h5>
                                                            <p>
                                                                Any other event you want to plan? We can help you with that too.
                                                            </p>
                                                        </div>
                                                    </div>

                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <section className='custom_tour_start_planing__form date_selector'>
                                    <h1 className='heder_2'>
                                        Explain Your Event & Requirements
                                    </h1>
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <Form.Item
                                                label='Explain Your Event & Requirements'
                                                name='message'
                                                rules={[{ required: true, message: 'Please enter message' }]}
                                            >
                                                <ReactQuill
                                                    modules={quillModules}
                                                    placeholder='Enter your message here...'
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </section>

                                <section className='custom_tour_start_planing__form date_selector'>
                                    <h1 className='heder_2'>
                                        Select tour start date & duration
                                    </h1>
                                    <p className=' main_para'>
                                        <strong>Note: </strong> We recommend starting your tour at least 2 weeks from today. This will give us enough time to plan your perfect trip.
                                    </p>
                                    <Form.Item
                                        name='tourStartDate'
                                        className='padding_wrapper'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select tour start date.',
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            className='date_picker'
                                            format='YYYY-MM-DD'
                                            showToday={false}
                                            style={{ width: '200px' }}
                                            disabledDate={(current) => current && current < getMinDate()}
                                        />
                                    </Form.Item>
                                </section>

                                <section className='custom_tour_start_planing__form date_selector'>
                                    <h1 className='heder_2'>
                                        How Long Do You Want Your Tour to Be?
                                    </h1>
                                    <p className=' main_para'>
                                        Tell us the duration of your trip in days.(No. of Nights)
                                    </p>
                                    <Form.Item
                                        name='tourDuration'
                                        className='padding_wrapper'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select how long you want your tour to be.',
                                            },
                                        ]}
                                    >
                                        <InputNumber size="medium" min={4} max={30} placeholder='Enter number of days' style={{ width: '200px' }} />
                                    </Form.Item>
                                </section>

                                <section className='custom_tour_start_planing__form date_selector'>
                                    <h1 className='heder_2'>
                                        Select Accommodation Type
                                    </h1>
                                    <p className=' main_para'>
                                        What type of accommodation would you like to stay in?
                                    </p>
                                    <Form.Item
                                        name='accommodationStayType'
                                        className='padding_wrapper'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select your preferred accommodation type.',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Add Accommodation Type"
                                            options={accommodationOptions}
                                            maxCount={1}
                                        />
                                    </Form.Item>
                                </section>

                                <section className='custom_tour_start_planing__form room_selector'>
                                    <h1 className='heder_2'>
                                        Select Your Preferred Accommodation Room Type
                                    </h1>
                                    <p className=' main_para'>
                                        2 Person in a Room. Enjoy the comfort and privacy of your own room with your travel partner. (Twin or double beds can be arranged on request- No extra charge)
                                    </p>
                                    <div className='wrapper'>
                                        <Form.Item
                                            name='accommodationType'
                                            className='padding_wrapper'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select your preferred accommodation type.',
                                                },
                                            ]}
                                        >
                                            <Radio.Group className='radio_group'>
                                                <Radio className='room_type' value='Single'>
                                                    <div className='inner_type'>
                                                        <div className='left'>
                                                            <h5>Single Room (1 person)</h5>
                                                            <p>This room is for 1 person only. If you are traveling alone, this room is for you.</p>
                                                        </div>
                                                    </div>
                                                </Radio>

                                                <Radio className='room_type' value='Double'>
                                                    <div className='left'>
                                                        <h5>
                                                            Standard double room (2 persons)
                                                        </h5>
                                                        <p>
                                                            This room is for 2 persons. If you are traveling with a partner, or if you are traveling with a child, this room is for you.
                                                        </p>
                                                    </div>
                                                </Radio>

                                                <Radio className='room_type' value='Triple'>
                                                    <div className='left'>
                                                        <h5>
                                                            Standard triple room (3 persons)
                                                        </h5>
                                                        <p>
                                                            This room is for 3 persons. If you are traveling with a group of 3, this room is for you.
                                                        </p>
                                                    </div>
                                                </Radio>

                                                <Radio className='room_type' value='Family'>
                                                    <div className='left'>
                                                        <h5>
                                                            Family Room (4 persons)
                                                        </h5>
                                                        <p>
                                                            This room is for a family of 4. If you are traveling with a family of 4, this room is for you.
                                                        </p>
                                                    </div>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                </section>

                                <section className='custom_tour_start_planing__form date_selector'>
                                    <h1 className='heder_2'>
                                        Accommodation Star Rating
                                    </h1>
                                    <p className=' main_para'>
                                        What star rating would you like for your accommodation?
                                    </p>
                                    <Form.Item
                                        name='accommodationStarRating'
                                        className='padding_wrapper'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select your preferred accommodation type.',
                                            },
                                        ]}
                                    >
                                        <Rate />
                                    </Form.Item>
                                </section>

                                <section className='custom_tour_start_planing__form room_selector'>
                                    <h1 className='heder_2'>
                                        Transport vehicle
                                    </h1>
                                    <p className=' main_para'>
                                        What type of vehicle would you like to travel in?
                                        Make sure to select a vehicle that can accommodate all the travelers in your group.
                                    </p>
                                    <div className='wrapper'>
                                        <Form.Item
                                            name='vehicleType'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select your preferred vehicle type.',
                                                },
                                            ]}
                                        >
                                            <Radio.Group className='radio_group_vehicle'>
                                                <Radio
                                                    className={`room_type`}
                                                    value='Hatchback'
                                                >
                                                    <div className='flex'>
                                                        <div className='image'>
                                                            <LazyLoadImage
                                                                src='https://visitpearlsharedblob.blob.core.windows.net/appassets/vehicleTypeHatchback.webp'
                                                                alt='vehicle'
                                                                effect='blur'
                                                            />
                                                        </div>
                                                        <div className='texts'>
                                                            <h5>
                                                                Hatchback (2 persons)
                                                            </h5>
                                                            <p>
                                                                Hatchback is a small car with 3 passenger seats and 1 driver seat. It is suitable for a small group of 2 with 2 luggage. <br />
                                                                <strong>
                                                                    Toyota Aqua, Suzuki Swift, Honda Fit, etc.
                                                                </strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Radio>,

                                                <Radio
                                                    className={`room_type`}
                                                    value='Sedan'
                                                >
                                                    <div className='flex'>
                                                        <div className='image'>
                                                            <LazyLoadImage
                                                                src='https://visitpearlsharedblob.blob.core.windows.net/appassets/vehicleTypeSedan.webp'
                                                                alt='vehicle'
                                                                effect='blur'
                                                            />
                                                        </div>
                                                        <div className='texts'>
                                                            <h5>
                                                                Sedan (2 persons)
                                                            </h5>
                                                            <p>
                                                                Sedan is a large car with 3 passenger seats and 1 driver seat. It is suitable for a small group of 2 with 2 luggage.
                                                                <br />
                                                                <strong>
                                                                    Toyota Axio, Toyota Allion, Toyota Premio, etc.
                                                                </strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Radio>,

                                                <Radio
                                                    className={`room_type`}
                                                    value='Suv'
                                                >
                                                    <div className='flex'>
                                                        <div className='image'>
                                                            <LazyLoadImage
                                                                src='https://visitpearlsharedblob.blob.core.windows.net/appassets/vehicleTypeSuv.webp'
                                                                alt='vehicle'
                                                                effect='blur'
                                                            />
                                                        </div>
                                                        <div className='texts'>
                                                            <h5>
                                                                SUV (2 persons)
                                                            </h5>
                                                            <p>
                                                                SUV is a large sizer four wheel drive vehicle with 3 passenger seats and 1 driver seat. It is suitable for a small group of 2 with 3 luggage and a child.
                                                                <br />
                                                                <strong>
                                                                    Toyota Rav4, Nissan X-Trail, Honda Vezel, etc.
                                                                </strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Radio>,

                                                <Radio
                                                    className={`room_type`}
                                                    value='Van'
                                                >
                                                    <div className='flex'>
                                                        <div className='image'>
                                                            <LazyLoadImage
                                                                src='https://visitpearlsharedblob.blob.core.windows.net/appassets/vehicleTypeVan.webp'
                                                                alt='vehicle'
                                                                effect='blur'
                                                            />
                                                        </div>
                                                        <div className='texts'>
                                                            <h5>
                                                                Van (6 persons)
                                                            </h5>
                                                            <p>
                                                                Van is a large vehicle with 6 passenger seats and 1 driver seat. It is suitable for a group of 6 with 6 luggage.
                                                                <br />
                                                                <strong>
                                                                    Toyota KDH, Nissan Caravan, Toyota Hiace, etc.
                                                                </strong>
                                                            </p>
                                                        </div>
                                                    </div>

                                                </Radio>,

                                                <Radio
                                                    className={`room_type`}
                                                    value='Coster'
                                                >
                                                    <div className='flex'>
                                                        <div className='image'>
                                                            <LazyLoadImage
                                                                src='https://visitpearlsharedblob.blob.core.windows.net/appassets/vehicleTypeCoster.webp'
                                                                alt='vehicle'
                                                                effect='blur'
                                                            />
                                                        </div>
                                                        <div className='texts'>
                                                            <h5>
                                                                Coaster (12 persons)
                                                            </h5>
                                                            <p>
                                                                Coaster is a large vehicle with 12 passenger seats and 1 driver seat. It is suitable for a group of 12 with 12 luggage.
                                                                <br />
                                                                <strong>
                                                                    Toyota Coaster, Nissan Civilian, etc.
                                                                </strong>
                                                            </p>
                                                        </div>
                                                    </div>

                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                </section>

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        gap: '10px'
                                    }}
                                >
                                    <Button
                                        loading={loading}
                                        type='primary'
                                        htmlType='submit'
                                        className='custom_primary_btn_2'
                                    >
                                        Crate My Event
                                    </Button>
                                </div>
                            </Form>
                            :
                            <div>Loading...</div>
                        }
                    </Spin>
                </  div>
            </div>

            <ConfigProvider
                modal={{
                    styles: {
                        mask: {
                            backdropFilter: 'blur(3px)',
                        },
                    },
                }}
            >
                <Modal
                    title="Event Request Submitted"
                    open={openModal}
                    footer={null}
                    closable={false}
                >
                    <p>
                        Your custom event request has been submitted successfully. Our experts will review your request and get back to you with the best possible event plan.
                    </p>
                    <Button
                        className='custom_primary_btn_2'
                        type="primary"
                        onClick={() => {
                            setOpenModal(false);
                            navigate('/dashboard/my-bookings/custom-events');
                        }}
                    >
                        OK
                    </Button>
                </Modal>
            </ConfigProvider>
        </div>
    )
}

export default PlanEvent