import { ExportOutlined } from '@ant-design/icons'
import { Button, Divider, Tag } from 'antd'
import React from 'react'
import { Card } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import ReactQuill from 'react-quill';
import usePageSettings from '../../../../../../hooks/usePageSettings';
import { capitalizeFisrtLetterEachWord } from '../../../../../../helpers/nameRender';
import { CONFIRMED, PENDING_PAYMENT, PENDING_VERIFICATION, REQUESTED } from '../../../../../../constants/finance';
import { convertCurrency } from '../../../../../../helpers/currencyConverter';
import useCurrencyRates from '../../../../../../hooks/useCurrencyRates';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};


const AccommodationBookingDetails = ({ booking }) => {
    const selectedCurrency = usePageSettings()?.selectedCurrency;
    const currencyRates = useCurrencyRates()?.currencyRates

    return (
        <div className='booking_details'>
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Booking Information
            </Divider>
            <div className='p_container'>
                <p><span>Booking ID: </span>{booking?.id}</p>
                <p className={booking?.bookingStatus === REQUESTED ? 'yellow' :
                    booking?.bookingStatus === PENDING_PAYMENT ? 'red' :
                        booking?.bookingStatus === PENDING_VERIFICATION ? 'yellow' :
                            booking?.bookingStatus === CONFIRMED ? 'green' :
                                'red'}
                ><span>Booking Status: </span>{booking?.bookingStatus ? capitalizeFisrtLetterEachWord(booking?.bookingStatus) : 'Pending'}
                </p>
                <p><span>Check-In: </span>{booking?.checkIn}</p>
                <p><span>Check-Out: </span>{booking?.checkOut}</p>
                <p><span>Booked Date: </span>{new Date(booking?.createdAt).toDateString()}</p>
                {
                    booking?.specialRequirements &&
                    <p><span>Special Request: </span>{booking?.specialRequest}</p>
                }
            </div>
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Meal Information
            </Divider>
            {booking?.mealsInfo && <p><span>Meals: </span>
                {JSON.parse(booking?.mealsInfo)?.breakfast && <Tag color="blue">Breakfast</Tag>}
                {JSON.parse(booking?.mealsInfo)?.lunch && <Tag color="blue">Lunch</Tag>}
                {JSON.parse(booking?.mealsInfo)?.dinner && <Tag color="blue">Dinner</Tag>}
            </p>}
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Accommodation Package Information
                <Button
                    type='link'
                    size='small'
                    onClick={() => window.open(`/accommodations/${booking?.accommodationId}`)}
                    style={{ marginLeft: '10px' }}
                >
                    View Package
                    <ExportOutlined />
                </Button>
            </Divider>
            <div className='slider'>
                {booking?.accommodationPackage?.packageImages &&
                    <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        ssr={true}
                        infinite={false}
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                        {
                            booking?.accommodationPackage?.packageImages?.split(',')?.map((acc, index) => (
                                <Card key={index} className='destination_card'>
                                    <Card.Img variant="top" src={acc} className='card_image' />
                                </Card>
                            ))
                        }
                    </Carousel>}
            </div>
            <div className='p_container'>
                <p><span>Package Name: </span>{booking?.accommodationPackage?.packageName}</p>
                <p><span>Accommodation Type: </span>{booking?.accommodationPackage?.accommodationType && capitalizeFisrtLetterEachWord(booking?.accommodationPackage?.accommodationType)}</p>
                <p><span>Room Type: </span>{booking?.accommodationPackage?.packageRooms && capitalizeFisrtLetterEachWord(booking?.accommodationPackage?.packageRooms)}</p>
                <p><span>Package Description: </span>{booking?.accommodationPackage?.packageDescription
                    && <ReactQuill
                        value={booking?.accommodationPackage?.packageDescription}
                        readOnly={true}
                        theme='bubble'
                        style={{ margin: '10px 0' }}
                    />
                }</p>
                <p><span>Package Price: </span>{booking?.accommodationPackage?.packagePrice && convertCurrency(booking?.accommodationPackage?.packagePrice, selectedCurrency, currencyRates)} {selectedCurrency} per night per person</p>
                <p><span>Package Facilities: </span> {
                    booking?.accommodationPackage?.packageFacilities?.split(',')?.map((facility, index) => (
                        <Tag
                            key={index}
                            color="cyan"
                            style={{ margin: '5px 5px 0 0' }}
                        >{facility && capitalizeFisrtLetterEachWord(facility)}</Tag>
                    ))
                }</p>
                <p><span>Package Included Meals: </span> {
                    booking?.accommodationPackage?.packageMeals?.split(',')?.map((facility, index) => (
                        <Tag
                            key={index}
                            color="lime"
                            style={{ margin: '5px 5px 0 0' }}
                        >{facility && capitalizeFisrtLetterEachWord(facility)}</Tag>
                    ))
                }</p>
            </div>
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Accommodation Information
            </Divider>
            <div className='p_container'>
                <p><span>Accommodation Name: </span>{booking?.accommodation?.accommodationName}</p>
                <div className='slider'>
                    {booking?.accommodation?.accommodationImages &&
                        <Carousel
                            swipeable={true}
                            draggable={false}
                            showDots={true}
                            responsive={responsive}
                            ssr={true}
                            infinite={false}
                            autoPlaySpeed={1000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                        >
                            {
                                booking?.accommodation?.accommodationImages?.split(',')?.map((acc, index) => (
                                    <Card key={index} className='destination_card'>
                                        <Card.Img variant="top" src={acc} className='card_image' />
                                    </Card>
                                ))
                            }
                        </Carousel>}
                </div>
                <p><span>Accommodation Type: </span>{booking?.accommodationPackage?.accommodationType && capitalizeFisrtLetterEachWord(booking?.accommodationPackage?.accommodationType)}</p>
                <p><span>City: </span>{booking?.accommodation?.accommodationCity}</p>
                <p><span>Location: </span>
                </p>
                <iframe
                    title="Accommodation Location"
                    src={booking?.accommodation?.mapLocation}
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe>
            </div>
        </div>
    )
}

export default AccommodationBookingDetails