import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, DatePicker, Divider, Form, InputNumber, Radio, Slider } from 'antd';
import React, { useEffect, useRef } from 'react';

import './styles.scss';
import moment from 'moment';

const DatesAndQty = ({
    next,
    prev,
    setUserTourData,
    userTourData,
    notificationApi,
    current,
}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const formRef = useRef(null);

    useEffect(() => {
        if (userTourData && formRef.current) {
            formRef.current.setFieldsValue({
                likeBeaches: userTourData?.favourites?.beaches,
                likeWildLife: userTourData?.favourites?.wildlife,
                likeHistoricalSites: userTourData?.favourites?.historialSites,
                likeAdventure: userTourData?.favourites?.adventure,
                likeRelaxation: userTourData?.favourites?.relaxation,
                likeCulturalExperience: userTourData?.favourites?.cultural,
                likeWellnessAndSpa: userTourData?.favourites?.wellnessAndSpa,
                likeFestivalsAndEvents: userTourData?.favourites?.festivalAndEvents,
                tourStartDate: userTourData?.startDate,
                tourDuration: userTourData?.duration,
                qtyAdults: userTourData?.travellers?.adults,
                qtyChildren: userTourData?.travellers?.children,
                qtyInfants: userTourData?.travellers?.infants,
                accommodationType: userTourData?.accommodationType,
            });
        }
    }, [userTourData]);

    const handleSubmit = (values) => {
        if (!values.qtyAdults) {
            values.qtyAdults = 0;
        }
        if (!values.qtyChildren) {
            values.qtyChildren = 0;
        }
        if (!values.qtyInfants) {
            values.qtyInfants = 0;
        }

        if (values.qtyAdults + values.qtyChildren + values.qtyInfants === 0) {
            notificationApi.error({
                message: 'Invalid Quantity',
                description: 'Please select at least 1 person to continue.',
            });
            return;
        }

        setUserTourData({
            ...userTourData,
            favourites: {
                beaches: values.likeBeaches || 0,
                wildlife: values.likeWildLife || 0,
                historialSites: values.likeHistoricalSites || 0,
                adventure: values.likeAdventure || 0,
                relaxation: values.likeRelaxation || 0,
                cultural: values.likeCulturalExperience || 0,
                wellnessAndSpa: values.likeWellnessAndSpa || 0,
                festivalAndEvents: values.likeFestivalsAndEvents || 0,
            },
            startDate: values.tourStartDate,
            duration: values.tourDuration,
            travellers: {
                adults: values.qtyAdults || 0,
                children: values.qtyChildren || 0,
                infants: values.qtyInfants || 0,
            },
            accommodationType: values.accommodationType,
        });
        next();
    };

    const getMinDate = () => {
        const today = moment();
        const minDate = today.add(2, 'weeks');
        return minDate;
    };

    return (
        <div className='custom_tour_start_planing'>
            <h1 className='header_1 section_header'>
                Start Planning Your Trip
            </h1>
            <p className=' main_para'>
                Let’s get started with some basic details about your trip. We’re excited to help you plan your perfect adventure!
            </p>

            <Divider />

            <Form
                onFinish={handleSubmit}
                scrollToFirstError
                onFinishFailed={() => {
                    notificationApi.error({
                        message: 'Missing Fields',
                        description: 'Please fill all the required fields',
                    });
                }}
                ref={formRef}
            >
                <section className='custom_tour_start_planing__form'>
                    <h1 className='heder_2'>
                        Select Your Favorite Types of Experiences
                    </h1>
                    <p className=' main_para'>
                        Tell us about your favorite types of experiences in Sri Lanka. Choose from categories like beaches, wildlife, historical sites, adventure, relaxation, and more. Rate each category based on your preference level.
                    </p>

                    <Form.Item
                        name="likeBeaches"
                        label={<span className='form_item_label'>Beaches</span>}
                        className='padding_wrapper fav_selector'
                    >
                        <Slider min={0} max={5} />
                    </Form.Item>
                    <Form.Item
                        name='likeWildLife'
                        label={<span className='form_item_label'>Wildlife</span>}
                        className='padding_wrapper fav_selector'
                    >
                        <Slider min={0} max={5} />
                    </Form.Item>
                    <Form.Item
                        name='likeHistoricalSites'
                        label={<span className='form_item_label'>Historical Sites</span>}
                        className='padding_wrapper fav_selector'
                    >
                        <Slider min={0} max={5} />
                    </Form.Item>
                    <Form.Item
                        name='likeAdventure'
                        label={<span className='form_item_label'>Adventure</span>}
                        className='padding_wrapper fav_selector'
                    >
                        <Slider min={0} max={5} />
                    </Form.Item>
                    <Form.Item
                        name='likeRelaxation'
                        label={<span className='form_item_label'>Relaxation</span>}
                        className='padding_wrapper fav_selector'
                    >
                        <Slider min={0} max={5} />
                    </Form.Item>
                    <Form.Item
                        name='likeCulturalExperience'
                        label={<span className='form_item_label'>Cultural Experience</span>}
                        className='padding_wrapper fav_selector'
                    >
                        <Slider min={0} max={5} />
                    </Form.Item>
                    <Form.Item
                        name='likeWellnessAndSpa'
                        label={<span className='form_item_label'>Wellness & Spa</span>}
                        className='padding_wrapper fav_selector'
                    >
                        <Slider min={0} max={5} />
                    </Form.Item>
                    <Form.Item
                        name='likeFestivalsAndEvents'
                        label={<span className='form_item_label'>Festivals & Events</span>}
                        className='padding_wrapper fav_selector'
                    >
                        <Slider min={0} max={5} />
                    </Form.Item>
                </section>

                <section className='custom_tour_start_planing__form date_selector'>
                    <h1 className='heder_2'>
                        Select tour start date & duration
                    </h1>
                    <p className=' main_para'>
                        <strong>Note: </strong> We recommend starting your tour at least 2 weeks from today. This will give us enough time to plan your perfect trip.
                    </p>
                    <Form.Item
                        name='tourStartDate'
                        label='Tour Start Date'
                        className='padding_wrapper'
                        rules={[
                            {
                                required: true,
                                message: 'Please select tour start date.',
                            },
                        ]}
                    >
                        <DatePicker
                            className='date_picker'
                            format='YYYY-MM-DD'
                            showToday={false}
                            style={{ width: '200px' }}
                            disabledDate={(current) => current && current < getMinDate()}
                        />
                    </Form.Item>
                </section>

                <section className='custom_tour_start_planing__form date_selector'>
                    <h1 className='heder_2'>
                        How Long Do You Want Your Tour to Be?
                    </h1>
                    <p className=' main_para'>
                        Tell us the duration of your trip in days.
                    </p>
                    <Form.Item
                        name='tourDuration'
                        label='Tour Duration (No. of Nights)'
                        className='padding_wrapper'
                        rules={[
                            {
                                required: true,
                                message: 'Please select how long you want your tour to be.',
                            },
                        ]}
                    >
                        <InputNumber size="medium" min={7} max={30} placeholder='Enter number of days' style={{ width: '200px' }} />
                    </Form.Item>
                </section>

                <section className='custom_tour_start_planing__form qty_selector'>
                    <h1 className='heder_2'>
                        How many people are traveling?
                    </h1>

                    <div className='wrapper'>
                        <div className='quantityItem padding_wrapper'>
                            <span>
                                <h3>Adults</h3>
                                <h4>Age 12 +</h4>
                            </span>
                            <Form.Item
                                name='qtyAdults'
                            >
                                <InputNumber
                                    min={0}
                                    max={50}
                                    defaultValue={0}
                                />
                            </Form.Item>
                        </div>
                        <div className='quantityItem padding_wrapper'>
                            <span>
                                <h3>Children</h3>
                                <h4>Age 2 - 11</h4>
                            </span>
                            <Form.Item
                                name='qtyChildren'
                            >
                                <InputNumber
                                    min={0}
                                    max={50}
                                    defaultValue={0}
                                />
                            </Form.Item>
                        </div>
                        <div className='quantityItem padding_wrapper'>
                            <span>
                                <h3>Infants</h3>
                                <h4>Age 1 & below</h4>
                            </span>
                            <Form.Item
                                name='qtyInfants'
                            >
                                <InputNumber
                                    min={0}
                                    max={50}
                                    defaultValue={0}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </section>

                <section className='custom_tour_start_planing__form room_selector'>
                    <h1 className='heder_2'>
                        Select Your Preferred Accommodation Room Type
                    </h1>
                    <p className=' main_para'>
                        2 Person in a Room. Enjoy the comfort and privacy of your own room with your travel partner. (Twin or double beds can be arranged on request- No extra charge)
                    </p>
                    <div className='wrapper'>
                        <Form.Item
                            name='accommodationType'
                            className='padding_wrapper'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select your preferred accommodation type.',
                                },
                            ]}
                        >
                            <Radio.Group className='radio_group'>
                                <Radio className='room_type' value='Single'>
                                    <div className='inner_type'>
                                        <div className='left'>
                                            <h5>Single Room (1 person)</h5>
                                            <p>This room is for 1 person only. If you are traveling alone, this room is for you.</p>
                                        </div>
                                    </div>
                                </Radio>

                                <Radio className='room_type' value='Double'>
                                    <div className='left'>
                                        <h5>
                                            Standard double room (2 persons)
                                        </h5>
                                        <p>
                                            This room is for 2 persons. If you are traveling with a partner, or if you are traveling with a child, this room is for you.
                                        </p>
                                    </div>
                                </Radio>

                                <Radio className='room_type' value='Triple'>
                                    <div className='left'>
                                        <h5>
                                            Standard triple room (3 persons)
                                        </h5>
                                        <p>
                                            This room is for 3 persons. If you are traveling with a group of 3, this room is for you.
                                        </p>
                                    </div>
                                </Radio>

                                <Radio className='room_type' value='Family'>
                                    <div className='left'>
                                        <h5>
                                            Family Room (4 persons)
                                        </h5>
                                        <p>
                                            This room is for a family of 4. If you are traveling with a family of 4, this room is for you.
                                        </p>
                                    </div>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </section>

                <section className='custom_tour_start_planing__form'>
                    <Form.Item
                        className='padding_wrapper'
                    >
                        <div className='button_wrapper'>

                            <Button
                                onClick={prev}
                                className='primary_btn_default'
                                disabled={current === 0}
                            >
                                <ArrowLeftOutlined /> Previous
                            </Button>

                            <Button
                                type='primary'
                                className='custom_primary_btn_2'
                                htmlType='submit'
                            >
                                Next <ArrowRightOutlined />
                            </Button>
                        </div>
                    </Form.Item>
                </section>
            </Form>
        </div>
    );
};

export default DatesAndQty;