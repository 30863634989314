import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, ConfigProvider, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';

import './styles.scss';

const expirationDays = 7;

const Flights = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const popupExpiration = localStorage.getItem('flightPopupExpiration');
        const currentDate = new Date();

        if (!popupExpiration || new Date(popupExpiration) < currentDate) {
            setIsModalOpen(true);
        }
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://tp.media/content?currency=usd&trs=351900&shmarker=573775.flight_search_form&show_hotels=false&powered_by=true&locale=en&searchUrl=www.aviasales.com%2Fsearch&primary_override=%2367DCD3&color_button=%2367DCD3&color_icons=%2367DCD3&dark=%23063D67&light=%23FFFFFF&secondary=%23FFFFFF&special=%23C4C4C4&color_focused=%2332a8dd&border_radius=10&no_labels=&plain=true&origin=SYD&destination=CMB&promo_id=7879&campaign_id=100";
        script.async = true;
        script.charset = "utf-8";

        const script2 = document.createElement('script');
        script2.src = "https://tp.media/content?currency=aud&trs=351900&shmarker=573775&color_button=%2367DCD3&target_host=www.aviasales.com%2Fsearch&locale=en&powered_by=true&origin=SYD&destination=CMB&with_fallback=false&non_direct_flights=true&min_lines=20&border_radius=10&color_background=%23FFFFFF&color_text=%23063D67&color_border=%23FFFFFF&promo_id=2811&campaign_id=100";
        script2.async = true;
        script2.charset = "utf-8";

        document.getElementById('widget-container')?.appendChild(script);
        document.getElementById('everyday-widget-container')?.appendChild(script2);
        return () => {
            document.getElementById('widget-container')?.removeChild(script);
            document.getElementById('everyday-widget-container')?.removeChild(script2);
        };
    }, []);

    const closePopup = () => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + expirationDays);
        localStorage.setItem('flightPopupExpiration', expirationDate);
        setIsModalOpen(false);
    };


    return (
        <div className='main__contaier flights_main'>
            <div className='hero section_main'>
                <div className='hero__background__overlay'></div>
                <div className='hero__background'>
                    <LazyLoadImage
                        alt='background-image'
                        effect="blur"
                        wrapperProps={{
                            style: { transitionDelay: "0.1s" },
                        }}
                        src='https://visitpearlsharedblob.blob.core.windows.net/appassets/blogs_bg.webp'
                    />
                </div>

                <div className='hero__content'>
                    <h1 data-aos="fade-up" data-aos-delay="300" className='section_title'>
                        Fly with Us
                    </h1>
                </div>
            </div>

            <div className="custom-shape-divider-top-1724082403">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                </svg>
            </div>

            <div className='destinations__content section_main blogs_main'>
                <div className='breadcrumb__section'>
                    <Breadcrumb
                        className='breadcrumb_pages'
                        items={[
                            {
                                title: <div className='breadcrumb__item' onClick={() => navigate('/')}><HomeOutlined /> Home</div>,
                            },
                            {
                                title: <div className='breadcrumb__item last'>
                                    Flights
                                </div>,
                            },
                        ]}
                    />
                </div>

                <div className='search_container' data-aos="fade-up" data-aos-delay="500">
                    <div id="widget-container"></div>
                    <h4>
                        <span>Flights by</span> <a href="https://www.aviasales.com/" target="_blank" rel="noreferrer" className='default_anchor'>
                            Aviasales
                        </a>
                    </h4>

                    <div className='available__flights'>
                        <h1 className='header_1' style={{ margin: '20px 0 15px 0' }}>
                            Every Day Availabe Flights
                        </h1>
                        <div id="everyday-widget-container"></div>
                    </div>
                </div>
            </div>
            <ConfigProvider
                modal={{
                    styles: {
                        mask: {
                            backdropFilter: 'blur(5px)',
                        },
                    }
                }}
            >
                <Modal
                    title="Aknowledgement"
                    open={isModalOpen}
                    footer={null}
                    closable={false}
                >
                    <p>
                        This page only displays data from a third party vendor per your inputs via our website. This functionality is only integrated for your ease of use. Please note that we are not partnered with this vendor. If you choose their services, you will be adhering to their terms and conditions. Furthermore, we are not liable for any issues or provide any further support should you choose their services.
                    </p>

                    <Button
                        className='custom_primary_btn_2'
                        type="primary"
                        onClick={closePopup}
                    >
                        I Agree
                    </Button>
                </Modal>
            </ConfigProvider>
        </div>
    );
};

export default Flights;