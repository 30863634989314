import axios from 'axios';
import { API_BASE_URL_OPEN } from '../../App/azure/api';

export const getCurrencyList = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL_OPEN}/getCurrencyList`,
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}