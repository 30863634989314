export const FAQ_TOURS = {
    title: 'Frequently Asked Questions',
    questions: [
        {
            question: 'What is included in the tour package price?',
            answer: 'The tour package price includes accommodation, meals, transportation, and guided tours as specified in the itinerary. Please review the tour details for a complete list of inclusions.'
        },
        {
            question: 'What is the single supplement charge?',
            answer: 'The single supplement charge is applicable for solo travelers who wish to have a private room. The single supplement charge is in addition to the tour package price.'
        },
        {
            question: 'Are airport transfers included in the tour package price?',
            answer: 'Airport transfers are included in the tour package price. Please provide us with your flight details at least 48 hours before your arrival to ensure a smooth transfer process.'
        },
        {
            question: 'Is travel insurance included in the tour package price?',
            answer: 'Travel insurance is not included in the tour package price. We recommend that you purchase travel insurance to protect yourself against unforeseen circumstances such as trip cancellations, delays, or medical emergencies.'
        },
        {
            question: 'What is the booking process?',
            answer: 'To confirm your booking, a non-refundable deposit is required. The remaining balance is due 30 days before the tour departure date. If you book within 30 days of the departure date, the full payment is required at the time of booking.'
        }
    ]
}

export const FAQ_ACCOUNT = {
    title: 'Frequently Asked Questions',
    questions: [
        {
            question: 'How do I create an account?',
            answer: 'To create an account, click on the "Sign Up" button on the top right corner of the website. Fill in the required information and click "Sign Up" to create your account.'
        },
        {
            question: 'How do I log in to my account?',
            answer: 'To log in to your account, click on the "Log In" button on the top right corner of the website. Enter your email address and password and click "Log In" to access your account.'
        },
        {
            question: 'How do I reset my password?',
            answer: 'To reset your password, click on the "Forgot Password" link on the login page. Enter your email address and click "Reset Password" to receive a password reset link in your email.'
        },
        {
            question: 'How do I update my account information?',
            answer: 'To update your account information, log in to your account and click on the "Profile" tab. You can update your personal information, contact details, and password on this page.'
        },
        {
            question: 'How do I delete my account?',
            answer: 'To delete your account, please contact our customer support team for assistance. Once your account is deleted, all your account information and booking history will be permanently removed.'
        }
    ]
}

export const FAQ_PAYMENT = {
    title: 'Frequently Asked Questions',
    questions: [
        {
            question: 'What payment methods do you accept?',
            answer: 'We accept Visa, MasterCard, American Express, and PayPal for online payments. You can also pay via bank transfer or in person at our office.'
        },
        {
            question: 'Is my payment secure?',
            answer: 'Yes, your payment is secure. We use industry-standard encryption technology to protect your personal and payment information. Your credit card details are not stored on our servers.'
        },
        {
            question: 'When will my credit card be charged?',
            answer: 'Your credit card will be charged at the time of booking. If you book within 30 days of the departure date, the full payment is required at the time of booking.'
        },
        {
            question: 'Can I pay in installments?',
            answer: 'Yes, you can pay in installments. Please contact our customer support team to discuss a payment plan that works for you.'
        },
        {
            question: 'Can I cancel or change my booking?',
            answer: 'Yes, you can cancel or change your booking. Please refer to our booking terms and conditions for more information on cancellation and change fees.'
        }
    ]
}

export const FAQ_BOOKING = {
    title: 'Frequently Asked Questions',
    questions: [
        {
            question: 'How do I book a tour package?',
            answer: 'To book a tour package, select your desired tour package and click on the "Book Now" button. Fill in the required information and make a payment to confirm your booking.'
        },
        {
            question: 'Can I book a tour package for someone else?',
            answer: 'Yes, you can book a tour package for someone else. Please provide the traveler\'s information when making the booking, including their full name, contact details, and any special requests.'
        },
        {
            question: 'Can I make changes to my booking?',
            answer: 'Yes, you can make changes to your booking. Please contact our customer support team for assistance with changes to your booking.'
        },
        {
            question: 'How do I cancel my booking?',
            answer: 'To cancel your booking, please refer to our booking terms and conditions for information on cancellation fees and refund policies. Contact our customer support team to initiate the cancellation process.'
        },
        {
            question: 'Can I get a refund if I cancel my booking?',
            answer: 'Refund policies vary depending on the tour package and cancellation date. Please refer to our booking terms and conditions for information on cancellation fees and refund policies.'
        }
    ]
}

export const FAQ_SUPPORT = {
    title: 'Frequently Asked Questions',
    questions: [
        {
            question: 'How can I contact customer support?',
            answer: 'You can contact our customer support team via phone, email, or live chat. Our customer support team is available 24/7 to assist you with any questions or concerns.'
        },
        {
            question: 'How do I submit a support ticket?',
            answer: 'To submit a support ticket, log in to your account and click on the "Support" tab. Fill in the required information and click "Submit" to create a support ticket.'
        },
        {
            question: 'How do I chat with a customer support representative?',
            answer: 'To chat with a customer support representative, click on the live chat icon on the website. Our customer support team is available 24/7 to provide instant assistance.'
        },
        {
            question: 'How do I access the FAQ section?',
            answer: 'To access the FAQ section, click on the "FAQ" link on the website. You can find answers to common questions and troubleshooting tips in the FAQ section.'
        },
        {
            question: 'How do I contact customer support by phone?',
            answer: 'To contact customer support by phone, please refer to the contact numbers listed on the website. Our customer support team is available 24/7 to assist you with any questions or concerns.'
        }
    ]
}

export const FAQ_GENERAL = {
    title: 'Frequently Asked Questions',
    questions: [
        {
            question: 'What are your office hours?',
            answer: 'Our office hours are Monday to Friday from 9:00 am to 5:00 pm. Our customer support team is available 24/7 to assist you with any questions or concerns.'
        },
        {
            question: 'Do you offer group discounts?',
            answer: 'Yes, we offer group discounts for bookings of 10 or more travelers. Please contact our customer support team for more information on group discounts and special rates.'
        },
        {
            question: 'Do you offer custom tour packages?',
            answer: 'Yes, we offer custom tour packages for travelers who wish to create a personalized itinerary. Please contact our customer support team to discuss your travel preferences and requirements.'
        },
        {
            question: 'Do you offer travel insurance?',
            answer: 'Yes, we offer travel insurance for an additional fee. Travel insurance can protect you against unforeseen circumstances such as trip cancellations, delays, or medical emergencies.'
        },
        {
            question: 'Do you offer gift cards?',
            answer: 'Yes, we offer gift cards for tour packages and travel services. Gift cards are available in various denominations and can be used to book tours, accommodations, and other travel services.'
        }
    ]
}