import { ArrowLeftOutlined, ArrowRightOutlined, CheckCircleOutlined, InfoCircleOutlined, MenuUnfoldOutlined, } from '@ant-design/icons'
import { Button, Checkbox, Divider, Drawer, Empty, Form, Input, Radio, Skeleton, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import './styles.scss'
import { destinationTypes, districtList } from '../../../../constants/itemTypes'
import { Card, Row, Col } from 'react-bootstrap'
import { TbMapPin } from 'react-icons/tb'
import useDestinations from '../../../../hooks/useDestinations'
import { useDispatch } from 'react-redux'
import { fetchDestinationsAC } from '../../../../../redux/actions/destinations/destinations.ac'

const SelectDestinations = ({
    next,
    prev,
    setUserTourData,
    userTourData,
    notificationApi,
    current,
}) => {
    const [selectedDestinations, setSelectedDestinations] = useState([])
    const [showAllCities, setShowAllCities] = useState(false)
    const [selectedType, setSelectedType] = useState(null)
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)
    const destinations = useDestinations();
    const [page, setPage] = useState(1)
    const dispatch = useDispatch()
    const formRef = useRef()

    useEffect(() => {
        window.scrollTo(0, 0)
        handleFetchDestinations({ page: 1 })
    }, [])

    useEffect(() => {
        if (formRef.current && userTourData?.favoriteDestinations?.length > 0) {
            setSelectedDestinations(userTourData?.favoriteDestinations)
            formRef.current.setFieldsValue({
                favoriteDestinations: userTourData?.favoriteDestinations.map((destination) => destination.id),
                destinationSpecifics: userTourData?.destinationSpecifics,
            })
        }
    }, [userTourData])

    useEffect(() => {
        handleFetchFilteredDestinations(page)
    }, [selectedType, selectedLocation])

    const handleFetchFilteredDestinations = (page) => {
        const data = {
            page,
            destinationType: selectedType || null,
            destinationLocation: selectedLocation || null,
        }

        handleFetchDestinations(data)
    }

    const handleFetchDestinations = (values) => {
        values.pageSize = 100
        dispatch(fetchDestinationsAC(values))
    }

    const handleSubmit = (values) => {
        if (selectedDestinations.length === 0) {
            notificationApi.error({
                message: 'Missing Fields',
                description: 'Please select at least one destination',
            });
            return
        }

        setUserTourData({
            ...userTourData,
            favoriteDestinations: selectedDestinations,
            destinationSpecifics: values?.destinationSpecifics,
        })

        next()
    }

    const handleDestinationSelect = (e, item) => {
        const checked = e.target?.checked
        let newSelectedDestinations = [...selectedDestinations]

        if (checked) {
            newSelectedDestinations.push(item)
        } else {
            newSelectedDestinations = newSelectedDestinations.filter((destination) => destination.id !== item.id)
        }

        setSelectedDestinations(newSelectedDestinations)
    }

    const handleRenderCities = () => {
        let cities = districtList
        if (!showAllCities) {
            cities = districtList.slice(0, 5)
        }

        return cities.map((city, index) => (
            <Radio key={index} value={city}>{city}</Radio>
        ))
    }

    return (
        <div className='custom_tour_destination_select'>
            <h1 className='header_1 section_header'>
                Choose Your Favorite Destinations
            </h1>
            <p className=' main_para'>
                Next, let's choose the amazing places you’d like to visit. Where do you dream of going? Select your favorite destinations from the list below. You can choose as many as you like. Don’t worry if you’re not sure yet, you can always change your mind later.
            </p>

            <Divider />

            <Form
                onFinish={handleSubmit}
                scrollToFirstError
                onFinishFailed={() => {
                    notificationApi.error({
                        message: 'Missing Fields',
                        description: 'Please fill all the required fields',
                    });
                }}
                ref={formRef}
            >
                <section className='custom_tour_start_planing__form'>
                    <h1 className='heder_2'>
                        Which Destinations Are on Your Must-Visit List?
                    </h1>
                    <p className=' main_para'>
                        Search for and select the cities, attractions or landmarks you want to explore.
                    </p>

                    <div className='selected_tags'>
                        <h1 className='heder_3'>
                            Selected Destinations
                        </h1>
                        <div className='tags'>
                            {
                                selectedDestinations.length > 0 ?
                                    selectedDestinations.map((destination, idx) => (
                                        <div key={idx} className='tag'>
                                            {destination?.destinationName}
                                        </div>
                                    ))
                                    :
                                    <p>
                                        No selected destinations
                                    </p>
                            }
                        </div>
                    </div>

                    <Button
                        type='primary'
                        className='filter__btn'
                        onClick={() => setIsFilterDrawerOpen(true)}
                    >
                        <MenuUnfoldOutlined />
                    </Button>

                    <div className='desctions_wrapper'>
                        <div className='filter__section'>
                            <Radio.Group
                                value={selectedType}
                                onChange={(e) => setSelectedType(e.target.value)}
                                className='filter__group'
                            >
                                <div className='header'>
                                    <h1 className='heder_3'>Type of Tour</h1>
                                    <Button
                                        type='link'
                                        onClick={() => setSelectedType(null)}
                                    >
                                        Clear
                                    </Button>
                                </div>

                                <div className='radios'>
                                    <Radio value={destinationTypes.CITY}>City</Radio>
                                    <Radio value={destinationTypes.WILDLIFE_PARK}>Wildlife Park</Radio>
                                    <Radio value={destinationTypes.MOUNTAIN}>Mountains</Radio>
                                    <Radio value={destinationTypes.BEACH}>Beach</Radio>
                                    <Radio value={destinationTypes.WATER_PARK}>Water Park</Radio>
                                </div>
                            </Radio.Group>

                            <Divider />


                            <Radio.Group
                                value={selectedLocation}
                                onChange={(e) => {
                                    setSelectedLocation(e.target.value);
                                }}
                                className='filter__group'
                            >
                                <div className='header'>
                                    <h1 className='heder_3'>Cities</h1>
                                    <Button
                                        type='link'
                                        onClick={() => setSelectedLocation(null)}
                                    >
                                        Clear
                                    </Button>
                                </div>

                                <div className='radios'>
                                    {handleRenderCities()}
                                </div>
                            </Radio.Group>
                            <Button className='show_all_btn' type='link' onClick={() => setShowAllCities(!showAllCities)}>
                                {showAllCities ? 'Hide' : 'Show All'}
                            </Button>
                        </div>

                        <Form.Item
                            name='favoriteDestinations'
                            className='destinations_selector'
                        >
                            <div className='items__list'>
                                <div className='section__all__destinations__content'>
                                    <Row xs={1} md={2} lg={3} xl={3}
                                        className='destinations_row'>
                                        {!destinations?.loading && destinations?.destinations?.map((item, idx) => (
                                            <Col key={idx}>
                                                <Tooltip title='More Info'>
                                                    <Button className='info__button'
                                                        onClick={() => {
                                                            window.open(`/destinations/${item?.id}`, '_blank');
                                                        }}
                                                    >
                                                        <InfoCircleOutlined />
                                                    </Button>
                                                </Tooltip>

                                                <Checkbox
                                                    className='destination__card'
                                                    style={{
                                                        backgroundImage: `url('${item?.thumbnailUrl}')`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center',
                                                    }}
                                                    value={item?.destinationId}
                                                    onChange={(e) => {
                                                        handleDestinationSelect(e, item)
                                                    }}
                                                    checked={selectedDestinations.some((destination) => destination.id === item.id)}
                                                >
                                                    <div className='background__overlay'></div>
                                                    <Card.Body
                                                        className='destination__card__content'
                                                    >
                                                        {/* <h2>{item?.destinationName}</h2> */}
                                                        <p>{item?.destinationCity}</p>
                                                    </Card.Body>
                                                    <div className='selected'>
                                                        <CheckCircleOutlined />
                                                        Selected
                                                    </div>
                                                    <div className='select'>
                                                        Click to select
                                                    </div>
                                                </Checkbox>
                                            </Col>
                                        ))}

                                        {
                                            destinations?.loading && <>
                                                {Array.from({ length: 6 }).map((_, idx) => (
                                                    <Col key={idx}>
                                                        <Card className='destination__card_loading'>
                                                            <Card.Body>
                                                                <Skeleton active />
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </>
                                        }
                                    </Row>

                                    {
                                        destinations?.loading === false && destinations?.destinations?.length === 0 && (
                                            <div className='empty_cards'>
                                                <Empty
                                                    description={<span>No found</span>}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </Form.Item>
                    </div>
                </section>

                <section className='custom_tour_start_planing__form date_selector'>
                    <h1 className='heder_2'>
                        Are There Specific Landmarks or Attractions You’re Excited About?
                    </h1>
                    <p className=' main_para'>
                        If there are specific attractions or landmarks you’re excited about, let us know. We’ll make sure to include them in your itinerary.
                    </p>
                    <Form.Item
                        name='destinationSpecifics'
                    >
                        <Input.TextArea
                            placeholder='Enter the specific landmarks or attractions you’re excited about'
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            maxLength={400}
                            showCount
                        />
                    </Form.Item>
                </section>

                <section className='custom_tour_start_planing__form'>
                    <Form.Item>
                        <div className='button_wrapper'>

                            <Button
                                onClick={prev}
                                className='primary_btn_default'
                                disabled={current === 0}
                            >
                                <ArrowLeftOutlined /> Previous
                            </Button>

                            <Button
                                type='primary'
                                className='custom_primary_btn_2'
                                htmlType='submit'
                            >
                                Next <ArrowRightOutlined />
                            </Button>
                        </div>
                    </Form.Item>
                </section>
            </Form>

            <Drawer
                title="Basic Drawer"
                className='filter__drawer custom_tour_filter'
                placement='bottom'
                closable={false}
                open={isFilterDrawerOpen}
                onClose={() => setIsFilterDrawerOpen(false)}
                height='70vh'
                headerStyle={{ display: 'none' }}
            >
                <div className='filter__section'>
                    <Radio.Group
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                        className='filter__group'
                    >
                        <div className='header'>
                            <h1 className='heder_3'>Type of Tour</h1>
                            <Button
                                type='link'
                                onClick={() => setSelectedType(null)}
                            >
                                Clear
                            </Button>
                        </div>

                        <div className='radios'>
                            <Radio value={destinationTypes.CITY}>City</Radio>
                            <Radio value={destinationTypes.WILDLIFE_PARK}>Wildlife Park</Radio>
                            <Radio value={destinationTypes.MOUNTAIN}>Mountains</Radio>
                            <Radio value={destinationTypes.BEACH}>Beach</Radio>
                            <Radio value={destinationTypes.WATER_PARK}>Water Park</Radio>
                        </div>
                    </Radio.Group>

                    <Divider />


                    <Radio.Group
                        value={selectedLocation}
                        onChange={(e) => {
                            setSelectedLocation(e.target.value);
                        }}
                        className='filter__group'
                    >
                        <div className='header'>
                            <h1 className='heder_3'>Cities</h1>
                            <Button
                                type='link'
                                onClick={() => setSelectedLocation(null)}
                            >
                                Clear
                            </Button>
                        </div>

                        <div className='radios'>
                            {handleRenderCities()}
                        </div>
                    </Radio.Group>
                    <Button className='show_all_btn' type='link' onClick={() => setShowAllCities(!showAllCities)}>
                        {showAllCities ? 'Hide' : 'Show All'}
                    </Button>
                </div>
            </Drawer>
        </div>
    )
}

export default SelectDestinations