import React, { useEffect, useState } from 'react'
import { Empty, Skeleton } from 'antd';

import './styles.scss'
import { getCustomEventsByUser } from '../../../../../redux/api/userTour.api';
import useMyProfile from '../../../../hooks/useMyProfile';
import { capitalizeFisrtLetterEachWord } from '../../../../helpers/nameRender';
import { useNavigate } from 'react-router-dom';

const CustomEvents = () => {
    const [loading, setLoading] = useState(false);
    const [bookings, setBookings] = useState(null);
    const myProfile = useMyProfile();
    const navigate = useNavigate();

    useEffect(() => {
        if (myProfile?.userProfile?.id && !bookings) {
            handleGetTourBoookings();
        }
    }, [myProfile]);

    const handleGetTourBoookings = async () => {
        setLoading(true);
        try {
            const response = await getCustomEventsByUser(myProfile?.userProfile?.id)
            setBookings(response?.data);
        } catch (error) {
            console.log('ERROR GETTING TOUR BOOKINGS', error);
        }
        setLoading(false);
    }

    return (
        <div className='personal_details'>
            <h1 className='heder_2'>
                Custom Events
            </h1>
            <p className='about'>
                Here you can view all your custom events and their status.
            </p>

            <div className='personal_details__container'>
                {
                    loading && Array(3).fill().map((_, i) => (
                        <div className='booking__card'>
                            <Skeleton
                                active
                                paragraph={{ rows: 2 }}
                            />
                        </div>
                    ))
                }
                {
                    bookings?.length > 0 ? bookings?.map((booking) => (
                        <div className='booking__card'
                            onClick={() => navigate(`/dashboard/my-bookings/custom-events/${booking?.id}`)}
                        >
                            <div className='booking__card__header'>
                                <h3>{booking?.packageName}</h3>
                                <h4><span>Start Date: </span><p>{booking?.startDate}</p></h4>
                                <h4
                                    className={
                                        booking?.bookingStatus === 'PENDING_PAYMENT' ? 'red'
                                            : booking?.bookingStatus === 'CONFIRMED' ? 'green'
                                                : booking?.bookingStatus === 'PENDING_VERIFICATION' ? 'yellow'
                                                    : booking?.bookingStatus === 'CANCELLED' ? 'red'
                                                        : booking?.bookingStatus === 'REQUESTED' ? 'yellow'
                                                            : 'blue'
                                    }
                                ><span>Status: </span><p>{booking?.bookingStatus ? capitalizeFisrtLetterEachWord(booking?.bookingStatus) : 'Pending'}</p></h4>
                            </div>
                        </div>
                    ))
                        :
                        <>
                            {
                                !loading && <div>
                                    <Empty
                                        description={
                                            <span>
                                                No Custom Events Found
                                            </span>
                                        }
                                    />
                                </div>
                            }
                        </>
                }
            </div>
        </div>
    )
}

export default CustomEvents