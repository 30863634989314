import axios from 'axios';

import { API_BASE_URL, API_BASE_URL_OPEN } from "../../App/azure/api";
import { getAccessToken } from '../../App/azure/auth';
import { msalInstance } from '../../App/azure/authConfig';

export const createSupportRequest = async (values) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL_OPEN}/createSupportrequests`,
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getSupportRequestsByUser = async (id) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getSupportRequestsByUser?id=${id}&clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getSupportRequestById = async (id) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getSupportRequestById?id=${id}&clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getSupportRequestMessages = async (id) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getSupportRequestMessages?id=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const addSupportRequestMessage = async (values) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/addSupportRequestMessage`,
        data: values,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}