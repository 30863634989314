import { Button, DatePicker, Divider, Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { capitalizeFisrtLetterEachWord } from '../../../../../../helpers/nameRender'
import { CONFIRMED, PAID, PENDING_PAYMENT, PENDING_VERIFICATION } from '../../../../../../constants/finance'
import moment from 'moment'
import { addUserTourFlightDetails } from '../../../../../../../redux/api/userTour.api'

const BookingDetails = ({
    userTour,
    handleGetUserTour,
    messageApi
}) => {
    const [addFlighDetailsVisible, setAddFlighDetailsVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const onSubmitFlightDetails = async (values) => {
        setLoading(true)
        values.id = userTour?.id;
        values.flightBooked = true;
        const response = await addUserTourFlightDetails(values)
        if (response.status === 200) {
            messageApi.success({
                message: 'Success',
                description: 'Flight details added successfully.'
            })
            setAddFlighDetailsVisible(false)
            handleGetUserTour()
        } else {
            messageApi.error({
                message: 'Error',
                description: 'Something went wrong! Try again later.'
            })
        }
        setLoading(false)
    }

    return (
        <div className='booking_details'>
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Basic Information
            </Divider>
            <div className='p_container'>
                <p><span>Booking ID: </span>{userTour?.id}</p>
                <p><span>Start Date: </span>{userTour?.tourStartDate}</p>
                <p><span>Duration: </span>{userTour?.tourPackage?.durationDays} Days / {userTour?.tourPackage?.durationNights} Nights</p>
                <p><span>Booked Date: </span>{userTour?.createdAt}</p>
            </div>
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Status
            </Divider>
            <div className='p_container'>
                <p
                    className={userTour?.isConfirmedByAdmin ? 'green' : 'red'}
                ><span>Approve Status: </span>{userTour?.isConfirmedByAdmin ? 'Approved' : 'Pending Approval'}</p>
                <p
                    className={userTour?.bookingStatus === PENDING_PAYMENT ? 'red' :
                        userTour?.bookingStatus === PENDING_VERIFICATION ? 'yellow' :
                            userTour?.bookingStatus === CONFIRMED ? 'green' :
                                'red'}
                ><span>Booking Status: </span>{userTour?.bookingStatus ? capitalizeFisrtLetterEachWord(userTour?.bookingStatus) : 'Pending'}
                </p>
                <p
                    className={userTour?.paymentStatus === PENDING_PAYMENT ? 'red' :
                        userTour?.paymentStatus === PENDING_VERIFICATION ? 'yellow' :
                            userTour?.paymentStatus === PAID ? 'green' :
                                'red'}
                ><span>Payment Status: </span>{userTour?.paymentStatus ? capitalizeFisrtLetterEachWord(userTour?.paymentStatus) : 'Pending'}
                </p>
            </div>
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Your Selections
            </Divider>
            <div className='p_container'>
                <p><span>Transport Option: </span>{userTour?.transportOption}</p>
                <p><span>Accommodation Room Type: </span>{userTour?.accommodationOption}</p>
            </div>
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Guide & Transport
            </Divider>
            <div className='p_container'>
                <p><span>Your Tour Guide: </span>{userTour?.guideId || 'Not yet assigned'}</p>
                <p><span>Vehicle: </span>{userTour?.tarnsportVehicle?.length < 1 ? 'Not yet assigned' : 'Assigned'}</p>
            </div>
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Travelers & Contacts
            </Divider>
            <div className='travellers'>
                {
                    userTour?.travellersInfromation?.adults?.length > 0
                    &&
                    <>
                        <h2>Adults</h2>
                        {
                            userTour?.travellersInfromation?.adults.map((traveler, index) => (
                                <div key={index} className='p_container'>
                                    <p><span>First Name: </span>{traveler.firstName}</p>
                                    <p><span>Last Name: </span>{traveler.lastName}</p>
                                    <p><span>Email: </span>{traveler.email}</p>
                                    <p><span>Phone: </span>{traveler.phone}</p>
                                    <p><span>Country: </span>{traveler.country}</p>
                                    <p><span>Passport No: </span>{traveler.passportNumber}</p>
                                    <p><span>Allergies & Dietary Requirements: </span>{traveler.allergies || 'None'}</p>
                                </div>
                            ))
                        }
                    </>
                }
                {
                    userTour?.travellersInfromation?.children?.length > 0
                    &&
                    <>
                        <h2>Children</h2>
                        {
                            userTour?.travellersInfromation?.children.map((traveler, index) => (
                                <div key={index} className='p_container'>
                                    <p><span>First Name: </span>{traveler.firstName}</p>
                                    <p><span>Last Name: </span>{traveler.lastName}</p>
                                    <p><span>Email: </span>{traveler.email}</p>
                                    <p><span>Phone: </span>{traveler.phone}</p>
                                    <p><span>Country: </span>{traveler.country}</p>
                                    <p><span>Passport No: </span>{traveler.passportNumber}</p>
                                    <p><span>Allergies & Dietary Requirements: </span>{traveler.allergies || 'None'}</p>
                                </div>
                            ))
                        }
                    </>
                }
                {
                    userTour?.travellersInfromation?.infants?.length > 0
                    &&
                    <>
                        <h2>Infants</h2>
                        {
                            userTour?.travellersInfromation?.infants.map((traveler, index) => (
                                <div key={index} className='p_container'>
                                    <p><span>First Name: </span>{traveler.firstName}</p>
                                    <p><span>Last Name: </span>{traveler.lastName}</p>
                                    <p><span>Email: </span>{traveler.email}</p>
                                    <p><span>Phone: </span>{traveler.phone}</p>
                                    <p><span>Country: </span>{traveler.country}</p>
                                    <p><span>Passport No: </span>{traveler.passportNumber}</p>
                                    <p><span>Allergies & Dietary Requirements: </span>{traveler.allergies || 'None'}</p>
                                </div>
                            ))
                        }
                    </>
                }
                <h2>Emergency Contacts</h2>
                <div className='p_container'>
                    <p><span>Name: </span>{userTour?.emergencyContactName}</p>
                    <p><span>Phone: </span>{userTour?.emergencyContactNumber}</p>
                    <p><span>Email: </span>{userTour?.emergencyEmail}</p>
                </div>

                <h2>Flight Details</h2>
                <div className='p_container'>
                    <p><span>Flight Booked: </span>{userTour?.flightDetails?.flightBooked ? 'Yes' : 'Not Yet'}</p>
                    {userTour?.flightDetails?.flightBooked ? <>
                        <p><span>Flight No: </span>{userTour?.flightDetails?.flightNumber}</p>
                        <p><span>Departure Airport: </span>{userTour?.flightDetails?.departureAirport}</p>
                        <p><span>Arrival Date & Time: </span>{
                            moment(userTour?.flightDetails?.arrivalDateTime).format('DD/MM/YYYY HH:mm')
                        }</p>
                    </> :
                        <Button
                            type='primary'
                            onClick={() => setAddFlighDetailsVisible(!addFlighDetailsVisible)}
                        >
                            <span
                                style={{ color: 'white' }}
                            >{
                                    addFlighDetailsVisible ? 'Cancel' : 'Add Flight Details'
                                }
                            </span>
                        </Button>
                    }

                    {
                        addFlighDetailsVisible &&
                        <Form
                            onFinish={onSubmitFlightDetails}
                            layout='vertical'
                        >
                            <Form.Item
                                label='Flight Number'
                                name='flightNumber'
                                rules={[{ required: true, message: 'Please enter flight number' }]}
                            >
                                <Input placeholder='Enter flight number' maxLength={50} />
                            </Form.Item>
                            <Form.Item
                                label='Departure Airport'
                                name='departureAirport'
                                rules={[{ required: true, message: 'Please enter departure airport' }]}
                            >
                                <Input placeholder='Enter departure airport' maxLength={50} />
                            </Form.Item>
                            <Form.Item
                                label='Arrival Date & Time'
                                name='arrivalDateTime'
                                rules={[{ required: true, message: 'Please enter departure airport' }]}
                            >
                                <DatePicker
                                    showTime
                                    format='YYYY-MM-DD HH:mm:ss'
                                    placeholder='Arrival Date & Time'
                                    showNow={false}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                            <p style={{ margin: '0 0 20px 0' }}>
                                <strong>Note:</strong> Please make sure to provide correct flight details to avoid any inconvenience. <span style={{ color: 'red' }}>Once submitted, you can't change the flight details.</span>
                            </p>
                            <Form.Item>
                                <Button
                                    type='primary'
                                    htmlType='submit'
                                    loading={loading}
                                >
                                    <span
                                        style={{ color: 'white' }}
                                    >
                                        Add Flight Details
                                    </span>
                                </Button>
                            </Form.Item>
                        </Form>
                    }
                </div>
            </div>
        </div>
    )
}

export default BookingDetails