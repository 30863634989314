import React, { useEffect, useState } from 'react'
import useMyProfile from '../../../hooks/useMyProfile'
import { deleteFavourite, getAllFavouritesByUser } from '../../../../redux/api/favourite.api'
import { BiCycling, BiLandscape, BiLoader, BiSolidMapPin, BiTrip } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { Button, Drawer, Empty, message, Skeleton, Tag } from 'antd'
import { FAV_ACCOMMODATION, FAV_ACTIVITY, FAV_ATTRACTION, FAV_DESTINATION, FAV_EVENT, FAV_TOUR_PACKAGE } from '../../../constants/favTypes'
import { CloseCircleOutlined, HeartTwoTone, MenuUnfoldOutlined } from '@ant-design/icons'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { DEFAULT_THUMBNAIL } from '../../../constants/otherConstsnts'
import ReactQuill from 'react-quill'
import { capitalizeFisrtLetterEachWord, captilizeFirstLetter, resizeString } from '../../../helpers/nameRender'
import { Card } from 'react-bootstrap'

import './styles.scss'

const items = [
    {
        key: '1',
        label: 'Tours',
        icon: <BiTrip style={{ fontSize: '20px' }} />,
    },
    {
        key: '2',
        label: 'Destinations',
        icon: <BiSolidMapPin style={{ fontSize: '20px' }} />,
    },
    {
        key: '3',
        label: 'Attractions',
        icon: <BiLandscape style={{ fontSize: '20px' }} />,
    },
    {
        key: '4',
        label: 'Activities',
        icon: <BiCycling style={{ fontSize: '20px' }} />,
    },
    {
        key: '5',
        label: 'Events',
        icon: <BiLoader style={{ fontSize: '20px' }} />,
    },
]
const WishList = () => {
    const myProfile = useMyProfile().userProfile
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState('1');
    const [isNavDrawerOpen, setIsNavDraweOpen] = useState(false);
    const [favouritesTours, setFavouritesTours] = useState([])
    const [favouritesHotels, setFavouritesHotels] = useState([])
    const [favouriteDestinations, setFavouriteDestinations] = useState([])
    const [favouriteAttractions, setFavouriteAttractions] = useState([])
    const [favouritActivities, setFavouritActivities] = useState([])
    const [favouriteEvents, setFavouriteEvents] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingFavourite, setLoadingFavourite] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        if (myProfile) {
            handleGetFavorites()
        }
    }, [myProfile])

    const handleGetFavorites = async () => {
        setLoading(true)
        try {
            const response = await getAllFavouritesByUser(myProfile?.id)
            const tempFavouritesTours = [];
            const tempFavouritesHotels = [];
            const tempFavouriteDestinations = [];
            const tempFavouriteAttractions = [];
            const tempFavouritActivities = [];
            const tempFavouriteEvents = [];

            response?.data?.forEach(fav => {
                switch (fav?.favouriteType) {
                    case FAV_TOUR_PACKAGE:
                        tempFavouritesTours.push(fav);
                        break;
                    case FAV_ACCOMMODATION:
                        tempFavouritesHotels.push(fav);
                        break;
                    case FAV_DESTINATION:
                        tempFavouriteDestinations.push(fav);
                        break;
                    case FAV_ATTRACTION:
                        tempFavouriteAttractions.push(fav);
                        break;
                    case FAV_ACTIVITY:
                        tempFavouritActivities.push(fav);
                        break;
                    case FAV_EVENT:
                        tempFavouriteEvents.push(fav);
                        break;
                    default:
                        break;
                }
            });

            setFavouritesTours(tempFavouritesTours);
            setFavouritesHotels(tempFavouritesHotels);
            setFavouriteDestinations(tempFavouriteDestinations);
            setFavouriteAttractions(tempFavouriteAttractions);
            setFavouritActivities(tempFavouritActivities);
            setFavouriteEvents(tempFavouriteEvents);
        } catch (error) {
            console.error('Error checking favourite', error)
        }
        setLoading(false)
    }

    const handleOnClikFavourite = async (id) => {
        setLoadingFavourite(true)
        await deleteFavourite(id)
        messageApi.open({
            type: 'success',
            content: 'Removed from favourites',
            duration: 2,
        });
        handleGetFavorites()
        setLoadingFavourite(false)
    }

    return (
        <div className='main__contaier favourites'>
            {contextHolder}
            <div className='header'>
                <h2 className='header_1' style={{ marginBottom: '20px' }}>Your Favourites</h2>
            </div>
            <div className='dashboard__Home'>
                <div className='dash_home_nav'>
                    {items.map((item) => (
                        <div
                            key={item.key}
                            className={`dash_home_nav__container__items__item ${activeItem === item.key ? 'active' : ''}`}
                            onClick={() => {
                                setActiveItem(item.key);
                            }}
                        >
                            {item.icon}
                            {item.label}
                        </div>
                    ))}
                </div>
                <div className='mobile_nav'>
                    <Button onClick={() => setIsNavDraweOpen(true)}><MenuUnfoldOutlined /> Open Menu</Button>
                </div>

                <div className='dash_home_content'>
                    {
                        loading &&
                        Array(3).fill(0).map((_, index) => (
                            <Card key={index} className='paymentLoading' style={{
                                marginBottom: '10px',
                                border: 'none',
                                padding: '20px',
                            }}>
                                <Skeleton active />
                            </Card>
                        ))
                    }
                    {
                        !loading && favouritesTours?.length > 0 && activeItem === '1' &&
                        <div className='fav_item'>
                            <h2 className='header_3' style={{ marginBottom: '15px' }}>Tours</h2>

                            {
                                favouritesTours.map((item, index) => (
                                    <div className='fav_item__content' key={index}>
                                        <Card
                                            className='tour__packages__main_card'
                                            data-aos="fade-up"
                                            style={{ marginBottom: '20px' }}
                                        >
                                            <Card.Body
                                                className='destination__card__content'
                                            >
                                                <div className='left'>
                                                    <LazyLoadImage
                                                        alt={item?.favItem?.packageName}
                                                        effect="blur"
                                                        wrapperProps={{
                                                            style: { transitionDelay: "0.1s" },
                                                        }}
                                                        src={item?.favItem?.thumbnailUrl || DEFAULT_THUMBNAIL}
                                                    />
                                                </div>

                                                <div className='right'>
                                                    <div
                                                        className='title'
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <h1>
                                                            {item?.favItem?.packageName}
                                                        </h1>

                                                        <Button
                                                            className='button'
                                                            type='link'
                                                            loading={loadingFavourite}
                                                            onClick={() => handleOnClikFavourite(item?.id)}
                                                        >
                                                            <HeartTwoTone style={{ fontSize: '30px' }} twoToneColor="#eb2f96" />
                                                        </Button>
                                                    </div>

                                                    <ReactQuill
                                                        value={item && resizeString(item?.favItem?.shotDescription, 200)}
                                                        readOnly={true}
                                                        theme='bubble'
                                                    />
                                                    <div className='tags'>
                                                        {
                                                            item?.favItem?.tags && item?.favItem?.tags?.split(',')?.map((tag, index) => (
                                                                <Tag key={index}>{tag}</Tag>
                                                            ))
                                                        }
                                                    </div>

                                                    <div className='card_footer'>
                                                        <div className='details'>
                                                            <div className='item'>
                                                                <h3>Duration</h3>
                                                                <p>{item?.favItem?.durationDays} Days / {item?.favItem?.durationNights} Nights</p>
                                                            </div>

                                                            <div className='item'>
                                                                <h3>Tour Type</h3>
                                                                <p>{captilizeFirstLetter(item?.favItem?.tourType)}</p>
                                                            </div>

                                                            <div className='item'>
                                                                <h3>Age Range</h3>
                                                                <p>
                                                                    {item?.minAge} - {item?.maxAge} Years
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='price'>
                                                            <h2>
                                                                <span>From </span>
                                                                {item?.startingPrice} AUD
                                                            </h2>

                                                            <Button
                                                                type='primary'
                                                                onClick={() => navigate(`/tours/${item?.id}`)}
                                                            >
                                                                View Tour
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ))
                            }
                        </div>
                    }

                    {
                        !loading && favouriteDestinations?.length > 0 && activeItem === '2' &&
                        <div className='fav_item '>
                            <h2 className='header_3' style={{ marginBottom: '15px' }}>Destinations</h2>

                            {
                                favouriteDestinations.map((item, index) => (
                                    <div className='fav_item__content' key={index}>
                                        <Card
                                            className='tour__packages__main_card'
                                            data-aos="fade-up"
                                            style={{ marginBottom: '20px' }}
                                        >
                                            <Card.Body
                                                className='destination__card__content'
                                            >
                                                <div className='left'>
                                                    <LazyLoadImage
                                                        alt={item?.favItem?.destinationName}
                                                        effect="blur"
                                                        wrapperProps={{
                                                            style: { transitionDelay: "0.1s" },
                                                        }}
                                                        src={item?.favItem?.thumbnailUrl || DEFAULT_THUMBNAIL}
                                                    />
                                                </div>

                                                <div className='right'>
                                                    <div
                                                        className='title'
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <h1>
                                                            {item?.favItem?.destinationName}
                                                        </h1>

                                                        <Button
                                                            className='button'
                                                            type='link'
                                                            loading={loadingFavourite}
                                                            onClick={() => handleOnClikFavourite(item?.id)}
                                                        >
                                                            <HeartTwoTone style={{ fontSize: '30px' }} twoToneColor="#eb2f96" />
                                                        </Button>
                                                    </div>

                                                    <ReactQuill
                                                        value={item && resizeString(item?.favItem?.destinationDescriptionShort, 200)}
                                                        readOnly={true}
                                                        theme='bubble'
                                                    />
                                                    <div className='tags'>
                                                        {
                                                            item?.favItem?.tags && item?.favItem?.tags?.split(',')?.map((tag, index) => (
                                                                <Tag key={index}>{tag && capitalizeFisrtLetterEachWord(tag)}</Tag>
                                                            ))
                                                        }
                                                    </div>


                                                    <div className='card_footer'>
                                                        <div className='price'>
                                                            <Button
                                                                type='primary'
                                                                onClick={() => navigate(`/tours/${item?.id}`)}
                                                            >
                                                                View Destination
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ))
                            }
                        </div>
                    }

                    {
                        !loading && favouriteAttractions?.length > 0 && activeItem === '3' &&
                        <div className='fav_item '>
                            <h2 className='header_3' style={{ marginBottom: '15px' }}>Attractions</h2>
                            {
                                favouriteAttractions.map((item, index) => (
                                    <div className='fav_item__content' key={index}>
                                        <Card
                                            className='tour__packages__main_card'
                                            data-aos="fade-up"
                                            style={{ marginBottom: '20px' }}
                                        >
                                            <Card.Body
                                                className='destination__card__content'
                                            >
                                                <div className='left'>
                                                    <LazyLoadImage
                                                        alt={item?.favItem?.attractionName}
                                                        effect="blur"
                                                        wrapperProps={{
                                                            style: { transitionDelay: "0.1s" },
                                                        }}
                                                        src={item?.favItem?.thumbnailUrl || DEFAULT_THUMBNAIL}
                                                    />
                                                </div>

                                                <div className='right'>
                                                    <div
                                                        className='title'
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <h1>
                                                            {item?.favItem?.attractionName}
                                                        </h1>

                                                        <Button
                                                            className='button'
                                                            type='link'
                                                            loading={loadingFavourite}
                                                            onClick={() => handleOnClikFavourite(item?.id)}
                                                        >
                                                            <HeartTwoTone style={{ fontSize: '30px' }} twoToneColor="#eb2f96" />
                                                        </Button>
                                                    </div>

                                                    <ReactQuill
                                                        value={item && resizeString(item?.favItem?.attractionDescriptionShort, 200)}
                                                        readOnly={true}
                                                        theme='bubble'
                                                    />
                                                    <div className='tags'>
                                                        {
                                                            item?.favItem?.tags && item?.favItem?.tags?.split(',')?.map((tag, index) => (
                                                                <Tag key={index}>{tag && capitalizeFisrtLetterEachWord(tag)}</Tag>
                                                            ))
                                                        }
                                                    </div>


                                                    <div className='card_footer'>
                                                        <div className='price'>
                                                            <Button
                                                                type='primary'
                                                                onClick={() => navigate(`/tours/${item?.id}`)}
                                                            >
                                                                View Attraction
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ))
                            }
                        </div>
                    }
                    {
                        !loading && favouritActivities?.length > 0 && activeItem === '4' &&
                        <div className='fav_item '>
                            <h2 className='header_3' style={{ marginBottom: '15px' }}>Activities</h2>
                            {
                                favouritActivities.map((item, index) => (
                                    <div className='fav_item__content' key={index}>
                                        <Card
                                            className='tour__packages__main_card'
                                            data-aos="fade-up"
                                            style={{ marginBottom: '20px' }}
                                        >
                                            <Card.Body
                                                className='destination__card__content'
                                            >
                                                <div className='left'>
                                                    <LazyLoadImage
                                                        alt={item?.favItem?.activityName}
                                                        effect="blur"
                                                        wrapperProps={{
                                                            style: { transitionDelay: "0.1s" },
                                                        }}
                                                        src={item?.favItem?.thumbnailUrl || DEFAULT_THUMBNAIL}
                                                    />
                                                </div>

                                                <div className='right'>
                                                    <div
                                                        className='title'
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <h1>
                                                            {item?.favItem?.activityName}
                                                        </h1>

                                                        <Button
                                                            className='button'
                                                            type='link'
                                                            loading={loadingFavourite}
                                                            onClick={() => handleOnClikFavourite(item?.id)}
                                                        >
                                                            <HeartTwoTone style={{ fontSize: '30px' }} twoToneColor="#eb2f96" />
                                                        </Button>
                                                    </div>

                                                    <ReactQuill
                                                        value={item && resizeString(item?.favItem?.activityDescriptionShort, 200)}
                                                        readOnly={true}
                                                        theme='bubble'
                                                    />
                                                    <div className='tags'>
                                                        {
                                                            item?.favItem?.tags && item?.favItem?.tags?.split(',')?.map((tag, index) => (
                                                                <Tag key={index}>{tag && capitalizeFisrtLetterEachWord(tag)}</Tag>
                                                            ))
                                                        }
                                                    </div>


                                                    <div className='card_footer'>
                                                        <div className='price'>
                                                            <Button
                                                                type='primary'
                                                                onClick={() => navigate(`/tours/${item?.id}`)}
                                                            >
                                                                View Activity
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ))
                            }
                        </div>
                    }
                    {
                        !loading && favouriteEvents?.length > 0 && activeItem === '5' &&
                        <div className='fav_item '>
                            <h2 className='header_3' style={{ marginBottom: '15px' }}>Activities</h2>
                            {
                                favouriteEvents.map((item, index) => (
                                    <div className='fav_item__content' key={index}>
                                        <Card
                                            className='tour__packages__main_card'
                                            data-aos="fade-up"
                                            style={{ marginBottom: '20px' }}
                                        >
                                            <Card.Body
                                                className='destination__card__content'
                                            >
                                                <div className='left'>
                                                    <LazyLoadImage
                                                        alt={item?.favItem?.eventName}
                                                        effect="blur"
                                                        wrapperProps={{
                                                            style: { transitionDelay: "0.1s" },
                                                        }}
                                                        src={item?.favItem?.thumbnailUrl || DEFAULT_THUMBNAIL}
                                                    />
                                                </div>

                                                <div className='right'>
                                                    <div
                                                        className='title'
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <h1>
                                                            {item?.favItem?.eventName}
                                                        </h1>

                                                        <Button
                                                            className='button'
                                                            type='link'
                                                            loading={loadingFavourite}
                                                            onClick={() => handleOnClikFavourite(item?.id)}
                                                        >
                                                            <HeartTwoTone style={{ fontSize: '30px' }} twoToneColor="#eb2f96" />
                                                        </Button>
                                                    </div>

                                                    <ReactQuill
                                                        value={item && resizeString(item?.favItem?.eventDescriptionShort, 200)}
                                                        readOnly={true}
                                                        theme='bubble'
                                                    />
                                                    <div className='tags'>
                                                        {
                                                            item?.favItem?.tags && item?.favItem?.tags?.split(',')?.map((tag, index) => (
                                                                <Tag key={index}>{tag && capitalizeFisrtLetterEachWord(tag)}</Tag>
                                                            ))
                                                        }
                                                    </div>


                                                    <div className='card_footer'>
                                                        <div className='price'>
                                                            <Button
                                                                type='primary'
                                                                onClick={() => navigate(`/tours/${item?.id}`)}
                                                            >
                                                                View Event
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ))
                            }
                        </div>
                    }

                    {/* 
                        Empty Favourites
                    */}

                    {
                        !loading && activeItem === '1' && favouritesTours.length === 0 &&
                        <div className='empty_favourites'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '1px solid #e3e3e3',
                                padding: '20px',
                                borderRadius: '10px'
                            }}
                        >
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={
                                    <span>
                                        No Favourite Tours
                                    </span>
                                }
                            />
                        </div>
                    }
                    {
                        !loading && activeItem === '2' && favouriteDestinations.length === 0 &&
                        <div className='empty_favourites'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '1px solid #e3e3e3',
                                padding: '20px',
                                borderRadius: '10px'
                            }}
                        >
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={
                                    <span>
                                        No Favourite Destinations
                                    </span>
                                }
                            />
                        </div>
                    }
                    {
                        !loading && activeItem === '3' && favouriteAttractions.length === 0 &&
                        <div className='empty_favourites'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '1px solid #e3e3e3',
                                padding: '20px',
                                borderRadius: '10px'
                            }}
                        >
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={
                                    <span>
                                        No Favourite Attractions
                                    </span>
                                }
                            />
                        </div>
                    }
                    {
                        !loading && activeItem === '4' && favouritActivities.length === 0 &&
                        <div className='empty_favourites'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '1px solid #e3e3e3',
                                padding: '20px',
                                borderRadius: '10px'
                            }}
                        >
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={
                                    <span>
                                        No Favourite Activities
                                    </span>
                                }
                            />
                        </div>
                    }
                    {
                        !loading && activeItem === '5' && favouriteEvents.length === 0 &&
                        <div className='empty_favourites'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '1px solid #e3e3e3',
                                padding: '20px',
                                borderRadius: '10px'
                            }}
                        >
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={
                                    <span>
                                        No Favourite Events
                                    </span>
                                }
                            />
                        </div>
                    }
                </div>
            </div>

            <Drawer
                title="Basic Drawer"
                className='home__nav__drawer'
                rootClassName='home__nav__drawer_body'
                placement='bottom'
                closable={false}
                open={isNavDrawerOpen}
                onClose={() => setIsNavDraweOpen(false)}
                height='70vh'
                headerStyle={{ display: 'none' }}
            >
                <div className='drawer__content_home_nav'>
                    {items.map((item) => (
                        <div
                            key={item.key}
                            className={`drawer__content__items ${activeItem === item.key ? 'active' : ''}`}
                            onClick={() => {
                                navigate(`/dashboard/my-bookings${item.link}`);
                                setActiveItem(item.key);
                                setIsNavDraweOpen(false);
                            }}
                        >
                            {item.icon}
                            {item.label}
                        </div>
                    ))}
                    <div className='drawer__content__items close' onClick={() => setIsNavDraweOpen(false)}>
                        <CloseCircleOutlined />
                        Close
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default WishList