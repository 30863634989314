import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Divider, Empty, Form, Input } from 'antd';
import React, { useEffect, useRef } from 'react';

import './styles.scss';

const OtherServices = ({
    next,
    prev,
    setUserTourData,
    userTourData,
    notificationApi,
    current,
}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const formRef = useRef(null);

    useEffect(() => {
        if (userTourData && formRef.current) {
            formRef.current.setFieldsValue({
                guideLanguage: userTourData.guideLanguage,
                preferencesSpecific: userTourData.preferencesSpecifics
            });
        }
    }, [userTourData]);

    const handleSubmit = (values) => {
        setUserTourData({
            ...userTourData,
            guideLanguage: values.guideLanguage,
            preferencesSpecifics: values.preferencesSpecific
        });
        next();
    };

    return (
        <div className='custom_tour_start_planing'>
            <h1 className='header_1 section_header'>
                Other Services & Preferences
            </h1>
            <p className=' main_para'>
                Add any other services or preferences you would like to include in your tour. If you have any special requests, please let us know here. We will do our best to accommodate your requests.
            </p>

            <Divider />

            <Form
                onFinish={handleSubmit}
                scrollToFirstError
                onFinishFailed={() => {
                    notificationApi.error({
                        message: 'Missing Fields',
                        description: 'Please fill all the required fields',
                    });
                }}
                ref={formRef}
            >
                <section className='custom_tour_start_planing__form date_selector'>
                    <h1 className='heder_2'>
                        Tour Guide & Language
                    </h1>
                    <p className=' main_para'>
                        Our tours are conducted by experienced local guides who are fluent in English. If you would like a guide who speaks a different language, please let us know here.
                    </p>
                    <Form.Item
                        name='guideLanguage'
                        label='Tour Guide Language'
                        className='padding_wrapper'
                        rules={[
                            {
                                required: true,
                                message: 'Please add your preferred tour guide language.',
                            },
                        ]}
                    >
                        <Input
                            placeholder='Enter Language'
                            maxLength={50}
                        />
                    </Form.Item>
                </section>

                <section className='custom_tour_start_planing__form room_selector'>
                    <h1 className='heder_2'>
                        Travel Insurance
                    </h1>
                    <p className=' main_para'>
                        We recommend that you purchase travel insurance before you travel. If you would like to purchase travel insurance through us, please let us know here.
                    </p>
                    <div className='wrapper'>
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description='Currently not available. Please contact us for more information.'
                        />
                    </div>
                </section>

                <section className='custom_tour_start_planing__form room_selector'>
                    <h1 className='heder_2'>
                        Free Services
                    </h1>
                    <p className=' main_para'>
                        We offer a range of free services to make your trip more enjoyable. Please let us know if you would like to take advantage of any of these services.
                    </p>
                    <div className='wrapper'>
                        <ul>
                            <li>Local SIM Card</li>
                            <li>
                                On the go free Wi-Fi
                            </li>
                            <li>
                                Airport Pickup
                            </li>
                            <li>
                                Airport Drop-off
                            </li>
                        </ul>
                    </div>
                </section>

                <section className='custom_tour_start_planing__form date_selector'>
                    <h1 className='heder_2'>
                        Any other specific requirements?
                    </h1>
                    <p className=' main_para'>
                        If you have any other specific requirements or requests, please let us know here.
                    </p>
                    <Form.Item
                        name='preferencesSpecifics'
                    >
                        <Input.TextArea
                            placeholder='Add any specific requirements'
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            maxLength={400}
                            showCount
                        />
                    </Form.Item>
                </section>

                <section className='custom_tour_start_planing__form'>
                    <Form.Item>
                        <div className='button_wrapper'>

                            <Button
                                onClick={prev}
                                className='primary_btn_default'
                                disabled={current === 0}
                            >
                                <ArrowLeftOutlined /> Previous
                            </Button>

                            <Button
                                type='primary'
                                className='custom_primary_btn_2'
                                htmlType='submit'
                            >
                                Next <ArrowRightOutlined />
                            </Button>
                        </div>
                    </Form.Item>
                </section>
            </Form>
        </div>
    );
};

export default OtherServices;