// Destinations
export const FETCH_DESTINATIONS = '@destinations/data/fetch'
export const FETCH_DESTINATIONS_SUCCESS = '@destinations/data/fetch/success'
export const FETCH_DESTINATIONS_FAIL = '@destinations/data/fetch/failed'

// Tour Packages
export const FETCH_TOUR_PACKAGES = '@tour-packages/data/fetch';
export const FETCH_TOUR_PACKAGES_SUCCESS = '@tour-packages/data/success';
export const FETCH_TOUR_PACKAGES_FAIL = '@tour-packages/data/failed';

// Currency
export const SET_SELECTED_CURRENCY = '@currency/set';
export const SET_SELECTED_CURRENCY_SUCCESS = '@currency/set/success';
export const SET_SELECTED_CURRENCY_FAIL = '@currency/set/failed';

// Currency Rates
export const FETCH_CURRENCY_RATES = '@currency/rates/fetch';
export const FETCH_CURRENCY_RATES_SUCCESS = '@currency/rates/success';
export const FETCH_CURRENCY_RATES_FAIL = '@currency/rates/failed';

// Language
export const SET_SELECTED_LANGUAGE = '@language/set';
export const SET_SELECTED_LANGUAGE_SUCCESS = '@language/set/success';
export const SET_SELECTED_LANGUAGE_FAIL = '@language/set/failed';

// User Profile
export const FETCH_PROFILE = '@user/data/fetch';
export const FETCH_PROFILE_SUCCESS = '@user/data/success';
export const FETCH_PROFILE_FAIL = '@user/data/failed';

// Notifications
export const FETCH_NEW_NOTIFICATIONS_COUNT = '@notifications/new/fetch';
export const FETCH_NEW_NOTIFICATIONS_COUNT_SUCCESS = '@notifications/new/success';
export const FETCH_NEW_NOTIFICATIONS_COUNT_FAIL = '@notifications/new/failed';