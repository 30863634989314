import { CustomerServiceOutlined, PlusOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Empty, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './styles.scss'
import { getSupportRequestsByUser } from '../../../../redux/api/support.api'
import useMyProfile from '../../../hooks/useMyProfile'
import LoadingComp from '../../../components/LoadingComp'
import moment from 'moment'
import { capitalizeFisrtLetterEachWord } from '../../../helpers/nameRender'

const SupportTickets = () => {
    const navigate = useNavigate()
    const [allTickets, setAllTickets] = useState([])
    const [loading, setLoading] = useState(true)
    const myProfile = useMyProfile().userProfile;

    useEffect(() => {
        handleGetTickets()
    }, [myProfile])

    const handleGetTickets = async () => {
        setLoading(true)
        const response = await getSupportRequestsByUser(myProfile?.id)
        console.log('response', response)
        if (response.status === 200) {
            setAllTickets(response.data)
        }
        setLoading(false)
    }

    return (
        <div className='support section_main support_ticket' style={{ marginBottom: '60px' }}>
            <div className='top_padding_container'>
                <Breadcrumb
                    className='breadcrumb_pages'
                    style={{ marginBottom: '10px' }}
                    items={[
                        {
                            title: <div className='breadcrumb__item' onClick={() => navigate('/support')}><CustomerServiceOutlined /> Help & Support</div>,
                        },
                        {
                            title: <div className='breadcrumb__item last'>
                                Support Tickets
                            </div>,
                        },
                    ]}
                />

                <div className='tickets_header'>
                    <h1 className='header_1'>
                        Support Tickets
                    </h1>
                    <div className='actions'>
                        <Button
                            className='add_btn'
                            type='primary'
                            onClick={() => navigate('/support/support-requests/new')}
                        >
                            <PlusOutlined />
                            Create Ticket
                        </Button>
                    </div>
                </div>

                <div className='support__container'>
                    {
                        loading &&
                        <div className='loading_container'>
                            <LoadingComp />
                        </div>
                    }
                    {
                        !loading && allTickets.length === 0 &&
                        <div className='no_tickets'>
                            <Empty
                                description='No tickets found'
                            />
                        </div>
                    }
                    {
                        !loading && allTickets.length > 0 &&
                        <div className='tickets_container'>
                            {
                                allTickets.map((ticket, index) => (
                                    <div className='ticket_card'
                                        key={index}
                                        onClick={() => navigate(`/support/support-requests/${ticket.id}`)}
                                    >
                                        <div className='ticket_card__header'>
                                            <h2>
                                                {ticket.subject && capitalizeFisrtLetterEachWord(ticket.subject)}
                                            </h2>
                                            <div className='p_container'>
                                                <p>
                                                    Created on: {moment(ticket.created_at).format('DD/MM/YYYY')}
                                                </p>
                                                <p>
                                                    Support Type: {ticket.supportType || 'CONTACT'}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='ticket_card__header__actions'>
                                            <Tag
                                                color={
                                                    ticket.status === 'PENDING' ? 'orange' :
                                                        ticket.status === 'CLOSED' ? 'red' :
                                                            ticket.status === 'IN_PROGRESS' ? 'blue' :
                                                                'green'
                                                }
                                            >
                                                {ticket.status ? capitalizeFisrtLetterEachWord(ticket.status) : 'PENDING'}
                                            </Tag>
                                            <Button
                                                type='primary'
                                                onClick={() => navigate(`/support/support-requests/${ticket.id}`)}
                                            >
                                                View
                                            </Button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default SupportTickets