import React, { useEffect, useRef, useState } from 'react'
import { reviewTypes } from '../../../../constants/reviewTypes';
import { Button, Col, Drawer, Form, Image, Input, notification, Rate, Row, Select, Spin, Upload } from 'antd';
import { getAllTourPackagsNameAndIds } from '../../../../../redux/api/tourPackages.api';
import { fetchDestinationNames } from '../../../../../redux/api/destinations.api';
import { fetchAttractionsNames } from '../../../../../redux/api/attractions.api';
import { fetchActivityNames } from '../../../../../redux/api/activities.api';
import { fetchEventsNames } from '../../../../../redux/api/events.api';
import { handleDeleteBlob, handleImageUpload, USER_POSTS } from '../../../../azure/blob';
import { editReview } from '../../../../../redux/api/reviews.api';
import useMyProfile from '../../../../hooks/useMyProfile';

const getOptionName = (value) => {
    value = value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
    return value.replace('Rev', '');
};

const reviewOptions = Object.keys(reviewTypes).map(key => {
    return {
        label: getOptionName(reviewTypes[key]),
        value: key
    };
})

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const EditReview = ({
    isOpen,
    setOpen,
    handleGetReviews,
    seletedReview,
}) => {
    const formRef = useRef();
    const [ratedForOptions, setRatedForOptions] = useState([]);
    const [loadingRatedForOptions, setLoadingRatedForOptions] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [loadingAddReview, setLoadingAddReview] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        handleGetRatedForOptions(seletedReview?.ratingType);
        if (isOpen && formRef.current) {
            formRef.current.setFieldsValue({
                ratingType: seletedReview?.ratingType,
                ratedFor: seletedReview?.ratedFor,
                ratingDescription: seletedReview?.ratingDescription,
                ratingValue: seletedReview?.ratingValue,
            });

            if (seletedReview?.images) {
                const images = seletedReview.images.split(',');
                setFileList(
                    images.map((url, index) => {
                        return {
                            uid: index,
                            name: `image-${index}`,
                            status: 'done',
                            url: url
                        }
                    })
                );
            } else {
                setFileList([]);
            }
        }
    }, [isOpen, formRef]);

    const handleGetRatedForOptions = async (value) => {
        setLoadingRatedForOptions(true);
        setLoadingText('Loading places...');
        if (value === reviewTypes.REV_TOUR_PACKAGE) {
            const res = await getAllTourPackagsNameAndIds();
            setRatedForOptions(
                res.data.map(item => {
                    return {
                        label: item.packageName,
                        value: item.id
                    }
                })
            );
        } else if (value === reviewTypes.REV_DESTINATION) {
            const res = await fetchDestinationNames();
            setRatedForOptions(
                res.data.map(item => {
                    return {
                        label: item.destinationName,
                        value: item.id
                    }
                })
            );
        } else if (value === reviewTypes.REV_ATTRACTION) {
            const res = await fetchAttractionsNames();
            setRatedForOptions(
                res.data.map(item => {
                    return {
                        label: item.attractionName,
                        value: item.id
                    }
                })
            );
        } else if (value === reviewTypes.REV_ACTIVITY) {
            const res = await fetchActivityNames();
            setRatedForOptions(
                res.data.map(item => {
                    return {
                        label: item.activityName,
                        value: item.id
                    }
                })
            );
        } else if (value === reviewTypes.REV_EVENT) {
            const res = await fetchEventsNames();
            setRatedForOptions(
                res.data.map(item => {
                    return {
                        label: item.eventName,
                        value: item.id
                    }
                })
            );
        }

        setLoadingRatedForOptions(false)
        setLoadingText('');
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const handleOnFinsih = async (values) => {
        setLoadingAddReview(true);

        let imageUrls = [];

        const newImages = fileList.filter(file => !file.url);
        const deletedImages = seletedReview.images?.split(',').filter(image => !fileList.find(file => file.url === image));

        if (newImages.length > 0) {
            setLoadingText('Uploading Images...');
            const name = `${values.ratingType}_${values.ratedFor}`;
            const imageUploadPromises = newImages.map(async (file, index) => {
                setLoadingText(`Uploading image ${index + 1}...`);
                const blobRes = await handleImageUpload(
                    USER_POSTS,
                    file.originFileObj,
                    file.size,
                    `${name}_${file.name}`
                );
                imageUrls.push(blobRes.blockBlobClient.url);
                setLoadingText(`Uploaded image ${index + 1}...`);
            });
            await Promise.all(imageUploadPromises);
            delete values.images;
        }

        if (deletedImages?.length > 0) {
            setLoadingText('Deleting Images');
            const imageDeletePromises = deletedImages.map(async (image, index) => {
                const blobName = getBlobName(image);
                setLoadingText(`Deleting image ${index + 1}`);
                return handleDeleteBlob(USER_POSTS, blobName);
            });
            await Promise.all(imageDeletePromises);
        }

        let updatedImages = [
            ...fileList.filter((file) => file.url).map((file) => file.url),
            ...imageUrls
        ];

        updatedImages.forEach((url, index) => {
            updatedImages[index] = sanitizeBlobUrls(url);
        });

        const data = {
            id: seletedReview.id,
            ratingType: values.ratingType,
            ratedFor: values.ratedFor,
            ratingDescription: values.ratingDescription,
            ratingValue: values.ratingValue,
            images: updatedImages.length > 0 ? updatedImages.join(',') : null
        }

        setLoadingText('Updating Review...');
        const response = await editReview(data);
        if (response.status === 200) {
            api.success({
                message: 'Review Updated Successfully',
                description: 'You have successfully updated the review'
            });

            handleGetReviews();
            setOpen(false);
        } else {
            api.error({
                message: 'Error Upadting Review',
                description: 'There was an error updating the review. Please try again'
            });
        }


        setLoadingAddReview(false);
        setLoadingText('');
    }

    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }

    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl.split('/')
        return splitUrl[splitUrl.length - 1]
    }

    return (
        <Drawer
            title="Edit Review"
            placement="right"
            closable={true}
            onClose={() => setOpen(false)}
            open={isOpen}
            width={720}
        >
            {contextHolder}
            <Spin spinning={loadingRatedForOptions || loadingAddReview} tip={loadingText}>
                <Form
                    layout='vertical'
                    ref={formRef}
                    onFinish={handleOnFinsih}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Review Type"
                                name="ratingType"
                                rules={[{ required: true, message: 'Please input your review type!' }]}
                            >
                                <Select
                                    placeholder="Select a review type"
                                    allowClear
                                    onChange={(value) => handleGetRatedForOptions(value)}
                                    loading={loadingRatedForOptions}
                                    disabled={loadingRatedForOptions}
                                >
                                    {reviewOptions.map(option => (
                                        <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Review Place"
                                name="ratedFor"
                                rules={[{ required: true, message: 'Please input your title!' }]}
                            >
                                <Select
                                    placeholder="Select a review type"
                                    allowClear
                                    showSearch
                                    loading={loadingRatedForOptions}
                                    disabled={loadingRatedForOptions}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {ratedForOptions.map(option => (
                                        <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Your Review"
                                name="ratingDescription"
                                rules={[{ required: true, message: 'Please add your review!' }]}
                            >
                                <Input.TextArea
                                    placeholder="Your review"
                                    autoSize={{ minRows: 5, maxRows: 8 }}
                                    maxLength={1000}
                                    showCount
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Your Rate (1-5)"
                                name="ratingValue"
                                rules={[{ required: true, message: 'Please add your rate!' }]}
                            >
                                <Rate
                                    count={5}
                                    defaultValue={0}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Your Images (Max 10)"
                                name="images"
                            >
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    multiple={true}
                                    maxCount={10}
                                    beforeUpload={(file) => {
                                        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/gif' || file.type === 'image/webp';
                                        if (!isJpgOrPng) {
                                            api.error({
                                                message: 'Unsupported image format!',
                                                description: 'Please upload a valid image format (jpeg, jpg, png, gif, webp)',
                                            });
                                        }
                                        const isSizeValid = file.size / 1024 / 1024 < 2;
                                        if (!isSizeValid) {
                                            api.error({
                                                message: 'Image must be smaller than 2MB!',
                                            });
                                        }

                                        const isVaid = isJpgOrPng && isSizeValid;

                                        return isVaid ? false : Upload.LIST_IGNORE;
                                    }}
                                >
                                    {fileList.length <= 10 && '+ Upload'}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loadingAddReview}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </Drawer>
    )
}

export default EditReview