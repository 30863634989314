import React, { useEffect, useState } from 'react'
import { getNotificationsByUser, messageMarkAsRead, messageMarkAsReadAll } from '../../../../redux/api/messages.api';
import useMyProfile from '../../../hooks/useMyProfile';
import LoadingComp from '../../../components/LoadingComp';

import './styles.scss'
import { Badge, Button, Empty } from 'antd';
import ReactQuill from 'react-quill';
import { CheckCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { fetchNewNotificationCountAC } from '../../../../redux/actions/notifications/notifications.ac';

const Inbox = () => {
    const myProfile = useMyProfile();
    const [messages, setMessages] = useState([]);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [loadingMarkAsRead, setLoadingMarkAsRead] = useState();
    const [loadingMarkAsReadAll, setLoadingMarkAsReadAll] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (myProfile?.userProfile?.id) {
            fetchMessages();
        }
    }, [myProfile]);

    const fetchMessages = async () => {
        setLoadingMessages(true);
        try {
            const response = await getNotificationsByUser(myProfile?.userProfile?.id);
            setMessages(response.data);
        } catch (error) {
            console.log('ERROR FETCHING MESSAGES', error);
        }
        setLoadingMessages(false);
    }

    const markAsRead = async (messageId) => {
        setLoadingMarkAsRead(messageId);
        try {
            const response = await messageMarkAsRead(messageId);
            if (response.status === 200) {
                fetchMessages();
                dispatch(fetchNewNotificationCountAC(myProfile?.userProfile?.id));
            }
        } catch (error) {
            console.log('ERROR MARK AS READ', error);
        }
        setLoadingMarkAsRead(null);
    }

    const markAllAsRead = async () => {
        setLoadingMarkAsReadAll(true);
        try {
            const response = await messageMarkAsReadAll();
            if (response.status === 200) {
                fetchMessages();
                dispatch(fetchNewNotificationCountAC(myProfile?.userProfile?.id));
            }
        } catch (error) {
            console.log('ERROR MARK AS READ', error);
        }
        setLoadingMarkAsReadAll(false);
    }

    return (
        <div className='main__contaier messages_inbox'>
            <div
                className='header'
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <h2 className='header_1' style={{ marginBottom: '20px' }}>
                    Inbox
                </h2>

                <Button
                    type='primary'
                    onClick={markAllAsRead}
                    loading={loadingMarkAsReadAll}
                >
                    <CheckOutlined />
                    Mark all as read
                </Button>
            </div>
            <div className='dashboard__Home'>
                <div className='messages'>
                    {
                        loadingMessages && <div className='loading_container'>
                            <LoadingComp />
                        </div>
                    }
                    {
                        !loadingMessages && messages.length === 0 && <div className='no_messages'>
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={
                                    <span>
                                        No messages
                                    </span>
                                }
                            />
                        </div>
                    }
                    {
                        !loadingMessages && messages.length > 0 && messages.map((message, index) => {
                            return (
                                <Badge.Ribbon
                                    text='New'
                                    color='red'
                                    key={index}
                                    style={{
                                        display: message.notificationStatus === 'read' ? 'none' : 'block'
                                    }}
                                >
                                    <div
                                        className={`message ${message.notificationStatus}`}
                                        key={index}
                                    >
                                        <div className='message__header'>
                                            <h3 >
                                                {message.notificationTitle}
                                            </h3>
                                            <p className='time'>
                                                {message.createdAt}
                                            </p>
                                        </div>
                                        <div className='message__content'>
                                            <ReactQuill
                                                value={message.notificationBody}
                                                readOnly={true}
                                                theme='bubble'
                                            />
                                        </div>
                                        {
                                            message.notificationStatus === 'unread' &&
                                            <div className='message__footer'>
                                                <Button
                                                    type='primary'
                                                    onClick={() => {
                                                        markAsRead(message.id);
                                                    }}
                                                    loading={
                                                        loadingMarkAsRead === message.id
                                                    }
                                                >
                                                    <CheckCircleOutlined />
                                                    Mark as read
                                                </Button>
                                            </div>}
                                    </div>
                                </Badge.Ribbon>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Inbox