import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Radio, Rate, Select } from 'antd';
import React, { useEffect, useRef } from 'react';

import './styles.scss';
import { accommodationTypes } from '../../../../constants/itemTypes';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const accommodationOptions = Object.keys(accommodationTypes).map(key => {
    return {
        label: getOptionName(accommodationTypes[key]),
        value: key
    };
});

const TransportAndAccommodation = ({
    next,
    prev,
    setUserTourData,
    userTourData,
    notificationApi,
    current,
}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const formRef = useRef(null);

    useEffect(() => {
        if (userTourData && formRef.current) {
            formRef.current.setFieldsValue({
                accommodationStayType: userTourData.accommodationStayType,
                accommodationStarRating: userTourData.accommodationStarRating,
                vehicleType: userTourData.vehicleType,
                transportSpecifics: userTourData.transportSpecifics,
            });
        }
    }, [userTourData]);

    const handleSubmit = (values) => {
        setUserTourData({
            ...userTourData,
            accommodationStayType: values.accommodationStayType,
            accommodationStarRating: values.accommodationStarRating,
            vehicleType: values.vehicleType,
            transportSpecifics: values.transportSpecifics,
        });
        next();
    };

    return (
        <div className='custom_tour_start_planing'>
            <h1 className='header_1 section_header'>
                Select Your Preferred Transport & Accommodation
            </h1>
            <p className=' main_para'>
                Where would you like to stay during your trip? Choose accommodations that match your comfort and style. We identified main cities or stay points from your destinations and activities.
                Please select hotels that you like to stay in each location.

                <br /><br />

                And how would you like to travel between these locations? Choose the mode of transport that suits your style and budget. We will provide you with a comfortable vehicle and a professional driver for your journey.
            </p>

            <Divider />

            <Form
                onFinish={handleSubmit}
                scrollToFirstError
                onFinishFailed={() => {
                    notificationApi.error({
                        message: 'Missing Fields',
                        description: 'Please fill all the required fields',
                    });
                }}
                ref={formRef}
            >

                <section className='custom_tour_start_planing__form date_selector'>
                    <h1 className='heder_2'>
                        Select Accommodation Type
                    </h1>
                    <p className=' main_para'>
                        What type of accommodation would you like to stay in?
                    </p>
                    <Form.Item
                        name='accommodationStayType'
                        className='padding_wrapper'
                        rules={[
                            {
                                required: true,
                                message: 'Please select your preferred accommodation type.',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder="Add Accommodation Type"
                            options={accommodationOptions}
                            maxCount={1}
                        />
                    </Form.Item>
                </section>

                <section className='custom_tour_start_planing__form date_selector'>
                    <h1 className='heder_2'>
                        Accommodation Star Rating
                    </h1>
                    <p className=' main_para'>
                        What star rating would you like for your accommodation?
                    </p>
                    <Form.Item
                        name='accommodationStarRating'
                        className='padding_wrapper'
                        rules={[
                            {
                                required: true,
                                message: 'Please select your preferred accommodation type.',
                            },
                        ]}
                    >
                        <Rate />
                    </Form.Item>
                </section>



                <section className='custom_tour_start_planing__form room_selector'>
                    <h1 className='heder_2'>
                        Transport vehicle
                    </h1>
                    <p className=' main_para'>
                        What type of vehicle would you like to travel in?
                        Make sure to select a vehicle that can accommodate all the travelers in your group.
                    </p>
                    <div className='wrapper'>
                        <Form.Item
                            name='vehicleType'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select your preferred vehicle type.',
                                },
                            ]}
                        >
                            <Radio.Group className='radio_group_vehicle'>
                                <Radio
                                    className={`room_type`}
                                    value='Hatchback'
                                >
                                    <div className='flex'>
                                        <div className='image'>
                                            <LazyLoadImage
                                                src='https://visitpearlsharedblob.blob.core.windows.net/appassets/vehicleTypeHatchback.webp'
                                                alt='vehicle'
                                                effect='blur'
                                            />
                                        </div>
                                        <div className='texts'>
                                            <h5>
                                                Hatchback (2 persons)
                                            </h5>
                                            <p>
                                                Hatchback is a small car with 3 passenger seats and 1 driver seat. It is suitable for a small group of 2 with 2 luggage. <br />
                                                <strong>
                                                    Toyota Aqua, Suzuki Swift, Honda Fit, etc.
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </Radio>,

                                <Radio
                                    className={`room_type`}
                                    value='Sedan'
                                >
                                    <div className='flex'>
                                        <div className='image'>
                                            <LazyLoadImage
                                                src='https://visitpearlsharedblob.blob.core.windows.net/appassets/vehicleTypeSedan.webp'
                                                alt='vehicle'
                                                effect='blur'
                                            />
                                        </div>
                                        <div className='texts'>
                                            <h5>
                                                Sedan (2 persons)
                                            </h5>
                                            <p>
                                                Sedan is a large car with 3 passenger seats and 1 driver seat. It is suitable for a small group of 2 with 2 luggage.
                                                <br />
                                                <strong>
                                                    Toyota Axio, Toyota Allion, Toyota Premio, etc.
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </Radio>,

                                <Radio
                                    className={`room_type`}
                                    value='Suv'
                                >
                                    <div className='flex'>
                                        <div className='image'>
                                            <LazyLoadImage
                                                src='https://visitpearlsharedblob.blob.core.windows.net/appassets/vehicleTypeSuv.webp'
                                                alt='vehicle'
                                                effect='blur'
                                            />
                                        </div>
                                        <div className='texts'>
                                            <h5>
                                                SUV (2 persons)
                                            </h5>
                                            <p>
                                                SUV is a large sizer four wheel drive vehicle with 3 passenger seats and 1 driver seat. It is suitable for a small group of 2 with 3 luggage and a child.
                                                <br />
                                                <strong>
                                                    Toyota Rav4, Nissan X-Trail, Honda Vezel, etc.
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </Radio>,

                                <Radio
                                    className={`room_type`}
                                    value='Van'
                                >
                                    <div className='flex'>
                                        <div className='image'>
                                            <LazyLoadImage
                                                src='https://visitpearlsharedblob.blob.core.windows.net/appassets/vehicleTypeVan.webp'
                                                alt='vehicle'
                                                effect='blur'
                                            />
                                        </div>
                                        <div className='texts'>
                                            <h5>
                                                Van (6 persons)
                                            </h5>
                                            <p>
                                                Van is a large vehicle with 6 passenger seats and 1 driver seat. It is suitable for a group of 6 with 6 luggage.
                                                <br />
                                                <strong>
                                                    Toyota KDH, Nissan Caravan, Toyota Hiace, etc.
                                                </strong>
                                            </p>
                                        </div>
                                    </div>

                                </Radio>,

                                <Radio
                                    className={`room_type`}
                                    value='Coster'
                                >
                                    <div className='flex'>
                                        <div className='image'>
                                            <LazyLoadImage
                                                src='https://visitpearlsharedblob.blob.core.windows.net/appassets/vehicleTypeCoster.webp'
                                                alt='vehicle'
                                                effect='blur'
                                            />
                                        </div>
                                        <div className='texts'>
                                            <h5>
                                                Coaster (12 persons)
                                            </h5>
                                            <p>
                                                Coaster is a large vehicle with 12 passenger seats and 1 driver seat. It is suitable for a group of 12 with 12 luggage.
                                                <br />
                                                <strong>
                                                    Toyota Coaster, Nissan Civilian, etc.
                                                </strong>
                                            </p>
                                        </div>
                                    </div>

                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </section>

                <section className='custom_tour_start_planing__form date_selector'>
                    <h1 className='heder_2'>
                        Transport Specifics
                    </h1>
                    <p className=' main_para'>
                        If there are any specific transport or accommodation requirements you have, please let us know.
                    </p>
                    <Form.Item
                        name='transportSpecifics'
                    >
                        <Input.TextArea
                            placeholder='Add any specific requirements'
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            maxLength={400}
                            showCount
                        />
                    </Form.Item>
                </section>

                <section className='custom_tour_start_planing__form'>
                    <Form.Item>
                        <div className='button_wrapper'>

                            <Button
                                onClick={prev}
                                className='primary_btn_default'
                                disabled={current === 0}
                            >
                                <ArrowLeftOutlined /> Previous
                            </Button>

                            <Button
                                type='primary'
                                className='custom_primary_btn_2'
                                htmlType='submit'
                            >
                                Next <ArrowRightOutlined />
                            </Button>
                        </div>
                    </Form.Item>
                </section>
            </Form>
        </div>
    );
};

export default TransportAndAccommodation;