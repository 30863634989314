import { CustomerServiceOutlined, SendOutlined, SyncOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Col, Divider, Empty, Form, notification, Row, Tag, Upload } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { addSupportRequestMessage, getSupportRequestById, getSupportRequestMessages } from '../../../../../redux/api/support.api'
import LoadingComp from '../../../../components/LoadingComp'

import './styles.scss'
import ReactQuill from 'react-quill'
import { capitalizeFisrtLetterEachWord } from '../../../../helpers/nameRender'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { DEFAULT_PROFILE_IMAGE } from '../../../../constants/otherConstsnts'
import useMyProfile from '../../../../hooks/useMyProfile'
import { handleImageUpload, SUPPORT_CONTAINER } from '../../../../azure/blob'
import { supportRequestStatus } from '../../../../constants/itemTypes'

const quillModules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"],
    ],
};


const Ticket = () => {
    const navigate = useNavigate()
    const url = window.location.pathname
    const ticketId = url.split('/').pop()
    const [supportTicket, setSupportTicket] = useState(null)
    const [loading, setLoading] = useState(false)
    const [messages, setMessages] = useState([])
    const [loadingMessages, setLoadingMessages] = useState(false)
    const [fileList, setFileList] = useState([])
    const [loadingSendMsg, setLoadingSendMsg] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const myProfile = useMyProfile();
    const formRef = useRef()

    useEffect(() => {
        handleGetSupportTicket()
    }, [])

    useEffect(() => {
        if (supportTicket) {
            handleGetMessages()
            const interval = setInterval(() => {
                handleGetMessages()
            }
                , 60000)
            return () => clearInterval(interval)
        }
    }, [supportTicket])

    const handleGetSupportTicket = async () => {
        setLoading(true)
        const response = await getSupportRequestById(ticketId)
        if (response.status === 200) {
            setSupportTicket(response.data[0])
        }
        setLoading(false)
    }

    const handleGetMessages = async () => {
        setLoadingMessages(true)
        const response = await getSupportRequestMessages(ticketId)
        if (response.status === 200) {
            setMessages(response.data)
        }
        setLoadingMessages(false)
    }

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const handleSubmitReply = async (values) => {
        setLoadingSendMsg(true)
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

        try {
            let imageUrls = [];
            const name = 'support_' + myProfile?.userProfile?.id + '_' + new Date().getTime();
            values.userOrAdmin = 'user';
            values.supportRequestId = ticketId;

            if (fileList.length > 0) {
                const totalSize = fileList.reduce((acc, file) => acc + file.size, 0)
                console.log('totalSize', totalSize)
                if (totalSize > 10485760) {
                    api.error({
                        message: 'Total size of files should not exceed 10MB'
                    })
                    return
                }

                const imageUploadPromises = fileList.map((file, index) => {
                    return handleImageUpload(
                        SUPPORT_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${name}_${file.name}`
                    ).then(blobRes => {
                        imageUrls.push(blobRes.blockBlobClient.url);
                    });
                });
                await Promise.all(imageUploadPromises);
            }

            delete values.attachments;

            if (imageUrls.length > 0) {
                imageUrls = imageUrls.map(url => sanitizeBlobUrls(url));
                values.attachments = imageUrls.join(',');
            }

            const response = await addSupportRequestMessage(values);
            if (response.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'Message sent successfully'
                });

                formRef.current.resetFields();
                setFileList([]);
                handleGetMessages();

            } else {
                console.log(response);
                api.error({
                    message: 'Error',
                    description: 'An error occurred while sending the message. Please try again later.'
                });
            }
        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: 'An error occurred while sending the message. Please try again later.'
            });
        }
        setLoadingSendMsg(false)
    }

    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }

    return (
        <div className='support section_main support_ticket_single' style={{ marginBottom: '60px' }}>
            {contextHolder}
            <div className='top_padding_container'>
                <Breadcrumb
                    className='breadcrumb_pages'
                    style={{ marginBottom: '10px' }}
                    items={[
                        {
                            title: <div className='breadcrumb__item' onClick={() => navigate('/support')}><CustomerServiceOutlined /> Help & Support</div>,
                        },
                        {
                            title: <div className='breadcrumb__item ' onClick={() => navigate('/support/support-requests')}>
                                Support Tickets
                            </div>,
                        },
                        {
                            title: <div className='breadcrumb__item last'>
                                Ticket #{ticketId}
                            </div>,
                        },
                    ]}
                />
                {
                    loading ?
                        <div className='loading_container'>
                            <LoadingComp />
                        </div>
                        :
                        <>
                            <div className='ticket_header'>
                                <h1 className='heder_2'>
                                    {supportTicket?.subject ? capitalizeFisrtLetterEachWord(supportTicket?.subject) : 'Ticket'}
                                </h1>
                                <Tag
                                    color={
                                        supportTicket?.status === 'PENDING' ? 'orange' :
                                            supportTicket?.status === 'CLOSED' ? 'red' :
                                                supportTicket?.status === 'IN_PROGRESS' ? 'blue' :
                                                    'green'
                                    }
                                >
                                    {supportTicket?.status ? capitalizeFisrtLetterEachWord(supportTicket?.status) : 'Pending'}
                                </Tag>
                            </div>
                            <div className='meta'>
                                <p>
                                    Crated on: {supportTicket?.createdAt}
                                </p>
                                <p>
                                    Support Type: {supportTicket?.supportType && capitalizeFisrtLetterEachWord(supportTicket?.supportType)}
                                </p>
                                <p>
                                    Impact:  <Tag
                                        color={supportTicket?.impactLevel === 'low' ? 'green' : supportTicket?.impactLevel === 'medium' ? 'orange' : 'red'}
                                    >
                                        {supportTicket?.impactLevel ? capitalizeFisrtLetterEachWord(supportTicket?.impactLevel) : 'Low'}
                                    </Tag>
                                </p>
                            </div>
                            <div className='meta'>
                                {supportTicket?.preferedContactMethod &&
                                    <p>
                                        Prefered Contact Method
                                        : {supportTicket?.preferedContactMethod && capitalizeFisrtLetterEachWord(supportTicket?.preferedContactMethod)}
                                    </p>}
                                {supportTicket?.preferedTimeToContact && <p>
                                    Prefered Time to Contact
                                    : {supportTicket?.preferedTimeToContact && capitalizeFisrtLetterEachWord(supportTicket?.preferedTimeToContact)}
                                </p>}
                                {supportTicket?.contactNo && <p>
                                    Contact No
                                    : {supportTicket?.contactNo && capitalizeFisrtLetterEachWord(supportTicket?.contactNo)}
                                </p>}
                            </div>
                            <ReactQuill
                                value={supportTicket?.message}
                                readOnly
                                theme='bubble'
                            />
                            <div className='attachments'>
                                <p>Attachments: </p>
                                {
                                    supportTicket?.attachments?.split(',')?.map((attachment, index) => (
                                        <div key={index} className='attachment'>
                                            <a href={attachment} target='_blank' rel='noreferrer'>
                                                attachment {index + 1}
                                            </a>
                                        </div>
                                    ))
                                }
                            </div>

                            <Divider />

                            <div className='messages'>
                                <h2>Messages</h2>

                                {
                                    loadingMessages &&
                                    <div className='loading'>
                                        <SyncOutlined spin /> Loading messages...
                                    </div>
                                }
                                {
                                    !loadingMessages && messages.length === 0 &&
                                    <div className='no_messages'>
                                        <Empty description='No messages found' />
                                    </div>
                                }
                                {
                                    messages.length > 0 &&
                                    <div className='messages_container'>
                                        {
                                            messages.map((message, index) => (
                                                <div className='message_card'
                                                    key={index}
                                                >
                                                    <div className='message_card__header'>
                                                        <div className='sender'>
                                                            <LazyLoadImage
                                                                src={message?.user?.profilePicUrl || DEFAULT_PROFILE_IMAGE}
                                                                alt='avatar'
                                                                effect='blur'
                                                            />
                                                        </div>
                                                        <div className='sender_details'>
                                                            <p className='author'>
                                                                {message?.userOrAdmin === 'admin' ?
                                                                    message?.user?.userName
                                                                    :
                                                                    'You'
                                                                }
                                                            </p>
                                                            <p>
                                                                {message.createdAt}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <ReactQuill
                                                        value={message.message}
                                                        readOnly
                                                        theme='bubble'
                                                    />
                                                    {message?.attachments &&
                                                        <div className='attachments'>
                                                            <p>Attachments: </p>
                                                            {
                                                                message.attachments?.split(',')?.map((attachment, index) => (
                                                                    <div key={index} className='attachment'>
                                                                        <a href={attachment} target='_blank' rel='noreferrer'>
                                                                            attachment {index + 1}
                                                                        </a>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                                {(supportTicket?.status !== supportRequestStatus.RESOLVED && supportTicket?.status !== supportRequestStatus.CLOSED) &&
                                    <div className='your_reply'>
                                        <h2>Reply</h2>
                                        <Form
                                            layout='vertical'
                                            onFinish={handleSubmitReply}
                                            ref={formRef}
                                        >
                                            <Row gutter={16}>
                                                <Col span={24}>
                                                    <Form.Item
                                                        label='Message'
                                                        name='message'
                                                        rules={[{ required: true, message: 'Please enter message' }]}
                                                    >
                                                        <ReactQuill
                                                            modules={quillModules}
                                                            placeholder='Enter your message here...'
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Form.Item
                                                label='Upload Attachments (Max 3)'
                                                name='attachments'
                                            >
                                                <Upload
                                                    listType="picture-card"
                                                    fileList={fileList}
                                                    onChange={handleChange}
                                                    multiple={true}
                                                    maxCount={10}
                                                    beforeUpload={(file) => {
                                                        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/gif' || file.type === 'image/webp' || file.type === 'application/pdf';
                                                        if (!isJpgOrPng) {
                                                            api.error({
                                                                message: 'Unsupported image format!',
                                                                description: 'Please upload a valid image format (jpeg, jpg, png, gif, webp, pdf)',
                                                            });
                                                        }
                                                        const isSizeValid = file.size / 1024 / 1024 < 2;
                                                        if (!isSizeValid) {
                                                            api.error({
                                                                message: 'Image must be smaller than 2MB!',
                                                            });
                                                        }

                                                        const isVaid = isJpgOrPng && isSizeValid;

                                                        return isVaid ? false : Upload.LIST_IGNORE;
                                                    }}
                                                >
                                                    {fileList.length <= 3 && '+ Upload'}
                                                </Upload>
                                            </Form.Item>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                }}
                                            >
                                                <Button
                                                    loading={loadingSendMsg}
                                                    type='primary'
                                                    htmlType='submit'
                                                >
                                                    Send Reply
                                                    <SendOutlined />
                                                </Button>
                                            </div>

                                        </Form>
                                    </div>}
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

export default Ticket