import axios from 'axios';
import { getAccessToken } from '../../App/azure/auth';
import { msalInstance } from '../../App/azure/authConfig';
import { API_BASE_URL } from "../../App/azure/api";

export const addToFavourites = async (values) => {
    const ipAddress = localStorage.getItem('vp-ip');
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/addToFavourites?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        return error;
    }
}


export const deleteFavourite = async (id) => {
    const ipAddress = localStorage.getItem('vp-ip');
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/deleteFavourite?id=${id}&clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        return error;
    }
}

export const getFavouriteById = async (favouriteId) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/checkIsFavourite?favouriteId=${favouriteId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        return error;
    }
}

export const getAllFavouritesByUser = async (userId) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/geAllFavouriteByUser?userId=${userId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}