import axios from 'axios';

import { API_BASE_URL, API_BASE_URL_OPEN } from "../../App/azure/api";
import { getAccessToken } from '../../App/azure/auth';
import { msalInstance } from '../../App/azure/authConfig';

export const addReview = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/addReview?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getReviewsByUser = async (id) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getReviewsByUser?id=${id}&clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const editReview = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/editReview?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const deleteReview = async (id) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/deleteReview?id=${id}&clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getReviewsByItem = async (id) => {
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL_OPEN}/getReviewsByItem?id=${id}&clientIp=${ipAddress}`,
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}