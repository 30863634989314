import { useEffect, useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import RoutesJS from './RoutesJS';

import AOS from 'aos';
import 'aos/dist/aos.css';

import '../theme/styles.scss';
import { useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { fetchProfileAC } from '../redux/actions/myProfile/myProfile.ac';
import { fetchCurrencyRatesAC } from '../redux/actions/currencyRates/currencyRates.ac';
import CookieConsentPopup from './components/CookieConsentPopup';

AOS.init({
  disable: false,
  startEvent: 'DOMContentLoaded',
  initClassName: 'aos-init',
  animatedClassName: 'aos-animate',
  useClassNames: false,
  disableMutationObserver: false,
  debounceDelay: 50,
  throttleDelay: 99,

  offset: 120,
  delay: 0,
  duration: 600,
  easing: 'ease',
  once: true,
  mirror: false,
  anchorPlacement: 'top-bottom',
});

function App() {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [logedInUser, setLogedInUser] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getIpAddress();
    dispatch(fetchCurrencyRatesAC());
  }, []);

  useEffect(() => {
    if (activeAccount) {
      setLogedInUser(true);
    }
  }, [activeAccount]);

  useEffect(() => {
    if (logedInUser) {
      fetchProfile();
    }
  }, [logedInUser]);

  const fetchProfile = async () => {
    try {
      dispatch(fetchProfileAC());
    } catch (error) {
      console.log('ERROR FETCHING PROFILE', error);
    }
  }

  const getIpAddress = async () => {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    localStorage.setItem('vp-ip', data.ip);
  }

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };

  return (
    <>
      <div id="google_translate_element" className='g_translate_container'></div>
      <Header />
      <div style={{
        minHeight: '100vh',
      }}>
        <RoutesJS />
      </div>
      <CookieConsentPopup />
      <Footer />
    </>
  );
}

export default App;
