export const accommodationTypes = {
    HOTEL: 'HOTEL',
    APARTMENT: 'APARTMENT',
    RESORT: 'RESORT',
    VILLA: 'VILLA',
    GUEST_HOUSE: 'GUEST_HOUSE',
    BUNGALOW: 'BUNGALOW',
    LODGE: 'LODGE',
    COTTAGE: 'COTTAGE',
    HOMESTAY: 'HOMESTAY',
    BOUTIQUE_HOTEL: 'BOUTIQUE_HOTEL',
    BEACH_HOTEL: 'BEACH_HOTEL',
}


export const experienceTypes = {
    ADVENTURE: 'ADVENTURE',
    CULTURE: 'CULTURE',
    LEISURE: 'LEISURE',
    NATURE: 'NATURE',
    RELAXATION: 'RELAXATION',
    SHOPPING: 'SHOPPING',
    SIGHTSEEING: 'SIGHTSEEING',
    SPORTS: 'SPORTS',
    WELLNESS: 'WELLNESS',
    WILDLIFE: 'WILDLIFE',
    FAMILY: 'FAMILY',
}

export const TravelTypes = {
    SOLO: 'SOLO',
    COUPLE: 'COUPLE',
    FAMILY: 'FAMILY',
    FRIENDS: 'FRIENDS',
    GROUP: 'GROUP',
    BUSINESS: 'BUSINESS',
}

export const IDENTITY_TYPES = {
    PASSPORT: 'PASSPORT',
    DRIVING_LICENSE: 'DRIVING_LICENSE',
    NATIONAL_ID: 'NATIONAL_ID',
    RESIDENCE_PERMIT: 'RESIDENCE_PERMIT',
    VISA: 'VISA',
    OTHER: 'OTHER',
}

export const countryList = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas (the)",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory (the)",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands (the)",
    "Central African Republic (the)",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands (the)",
    "Colombia",
    "Comoros (the)",
    "Congo (the Democratic Republic of the)",
    "Congo (the)",
    "Cook Islands (the)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic (the)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories (the)",
    "Gabon",
    "Gambia (the)",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (the)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic (the)",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands (the)",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (the Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands (the)",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger (the)",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands (the)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines (the)",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of North Macedonia",
    "Romania",
    "Russian Federation (the)",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan (the)",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands (the)",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (the)",
    "United Kingdom of Great Britain and Northern Ireland (the)",
    "United States Minor Outlying Islands (the)",
    "United States of America (the)",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "Åland Islands"
];


export const destinationTypes = {
    CITY: 'CITY',
    REGION: 'REGION',
    ISLAND: 'ISLAND',
    CONTINENT: 'CONTINENT',
    WILDLIFE_PARK: 'WILDLIFE_PARK',
    THEME_PARK: 'THEME_PARK',
    WATER_PARK: 'WATER_PARK',
    ZOO: 'ZOO',
    AQUARIUM: 'AQUARIUM',
    MUSEUM: 'MUSEUM',
    GALLERY: 'GALLERY',
    LIBRARY: 'LIBRARY',
    BOTANICAL_GARDEN: 'BOTANICAL_GARDEN',
    HISTORICAL_SITE: 'HISTORICAL_SITE',
    MONUMENT: 'MONUMENT',
    ARCHEOLOGICAL_SITE: 'ARCHEOLOGICAL_SITE',
    FORTRESS: 'FORTRESS',
    CASTLE: 'CASTLE',
    PALACE: 'PALACE',
    MANSION: 'MANSION',
    LIGHTHOUSE: 'LIGHTHOUSE',
    TOWER: 'TOWER',
    BRIDGE: 'BRIDGE',
    TUNNEL: 'TUNNEL',
    DAM: 'DAM',
    WATERFALL: 'WATERFALL',
    CAVE: 'CAVE',
    BEACH: 'BEACH',
    COAST: 'COAST',
    MOUNTAIN: 'MOUNTAIN',
    HILL: 'HILL',
    LAKE: 'LAKE',
    RIVER: 'RIVER'
}

export const weatherTypes = {
    SUNNY: 'SUNNY',
    CLOUDY: 'CLOUDY',
    RAINY: 'RAINY',
    SNOWY: 'SNOWY',
    STORMY: 'STORMY',
    FOGGY: 'FOGGY',
    WINDY: 'WINDY'
}

export const months = {
    JANUARY: 'JANUARY',
    FEBRUARY: 'FEBRUARY',
    MARCH: 'MARCH',
    APRIL: 'APRIL',
    MAY: 'MAY',
    JUNE: 'JUNE',
    JULY: 'JULY',
    AUGUST: 'AUGUST',
    SEPTEMBER: 'SEPTEMBER',
    OCTOBER: 'OCTOBER',
    NOVEMBER: 'NOVEMBER',
    DECEMBER: 'DECEMBER'
}


export const attractionTypes = {
    MUSEUM: 'MUSEUM',
    GALLERY: 'GALLERY',
    LIBRARY: 'LIBRARY',
    BOTANICAL_GARDEN: 'BOTANICAL_GARDEN',
    HISTORICAL_SITE: 'HISTORICAL_SITE',
    MONUMENT: 'MONUMENT',
    ARCHEOLOGICAL_SITE: 'ARCHEOLOGICAL_SITE',
    FORTRESS: 'FORTRESS',
    CASTLE: 'CASTLE',
    PALACE: 'PALACE',
    MANSION: 'MANSION',
    LIGHTHOUSE: 'LIGHTHOUSE',
    TOWER: 'TOWER',
    BRIDGE: 'BRIDGE',
    TUNNEL: 'TUNNEL',
    DAM: 'DAM',
    WATERFALL: 'WATERFALL',
    CAVE: 'CAVE',
    BEACH: 'BEACH',
    COAST: 'COAST',
    MOUNTAIN: 'MOUNTAIN',
    HILL: 'HILL',
    LAKE: 'LAKE',
    RIVER: 'RIVER'
}

export const activityTypes = {
    ADVENTURE: 'ADVENTURE',
    CULTURE: 'CULTURE',
    LEISURE: 'LEISURE',
    NATURE: 'NATURE',
    RELAXATION: 'RELAXATION',
    SHOPPING: 'SHOPPING',
    SIGHTSEEING: 'SIGHTSEEING',
    SPORTS: 'SPORTS',
    WELLNESS: 'WELLNESS',
    WILDLIFE: 'WILDLIFE',
    FAMILY: 'FAMILY',
    HIKING: 'HIKING',
    HISTORICAL: 'HISTORICAL',
}

export const eventsTypes = {
    MUSIC: 'MUSIC',
    FESTIVAL: 'FESTIVAL',
    FOOD: 'FOOD',
    SPORTS: 'SPORTS',
    CULTURE: 'CULTURE',
    ART: 'ART',
    RELIGIOUS: 'RELIGIOUS',
    HOLIDAY: 'HOLIDAY',
    PARADE: 'PARADE',
    CONFERENCE: 'CONFERENCE',
    EXHIBITION: 'EXHIBITION',
    FAIR: 'FAIR',
    CARNIVAL: 'CARNIVAL',
}

export const hotelRoomTypes = {
    SINGLE: 'SINGLE',
    DOUBLE: 'DOUBLE',
    FAMILY: 'FAMILY',
    SUITE: 'SUITE',
    VILLA: 'VILLA',
    BUNGALOW: 'BUNGALOW',
    CABIN: 'CABIN',
}

export const hotelFacilityTypes = {
    AIR_CONDITIONING: 'AIR_CONDITIONING',
    HEATING: 'HEATING ',
    WIFI: 'WIFI',
    PARKING: 'PARKING',
    RESTAURANT: 'RESTAURANT',
    BAR: 'BAR',
    SWIMMING_POOL: 'SWIMMING_POOL',
    SPA: 'SPA',
    FITNESS_CENTER: 'FITNESS_CENTER',
    BUSINESS_CENTER: 'BUSINESS_CENTER',
    CONFERENCE_ROOM: 'CONFERENCE_ROOM',
    LAUNDRY: 'LAUNDRY',
    ROOM_SERVICE: 'ROOM_SERVICE',
    CONCIERGE: 'CONCIERGE',
    ELEVATOR: 'ELEVATOR',
    PET_FRIENDLY: 'PET_FRIENDLY',
    FAMILY_FRIENDLY: 'FAMILY_FRIENDLY',
    BEACHFRONT: 'BEACHFRONT',
    OCEAN_VIEW: 'OCEAN_VIEW',
    MOUNTAIN_VIEW: 'MOUNTAIN_VIEW',
    CITY_VIEW: 'CITY_VIEW',
    BALCONY: 'BALCONY',
    TERRACE: 'TERRACE',
    GARDEN: 'GARDEN',
    BBQ: 'BBQ',
    KITCHEN: 'KITCHEN',
    MINIBAR: 'MINIBAR',
    SAFE: 'SAFE',
    TV: 'TV',
    DVD_PLAYER: 'DVD_PLAYER',
    RADIO: 'RADIO',
    TELEPHONE: 'TELEPHONE',
    HAIR_DRYER: 'HAIR_DRYER',
    IRON: 'IRON',
    DESK: 'DESK',
    SOFA: 'SOFA',
    FIREPLACE: 'FIREPLACE',
    JACUZZI: 'JACUZZI',
    BATHTUB: 'BATHTUB',
    SHOWER: 'SHOWER',
    TOILETRIES: 'TOILETRIES',
    BATHROBE: 'BATHROBE',
    SLIPPERS: 'SLIPPERS',
    TOWELS: 'TOWELS',
    LINENS: 'LINENS',
}

export const partnerTypes = {
    'HOTEL': 'hotels',
    'TOUR': 'tour',
    'RENTAL': 'rental',
    'TRANSPORT': 'transport',
    'EVENT': 'event',
    'RESTAURANT': 'resturant',
    'SHOP': 'shop',
}

export const vehiclesTypes = {
    'MINI_CAR': 'MINI_CAR',
    'HATCHBACK': 'HATCHBACK',
    'SEDAN': 'SEDAN',
    'SUV': 'SUV',
    'CROSSOVER': 'CROSSOVER',
    'COUPE': 'COUPE',
    'CONVERTIBLE': 'CONVERTIBLE',
    'MINIVAN': 'MINIVAN',
    'VAN': 'VAN',
    'COSTER': 'COSTER',
    'BUS': 'BUS',
}

export const transportPackageTypes = {
    'PREMIUM': 'PREMIUM',
    'BASIC': 'BASIC',
    'STANDARD': 'STANDARD',
    'ECONOMY': 'ECONOMY',
}

export const tourGuideTypes = {
    'TRADITIONAL': 'TRADITIONAL',
    'MODERN': 'MODERN',
    'CULTURAL': 'CULTURAL',
    'HISTORICAL': 'HISTORICAL',
    'NATURE': 'NATURE',
    'WILDLIFE': 'WILDLIFE',
    'ADVENTURE': 'ADVENTURE',
    'LEISURE': 'LEISURE',
    'SHOPPING': 'SHOPPING',
}


export const guideLanguages = {
    'ENGLISH': 'ENGLISH',
    'SPANISH': 'SPANISH',
    'FRENCH': 'FRENCH',
    'GERMAN': 'GERMAN',
    'ITALIAN': 'ITALIAN',
    'RUSSIAN': 'RUSSIAN',
    'CHINESE': 'CHINESE',
    'JAPANESE': 'JAPANESE',
    'KOREAN': 'KOREAN',
    'ARABIC': 'ARABIC',
    'HINDI': 'HINDI',
}

export const tourTypes = {
    ADVENTURE: 'ADVENTURE',
    CULTURE: 'CULTURE',
    LEISURE: 'LEISURE',
    NATURE: 'NATURE',
    RELAXATION: 'RELAXATION',
    SHOPPING: 'SHOPPING',
    SIGHTSEEING: 'SIGHTSEEING',
    SPORTS: 'SPORTS',
    WELLNESS: 'WELLNESS',
    WILDLIFE: 'WILDLIFE',
    FAMILY: 'FAMILY',
}

export const paymentTypes = {
    CASH: 'CASH',
    CARD: 'CARD',
    PAYPAL: 'PAYPAL',
    CHEQUE: 'CHEQUE',
    BANK_TRANSFER: 'BANK_TRANSFER',
}

export const supporRequestTypes = {
    GENERAL: 'GENERAL',
    BOOKING: 'BOOKING',
    PAYMENT: 'PAYMENT',
    CANCELLATION: 'CANCELLATION',
    REFUND: 'REFUND',
    COMPLAINT: 'COMPLAINT',
    SUGGESTION: 'SUGGESTION',
    OTHER: 'OTHER',
    FEEDBACK: 'FEEDBACK',
    REQUEST_CHANGE: 'REQUEST_CHANGE',
}

export const districtList = [
    'Ampara',
    'Anuradhapura',
    'Badulla',
    'Batticaloa',
    'Colombo',
    'Galle',
    'Gampaha',
    'Hambantota',
    'Jaffna',
    'Kalutara',
    'Kandy',
    'Kegalle',
    'Kilinochchi',
    'Kurunegala',
    'Mannar',
    'Matale',
    'Matara',
    'Monaragala',
    'Mullaitivu',
    'Nuwara Eliya',
    'Polonnaruwa',
    'Puttalam',
    'Ratnapura',
    'Trincomalee',
    'Vavuniya'
]

export const Genders = {
    MALE: 'Male',
    FEMALE: 'Female',
    OTHER: 'Other',
    NOT_TO_SAY: 'Prefer not to say'
}

export const SUPPORT_INCIDENT_TYPES = {
    BOOKING_CHANGE: 'BOOKING_CHANGE',
    REQUEST: 'REQUEST',
    PROBLEM: 'PROBLEM',
    QUESTION: 'QUESTION',
    COMPLAINT: 'COMPLAINT',
    FEEDBACK: 'FEEDBACK',
    SUGGESTION: 'SUGGESTION',
    OTHER: 'OTHER',
}

export const supportRequestStatus = {
    PENDING: 'PENDING',
    IN_PROGRESS: 'IN_PROGRESS',
    RESOLVED: 'RESOLVED',
    CLOSED: 'CLOSED',
}

export const customEventsTypes = {
    WEDDING: 'WEDDING',
    BIRTHDAY: 'BIRTHDAY',
    ANNIVERSARY: 'ANNIVERSARY',
    CONFERENCE: 'CONFERENCE',
    HONEYMOON: 'HONEYMOON',
    ENGAGEMENT_PARTY: 'ENGAGEMENT_PARTY',
    ROMANTIC_PROPOSAL: 'ROMANTIC_PROPOSAL',
    OTHER: 'OTHER',
}

export const blogStatus = {
    PENDING: 'PENDING',
    PUBLISHED: 'PUBLISHED',
    DRAFT: 'DRAFT',
    ARCHIVED: 'ARCHIVED',
    DELETED: 'DELETED',
}


export const hotelTags = {
    'BEACHFRONT': 'BEACHFRONT',
    'OCEAN_VIEW': 'OCEAN_VIEW',
    'MOUNTAIN_VIEW': 'MOUNTAIN_VIEW',
    'CITY_VIEW': 'CITY_VIEW',
    'BALCONY': 'BALCONY',
    'TERRACE': 'TERRACE',
    'GARDEN': 'GARDEN',
    'BBQ': 'BBQ',
    'KITCHEN': 'KITCHEN',
    'MINIBAR': 'MINIBAR',
}