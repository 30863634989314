import { Button, DatePicker, Divider, Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { capitalizeFisrtLetterEachWord } from '../../../../../../helpers/nameRender'
import { CONFIRMED, PAID, PENDING_PAYMENT, PENDING_VERIFICATION } from '../../../../../../constants/finance'
import moment from 'moment'
import { editCustomTourCutsomer } from '../../../../../../../redux/api/userTour.api'
import { Card } from 'react-bootstrap'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import './styles.scss'

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const BookingDetails = ({
    userTour,
    handleGetUserTour,
    messageApi
}) => {
    const [addFlighDetailsVisible, setAddFlighDetailsVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const onSubmitFlightDetails = async (values) => {
        setLoading(true)
        let flightDetails = {
            flightBooked: true,
            flightNumber: values.flightNumber,
            departureAirport: values.departureAirport,
            arrivalDateTime: values.arrivalDateTime
        }
        const data = {
            id: userTour?.id,
            flightDetails: JSON.stringify(flightDetails)
        }
        const response = await editCustomTourCutsomer(data)
        if (response.status === 200) {
            messageApi.success({
                message: 'Success',
                description: 'Flight details added successfully.'
            })
            setAddFlighDetailsVisible(false)
            handleGetUserTour()
        } else {
            messageApi.error({
                message: 'Error',
                description: 'Something went wrong! Try again later.'
            })
        }
        setLoading(false)
    }

    return (
        <div className='booking_details'>
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Basic Information
            </Divider>
            <div className='p_container'>
                <p><span>Booking ID: </span>{userTour?.id}</p>
                <p><span>Start Date: </span>{userTour?.startDate}</p>
                <p><span>Duration: </span>{userTour?.durationNights} Nights</p>
                <p><span>Booked Date: </span>{
                    moment(userTour?.createdAt).format('DD/MM/YYYY hh:mm A')
                }</p>
            </div>
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Status
            </Divider>
            <div className='p_container'>
                <p
                    className={userTour?.isConfirmedByAdmin ? 'green' : 'red'}
                ><span>Approve Status: </span>{userTour?.isConfirmedByAdmin ? 'Approved' : 'Pending Approval'}</p>
                <p
                    className={userTour?.bookingStatus === PENDING_PAYMENT ? 'red' :
                        userTour?.bookingStatus === PENDING_VERIFICATION ? 'yellow' :
                            userTour?.bookingStatus === CONFIRMED ? 'green' :
                                'red'}
                ><span>Booking Status: </span>{userTour?.bookingStatus ? capitalizeFisrtLetterEachWord(userTour?.bookingStatus) : 'Pending'}
                </p>
                <p
                    className={userTour?.paymentStatus === PENDING_PAYMENT ? 'red' :
                        userTour?.paymentStatus === PENDING_VERIFICATION ? 'yellow' :
                            userTour?.paymentStatus === PAID ? 'green' :
                                'red'}
                ><span>Payment Status: </span>{userTour?.paymentStatus ? capitalizeFisrtLetterEachWord(userTour?.paymentStatus) : 'Pending'}
                </p>
            </div>
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Your Selections
            </Divider>
            <div className='p_container'>
                <p><span>Transport Option: </span>{userTour?.vehicleType}</p>
                <p><span>Accommodation Type: </span>{userTour?.accommodationType}</p>
                <p><span>Accommodation rating: </span>{userTour?.accommodationRating} Star</p>
                <p><span>Room Type: </span>{userTour?.roomType}</p>
                <p><span>Guide Language: </span>{userTour?.tourGuideLanguage}</p>
            </div>
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Destinations Selected by You
            </Divider>
            <div className='slider'>
                {userTour?.destinations && <Carousel
                    swipeable={true}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    ssr={true}
                    infinite={false}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    {
                        userTour?.destinations?.map((destination, index) => (
                            <Card
                                key={index} className='destination_card'
                                onClick={() => window.open(`/destinations/${destination?.id}`, '_blank')}
                            >
                                <Card.Img variant="top" src={destination?.thumbnailUrl} className='card_image' />
                                <Card.Body className='card_body'>
                                    <Card.Title className='card_title'>{destination?.destinationName}</Card.Title>
                                </Card.Body>
                            </Card>
                        ))
                    }
                </Carousel>}
            </div>
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Guide & Transport
            </Divider>
            <div className='p_container'>
                <p><span>Your Tour Guide: </span>{userTour?.guide?.guideName || 'Not yet assigned'}</p>
                <p><span>Vehicle: </span>{!userTour?.vehicleInfo ? 'Not yet assigned' : 'Assigned'}</p>
                {
                    userTour?.vehicleInfo && <div className='p_container'>
                        <p><span>Vehicle Type: </span>{userTour?.vehicleInfo?.vehicleType}</p>
                        <p><span>Vehicle Brand & Model: </span>{userTour?.vehicleInfo?.vehicleBrand}</p>
                        <p><span>Plate Number: </span>{userTour?.vehicleInfo?.vehicleplateNumber}</p>
                        <p><span>Max Passenger Count: </span>{userTour?.vehicleInfo?.vehicleMaxPassenger}</p>
                    </div>
                }
            </div>
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Your Preferences
            </Divider>
            <div className='p_container'>
                <p><span>Beaches: </span>{userTour?.favItems?.beaches} /5</p>
                <p><span>Wildlife: </span>{userTour?.favItems?.wildlife} /5</p>
                <p><span>Historical Sites: </span>{userTour?.favItems?.historialSites} /5</p>
                <p><span>Adventure: </span>{userTour?.favItems?.adventure} /5</p>
                <p><span>Relaxation: </span>{userTour?.favItems?.relaxation} /5</p>
                <p><span>Cultural: </span>{userTour?.favItems?.cultural} /5</p>
                <p><span>wellness & Spa: </span>{userTour?.favItems?.wellnessAndSpa} /5</p>
                <p><span>Events & Festivals: </span>{userTour?.favItems?.festivalAndEvents} /5</p>
            </div>
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Flight Details
            </Divider>
            <div className='p_container'>
                <p><span>Flight Booked: </span>{userTour?.flightDetails?.flightBooked ? 'Yes' : 'Not Yet'}</p>
                {userTour?.flightDetails?.flightBooked ? <>
                    <p><span>Flight No: </span>{userTour?.flightDetails?.flightNumber}</p>
                    <p><span>Departure Airport: </span>{userTour?.flightDetails?.departureAirport}</p>
                    <p><span>Arrival Date & Time: </span>{
                        moment(userTour?.flightDetails?.arrivalDateTime).format('DD/MM/YYYY HH:mm')
                    }</p>
                </> :
                    <Button
                        type='primary'
                        onClick={() => setAddFlighDetailsVisible(!addFlighDetailsVisible)}
                    >
                        <span
                            style={{ color: 'white' }}
                        >{
                                addFlighDetailsVisible ? 'Cancel' : 'Add Flight Details'
                            }
                        </span>
                    </Button>
                }

                {
                    addFlighDetailsVisible &&
                    <Form
                        onFinish={onSubmitFlightDetails}
                        layout='vertical'
                    >
                        <Form.Item
                            label='Flight Number'
                            name='flightNumber'
                            rules={[{ required: true, message: 'Please enter flight number' }]}
                        >
                            <Input placeholder='Enter flight number' maxLength={50} />
                        </Form.Item>
                        <Form.Item
                            label='Departure Airport'
                            name='departureAirport'
                            rules={[{ required: true, message: 'Please enter departure airport' }]}
                        >
                            <Input placeholder='Enter departure airport' maxLength={50} />
                        </Form.Item>
                        <Form.Item
                            label='Arrival Date & Time'
                            name='arrivalDateTime'
                            rules={[{ required: true, message: 'Please enter departure airport' }]}
                        >
                            <DatePicker
                                showTime
                                format='YYYY-MM-DD HH:mm:ss'
                                placeholder='Arrival Date & Time'
                                showNow={false}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <p style={{ margin: '0 0 20px 0' }}>
                            <strong>Note:</strong> Please make sure to provide correct flight details to avoid any inconvenience. <span style={{ color: 'red' }}>Once submitted, you can't change the flight details.</span>
                        </p>
                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                loading={loading}
                            >
                                <span
                                    style={{ color: 'white' }}
                                >
                                    Add Flight Details
                                </span>
                            </Button>
                        </Form.Item>
                    </Form>
                }
            </div>
        </div>
    )
}

export default BookingDetails