import { getCurrencyList } from '../../api/currencyRates.api';
import {
    FETCH_CURRENCY_RATES,
    FETCH_CURRENCY_RATES_SUCCESS,
    FETCH_CURRENCY_RATES_FAIL,
} from '../../types';

export const fetchCurrencyRatesAC = () => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_CURRENCY_RATES
        })
        try {

            const response = await getCurrencyList()

            dispatch({
                type: FETCH_CURRENCY_RATES_SUCCESS,
                payload: response.data
            })
        } catch (error) {
            dispatch({
                type: FETCH_CURRENCY_RATES_FAIL,
                payload: error.message
            })
        }
    }
}