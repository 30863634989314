import React from 'react'

const AboutUs = () => {
    return (
        <div className='support section_main' style={{ marginBottom: '60px' }}>
            <div className='top_padding_container'>
                <h1 className='header_1'>
                    About Us
                </h1>
            </div>
        </div>
    )
}

export default AboutUs