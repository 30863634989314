import React, { useEffect, useState } from 'react'
import useMyProfile from '../../../../hooks/useMyProfile';

import './styles.scss'
import { Empty, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getAllAccommodationBookingsByUser } from '../../../../../redux/api/accommodations.api';
import { capitalizeFisrtLetterEachWord } from '../../../../helpers/nameRender';

const AccommodationsBookings = () => {
    const [loading, setLoading] = useState(false);
    const [bookings, setBookings] = useState(null);
    const myProfile = useMyProfile();
    const navigate = useNavigate();

    useEffect(() => {
        if (myProfile?.userProfile?.id && !bookings) {
            handleGetTourBoookings();
        }
    }, [myProfile]);

    const handleGetTourBoookings = async () => {
        setLoading(true);
        try {
            const response = await getAllAccommodationBookingsByUser(myProfile?.userProfile?.id)
            const data = response?.data;
            const sortedData = data?.sort((a, b) => new Date(b?.tourStartDate) - new Date(a?.tourStartDate));

            setBookings(sortedData);
        } catch (error) {
            console.log('ERROR GETTING TOUR BOOKINGS', error);
        }
        setLoading(false);
    }

    return (
        <div className='personal_details'>
            <h1 className='heder_2'>
                Accommodations Booking
            </h1>
            <p className='about'>
                Accommodation bookings is not available yet. The feature is still under development. Please check back later.
            </p>

            <div className='personal_details__container'>
                {
                    loading && Array(3).fill().map((_, i) => (
                        <div className='booking__card'>
                            <Skeleton
                                active
                                paragraph={{ rows: 2 }}
                            />
                        </div>
                    ))
                }
                {
                    bookings?.length > 0 ? bookings?.map((booking) => (
                        <div className='booking__card'
                            onClick={() => navigate(`/dashboard/my-bookings/accommodations/${booking?.id}`)}
                        >
                            <div className='booking__card__header'>
                                <h3>Accommodation Name: {booking?.accommodationName}</h3>
                                <h4><span>City: </span><p>{booking?.accommodationCity}</p></h4>
                                <h4><span>Check-In: </span><p>{booking?.checkIn}</p></h4>
                                <h4><span>Check-Out: </span><p>{booking?.checkOut}</p></h4>
                                <h4
                                    className={
                                        booking?.bookingStatus === 'REQUESTED' ? 'yellow'
                                            : booking?.bookingStatus === 'PENDING_PAYMENT' ? 'red'
                                                : booking?.bookingStatus === 'CONFIRMED' ? 'green'
                                                    : booking?.bookingStatus === 'PENDING_VERIFICATION' ? 'yellow'
                                                        : booking?.bookingStatus === 'CANCELLED' ? 'red'
                                                            : 'blue'
                                    }
                                ><span>Status: </span><p>{booking?.bookingStatus ? capitalizeFisrtLetterEachWord(booking?.bookingStatus) : 'Pending'}</p></h4>
                            </div>
                        </div>
                    ))
                        :
                        <>
                            {
                                !loading &&
                                <div className='empty'>
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description='You have not done any transactions yet.'
                                    />
                                </div>
                            }
                        </>
                }
            </div>
        </div>
    )
}

export default AccommodationsBookings