export const TAX_RATE = 0.1;
export const TAX_PERCENTAGE = 10;

// Payment Status
export const PENDING_PAYMENT = 'PENDING_PAYMENT';
export const PENDING_VERIFICATION = 'PENDING_VERIFICATION';
export const PAID = 'PAID';
export const INSTALMENT_PENDING = 'INSTALMENT_PENDING';
export const INSTALMENT_PAID = 'INSTALMENT_PAID';
export const CANCELLED = 'CANCELLED';

// Payment Types
export const TOUR_PACKAGE_PAYMENT = 'TOUR_PACKAGE_PAYMENT';
export const ACCOMMODATION_PAYMENT = 'ACCOMMODATION_PAYMENT';
export const ADDITIONAL_PAYMENT = 'ADDITIONAL_PAYMENT';
export const REFUND = 'REFUND';

// Payment Methods
export const BANK_TRANSFER = 'BANK_TRANSFER';
export const CREDIT_CARD = 'CREDIT_CARD';
export const PAYPAL = 'PAYPAL';
export const CASH = 'CASH';
export const INSTALMENT = 'Monthly';

// Booking Status
export const CONFIRMED = 'CONFIRMED';
export const PENDING = 'PENDING';
export const REJECTED = 'REJECTED';
export const REQUESTED = 'REQUESTED';

// TOUR STATUS
export const ACTIVE = 'PENDING';
export const NOT_STARTED = 'NOT_STARTED';
export const ONGOING = 'ONGOING';
export const COMPLETED = 'COMPLETED';
export const CANCELLED_TOUR = 'CANCELLED_TOUR';
