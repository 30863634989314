import { Button, Divider, Tabs, Upload } from 'antd'
import React, { useState } from 'react'
import { ACCOMMODATION_PAYMENT, PAID, PENDING_PAYMENT, PENDING_VERIFICATION, REQUESTED } from '../../../../../../constants/finance'
import { capitalizeFisrtLetterEachWord } from '../../../../../../helpers/nameRender'
import usePageSettings from '../../../../../../hooks/usePageSettings'
import useCurrencyRates from '../../../../../../hooks/useCurrencyRates';
import { CreditCardOutlined, InboxOutlined } from '@ant-design/icons'
import moment from 'moment'
import { convertCurrency } from '../../../../../../helpers/currencyConverter';

import './styles.scss'
import { handleImageUpload, PAYMENTS_CONTAINER } from '../../../../../../azure/blob'
import useMyProfile from '../../../../../../hooks/useMyProfile'
import { addPayment } from '../../../../../../../redux/api/userTour.api'
import { editAccommodationBookingCustomer } from '../../../../../../../redux/api/accommodations.api'

const { Dragger } = Upload;

const AccommodationPayment = ({
    booking,
    handleGetBooking,
    messageApi
}) => {
    const myProfile = useMyProfile().userProfile;
    const selectedCurrency = usePageSettings()?.selectedCurrency;
    const currencyRates = useCurrencyRates()?.currencyRates
    const [fileList, setFileList] = useState([]);
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const calculateDueDate = (date, days) => {
        const newDate = moment(date).add(days, 'days');
        return (
            <span style={{ color: newDate.isBefore(moment()) ? 'red' : 'green' }}>
                {newDate.format('MMM D YYYY h:mm A')}
            </span>
        );
    }

    const handleCreatePayment = async () => {
        setLoadingSubmit(true);
        if (fileList.length < 1) {
            messageApi.error({
                message: 'No file selected',
                description: 'Please select a file to upload'
            })
            setLoadingSubmit(false);
            return;
        } else {
            const blobName = `${booking?.id}_${fileList[0].originFileObj?.name}`;
            const blobRes = await handleImageUpload(
                PAYMENTS_CONTAINER,
                fileList[0].originFileObj,
                fileList[0].size,
                blobName
            )
            if (blobRes?.blockBlobClient.url) {
                const paymentValues = {
                    userId: myProfile?.id,
                    paidFor: booking?.id,
                    paymentType: ACCOMMODATION_PAYMENT,
                    paymentMethod: 'BANK_TRANSFER',
                    paymentStatus: PENDING_VERIFICATION,
                    paymentAmount: booking?.total,
                    paymentCurrency: 'AUD',
                    paymentDate: moment().format('MMM D YYYY h:mma'),
                    paymentTime: moment().format('h:mma'),
                    proof: blobRes?.blockBlobClient.url,
                    paymentReference: `Booking ID (${booking?.id})`

                }

                const response = await addPayment(paymentValues)
                if (response?.status === 200) {
                    const userTourValues = {
                        id: booking?.id,
                        paymentId: response?.data?.id,
                        paymentStatus: PENDING_VERIFICATION,
                        bookingStatus: PENDING_VERIFICATION
                    }

                    const updateResponse = await editAccommodationBookingCustomer(userTourValues)

                    if (updateResponse?.status === 200) {
                        messageApi.success({
                            message: 'Payment Slip Submitted',
                            description: 'Your payment slip has been submitted successfully. We will verify your payment and confirm your booking.'
                        })
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000)
                    } else {
                        console.log('ERROR UPDATING BOOKING', updateResponse)
                        messageApi.error({
                            message: 'Error Updating Booking',
                            description: 'Your payment slip has been submitted successfully. However, there was an error updating your booking. Please contact us.'
                        })
                    }
                } else {
                    console.log('ERROR ADDING PAYMENT', response)
                    messageApi.error({
                        message: 'Error Submitting Payment Slip',
                        description: 'There was an error submitting your payment slip. Please try again later.'
                    })
                }
            } else {
                messageApi.error({
                    message: 'Error Uploading Payment Slip',
                    description: 'There was an error uploading your payment slip. Please try again later.'
                })
            }
        }

        setLoadingSubmit(false);
    }

    return (
        <div className='booking_details acc-booking-detals'>
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Basic Information
            </Divider>
            <div className='p_container'>
                <p><span>Booking ID: </span>{booking?.id}</p>
                <p><span>Check-In: </span>{booking?.checkIn}</p>
                <p><span>Check-Out: </span>{booking?.checkOut}</p>
                <p><span>Booked Date: </span>{new Date(booking?.updatedAt).toDateString()}</p>
                <p><span>Adults: </span>{booking?.adults}</p>
                <p><span>Children: </span>{booking?.childrens}</p>
            </div>
            <Divider
                orientation="left"
                orientationMargin="0"
            >
                Payment Information
            </Divider>
            <div className='p_container'>
                <p><span>Initial Price: </span>{booking?.initailCostPerPerson && convertCurrency(booking?.initailCostPerPerson, selectedCurrency, currencyRates)} {selectedCurrency} per person per night</p>
                <p><span>Finel Price: </span>{booking?.givenPricePerPerson && convertCurrency(booking?.givenPricePerPerson, selectedCurrency, currencyRates)} {selectedCurrency} per person per night</p>
                <p className={booking?.paymentStatus === REQUESTED ? 'yellow' :
                    booking?.paymentStatus === PENDING_PAYMENT ? 'red' :
                        booking?.paymentStatus === PENDING_VERIFICATION ? 'yellow' :
                            booking?.paymentStatus === PAID ? 'green' :
                                'red'}
                ><span>Payment Status: </span>{booking?.paymentStatus ? capitalizeFisrtLetterEachWord(booking?.paymentStatus) : 'Pending'}
                </p>
                <p><span>Total Price: </span>{booking?.total ? <>{booking?.total && convertCurrency(booking?.total, selectedCurrency, currencyRates)} {selectedCurrency} </> : "Not yet updated"}</p>
                {booking?.paymentId && <p><span>Payment ID: </span>{booking?.paymentId}</p>}
            </div>
            {
                booking?.bookingStatus !== REQUESTED &&
                booking?.paymentStatus === PENDING_PAYMENT
                &&
                <>
                    <Divider
                        orientation="left"
                        orientationMargin="0"
                    >
                        Payments
                    </Divider>

                    <div className='p_container'>
                        <p>
                            Pleas proceed with the payment to confirm your booking.
                        </p>
                        <p>
                            available payment methods are:
                        </p>
                    </div>
                    <h3 className='header_4' style={{ marginBottom: '10px', marginTop: '10px' }}>Bank Transfer</h3>
                    <p>
                        Please transfer the total amount to the following bank account. Make sure to include the reference number in the payment description. Once you have made the payment, please submit the payment slip below.
                    </p>
                    <div className='bank_details_container'>
                        <Tabs
                            defaultActiveKey="1"
                            items={[
                                {
                                    key: '1',
                                    label: 'AUD',
                                    children: <div className='p_container'>
                                        <p>Account Name: <strong>VISIPEARL.COM</strong></p>
                                        <p>BSB: <strong>064-437</strong></p>
                                        <p>Account Number: <strong>1011 6377</strong></p>
                                        <p>Reference: <strong>ACC Booking ID ({booking?.id})</strong></p>
                                    </div>
                                },
                                {
                                    key: '2',
                                    label: 'USD',
                                    children: <div className='p_container'>
                                        <p>Account Name: <strong>VISIPEARL.COM</strong></p>
                                        <p>BSB: <strong>064-437</strong></p>
                                        <p>Account Number: <strong>1011 6385</strong></p>
                                        <p>Reference: <strong>ACC Booking ID ({booking?.id})</strong></p>
                                    </div>
                                }
                            ]}
                        />
                    </div>
                    <div className='payment_splip_submit' style={{ marginTop: '20px' }}>
                        <h4 className='header_4'>Submit Payment Slip</h4>
                        <p>
                            If you have already made the payment, please submit the payment slip below.
                        </p>
                        <h5 className='payment_due_date'>
                            Payment Due: {calculateDueDate(booking?.updatedAt, 3)}
                        </h5>
                        <h5 className='payment_due_date'>
                            Amount: {booking?.total && convertCurrency(booking?.total, selectedCurrency, currencyRates)} {selectedCurrency}
                        </h5>
                        <Dragger
                            fileList={fileList}
                            onChange={({ fileList }) => setFileList(fileList)}
                            beforeUpload={
                                (file) => {
                                    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
                                    const isSizeValid = file.size / 1024 / 1024 < 2;

                                    if (!isSizeValid) {
                                        setFileList([]);
                                        messageApi.error({
                                            message: 'File too large',
                                            description: 'Please upload a file less than 2MB'
                                        })
                                        return Upload.LIST_IGNORE;
                                    } else if (!isJpgOrPng) {
                                        setFileList([]);
                                        messageApi.error({
                                            message: 'Unsupported file format',
                                            description: 'Please upload only JPG, PNG or PDF files'
                                        })
                                        return Upload.LIST_IGNORE;
                                    } else {
                                        return false;
                                    }
                                }}
                            maxCount={1}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                Click or drag payment slip to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                                Please upload a clear image of your payment slip. We accept JPG, PNG, PDF files. Upload only one file.
                            </p>
                        </Dragger>
                        <Button
                            type='primary'
                            onClick={handleCreatePayment}
                            loading={loadingSubmit}
                        >
                            Submit Payment Slip
                        </Button>
                    </div>
                    <Divider />
                    <h3 className='header_4' style={{ marginBottom: '10px', marginTop: '10px' }}>Online Payment</h3>
                    <div className='p_container'>
                        <p>
                            You can also pay online using the following button.
                        </p>
                    </div>
                    <Button
                        type='primary'
                        className='custom_primary_btn_2'
                        style={{ marginTop: '10px' }}
                        onClick={() => {
                            alert('This feature is not available yet')
                        }}
                    >
                        Pay Online <CreditCardOutlined />
                    </Button>
                </>
            }
            {
                booking?.bookingStatus === REQUESTED &&
                <>
                    <Divider
                        orientation="left"
                        orientationMargin="0"
                    >
                        Payments
                    </Divider>
                    <div className='p_container'>
                        <p>
                            Your booking is requested. Please wait for the confirmation. You will be notified once the booking is confirmed. Then you can proceed with the payment.
                        </p>
                        <p>
                            If you have any questions, please contact us at <a className='default_anchor' href="mailto:info@visitpearl.com.au"> info@visitpearl.com.au</a>
                        </p>
                    </div>
                </>
            }
        </div>
    )
}

export default AccommodationPayment