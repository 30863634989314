import React from 'react';
import ReactDOM from 'react-dom/client';
import { msalInstance } from './App/azure/authConfig';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ConfigProvider } from 'antd';
import configureStore from './redux/configStore';

import theme from './theme/theme.json';
import { Provider } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-quill/dist/quill.snow.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-photo-view/dist/react-photo-view.css';
import './index.css';
import './theme/typo.scss';
import './theme/containers.scss';
import './theme/cards.scss';

import Onboarding from './App/pages/Onboarding';
import App from './App/App';

const { store } = configureStore();

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
})

if (true) {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
  console.warn = () => { }
  console.info = () => { }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MsalProvider instance={msalInstance}>
    <ConfigProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<App />} />
            <Route path='/onboarding' element={<Onboarding />} />
            <Route path='*' element={<App />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  </MsalProvider>
);
