import React, { useEffect, useState } from 'react'

import './styles.scss'
import { BookOutlined, CaretRightOutlined, CloseCircleOutlined, CompassOutlined, CustomerServiceOutlined, DollarOutlined, HomeOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { FAQ_ACCOUNT, FAQ_BOOKING, FAQ_GENERAL, FAQ_PAYMENT, FAQ_SUPPORT, FAQ_TOURS } from '../../../constants/faq'
import { Breadcrumb, Button, Collapse, Drawer } from 'antd'

const items = [
    {
        key: '1',
        label: 'General',
        icon: <HomeOutlined />,
        link: 'general',
        faqItem: FAQ_GENERAL
    },
    {
        key: '2',
        label: 'User Account',
        icon: <UserOutlined />,
        link: 'account',
        faqItem: FAQ_ACCOUNT
    },
    {
        key: '3',
        label: 'Tours',
        icon: <CompassOutlined />,
        link: 'tours',
        faqItem: FAQ_TOURS
    },
    {
        key: '4',
        label: 'Payments',
        icon: <DollarOutlined />,
        link: 'payments',
        faqItem: FAQ_PAYMENT
    },
    {
        key: '5',
        label: 'Booking',
        icon: <BookOutlined />,
        link: 'booking',
        faqItem: FAQ_BOOKING
    },
    {
        key: '6',
        label: 'Support',
        icon: <CustomerServiceOutlined />,
        link: 'support',
        faqItem: FAQ_SUPPORT
    },
]


const FAQ = () => {
    const [activeItem, setActiveItem] = useState('1');
    const navigate = useNavigate();
    const url = window.location.href;
    const [isNavDrawerOpen, setIsNavDraweOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const activeItemKey = url.split('?')[1];
        setActiveItem(activeItemKey ? activeItem : '1')
    }, [url])


    return (
        <div className='support section_main' style={{ marginBottom: '60px' }}>
            <div className='top_padding_container'>
                <Breadcrumb
                    className='breadcrumb_pages'
                    style={{ marginBottom: '10px' }}
                    items={[
                        {
                            title: <div className='breadcrumb__item' onClick={() => navigate('/support')}><CustomerServiceOutlined /> Help & Support</div>,
                        },
                        {
                            title: <div className='breadcrumb__item last'>FAQ</div>,
                        },
                    ]}
                />
                <h1 className='header_1'>
                    Frequently Asked Questions
                </h1>
            </div>
            <div className='faq_mobile_nav'>
                <Button onClick={() => setIsNavDraweOpen(true)}><MenuUnfoldOutlined /> Open Menu</Button>
            </div>
            <div className='dashboard__Home'>
                <div className='dash_home_nav'>
                    {items.map((item) => (
                        <div
                            key={item.key}
                            className={`dash_home_nav__container__items__item ${activeItem === item.key ? 'active' : ''}`}
                            onClick={() => {
                                setActiveItem(item.key);
                                navigate(`/support/faq?${item.key}`)
                            }}
                        >
                            {item.icon}
                            {item.label}
                        </div>
                    ))}
                </div>
                <div className='dash_home_content'>
                    <Collapse
                        expandIconPosition='right'
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    >
                        {
                            items?.find(item => item.key === activeItem)?.faqItem.questions.map((question, index) => (
                                <>
                                    <Collapse.Panel
                                        header={<span>
                                            <h6>{question.question}</h6>
                                        </span>}
                                        key={index}
                                        className='itinerary_collapse'
                                    >
                                        <p>{question.answer}</p>
                                    </Collapse.Panel>
                                </>
                            ))
                        }
                    </Collapse>
                </div>
            </div>

            <Drawer
                title="Basic Drawer"
                className='home__nav__drawer'
                rootClassName='home__nav__drawer_body'
                placement='bottom'
                closable={false}
                open={isNavDrawerOpen}
                onClose={() => setIsNavDraweOpen(false)}
                height='70vh'
                headerStyle={{ display: 'none' }}
            >
                <div className='drawer__content_home_nav'>
                    {items.map((item) => (
                        <div
                            key={item.key}
                            className={`drawer__content__items ${activeItem === item.key ? 'active' : ''}`}
                            onClick={() => {
                                navigate(`/support/faq?${item.key}`);
                                setActiveItem(item.key);
                                setIsNavDraweOpen(false);
                            }}
                        >
                            {item.icon}
                            {item.label}
                        </div>
                    ))}
                    <div className='drawer__content__items close' onClick={() => setIsNavDraweOpen(false)}>
                        <CloseCircleOutlined />
                        Close
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default FAQ