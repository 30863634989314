import { CloudOutlined, EnvironmentOutlined, FireOutlined, HeartOutlined, HeartTwoTone, HomeOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Carousel, message, Skeleton, Tag } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { useNavigate } from 'react-router-dom'
import { DEFAULT_PROFILE_IMAGE, DEFAULT_PROFILE_IMAGE_VISIT_PEARL, DEFAULT_THUMBNAIL } from '../../../../constants/otherConstsnts';
import { captilizeEachWord, resizeString } from '../../../../helpers/nameRender';
import ReactQuill from 'react-quill';
import { getAttractionById } from '../../../../../redux/api/attractions.api';
import LoadingComp from '../../../../components/LoadingComp';
import useMyProfile from '../../../../hooks/useMyProfile';
import { addToFavourites, deleteFavourite, getFavouriteById } from '../../../../../redux/api/favourite.api';
import { FAV_ATTRACTION } from '../../../../constants/favTypes';
import { getBlogByRelatedId } from '../../../../../redux/api/blogs.api';
import { Card, Col, Row } from 'react-bootstrap';
import moment from 'moment';

const Attraction = () => {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const myProfile = useMyProfile().userProfile
    const [redMoreOpen, setReadMoreOpen] = useState(false)
    const url = window.location.pathname
    const attractionId = url.split('/')[4]
    const destinationId = url.split('/')[2]
    const [loading, setLoading] = useState(true)
    const [attraction, setAttraction] = useState(null)
    const moreDetainsRef = useRef()

    const [isFavourite, setIsFavourite] = useState(null)
    const [loadingFavourite, setLoadingFavourite] = useState(false)

    const [relatedBlogs, setRelatedBlogs] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (attractionId) {
            handleGetAttraction(attractionId)
            handleGetRelatedBlogs()
        }
    }, [attractionId])

    useEffect(() => {
        if (myProfile) {
            handleCheckIsFavourite()
        }
    }, [myProfile])

    const handleGetRelatedBlogs = async () => {
        try {
            const response = await getBlogByRelatedId(attractionId, 'attraction')
            setRelatedBlogs(response.data)
        } catch (error) {
            console.error('Error fetching related blogs', error)
        }
    }

    const handleCheckIsFavourite = async () => {
        setLoadingFavourite(true)
        try {
            const response = await getFavouriteById(attractionId)
            setIsFavourite(response.data)
        } catch (error) {
            console.error('Error checking favourite', error)
        }
        setLoadingFavourite(false)
    }

    const handleOnClikFavourite = async () => {
        setLoadingFavourite(true)
        if (isFavourite) {
            await deleteFavourite(isFavourite.id)
            setIsFavourite(null)
            messageApi.open({
                type: 'success',
                content: 'Removed from favourites',
                duration: 2,
            });
        } else {
            await addToFavourites({
                userId: myProfile?.id,
                favouriteId: attractionId,
                favouriteType: FAV_ATTRACTION
            })
            await handleCheckIsFavourite()
            messageApi.open({
                type: 'success',
                content: 'Added to favourites',
                duration: 2,
            });
        }
        setLoadingFavourite(false)
    }

    const handleGetAttraction = async (id) => {
        setLoading(true)
        try {
            const attraction = await getAttractionById(id)
            setAttraction(attraction.data[0])
        } catch (error) {
            console.error('Error fetching destination', error)
        }
        setLoading(false)
    }

    const renderImages = (images) => {
        if (images?.length > 2) {
            const moreImages = images.slice(2)
            return (
                <div className='images_container more_than_two'>
                    <PhotoView src={images[0]}>
                        <LazyLoadImage
                            alt='image'
                            effect="blur"
                            wrapperProps={{
                                style: { transitionDelay: "0.1s" },
                            }}
                            src={images[0]}
                        />
                    </PhotoView>
                    <PhotoView src={images[1]}>
                        <LazyLoadImage
                            alt='image'
                            effect="blur"
                            wrapperProps={{
                                style: { transitionDelay: "0.1s" },
                            }}
                            src={images[1]}
                        />
                    </PhotoView>
                    <div className='more_images'>
                        {
                            moreImages.map((image, index) => (
                                <PhotoView key={index} src={image}>
                                    <LazyLoadImage
                                        alt='image'
                                        effect="blur"
                                        wrapperProps={{
                                            style: { transitionDelay: "0.1s" },
                                        }}
                                        src={image}
                                    />
                                </PhotoView>
                            ))
                        }
                    </div>
                </div>
            )
        } else {
            return (
                <div className='images_container'>
                    {
                        images?.map((image, index) => (
                            <PhotoView key={index} src={image}>
                                <LazyLoadImage
                                    alt='image'
                                    effect="blur"
                                    wrapperProps={{
                                        style: { transitionDelay: "0.1s" },
                                    }}
                                    src={image}
                                />
                            </PhotoView>
                        ))
                    }
                </div>
            )
        }
    }

    const descriptionCount = (description) => {
        let count = window.innerWidth > 1024 ? 1200 : 500
        return !redMoreOpen ? (description?.length > 200 ? description.slice(0, count) + '...' : description) : description
    }

    const copyLinkToClipboard = () => {
        const url = window.location.href
        navigator.clipboard.writeText(url)
        messageApi.open({
            type: 'success',
            content: 'Link copied to clipboard',
            duration: 2,
        });
    }

    return (
        <>
            {
                !loading ?
                    <div className='main__contaier destination section_main' >
                        {contextHolder}
                        <div className='hero top_margin_main'>
                            <Breadcrumb
                                className='breadcrumb_pages'
                                items={[
                                    {
                                        title: <div className='breadcrumb__item' onClick={() => navigate('/')}><HomeOutlined /> Home</div>,
                                    },
                                    {
                                        title: <div className='breadcrumb__item' onClick={() => navigate('/destinations')} >Destinations</div>,
                                    },
                                    {
                                        title: <div className='breadcrumb__item'
                                            onClick={() => navigate(`/destinations/${destinationId}`)}
                                        >
                                            {loading ? 'Loading...' : attraction?.destinationName}
                                        </div>,
                                    },
                                    {
                                        title: <div className='breadcrumb__item last'> Attraction: {loading ? 'Loading...' : attraction?.attractionName}</div>,
                                    }
                                ]}
                            />
                            <div className='hero__content_header'>
                                <h1 data-aos="fade-up" data-aos-delay="300" className='header_1 align_start'>
                                    {loading ? 'Loading...' : attraction?.attractionName}
                                </h1>

                                <div className='actions'>
                                    <Button onClick={copyLinkToClipboard} className='button dark' type='link'><ShareAltOutlined /></Button>
                                    <Button
                                        className='button'
                                        type='link'
                                        loading={loadingFavourite}
                                        onClick={handleOnClikFavourite}
                                    >
                                        {isFavourite
                                            ?
                                            <HeartTwoTone twoToneColor="#eb2f96" />
                                            :
                                            <HeartOutlined />}
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className='image_gallery'>
                            <PhotoProvider>
                                <div className='left'>
                                    <PhotoView src={attraction?.thumbnailUrl}>
                                        <LazyLoadImage
                                            alt='image'
                                            effect="blur"
                                            wrapperProps={{
                                                style: { transitionDelay: "0.1s" },
                                            }}
                                            src={attraction && (attraction?.thumbnailUrl || DEFAULT_THUMBNAIL)}
                                        />
                                    </PhotoView>
                                </div>
                                <div className='right'>
                                    {
                                        renderImages(attraction?.attractionImages?.split(','))
                                    }
                                </div>
                                <div className='total'>
                                    <span>{attraction?.attractionImages?.split(',').length + (attraction?.thumbnailUrl ? 1 : 0)} photos</span>
                                </div>
                            </PhotoProvider>
                        </div>

                        <div className='about_destination section'>
                            <div className='left'>
                                <h2 className='heder_2'>
                                    About {attraction?.attractionName}
                                </h2>

                                <div className='description_short'>
                                    <ReactQuill
                                        value={attraction?.attractionDescriptionShort}
                                        readOnly={true}
                                        theme='bubble'
                                    />
                                    <span
                                        onClick={() => {
                                            moreDetainsRef.current.scrollIntoView({ behavior: 'smooth' })
                                        }}
                                        className='read_more'>Read More...</span>
                                </div>


                                <div className='weather_district'>
                                    <div className='item'>
                                        <EnvironmentOutlined />
                                        <p>{attraction?.attractionCity}</p>
                                    </div>
                                    <div className='item'>
                                        <CloudOutlined />
                                        <p>
                                            {attraction?.weather}
                                        </p>
                                    </div>
                                </div>

                                <div className='tags'>
                                    {
                                        attraction?.tags?.split(',').map((tag, index) => (
                                            <Tag key={index}
                                                className={`tag ${tag === 'Must Visit' ? 'must_visit' : ''}`}
                                            >
                                                <FireOutlined />
                                                {captilizeEachWord(tag)}
                                            </Tag>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className='right'>
                                <iframe
                                    title='map'
                                    src={attraction?.mapLocation}
                                    allowfullscreen='true'
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                >
                                </iframe>
                            </div>
                        </div>

                        <div className='more_about_destination section'>
                            <h2 className='header_1'>
                                Read More about <span className='light'>{attraction?.attractionName}</span>
                            </h2>

                            <div
                                className='more_about_destination__content'
                                ref={moreDetainsRef}
                            >
                                <div
                                    className={`overlay ${redMoreOpen ? 'open' : ''}`}
                                >
                                    <Button onClick={() => setReadMoreOpen(!redMoreOpen)} className='button more' type='link'>
                                        Read More...
                                    </Button>
                                </div>
                                <ReactQuill
                                    value={descriptionCount(attraction?.attractionDescription)}
                                    readOnly={true}
                                    theme='bubble'
                                />
                                {
                                    redMoreOpen &&
                                    <Button onClick={() => setReadMoreOpen(!redMoreOpen)} className='button less' type='link'>
                                        Read Less...
                                    </Button>
                                }
                            </div>
                        </div>

                        {attraction?.rules && <div className='more_about_destination section'>
                            <h2 className='header_1'>
                                Traveler <span className='light'>Guidelines</span>
                            </h2>
                            <div className='more_about_destination__content'>
                                <ReactQuill
                                    value={attraction?.rules}
                                    readOnly={true}
                                    theme='bubble'
                                />
                            </div>
                        </div>
                        }

                        {attraction?.attractionVideos && <div className='videos section'
                            style={{
                                backgroundImage: `url(${attraction?.thumbnailUrl || DEFAULT_THUMBNAIL})`,
                            }}
                        >
                            <Carousel arrows>
                                {
                                    attraction?.attractionVideos && attraction?.attractionVideos.split(',').map((video, index) => (
                                        <div key={index}>
                                            <video controls>
                                                <source src={video} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    ))
                                }
                            </Carousel>
                        </div>}

                        {relatedBlogs?.length > 0 &&
                            <div className='blogs section'>
                                <h2 className='header_1'>
                                    Read our Blogs
                                </h2>

                                <div className='items__list' style={{ marginTop: 20 }}>
                                    <div className='section__all__destinations__content'>
                                        <Row xs={1} md={2} lg={3} xl={4} className="g-4">
                                            {!loading && relatedBlogs?.map((item, idx) => (
                                                <Col key={idx}>
                                                    <Card
                                                        data-aos="fade-up"
                                                        data-aos-delay={`${idx * 100}`}
                                                        className='package__card'
                                                        style={{
                                                            backgroundSize: 'cover',
                                                            backgroundPosition: 'center',
                                                        }}
                                                        onClick={() => navigate(`/blogs/${item?.id}`)}
                                                    >
                                                        <LazyLoadImage
                                                            src={item?.blogImages?.split(',')[0] || DEFAULT_THUMBNAIL}
                                                            alt={item?.packageName}
                                                            effect='blur'
                                                        />

                                                        <Card.Body
                                                            className='destination__card__content'
                                                        >
                                                            <h2>{item?.blogTitle}</h2>
                                                            <div className='tags'>
                                                                {
                                                                    item?.blogTags?.split(',').map((tag, idx) => (
                                                                        <Tag > {tag} </Tag>
                                                                    ))
                                                                }
                                                            </div>
                                                            <p>{item?.blogDescription ? resizeString(item?.blogDescription, 100) : 'No Description'}</p>

                                                            {item?.blogType === 'admin' ?
                                                                <div className='author'>
                                                                    <div className='author_image'>
                                                                        <LazyLoadImage
                                                                            alt='author'
                                                                            src={DEFAULT_PROFILE_IMAGE_VISIT_PEARL}
                                                                            effect='blur'
                                                                        />
                                                                    </div>
                                                                    <div className='author_name'>
                                                                        <h3>Visit Pearl</h3>
                                                                        <p>{moment(item?.createdAt, 'MMM D YYYY h:mma').format('MMMM Do YYYY')}</p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='author'>
                                                                    <div className='author_image'>
                                                                        <LazyLoadImage
                                                                            alt='author'
                                                                            src={item?.profilePicUrl || DEFAULT_PROFILE_IMAGE}
                                                                            effect='blur'
                                                                        />
                                                                    </div>
                                                                    <div className='author_name'>
                                                                        <h3>{item?.userName}</h3>
                                                                        <p>{moment(item?.createdAt, 'MMM D YYYY h:mma').format('MMMM Do YYYY')}</p>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                </div>
                            </div>}
                    </div>
                    :
                    <div className='loading_page'>
                        <LoadingComp />
                    </div>
            }
        </>
    )
}

export default Attraction