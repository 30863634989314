import axios from 'axios';
import { API_BASE_URL, API_BASE_URL_OPEN } from "../../App/azure/api";
import { getAccessToken } from '../../App/azure/auth';
import { msalInstance } from '../../App/azure/authConfig';

export const fetchAllAttractions = async (values) => {
    const params = new URLSearchParams();

    if (values.page !== undefined && values.page !== null) params.append('page', values.page);
    if (values.pageSize) {
        params.append('pageSize', values.pageSize)
    } else {
        params.append('pageSize', 5);
    }

    if (values.destinationId) params.append('destinationId', values.destinationId);
    if (values.attractionType) params.append('attractionType', values.attractionType);
    if (values.attractionCity) params.append('attractionCity', values.attractionCity);
    if (values.weather) params.append('weather', values.weather);

    const url = `${API_BASE_URL_OPEN}/getAllAttraction?${params.toString()}`;

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: url,
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getAttractionById = async (id) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL_OPEN}/getAttractionById?attractionId=${id}`,
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const fetchAttractionsNames = async () => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getAllAttractionNamesAndIds`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}