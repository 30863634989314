import React from 'react'
import { Empty } from 'antd';

import './styles.scss'

const FlightBooking = () => {
    return (
        <div className='personal_details'>
            <h1 className='heder_2'>
                Flight Booking
            </h1>
            <p className='about'>
                Flight bookings is not available yet. The feature is still under development. Please check back later.
            </p>

            <div className='personal_details__container'>
                <div className='empty'>
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description='You have not done any transactions yet.'
                    />
                </div>
            </div>
        </div>
    )
}

export default FlightBooking