import axios from 'axios';

import { API_BASE_URL, API_BASE_URL_OPEN } from "../../App/azure/api";
import { getAccessToken } from '../../App/azure/auth';
import { msalInstance } from '../../App/azure/authConfig';

export const searchEvents = async (searchTerm) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL_OPEN}/searchEvent?keyword=${searchTerm}`,
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const fetchAllEvents = async (values) => {
    const params = new URLSearchParams();

    if (values.page !== undefined && values.page !== null) params.append('page', values.page);
    if (values.pageSize) {
        params.append('pageSize', values.pageSize)
    } else {
        params.append('pageSize', 5);
    }

    if (values.destinationId) params.append('destinationId', values.destinationId);
    if (values.eventType) params.append('eventType', values.eventType);
    if (values.eventCity) params.append('eventCity', values.eventCity);
    if (values.eventStartDate) params.append('eventStartDate', values.eventStartDate);
    if (values.eventEndDate) params.append('eventEndDate', values.eventEndDate);

    const url = `${API_BASE_URL_OPEN}/getAllEvents?${params.toString()}`;

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: url,
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getEventById = async (id) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL_OPEN}/getEventById?eventId=${id}`,
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const fetchEventsNames = async () => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getAllEventsNamesAndIds`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}
