import {
    FETCH_DESTINATIONS,
    FETCH_DESTINATIONS_SUCCESS,
    FETCH_DESTINATIONS_FAIL,
} from '../../types';

const initialState = {
    destinations: [],
    loading: false,
    error: null,
};

const destinationsReducer = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case FETCH_DESTINATIONS:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_DESTINATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                destinations: action.payload,
                error: null,
            };
        case FETCH_DESTINATIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

export default destinationsReducer;