import { SearchOutlined, MenuOutlined, ExclamationCircleFilled, SettingOutlined, QuestionCircleOutlined, LogoutOutlined, LoadingOutlined, ReadOutlined, HeartOutlined, StarOutlined, BookOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Dropdown, Drawer, Modal, Spin, Badge, Popover, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { countriesArray, currencies } from '../../constants/countries';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { setSelectedCurrencyAC, setSelectedLanguageAC } from '../../../redux/actions/pageSettings/pageSettings.ac';
import { BiHome, BiHotel, BiLandscape, BiNews, BiRocket, BiTrip } from 'react-icons/bi';
import { useMsal } from '@azure/msal-react';
import { LOGIN_REQUEST } from '../../azure/authConfig';
import { DEFAULT_PROFILE_IMAGE } from '../../constants/otherConstsnts';
import useMyProfile from '../../hooks/useMyProfile';
import { useNavigate } from 'react-router-dom';
import useNotifications from '../../hooks/useNotifications';
import { fetchNewNotificationCountAC } from '../../../redux/actions/notifications/notifications.ac';
import usePageSettings from '../../hooks/usePageSettings';

const { confirm } = Modal;

const Header = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('au');
    const selectedCurrency = usePageSettings()?.selectedCurrency;
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const url = window.location.pathname
    const { instance } = useMsal();
    const [loginInProgress, setLoginInProgress] = useState(false);
    const myProfile = useMyProfile();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const newNotificationsCount = useNotifications()?.newNotificationsCount;
    const [openTourMenu, setOpenTourMenu] = useState(false);

    useEffect(() => {
        handleActiveNav();
    }, [url]);

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (myProfile?.userProfile) {
            handleGetNewNotificationsCount();
        }
    }, [myProfile]);


    const handleGetNewNotificationsCount = async () => {
        dispatch(fetchNewNotificationCountAC(myProfile?.userProfile?.id));
    }

    const handleResize = () => {
        setIsMobile(window.innerWidth < 830);
    };

    const handleScroll = () => {
        setIsScrolled(window.scrollY > 50);
    };

    const toggleDrawer = () => {
        setDrawerVisible(!drawerVisible);
    };

    const showDLogoutConfirm = () => {
        confirm({
            title: 'Do you want to logout?',
            icon: <ExclamationCircleFilled />,
            content: 'You will be logged out from the system.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                await handleLogout();
            },
        });
    };

    const items = [
        {
            key: '1',
            label: (
                <div className='my-profile' onClick={() => handleMobitelNavigate('/dashboard/home/personal-information')} href='' rel='noopener noreferrer'>
                    <img src={myProfile?.userProfile?.profilePicUrl || DEFAULT_PROFILE_IMAGE} alt='profile' />
                    {myProfile?.userProfile?.userName}
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div target="_blank" rel="noopener noreferrer" className='menu-item' onClick={() => handleMobitelNavigate(`/dashboard/inbox`)}>
                    <MailOutlined />
                    <Badge
                        count={newNotificationsCount}
                        offset={[10, 0]}
                        placement="topLeft"
                    >
                        Inbox
                    </Badge>
                </div>
            ),
        },
        {
            key: '3',
            label: (
                <div target="_blank" rel="noopener noreferrer" className='menu-item' onClick={() => handleMobitelNavigate(`/dashboard/my-bookings`)}>
                    <BookOutlined /> My Bookings
                </div>
            ),
        },
        {
            key: '4',
            label: (
                <div target="_blank" rel="noopener noreferrer" className='menu-item' onClick={() => handleMobitelNavigate(`/dashboard/my-reviews`)}>
                    <StarOutlined /> My Reviews
                </div>
            ),
        },
        {
            key: '5',
            label: (
                <div target="_blank" rel="noopener noreferrer" className='menu-item' onClick={() => handleMobitelNavigate(`/dashboard/wishlist`)}>
                    <HeartOutlined /> Wishlist
                </div>
            ),
        },
        {
            key: '6',
            label: (
                <div target="_blank" rel="noopener noreferrer" className='menu-item' onClick={() => handleMobitelNavigate(`/dashboard/my-blogs`)}>
                    <ReadOutlined /> My Blogs
                </div>
            ),
        },
        {
            key: '7',
            label: (
                <div target="_blank" rel="noopener noreferrer" className='menu-item' onClick={() => handleMobitelNavigate(`/support`)}>
                    <QuestionCircleOutlined /> Help & Support
                </div>
            ),
        },
        {
            key: '8',
            label: (
                <div target="_blank" rel="noopener noreferrer" className='menu-item' onClick={() => handleMobitelNavigate(`/dashboard/home/settings`)}>
                    <SettingOutlined /> Settings
                </div>
            ),
        },
        {
            key: '9',
            label: (
                <div className='menu-item logout' onClick={showDLogoutConfirm}>
                    <LogoutOutlined /> Logout
                </div>
            ),
        },
    ];

    // Login Handler
    const handelLogin = async () => {
        setLoginInProgress(true);
        await handleLoginRedirect();
    };

    const handleLoginRedirect = async () => {
        instance.handleRedirectPromise()
            .then(async (response) => {
                if (response) {
                    instance.setActiveAccount(response.account);
                } else {
                    instance.loginRedirect(LOGIN_REQUEST)
                        .catch((e) => {
                            console.error('User Login Error', e);
                        });
                }
            })
            .catch((error) => {
                console.error('Redirect Handling Error', error);
            });
    }

    const handleLogout = async () => {
        navigate('/');
        instance.handleRedirectPromise()
            .then(() => {
                instance.logout();
            });
    };

    const handleLaguageChange = (lang) => {
        const googleTranslateDropdown = document.querySelector('.goog-te-combo');
        if (googleTranslateDropdown) {
            googleTranslateDropdown.value = lang;
            googleTranslateDropdown.dispatchEvent(new Event('change'));
        }

        dispatch(setSelectedLanguageAC(lang));
    };

    const handleMobitelNavigate = (path) => {
        navigate(path);
        setDrawerVisible(false);
    }

    const handleActiveNav = () => {
        const navs = document.querySelectorAll('.nav__item');
        navs.forEach((nav) => {
            nav.classList.remove('active');
            if (nav.classList.contains(`nav_${url.split('/')[1]}`)) {
                nav.classList.add('active');
            } else if (url === '/' && nav.classList.contains('nav_home')) {
                nav.classList.add('active');
            } else {
                nav.classList.remove('active');
            }
        });
    }

    const renderTourMenu = () => {
        return (
            <div className='tour_menu'>
                <div className='item tours' onClick={() => {
                    setOpenTourMenu(false);
                    navigate('/tours')
                }}>
                    <div className='icon'>
                        <img src='https://visitpearlsharedblob.blob.core.windows.net/appassets/made_tour_icon.gif' alt='tour' />
                    </div>
                    <div className='text'>
                        <p>Explore Packages</p>
                        <span>Discover our handpicked tours for a seamless travel experience.</span>
                    </div>
                </div>
                <div className='item custom-tours' onClick={() => {
                    setOpenTourMenu(false);
                    navigate('/custom-tours')
                }}>
                    <div className='icon'>
                        <img src='https://visitpearlsharedblob.blob.core.windows.net/appassets/custom_tour_icon.gif' alt='tour' />
                    </div>
                    <div className='text'>
                        <p>Plan Your Adventure</p>
                        <span>Craft your own journey, tailored to your unique preferences.</span>
                    </div>
                </div>
            </div>
        );
    }

    const renderDesktopHeader = () => (
        <div className={`header__desktop ${isScrolled ? 'scroll' : ''}`}>
            <div className="header__desktop__inner">
                <div className="left">
                    <div className="header__desktop__logo">
                        <img src="https://visitpearlsharedblob.blob.core.windows.net/appassets/weblogo.webp" alt="logo" />
                    </div>
                    <div className="header__desktop__menu">
                        <div
                            className="nav__item active nav_home"
                            onClick={() => navigate('/')}
                        >
                            Home
                        </div>
                        <div
                            className="nav__item nav_destinations"
                            onClick={() => navigate('/destinations')}
                        >
                            Destinations
                        </div>
                        <div
                            className="nav__item nav_tours nav_custom-tours"
                        >
                            <Popover
                                placement="bottom"
                                content={renderTourMenu()}
                                trigger='hover'
                                rootClassName='tour_menu_popover'
                                open={openTourMenu}
                                onVisibleChange={(visible) => setOpenTourMenu(visible)}
                            >
                                Tours
                            </Popover>
                        </div>
                        <div
                            className="nav__item nav_accommodation"
                            onClick={() => navigate('/accommodations')}
                        >
                            Accommodations
                        </div>
                        <div
                            className="nav__item nav_flights"
                            onClick={() => navigate('/flights')}
                        >
                            Flights
                        </div>
                        <div
                            className="nav__item nav_blogs"
                            onClick={() => navigate('/blogs')}
                        >
                            Blog
                        </div>
                    </div>
                </div>
                <div className="header__desktop__actions">
                    <Dropdown
                        menu={{
                            items: currencies.map((currency) => ({
                                key: currency.name,
                                value: currency.name,
                                label: (
                                    <div
                                        className="country_select_menu_item"
                                        onClick={() => {
                                            dispatch(setSelectedCurrencyAC(currency.name));
                                        }}
                                    >
                                        {currency.name}
                                    </div>
                                ),
                            })),
                        }}
                    >
                        <div className="currency_selector">
                            {selectedCurrency}
                        </div>
                    </Dropdown>
                    <Divider type="vertical" />
                    <Dropdown
                        menu={{
                            items: countriesArray.map((country) => ({
                                key: country.code,
                                value: country.code,
                                label: (
                                    <div
                                        className="country_select_menu_item"
                                        onClick={() => {
                                            setSelectedCountry(country.code);
                                            handleLaguageChange(country.lang);
                                        }}
                                    >
                                        <img src={`https://flagcdn.com/w20/${country.code}.png`} alt={country.name} />
                                        {country.lang.toUpperCase()}
                                    </div>
                                ),
                            })),
                        }}
                    >
                        <div className="country_selector">
                            <img src={`https://flagcdn.com/w40/${selectedCountry.toLowerCase()}.png`} alt={selectedCountry} />
                        </div>
                    </Dropdown>
                    <>
                        {myProfile?.loading ?
                            <>
                                <Spin indicator={<LoadingOutlined spin />} size="large" />
                            </>
                            :
                            <>
                                {
                                    myProfile?.userProfile ?
                                        <Dropdown
                                            menu={{ items, }}
                                            className='profile-dropdown'
                                            overlayClassName='profile-dropdown'
                                            trigger={['click']}
                                        >
                                            <Badge count={newNotificationsCount}>
                                                <div className='profile-button'>
                                                    <img src={myProfile?.userProfile?.profilePicUrl || DEFAULT_PROFILE_IMAGE} alt='profile' />
                                                </div>
                                            </Badge>
                                        </Dropdown>
                                        :
                                        <Button loading={loginInProgress} onClick={handelLogin} type="primary" className="custom_primary_btn">Sign In</Button>
                                }
                            </>
                        }
                    </>
                </div>
            </div>
        </div>
    );

    const renderMobileHeader = () => (
        <div className="header__mobile">
            <div className="header__mobile__inner">
                <Badge count={newNotificationsCount}>
                    <MenuOutlined onClick={toggleDrawer} />
                </Badge>
                <div className="header__mobile__logo">
                    <img src="https://visitpearlsharedblob.blob.core.windows.net/appassets/weblogo.webp" alt="logo" />
                </div>
                <SearchOutlined />
            </div>
            <Drawer
                title=""
                placement="left"
                onClose={toggleDrawer}
                visible={drawerVisible}
                style={{
                    backgroundImage: 'url("https://visitpearlsharedblob.blob.core.windows.net/appassets/mobile_nav_bg.webp")',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    borderTopRightRadius: '30px',
                    borderBottomRightRadius: '30px',
                }}
                width={360}
                rootClassName='mobile_nav_drawer_root'
            >
                <div className='menu_items_mobile'>
                    <div
                        className="nav__item active nav_home"
                        onClick={() => handleMobitelNavigate('/')}
                    >
                        <BiHome /> Home
                    </div>
                    <div
                        className="nav__item nav_destinations"
                        onClick={() => handleMobitelNavigate('/destinations')}
                    >
                        <BiLandscape /> Destinations
                    </div>
                    <div
                        className="nav__item nav_tours"
                        onClick={() => handleMobitelNavigate('/tours')}
                    >
                        <BiTrip /> Tours
                    </div>
                    <div
                        className="nav__item nav_accommodation"
                        onClick={() => handleMobitelNavigate('/accommodations')}
                    >
                        <BiHotel /> Accommodations
                    </div>
                    <div
                        className="nav__item nav_flights"
                        onClick={() => handleMobitelNavigate('/flights')}
                    >
                        <BiRocket /> Flights
                    </div>
                    <div
                        className="nav__item nav_blogs"
                        onClick={() => handleMobitelNavigate('/blogs')}
                    >
                        <BiNews /> Blog
                    </div>


                    <div className='country_currency'>
                        <Dropdown
                            bottom
                            rootClassName='mobile_nav_dropdown_lang'
                            menu={{
                                items: countriesArray.map((country) => ({
                                    key: country.code,
                                    value: country.code,
                                    label: (
                                        <div
                                            className="country_select_menu_item"
                                            onClick={() => {
                                                setSelectedCountry(country.code);
                                                handleLaguageChange(country.lang);
                                                setDrawerVisible(false);
                                            }}
                                        >
                                            <img src={`https://flagcdn.com/w20/${country.code}.png`} alt={country.name} />
                                            <span>
                                                {country.lang.toUpperCase()}
                                            </span>
                                        </div>
                                    ),
                                })),
                            }}
                        >
                            <div className="country_selector">
                                <img src={`https://flagcdn.com/w40/${selectedCountry.toLowerCase()}.png`} alt={selectedCountry} />
                            </div>
                        </Dropdown>
                        <Divider type="vertical" />
                        <Dropdown
                            menu={{
                                items: currencies.map((currency) => ({
                                    key: currency.name,
                                    value: currency.name,
                                    label: (
                                        <div
                                            className="country_select_menu_item"
                                            onClick={() => {
                                                dispatch(setSelectedCurrencyAC(currency.name));
                                                setDrawerVisible(false);
                                            }}
                                        >
                                            {currency.name}
                                        </div>
                                    ),
                                })),
                            }}
                        >
                            <div className="currency_selector">
                                {selectedCurrency}
                            </div>
                        </Dropdown>
                    </div>

                    <>
                        {myProfile?.loading ?
                            <>
                                <Spin indicator={<LoadingOutlined spin />} size="large" />
                            </>
                            :
                            <>
                                {
                                    myProfile?.userProfile ?
                                        <Dropdown
                                            menu={{ items, }}
                                            className='profile-dropdown'
                                            overlayClassName='profile-dropdown'
                                            trigger={['click']}
                                        >
                                            <div className='profile-button'>
                                                <Badge count={newNotificationsCount}>
                                                    <img src={myProfile?.userProfile?.profilePicUrl || DEFAULT_PROFILE_IMAGE} alt='profile' />
                                                </Badge>
                                                <div className='profile-name'>
                                                    <p>{myProfile?.userProfile?.userName}</p>
                                                    <span>{myProfile?.userProfile?.email}</span>
                                                </ div>
                                            </div>
                                        </Dropdown>
                                        :
                                        <Button loading={loginInProgress} onClick={handelLogin} type="primary" className="custom_primary_btn__mobile">Sign In</Button>
                                }
                            </>
                        }
                    </>
                </div>
            </Drawer>
        </div>
    );

    return (
        <>
            {isMobile ? renderMobileHeader() : renderDesktopHeader()}
        </>
    );
};

export default Header;
