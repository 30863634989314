import React, { useEffect, useState } from 'react'

import './styles.scss'
import { Route, Routes, useNavigate } from 'react-router-dom';
import DashboardHome from './DashboardHome';
import useMyProfile from '../../hooks/useMyProfile';
import { useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { fetchProfileAC } from '../../../redux/actions/myProfile/myProfile.ac';
import MyBookings from './MyBookings';
import WishList from './WishList';
import MyReviews from './MyReviews';
import Inbox from './Inbox';
import MyBlogs from './MyBlogs';
import Blog from './MyBlogs/Blog';
import EditBlog from './MyBlogs/EditBlog';

const Dashboard = () => {
    const myProfile = useMyProfile();
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [logedInUser, setLogedInUser] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!activeAccount) {
            navigate('/');
        } else {
            setLogedInUser(true);
        }
    }, [activeAccount]);

    useEffect(() => {
        if (logedInUser && !myProfile?.userProfile && !myProfile?.loading) {
            fetchProfile();
        }
    }, [logedInUser]);

    const fetchProfile = async () => {
        try {
            dispatch(fetchProfileAC());
        } catch (error) {
            console.log('ERROR FETCHING PROFILE', error);
        }
    }

    return (
        <div className='dashboard top_padding_container'>
            <div className='section_main'>
                <Routes>
                    <Route path="/home/*" element={<DashboardHome />} />
                    <Route path="/my-bookings/*" element={<MyBookings />} />
                    <Route path='/my-reviews' element={<MyReviews />} />
                    <Route path='/inbox' element={<Inbox />} />
                    <Route path="/my-blogs" element={<MyBlogs />} />
                    <Route path="/my-blogs/:id" element={<Blog />} />
                    <Route path="/my-blogs/edit/:id" element={<EditBlog />} />
                    <Route path="wishlist" element={<WishList />} />
                </Routes>
            </div>
        </div>
    )
}

export default Dashboard