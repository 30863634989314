import { ArrowLeftOutlined, ArrowRightOutlined, SmileOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Modal, Rate, Result, Slider, Steps } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import './styles.scss';
import moment from 'moment';
import { createCustomTour } from '../../../../../redux/api/userTour.api';
import useMyProfile from '../../../../hooks/useMyProfile';
import { useNavigate } from 'react-router-dom';

const ReviewAndConf = ({
    next,
    prev,
    setUserTourData,
    userTourData,
    notificationApi,
    current,
}) => {
    const formRef = useRef(null);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [submitPopup, setSubmitPopup] = useState(false);
    const myProfile = useMyProfile().userProfile;
    const navigate = useNavigate();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSubmit = async () => {
        await handleLoadingSubmit();
    };

    const handleLoadingSubmit = async () => {
        setLoadingSubmit(true);

        const requestedDestinations = userTourData.favoriteDestinations.map((destination) => destination.id);

        const data = {
            userId: myProfile?.id,
            packageName: `${myProfile?.userName}'s ${userTourData?.duration} Days Custom Tour`,
            requestedDestinations: requestedDestinations.join(','),
            accommodationType: userTourData?.accommodationStayType,
            accommodationRating: userTourData?.accommodationStarRating,
            roomType: userTourData?.accommodationType,
            vehicleType: userTourData?.vehicleType,
            favItems: JSON.stringify(userTourData?.favourites),
            startDate: moment(userTourData.startDate.$d).format('YYYY-MM-DD'),
            durationNights: userTourData?.duration,
            destinationSpecifications: userTourData?.destinationSpecifics || 'None',
            transportSpecifications: userTourData?.transportSpecifics || 'None',
            otherSpesifications: userTourData?.preferencesSpecifics || 'None',
            tourGuideLanguage: userTourData?.tourGuideLanguage || 'English',
            insuarenceId: userTourData?.insuarenceId,
            travellers: JSON.stringify(userTourData?.travellers),
        }

        const response = await createCustomTour(data);
        console.log('response', response);
        if (response.status === 200) {
            setSubmitPopup(true);
            notificationApi.success({
                message: 'Tour Request Submitted',
                description: 'Your tour request has been submitted successfully. Our experts will review your request and get back to you with the best possible itinerary.',
            });
        } else {
            notificationApi.error({
                message: 'Error',
                description: 'Something went wrong. Please try again later.',
            });
        }

        setLoadingSubmit(false);
    }

    return (
        <div className='custom_tour_start_planing'>
            <h1 className='header_1 section_header'>
                Review & Confirm
            </h1>
            <p className=' main_para'>
                Please review your tour details below. If you need to make any changes, you can go back and edit your preferences. Once you are happy with your tour details, click the 'Confirm' button to submit your request.
            </p>

            <Divider />

            <section className='custom_tour_start_planing__form details_selector'>
                <div className='review_section'>
                    <h3>Tour Start Date:</h3>
                    <p>{moment(userTourData.startDate.$d).format('YYYY-MM-DD')}</p>
                </div>
                <div className='review_section'>
                    <h3>Duration:</h3>
                    <p>{userTourData.duration} Nights</p>
                </div>
                {
                    console.log('userTourData', userTourData)
                }
                <div className='review_section'>
                    <h3>Travelers:</h3>
                    <p>{
                        userTourData.travellers.adults +
                        userTourData.travellers.children +
                        userTourData.travellers.infants
                    } Travelers - {userTourData.travellers.adults ? userTourData.travellers.adults + ' Adults' : ''} {userTourData.travellers.children ? userTourData.travellers.children + ' Children' : ''} {userTourData.travellers.infants ? userTourData.travellers.infants + ' Infants' : ''}
                    </p>
                </div>
                <div className='review_section'>
                    <h3>Destinations: </h3>
                    <div className='tags'>
                        {userTourData.favoriteDestinations.map((destination, index) => (
                            <span
                                key={index}
                                onClick={() => {
                                    window.open(`/destinations/${destination?.id}`, '_blank');
                                }}
                            >{destination?.destinationName}</span>
                        ))}
                    </div>
                </div>
                <div className='review_section'>
                    <h3>
                        Preferred Accommodation Type:
                    </h3>
                    <p>{userTourData?.accommodationStayType}</p>
                </div>
                <div className='review_section'>
                    <h3>
                        Accommodation Star Rating:
                    </h3>
                    <Rate disabled defaultValue={userTourData?.accommodationStarRating} />
                </div>
                <div className='review_section'>
                    <h3>
                        Preferred Room Type:
                    </h3>
                    <p>{userTourData?.accommodationType}</p>
                </div>
                <div className='review_section'>
                    <h3>
                        Preferred Vehicle Type:
                    </h3>
                    <p>{userTourData?.vehicleType}</p>
                </div>
                <div className='review_section'>
                    <h3>
                        Special Destination Requests:
                    </h3>
                    <p>{userTourData?.destinationSpecifics || 'None'}</p>
                </div>
                <div className='review_section'>
                    <h3>
                        Special Transport Requests:
                    </h3>
                    <p>{userTourData?.transportSpecifics || 'None'}</p>
                </div>
                <div className='review_section'>
                    <h3>
                        Other Special Requests:
                    </h3>
                    <p>{userTourData?.preferencesSpecifics || 'None'}</p>
                </div>
                <div className='review_section_fav'>
                    <h3>
                        Tour Favorites:
                    </h3>
                    <div className='tags'>
                        <div className='item'>
                            <h4>Beaches:</h4>
                            <Slider value={userTourData?.favourites?.beaches} min={1} max={5} disabled label='Beaches' />
                            <h4>{userTourData?.favourites?.beaches}</h4>
                        </div>
                        <div className='item'>
                            <h4>Wildlife:</h4>
                            <Slider value={userTourData?.favourites?.wildlife} min={1} max={5} disabled label='Beaches' />
                            <h4>{userTourData?.favourites?.wildlife}</h4>
                        </div>
                        <div className='item'>
                            <h4>Historical Sites:</h4>
                            <Slider value={userTourData?.favourites?.historialSites} min={1} max={5} disabled label='Beaches' />
                            <h4>{userTourData?.favourites?.historialSites}</h4>
                        </div>
                        <div className='item'>
                            <h4>Adventure:</h4>
                            <Slider value={userTourData?.favourites?.adventure} min={1} max={5} disabled label='Beaches' />
                            <h4>{userTourData?.favourites?.adventure}</h4>
                        </div>
                        <div className='item'>
                            <h4>Relaxation:</h4>
                            <Slider value={userTourData?.favourites?.relaxation} min={1} max={5} disabled label='Beaches' />
                            <h4>{userTourData?.favourites?.relaxation}</h4>
                        </div>
                        <div className='item'>
                            <h4>Cultural:</h4>
                            <Slider value={userTourData?.favourites?.cultural} min={1} max={5} disabled label='Beaches' />
                            <h4>{userTourData?.favourites?.cultural}</h4>
                        </div>
                        <div className='item'>
                            <h4>Wellness & Spa:</h4>
                            <Slider value={userTourData?.favourites?.wellnessAndSpa} min={1} max={5} disabled label='Beaches' />
                            <h4>{userTourData?.favourites?.wellnessAndSpa}</h4>
                        </div>
                        <div className='item'>
                            <h4>Festival & Events:</h4>
                            <Slider value={userTourData?.favourites?.festivalAndEvents} min={1} max={5} disabled label='Beaches' />
                            <h4>{userTourData?.favourites?.festivalAndEvents}</h4>
                        </div>
                    </div>
                </div>
            </section>

            <Form
                onFinish={handleSubmit}
                scrollToFirstError
                onFinishFailed={() => {
                    notificationApi.error({
                        message: 'Missing Fields',
                        description: 'Please fill all the required fields',
                    });
                }}
                ref={formRef}
            >

                <section className='custom_tour_start_planing__form agrreement_selector'>
                    <h1 className='heder_2'>
                        Confirm Your Tour Details
                    </h1>
                    <p className=' main_para'>
                        Please confirm that the above details are correct and you would like to proceed with your tour request.
                    </p>
                    <Form.Item
                        name='confirmAgreement'
                        valuePropName="checked"
                        rules={[{ required: true, message: 'Please accept the terms and conditions' }]}
                    >
                        <Checkbox className='agreemnet'>
                            I confirm that the above details are correct and I would like to proceed with my tour request. <a href='/privacy-policy' target='_blank' >Privacy Policy</a> and <a href='/terms-and-conditions' target='_blank' >Terms & Conditions</a> apply.
                        </Checkbox>
                    </Form.Item>
                </section>


                <section className='custom_tour_start_planing__form'>
                    <Form.Item>
                        <div className='button_wrapper'>

                            <Button
                                onClick={prev}
                                className='primary_btn_default'
                                disabled={current === 0}
                            >
                                <ArrowLeftOutlined /> Previous
                            </Button>

                            {current < 4 ?
                                <Button
                                    type='primary'
                                    className='custom_primary_btn_2'
                                    htmlType='submit'
                                >
                                    Next <ArrowRightOutlined />
                                </Button>
                                :
                                <Button
                                    type='primary'
                                    className='custom_primary_btn_2'
                                    htmlType='submit'
                                    loading={loadingSubmit}
                                >
                                    Confirm <SmileOutlined />
                                </Button>
                            }
                        </div>
                    </Form.Item>
                </section>
            </Form>

            <Modal
                title="Confirm Booking"
                rootClassName='tour_booking_modal'
                open={submitPopup}
                closable={false}
                onOk={() => setSubmitPopup(false)}
                footer={[
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => {
                            setSubmitPopup(false)
                            navigate('/dashboard/my-bookings')
                        }}
                    >
                        Ok
                    </Button>,
                ]}
            >
                <p>
                    Your booking is confirmed. Please make the payment to the following bank account to complete the booking process.
                </p>
                <Steps
                    direction="vertical"
                    current={0}
                    items={[
                        {
                            title: 'Request Submitted',
                            description: 'Your tour request has been submitted successfully.',
                        },
                        {
                            title: 'Expert Review',
                            description: 'Our experts will review your request and get back to you with the best possible itinerary.',
                        },
                        {
                            title: 'Your Confirmation',
                            description: 'You will receive the final itinerary and the payment details to your email.',
                        },
                        {
                            title: 'Enjoy Your Tour',
                            description: 'Once the payment is done, you are all set to enjoy your tour.',
                        }
                    ]}
                />
            </Modal>

        </div>
    );
};

export default ReviewAndConf;