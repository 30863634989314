import React, { useEffect, useState } from 'react'
import useMyProfile from '../../../hooks/useMyProfile';
import { Button, notification, Popconfirm, Rate, Skeleton, Tooltip } from 'antd';
import { CheckCircleFilled, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import './styles.scss'
import AddReview from './AddReview';
import { deleteReview, getReviewsByUser } from '../../../../redux/api/reviews.api';
import { Card } from 'react-bootstrap';
import EditReview from './EditReview';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { handleDeleteBlob, USER_POSTS } from '../../../azure/blob';

const getTypeName = (value) => {
    value = value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
    return value.replace('Rev', '');
};

const MyReviews = () => {
    const myProfile = useMyProfile();
    const [openAddReview, setOpenAddReview] = useState(false);
    const [openEditReview, setOpenEditReview] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(false);
    const [seletedReview, setSelectedReview] = useState(null);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        if (myProfile?.userProfile) {
            handlefetchReviews();
        }
    }, [myProfile]);

    const handlefetchReviews = async () => {
        setLoading(true);
        const response = await getReviewsByUser(myProfile?.userProfile?.id);
        if (response.status === 200) {
            setReviews(response.data);
        }
        setLoading(false);
    }

    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl.split('/')
        return splitUrl[splitUrl.length - 1]
    }


    const handleDeleteReview = async (review) => {
        let images = review.images ? review.images.split(',') : [];

        if (images.length > 0) {
            const imageDeletePromises = images.map(async (image, index) => {
                const blobName = getBlobName(image);
                return handleDeleteBlob(USER_POSTS, blobName);
            });
            await Promise.all(imageDeletePromises);
        }

        const response = await deleteReview(review?.id);
        if (response.status === 200) {
            api.success({
                message: 'Review Deleted',
                description: 'You have successfully deleted the review',
            });
            handlefetchReviews();
        } else {
            api.error({
                message: 'Error',
                description: 'An error occured while deleting the review',
            });
        }
    }

    return (
        <div className='main__contaier my_reviews'>
            {contextHolder}
            <div className='header'>
                <h2 className='header_1' style={{ marginBottom: '20px' }}>Your Reviews</h2>
                <Button
                    className='custom_primary_btn'
                    onClick={() => setOpenAddReview(true)}
                ><PlusOutlined /> Add Review</Button>
            </div>

            <div className='reviews'>
                {
                    loading &&
                    Array(3).fill(0).map((_, index) => (
                        <Card key={index} className='paymentLoading' style={{
                            marginBottom: '10px',
                            border: 'none',
                            padding: '20px',
                        }}>
                            <Skeleton active />
                        </Card>
                    ))
                }
                {
                    !loading && reviews.length > 0 &&
                    reviews.map((review, index) => (
                        <Card key={index} className='review_card'>
                            <div className='actions'>
                                <Popconfirm
                                    title='Are you sure to delete this review?'
                                    onConfirm={() => handleDeleteReview(review)}
                                    okText='Yes'
                                    cancelText='No'
                                >
                                    <Button loading={loadingDelete} danger type='link'><DeleteOutlined /></Button>
                                </Popconfirm>
                                <Button type='link'
                                    onClick={() => {
                                        setOpenEditReview(true)
                                        setSelectedReview(review)
                                    }}
                                ><EditOutlined /></Button>
                            </div>
                            <div className='review_card__header'>
                                <h3>{review?.ratedForItem?.name}</h3>
                                <Tooltip title={review?.isVerified ? 'Verified' : 'Pending Verification'}>
                                    <CheckCircleFilled style={{ fontSize: '20px', color: review?.isVerified ? '#52C41A' : 'gray' }} />
                                </Tooltip>
                            </div>
                            <div className='review_card__body'>
                                <h5>{review?.ratingType && getTypeName(review?.ratingType)}</h5>
                                <Rate disabled value={review?.ratingValue} />
                                <p>{review?.ratingDescription}</p>

                                <div className='images'
                                    style={{
                                        display: 'flex',
                                        gap: '10px',
                                        marginTop: '10px',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <PhotoProvider>
                                        <PhotoView
                                            src={review?.images?.split(',')[0]}>
                                        </PhotoView>
                                        {
                                            review?.images?.split(',').map((image, index) => (
                                                <LazyLoadImage
                                                    key={index}
                                                    src={image}
                                                    alt='review_image'
                                                    effect='blur'
                                                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                                />
                                            ))
                                        }
                                    </PhotoProvider>
                                </div>
                            </div>
                        </Card>
                    ))
                }
            </div>

            <AddReview
                isOpen={openAddReview}
                setOpen={setOpenAddReview}
                handleGetReviews={handlefetchReviews}
                ratingType={null}
                ratedFor={null}
            />

            <EditReview
                isOpen={openEditReview}
                setOpen={setOpenEditReview}
                handleGetReviews={handlefetchReviews}
                seletedReview={seletedReview}
            />
        </div>
    )
}

export default MyReviews