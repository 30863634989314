import React, { useEffect, useState } from 'react'
import { accommodationTypes, activityTypes, experienceTypes } from '../../constants/itemTypes';
import { Button, Checkbox, message, Progress } from 'antd';

import './styles.scss'
import { ArrowRightOutlined, LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { editUserProfile } from '../../../redux/api/userProfile.api';
import useMyProfile from '../../hooks/useMyProfile';
import { useDispatch } from 'react-redux';
import { fetchProfileAC } from '../../../redux/actions/myProfile/myProfile.ac';

const items = [
    {
        key: '1',
        question: 'What type of traveler are you?',
        options: [
            {
                key: experienceTypes.ADVENTURE,
                value: 'Adventure Seeker'
            },
            {
                key: experienceTypes.CULTURE,
                value: 'Culture Enthusiast'
            },
            {
                key: experienceTypes.NATURE,
                value: 'Nature Lover'
            },
            {
                key: experienceTypes.RELAXATION,
                value: 'Relaxation Seeker'
            },
            {
                key: experienceTypes.FAMILY,
                value: 'Family Traveler'
            }
        ]
    },
    {
        key: '2',
        question: 'What is your preferred type of accommodation?',
        options: [
            {
                key: accommodationTypes.LUXURY_HOTEL,
                value: 'Luxury Hotel'
            },
            {
                key: accommodationTypes.BOUTIQUE_HOTEL,
                value: 'Hostel'
            },
            {
                key: accommodationTypes.BUDGET_HOTEL,
                value: 'Budget Hotel'
            },
            {
                key: accommodationTypes.VILLA,
                value: 'Villa'
            },
            {
                key: accommodationTypes.HOMESTAY,
                value: 'Homestay'
            }
        ]
    },
    {
        key: '3',
        question: 'Which activities do you enjoy?',
        options: [
            {
                key: activityTypes.HIKING,
                value: 'Hiking & Trekking'
            },
            {
                key: activityTypes.HISTORICAL,
                value: 'Historical & Cultural Sites'
            },
            {
                key: activityTypes.LEISURE,
                value: 'Leisure & Relaxation'
            },
            {
                key: activityTypes.WILDLIFE,
                value: 'Wildlife & Nature'
            },
            {
                key: activityTypes.WELLNESS,
                value: 'Wellness & Spa'
            }
        ]
    }
]

const Onboarding = () => {
    const [loading, setLoading] = useState(false);
    const [loadingSkip, setLoadingSkip] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [q1Answers, setQ1Answers] = useState([]);
    const [q2Answers, setQ2Answers] = useState([]);
    const [q3Answers, setQ3Answers] = useState([]);
    const [warning, setWarning] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const myProfile = useMyProfile().userProfile;

    useEffect(() => {
        if (!myProfile) {
            navigate('/');
        } else if (myProfile?.favouriteTravelTypes || myProfile?.favouriteAccommodationTypes || myProfile?.favouriteExperienceType) {
            navigate('/');
        }
    }, [myProfile])


    const handleSetupFinish = async () => {
        setLoading(true);

        if (q1Answers.length === 0 || q2Answers.length === 0 || q3Answers.length === 0) {
            setWarning('Please select an answer to proceed.');
            return;
        }

        try {
            const data = {
                favouriteTravelTypes: q1Answers.join(','),
                favouriteAccommodationTypes: q2Answers.join(','),
                favouriteExperienceType: q3Answers.join(',')
            }

            const res = await editUserProfile(data);

            if (res.status === 200) {
                messageApi.success('Account setup completed successfully.');
                dispatch(fetchProfileAC());
                localStorage.setItem('vp-onboarding', 'done');
                navigate('/');
            } else {
                messageApi.error('Failed to complete account setup.');
            }

        } catch (error) {
            console.error(error);
            messageApi.error('Failed to complete account setup.');
        }

        setLoading(false);
    }

    const handleOnClickNext = () => {
        if (currentQuestion === 1 && q1Answers.length === 0) {
            setWarning('Please select an answer to proceed.');
            return;
        } else if (currentQuestion === 2 && q2Answers.length === 0) {
            setWarning('Please select an answer to proceed.');
            return;
        } else if (currentQuestion === 3 && q3Answers.length === 0) {
            setWarning('Please select an answer to proceed.');
            return;
        }

        setCurrentQuestion(currentQuestion + 1);
    }

    const skip = async () => {
        setLoadingSkip(true);
        try {
            const data = {
                favouriteTravelTypes: experienceTypes.ADVENTURE,
                favouriteAccommodationTypes: accommodationTypes.LUXURY_HOTEL,
                favouriteExperienceType: activityTypes.HIKING
            }

            const res = await editUserProfile(data);

            if (res.status === 200) {
                dispatch(fetchProfileAC());
                localStorage.setItem('vp-onboarding', 'done');
                navigate('/');
            } else {
                messageApi.error('Failed to complete account setup.');
            }

        } catch (error) {
            console.error(error);
            messageApi.error('Failed to complete account setup.');
        }

        setLoadingSkip(false);
    }

    return (
        <>
            {contextHolder}
            <div className='onboarding__page'>
                <div className='header'>
                    <img src="https://visitpearlsharedblob.blob.core.windows.net/appassets/weblogo.webp" alt="logo" />
                </div>

                <div className='onboarding__content'>
                    <h1>Let's finish your account setup.</h1>

                    <div className='middle'>
                        <h1>
                            {`Question ${currentQuestion}`}
                        </h1>

                        <h3>
                            {items[currentQuestion - 1].question}
                        </h3>

                        <Checkbox.Group
                            onChange={(values) => {
                                if (currentQuestion === 1) {
                                    setQ1Answers(values);
                                } else if (currentQuestion === 2) {
                                    setQ2Answers(values);
                                } else {
                                    setQ3Answers(values);
                                }

                                setWarning(null);
                            }}
                        >
                            {items[currentQuestion - 1].options.map(option => (
                                <div key={option.key}>
                                    <Checkbox value={option.key}>{option.value}</Checkbox>
                                </div>
                            ))}
                        </Checkbox.Group>

                        {warning && <p className='warning'>{warning}</p>}

                        <div className='buttons'>
                            {currentQuestion > 1 && <Button className='prev' onClick={() => setCurrentQuestion(currentQuestion - 1)}><LeftOutlined /></Button>}
                            {currentQuestion < 3 && <Button type='primary' className='def' onClick={handleOnClickNext}>Next</Button>}

                            {currentQuestion === 3 &&
                                <Button
                                    type='primary'
                                    className='def'
                                    onClick={handleSetupFinish}
                                    loading={loading}
                                >
                                    Finish
                                </Button>}
                        </div>
                    </div>

                    <Progress
                        percent={(currentQuestion / 3) * 100}
                        percentPosition={{
                            align: 'start',
                            type: 'outer',
                        }}
                        size={[400, 15]}
                        format={() => `STEP ${currentQuestion} OUT OF 3`}
                        strokeColor="#67DCD3"
                    />
                </div>

                {<div className='bottom'>
                    <Button
                        onClick={skip}
                        loading={loadingSkip}
                    >
                        Skip <ArrowRightOutlined /></Button>
                </div>}
            </div>
        </>
    )
}

export default Onboarding