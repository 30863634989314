import axios from 'axios';
import { getAccessToken } from '../../App/azure/auth';
import { msalInstance } from '../../App/azure/authConfig';
import { API_BASE_URL } from '../../App/azure/api';

export const signupUser = async () => {
    const ipAddress = localStorage.getItem('vp-ip');
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/signupUser?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        return error;
    }
}

export const fetchProfile = async () => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getUserData`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    const response = await axios(config);
    return response;
}


export const checkUserAvailability = async () => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/checkUserAvailability`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    const response = await axios(config);
    return response;
}

export const editUserProfile = async (data) => {
    const ipAddress = localStorage.getItem('vp-ip');
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/editUserProfile?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: JSON.stringify(data)
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const deleteUserPermanently = async (userId) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/deleteUserPermanently?userId=${userId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const createUserGroup = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/createUserGroup?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getGroupByUserId = async (userId) => {
    const token = await getAccessToken(msalInstance);
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${API_BASE_URL}/getGroupByUserId?userId=${userId}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        const response = await axios(config);
        return response;
    } catch (error) {
        return error;
    }
}

export const getGroupMembers = async (groupId) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getGroupMembers?groupId=${groupId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const removeMemberFromGroup = async (groupId, userId) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/removeMemberFromGroup?clientIp=${ipAddress}&groupId=${groupId}&userId=${userId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const addUserToGroup = async (groupId, email) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/addUserToGroup?clientIp=${ipAddress}&groupId=${groupId}&email=${email}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const acceptGroupInvitation = async (userId, groupMenberId, groupId, status) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/acceptGroupInvitation?clientIp=${ipAddress}&userId=${userId}&groupMenberId=${groupMenberId}&groupId=${groupId}&status=${status}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const deleteUserGroup = async (groupId) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/deleteUserGroup?clientIp=${ipAddress}&groupId=${groupId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getPaymentsByUser = async (userId) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getPaymentsByUser?id=${userId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const editUserPreferences = async (data) => {
    const ipAddress = localStorage.getItem('vp-ip');
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/editUserPreferences?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: JSON.stringify(data)
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}
