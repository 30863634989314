export const cities = [
    "Colombo",
    "Kandy",
    "Galle",
    "Jaffna",
    "Negombo",
    "Anuradhapura",
    "Trincomalee",
    "Ratnapura",
    "Matara",
    "Kurunegala",
    "Badulla",
    "Nuwara Eliya",
    "Batticaloa",
    "Polonnaruwa",
    "Hambantota",
    "Mannar",
    "Vavuniya",
    "Puttalam",
    "Chilaw",
    "Kalutara",
    "Kilinochchi",
    "Ampara",
    "Monaragala",
    "Kegalle",
    "Dambulla",
    "Dehiwala",
    "Moratuwa",
    "Sri Jayawardenepura Kotte",
    "Wattala",
    "Gampaha",
    "Katunayake",
    "Panadura",
    "Beruwala",
    "Ambalangoda",
    "Hikkaduwa",
    "Kalmunai",
    "Valvettithurai",
    "Point Pedro",
    "Kataragama",
    "Embilipitiya",
    "Mawanella",
    "Hatton",
    "Talawakele",
    "Haputale",
    "Bandarawela",
    "Piliyandala",
    "Horana",
    "Boralesgamuwa",
    "Kadawatha",
    "Ja-Ela",
    "Weligama",
    "Mirissa",
    "Tangalle",
    "Aluthgama",
    "Balangoda",
    "Avissawella",
    "Homagama",
    "Maharagama",
    "Kelaniya",
    "Galgamuwa",
    "Mihintale",
    "Sigiriya",
    "Habarana",
    "Pannipitiya",
    "Kotmale",
    "Eheliyagoda",
    "Pelmadulla",
    "Deniyaya",
    "Akkaraipattu",
    "Eravur",
    "Mutur",
    "Kalawanchikudi",
    "Nawalapitiya",
    "Chavakachcheri",
    "Mulaitivu",
    "Kuchchaveli",
    "Pothuvil",
    "Tissamaharama",
    "Wellawaya",
    "Udawalawe",
    "Uva Paranagama",
    "Wattegama",
    "Galewela",
    "Medawachchiya",
    "Mullaitivu"
];

