import { CustomerServiceOutlined, StarOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'

import './styles.scss'
import { useNavigate } from 'react-router-dom'

const SecTripPlanner = () => {
    const navigate = useNavigate()

    return (
        <div className='section_main custom_section_left'>
            <div className='left'>
                <h1 className='section_title custom_title' data-aos="fade-right">
                    Plan your vacation with
                    <br />
                    <span className='green'>visit pearl</span> <span className='light_green'>planner</span>
                </h1>

                <ul>
                    <li data-aos="fade-right" data-aos-delay="100"><p>On your own preference</p></li>
                    <li data-aos="fade-right" data-aos-delay="200"><p>On your own time allocation</p></li>
                    <li data-aos="fade-right" data-aos-delay="300"><p>Visit your favourite destinations</p></li>
                    <li data-aos="fade-right" data-aos-delay="400"><p>And on your budget...</p></li>
                </ul>
                <p className='sub_text' data-aos="fade-up"
                    data-aos-delay="300">Craft Your Dream Sri Lanka Adventure with Our Custom Trip Planner - Tailored Just for You!</p>

                <div className='btns' data-aos="fade-right" data-aos-delay="300">
                    <Button
                        className='custom_primary_btn'
                        onClick={() => navigate('/custom-tours')}
                    ><StarOutlined />Start Planning</Button>
                    <Button
                        className='custom_primary_btn_link'
                        onClick={() => navigate('/support')}
                    >Get Support <CustomerServiceOutlined /></Button>
                </div>
            </div>

            <div className='right' data-aos="fade-left" >
                <img
                    src={require('../../../Assets/images/customEvenrThumb.webp')}
                    alt='trip planner'
                    loading='lazy'
                />
            </div>

        </div>
    )
}

export default SecTripPlanner