import React, { useEffect, useState } from 'react'

import './styles.scss'
import { Button, Divider, Drawer } from 'antd'
import { MenuUnfoldOutlined } from '@ant-design/icons'

const items = [
    {
        key: '1',
        label: 'Privacy Statement',
        link: '#privacy-statement',
    },
    {
        key: '2',
        label: 'When does this Privacy Policy apply?',
        link: '#when-apply',
    },
    {
        key: '3',
        label: 'About your personal information and how we use it',
        link: '#about-personal-info',
    },
    {
        key: '4',
        label: 'Your Rights',
        link: '#your-rights',
    },
    {
        key: '5',
        label: 'Use of Website by Children',
        link: '#use-website',
    },
    {
        key: '6',
        label: 'Information Security',
        link: '#information-security',
    },
    {
        key: '7',
        label: 'Cookies',
        link: '#cookies',
    },
    {
        key: '8',
        label: 'Changes to Our Privacy Policy',
        link: '#changes-privacy',
    }
]

const PrivacyPolicy = () => {
    const [isNavDrawerOpen, setIsNavDraweOpen] = useState(false);

    useEffect(() => {
        const url = window.location.href;
        const section = url.split('#')[1];
        if (section) {
            scrollToSection(`#${section}`);
        }
    }, []);

    const scrollToSection = (id) => {
        const section = document.querySelector(id);
        if (section) {
            const offset = window.innerWidth > 768 ? 100 : 60;
            const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - offset;

            window.scrollTo({
                top: sectionPosition,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div className='privacy section_main' style={{ marginBottom: '60px' }}>
            <div className='top_padding_container'>
                <h1 className='header_1'>
                    Privacy Policy
                </h1>
                <p>
                    Version 1.0.0 - Last updated: 2024-10-06
                </p>
            </div>

            <div className='mobile_nav'>
                <Button onClick={() => setIsNavDraweOpen(true)}><MenuUnfoldOutlined /> Open Menu</Button>
            </div>

            <div className='body'>
                <div className='terms__nav'>
                    {items.map((item) => (
                        <div
                            key={item.key}
                            className='terms__nav__container__items__item'
                            onClick={() => scrollToSection(item.link)}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>

                <div className='terms'>
                    <p className='paragraph' id='privacy-statement'>
                        Greetings from Visitpearl.com! This Privacy Statement, which also includes our Cookie Statement and Terms and Conditions, outlines Visitpearl.com's policy for gathering and managing any personally identifiable information about you. It covers all of our websites (including those that are affiliated with us), mobile applications, and other services that we provide (collectively, the "Services").
                        <br />
                        <br />
                        We are aware that you are putting a lot of faith in us when you provide information online. We value your trust and take great care to protect the security and privacy of any personal information you provide us when using our services or visiting our website. Please carefully read our Privacy Statement to understand our privacy standards before giving us any personal information.
                    </p>

                    <Divider />

                    <h2 className='heder_2 sec' id='when-apply'>
                        When does this Privacy Policy apply?
                    </h2>
                    <p className='paragraph'>
                        This privacy statement covers any information that Visitpearl.com or its mobile application obtains from or about you as an identifiable individual. Anonymous data that cannot be used to identify you in any way is not covered by this privacy statement. Links to external websites may be found on this website. The content of these third-party websites and their privacy policies are not under the control of Visitpearl.com. It is advised that you thoroughly review these websites' privacy policies.
                    </p>

                    <Divider />

                    <h2 className='heder_2 sec' id='about-personal-info'>
                        About your personal information and how we use it.
                    </h2>
                    <h3 className='header_4'>
                        Information You Provide To Us Directly
                    </h3>
                    <p className='paragraph'>
                        Visitpearl.com collects and uses the information you provide to us directly in order to provide you with the services you request. For example, you may provide us your name, gender, birthday, mobile number, email, and other information when you register a Visitpearl.com account. When booking flight tickets for example, you may provide passenger names, genders, ID document numbers and the contact person’s name, mobile number, email and delivery address, as well as other information. When booking hotels and tickets for tourist attractions, you may provide the travelers names, contact person’s name, mobile number, and email, etc. When paying for travel products, you may provide your bank card number, the mobile number previously provided to the bank, or your credit card number, cardholder name, expiry date, etc.
                    </p>
                    <p className='paragraph'>
                        We may also gather information about your travel plans and preferences in order to tailor the services we offer you. This information may include your preferred meal options, departure date, check-in and check-out times, seat preference, ticket selection, insurance option, and the other services (like car rental, attraction tickets, and travel guides, among others) that Visitpearl.com offers.
                    </p>
                    <p className='paragraph'>In the event that you contact our customer service department or us via another channel (such social media), we will also gather personal data from you, including your name and contact details.</p>
                    <p className='paragraph'>In the event that you are reserving on someone else's behalf or are going with other people, you will give Visitpearl.com identical information. We do want to remind you, nevertheless, that it is your duty to make sure the person or people whose personal information you have given you are aware that you have done so and have given you permission to share it with Visitpearl.com for these reasons.</p>
                    <h3 className='header_4'>
                        Information We Collect Automatically
                    </h3>
                    <p className='paragraph'>Visitpearl.com collects some information about you automatically</p>
                    <p className='paragraph'>Specifically:</p>
                    <ul>
                        <li>Log information, which refers to information that the system may automatically collect through cookies, a web beacon, or other channels (including those placed by our third-party business partners) when you use our services, including:</li>
                        <li>Device information or software information, such as your IP address, software version and device identifier (IDFA / IMEI) of the mobile device you use, and configurations of the web browser or mobile device you use to access our services.</li>
                        <li>Information that is searched for or browsed when you use our services, such as web pages used or terms searched and pages visited through the app, as well as other information browsed or provided when using our services.</li>
                        <li>Information contained in the content you share through our services, such as a photo shared or uploaded, comments, and dates and times, etc.</li>
                        <li>Information on your preferences, such as language, region and currency.</li>
                        <li>Information on your online activities.</li>
                        <li>Location information, which we collect when you turn on your device’s location feature and use our location-based services. This includes your geographical position information collected via GPS or Wi-Fi, your address information contained in the account information you provide to us, and shared information uploaded by you or others showing your current or previous location. You can turn off the location feature to prevent us from collecting your location information.</li>
                    </ul>
                    <p className='paragraph'>More information on how we use cookies can we found in our <a className='default_anchor' href='#cookies'>Cookie Statement</a> below.</p>
                    <h3 className='header_4'>
                        Information We Receive Through Other Sources
                    </h3>
                    <p className='paragraph'>Additionally, our business partners and affiliates will provide us with your information. For instance, your booking or reservation information may be sent to one of our affiliates or business partners when you make a reservation through our website or mobile app. This is done so they can process your booking and guarantee its success. Additionally, you can use your social networking account to log into Visitpearl.com. In this case, your personal information will be shared with us by the social media platform with your permission.</p>
                    <p className='paragraph'>Additionally, we may integrate calling services into our platforms to enable you to connect with us, and when we do, we receive metadata about the call activities (such as where you called from, who you are, and the date and length of the call).</p>
                    <h3 className='header_4'>
                        Special Categories of Personal Information
                    </h3>
                    <p className='paragraph'>In connection with fulfilling your travel requests for disability considerations and dietary preferences, some information you provide us with may be considered as “special category” personal information.</p>
                    <h3 className='header_4'>
                        How does visitpearl.com use my personal information and what are the related lawful bases?
                    </h3>
                    <p className='paragraph'>Visitpearl.com will generally collect and use your personal information for the following purposes:</p>
                    <ol>
                        <li><strong>To fulfil a contract, or take steps linked to a contract: The use of your information may be necessary to perform the contract that you have with us, including:</strong>
                            <ol style={{ listStyleType: 'lower-alpha' }}>
                                <li><strong>Providing services to you:</strong> Your personal information will be used by us to process and oversee your online reservation. Additionally, through affiliates, business partners, and other third parties, we will offer you related goods and services.</li>
                                <li><strong>Fulfilling obligations: </strong>Processing commissions paid to partners, handling insurance claims and payments that arise under applicable policies, filing claims for losses caused by service partners, retrieving payments received, etc.</li>
                            </ol>
                        </li>
                        <li><strong>To conduct our business and pursue our legitimate interests. In particular:</strong>
                            <ol style={{ listStyleType: 'lower-alpha' }}>
                                <li><strong>Account management:</strong> You can register for an account on Visitpearl.com, and we'll utilize the details you submit to handle your account and give you useful features. Your account can be used for a number of tasks, including order evaluation, payment management, adding frequently used passenger information, managing reservations, modifying personal settings, and more.</li>
                                <li><strong>Responding to your inquiries and requests:</strong> We provide customer support in multiple channels to offer help when you need it.</li>
                                <li><strong>Contacting you:</strong> answering and handling your inquiries and requests, as well as communicating with you regarding bookings (notifying you when a booking has been successfully filed, reminding you to finish unfinished bookings, etc.). Additionally, we might ask for your opinion on our services or send you surveys.</li>
                                <li><strong>Market research:</strong> We will sometimes invite you to participate in market research to better understand your interest in our products, services, and websites.</li>
                                <li><strong>Improving service security and reliability:</strong> We may utilize your personal information for risk assessment and security purposes, as well as to detect and stop fraud and other unlawful activity. If you are using our app, when it crashes or hangs, it will send us error-reporting data. This gives us the opportunity to look into the mistake and make the software more stable for further updates.</li>
                                <li><strong>Daily operations:</strong>Including but not restricted to managing reservations, confirming customers, providing technical assistance, maintaining networks, troubleshooting, handling internal administrative matters, creating internal policies and procedures, and generating internal reports.</li>
                                <li><strong>Monitoring:</strong>
                                    <ul>
                                        <li>We monitor customer accounts to prevent, investigate and/or report fraud, terrorism, misrepresentation, security incidents or crime, in accordance with applicable law.</li>
                                        <li>As mentioned in the question "How does Visitpearl.com share my personal data?", any calls you make via our platform to our partner providers or your interactions with our customer service representatives over the phone may also be recorded. We might use these recordings to keep an eye on the caliber of our customer service, verify the veracity of the data you submit to avoid fraud, or conduct internal staff training.</li>
                                        <li>We monitor your use of our website or app, to help us refine and improve the way our website or app works.</li>
                                    </ul>
                                </li>
                                <li><strong>Reviews and feedbacks:</strong>
                                    <ul>
                                        <li>We show visitor reviews on the relevant lodging information page on our websites, mobile applications, social media accounts, and apps, as well as on the websites of the relevant lodging and business partners. This is to let other travellers know how good the travel product was, where you went, and what other services you had used.</li>
                                        <li>To sort and prioritise visitor evaluations, we will also combine the information you provide about them with input from other users. Unless you have made this information public in your profile, we may use the information you provide us anonymously in your reviews or other information about your travels. This is to assist tourists such as yourself in selecting the ideal location and lodging.</li>
                                    </ul>
                                </li>
                                <li><strong>Payment verification:</strong> If you use a credit or debit card as payment, we also use third parties to check the validity of the sort code, account number, and card number you submit in order to prevent fraud.</li>
                                <li><strong>Investigations and complaints management:</strong> To sort and prioritize visitor evaluations, we will also combine the information you provide about them with input from other users. Unless you have made this information public in your profile, we may use the information you provide us anonymously in your reviews or other information about your travels. This is to assist tourists such as yourself in selecting the ideal location and lodging.</li>
                            </ol>
                        </li>
                        <li><strong>Where you give us consent. We may ask for your consent to:</strong>
                            <ol style={{ listStyleType: 'lower-alpha' }}>
                                <li>Send you certain electronic direct marketing content in relation to our products and services, or other products and services provided by our affiliates and partners.</li>
                                <li>Utilize your personal data for analysis so that we can learn about your interests, location, and demographics. We may also combine this data with data from other sources (including other parties) to create new services and products, enhance our marketing strategies, and develop our offerings. This includes cross-device tracking, in which third parties use cookies to gather data about your online activity across several connected devices and then combine this data to display ads on the Internet that are specifically tailored to your interests (sometimes referred to as Internet-based advertising).</li>
                                <li>We will use the data for the purposes we shall specify when we request your consent on other times.</li>
                                <li>You have the right to revoke your consent at any time when we depend on it, even though there may be other legitimate reasons why we can treat your information for purposes other than those mentioned above. If you have an account on Visitpearl.com, we may occasionally be able to contact you with direct marketing offers without getting your permission. You have the complete right to withdraw from our direct marketing at any moment, as well as from any profiling we do for it. To accomplish this, get in touch with us using the information provided below. By selecting the unsubscribe link found at the bottom of non-essential emails or by changing the notification preferences in your account profile on the Visitpearl.com website or app, you can also choose not to receive any marketing emails in the future. Our Cookie Statement's "How can I manage cookies?" section contains information on how to modify your cookie settings.</li>
                            </ol>
                        </li>
                        <li><strong>For purposes which are required by law:</strong>
                            <ol style={{ listStyleType: 'lower-alpha' }}>
                                <li><strong>Legal purposes:</strong> Sometimes, we may need to use your information to deal with legal issues and their resolution, regulatory investigations and compliance, government or law enforcement requests for information, or to reasonably enforce the terms and conditions pertaining to any services that Visitpearl.com provides.</li>
                            </ol>
                        </li>
                    </ol>
                    <h3 className='header_4'>
                        Duration of Processing of Personal Data
                    </h3>
                    <p className='paragraph'>Visitpearl.com will only keep your personal information for as long as is necessary to fulfill the purposes listed above, ii) until you object to our use of your personal information (where Visitpearl.com has a legitimate interest in using your personal data), or iii) until you withdraw your consent, whichever is applicable, when processing your personal information as permitted by law or with your consent. Nevertheless, Visitpearl.com will keep your personal information until the end of the legally mandated term or until the disputed claims have been resolved if it is obligated to do so by law or to handle legal claims.</p>
                    <h3 className='header_4'>
                        Where will your personal information be processed?
                    </h3>
                    <p className='paragraph'>The company Visitpearl.com solely conducts business in Australia and Sri Lanka. Nonetheless, it might collaborate with foreign sellers situated in Australia and Sri Lanka. As a result, Visitpearl.com may send your personal information to nations other than those listed above, including nations whose laws might not provide the same level of privacy protection as those in Australia and Sri Lanka. Microsoft Azure provides support for both the website and our online services, which are hosted in Australia. In this case, our service providers' criteria will take effect.</p>
                    <h3 className='header_4'>
                        How does Visitpearl.com share my personal data?
                    </h3>
                    <p className='paragraph'>To guarantee the successful delivery of services to you, we may share your booking information, account information, device information, and location information with affiliate businesses within our corporate family and third parties, such as the partners listed below. Among our partners are the following categories:</p>
                    <ul>
                        <li><strong>Providers:</strong> including, but not restricted to, lodging facilities, airlines, vehicle rental companies, travel agencies, operators of beautiful locations, event organizers, and booking brokers. These suppliers might get in touch with you when they need to offer you travel-related goods or services. Visitpearl.com has no control over these services, and any personal information you supply them is governed by the privacy statement and security procedures of that particular source. As a result, we advise you to go over the privacy statements of every travel company whose goods you buy via Visitpearl.com.</li>
                        <li><strong>Third-party vendors:</strong> Under contract, we might collaborate with other suppliers to handle certain tasks or services on our behalf, such as credit card processing, business analytics, customer support, marketing, survey distribution, prize promotion, and fraud prevention. Additionally, we might provide third-party suppliers permission to gather data on our behalf, including the data required to run our website's functions and enable the transmission of online ads based on your interests. Third-party vendors are not allowed to share or use the information for any other reason. They are only allowed to access and acquire the information as necessary to carry out their duties.</li>
                        <li><strong>Financial institutions and third-party payment agencies:</strong> We will provide the relevant financial institutions or third-party payment agencies with the information we receive from you when you make a reservation, request a refund, or buy insurance. In addition, we will share your email address, phone number, and IP address with the relevant financial institutions in order to prevent and detect fraud.</li>
                        <li><strong>Business partners:</strong> Whom we may collaborate with to offer goods or services, or whose goods or services we may offer on our websites or applications. When a third party is associated with a product or service you have ordered, you can identify it by looking for their name, either by itself or in conjunction with ours. We may share information about you, including personal information, with such partners if you decide to use these optional products or services. Please be aware that these third-party business partners' privacy policies are independent of us.</li>
                        <li><strong>Affiliated companies within our corporate family:</strong> In order to give you the service you've requested as well as information on other travel-related goods and services, we might share your personal information with our affiliated businesses. Your personal information is protected by comparable safeguards offered by every division of our organization, which are just as strict as this Privacy Statement.</li>
                    </ul>
                    <p className='paragraph'>We may also share your information in the context of complying with legal obligations, to protect our or your interests in legal matters, and in the case of a merger or acquisition.</p>

                    <Divider />

                    <h2 className='heder_2 sec' id='your-rights'>
                        Your Rights
                    </h2>
                    <h3 className='header_4'>
                        Erasure, Access and Other
                    </h3>
                    <p className='paragraph'>You can call the customer support number listed on Visitpearl.com's official website and follow the instructions to speak with a customer support specialist who will assist you in deleting your account information after a verification process is finished if you would like to delete your Visitpearl.com account information. Following the deletion, we won't be gathering, using, or disclosing any personal data associated with this account.</p>
                    <p className='paragraph'>If you would like us to stop using your personal information, you can ask that it be deleted.</p>
                    <p className='paragraph'>Furthermore, you have the right to seek access to your personal data, the correction of any errors, and the inclusion of remarks or justifications to information that is kept about you. You may also ask us to send structured, machine-readable copies of your personal data to another service provider, if it is technically possible.</p>
                    <p className='paragraph'>Additionally, you are free to request at any time that we not use your personal data for marketing. You can contact us at any moment to exercise this right. By selecting the unsubscribe link in non-essential emails or changing the notification preferences in your account profile on the Visitpearl.com website or app, you can also choose not to receive any marketing emails in the future.
                        Lastly, you have the option to request that we not gather or utilize your personal data for a particular purpose or that we either not share your data with third parties or expressly request that we not do so.</p>
                    <p className='paragraph'>The aforementioned rights might not always be applicable. We might not be able to remove all of your personal information, in which case we will notify you of the applicable exceptions, for instance, if you ask us to remove information that we are compelled by law to maintain or that we have strong legitimate interests in keeping.</p>
                    <p className='paragraph'>We will reply to the requests you made above as soon as possible. Before completing your request, Visitpearl.com maintains the right to take reasonable measures to confirm your identity.
                        Please email us at <a href='mailto:info@visitpearl.com.au ' className='default_anchor'>info@visitpearl.com.au</a> with specifics about your request if you have any questions or concerns regarding privacy or security, or if you would want to make any of these requests.</p>
                    <h3 className='header_4'>
                        How you can lodge a complaint
                    </h3>
                    <p className='paragraph'>We are committed to investigating and resolving complaints about our collection or use of your personal information. To make a complaint, contact us at <a href='mailto:info@visitpearl.com.au ' className='default_anchor'>info@visitpearl.com.au</a></p>
                    <p className='paragraph'>Clearly provide the following information to help us address the issue effectively:</p>
                    <ul>
                        <li>The specific data privacy complaint (please provide as much detail as possible including country, your understanding of the data privacy infringement, and redress requested)</li>
                        <li>Your full name and how we can contact you</li>
                        <li>Any previous correspondence on this specific data privacy issue</li>
                    </ul>
                    <p className='paragraph'>If you live in the European Union and are dissatisfied with how we handled your complaint, you can file a complaint with the relevant data protection authority in your area or, if you think there may have been a violation, in that location.</p>
                    <p className='paragraph'>We strive to promptly address any issues, or as required by applicable law. If this is not feasible due to the need for a more thorough investigation, we will stay in constant communication with you to make sure you are aware of the status of your matter.</p>

                    <Divider />

                    <h2 className='heder_2 sec' id='use-website'>
                        Use of Website by Children
                    </h2>
                    <p className='paragraph'>This website is not intended for anyone under the age of 18 years old. If you are under 18, you may not register with, or use this website.</p>

                    <Divider />

                    <h2 className='heder_2 sec' id='information-security'>
                        Information Security
                    </h2>
                    <p className='paragraph'>Information security is very important to Visitpearl.com, and they have a specialized team just for that purpose. We have implemented the necessary technical, administrative, and physical security measures in an effort to safeguard your personal information.</p>
                    <p className='paragraph'>We have put in place security safeguards for every phase of the data life cycle, which includes data collection, storage, processing, presentation, usage, and deletion. In order to hide the sensitive information to be displayed, we implement a variety of control mechanisms based on the sensitivity of the information. These measures include, but are not limited to, access control, SSL encryption transmission, and high-level encryption algorithms for encrypted storage.</p>
                    <p className='paragraph'>Please be aware that there are no "perfect security measures" for the Internet, even with the aforementioned precautions. To protect your information, we use the best technology currently on the market and implement the necessary security precautions.</p>
                    <p className='paragraph'>There are built-in security measures in your account. It is recommended that you keep your password and account secure and don't share them with anyone. Please get in touch with our customer service team right once if you suspect that any of your personal information has been compromised, particularly your account and password, so that we can take the necessary action.</p>
                    <p className='paragraph'>In the extremely unlikely event that your personal information is compromised by a data breach, we will notify you via email or another method in compliance with applicable laws and regulations.</p>

                    <Divider />

                    <h2 className='heder_2 sec' id='cookies'>
                        Cookies
                    </h2>
                    <p className='paragraph'>Visitpearl.com uses cookies to provide you with a better browsing experience. Cookies are small text files that are stored on your computer or mobile device when you visit a website. They help us to remember your preferences and improve your experience on our website. We use cookies to understand how our website is used and to improve its performance. We also use cookies to provide you with personalized content and to help us understand how you use our website.</p>
                    <h3 className='header_4'>
                        What types of cookies do we use?
                    </h3>
                    <p className='paragraph'>We use the following types of cookies:</p>
                    <ul>
                        <li><strong>Essential cookies:</strong> These cookies are necessary for the website to function properly. They enable you to navigate the website and use its features. Without these cookies, the website would not work properly.</li>
                        <li><strong>Performance cookies:</strong> These cookies collect information about how visitors use the website. They help us to improve the website and provide a better user experience.</li>
                        <li><strong>Functionality cookies:</strong> These cookies allow the website to remember choices you make and provide enhanced, more personal features. They may also be used to provide services you have asked for, such as watching a video or commenting on a blog.</li>
                        <li><strong>Targeting cookies:</strong> These cookies are used to deliver advertisements that are more relevant to you and your interests. They are also used to limit the number of times you see an advertisement and help measure the effectiveness of the advertising campaign.</li>
                    </ul>
                    <p className='paragraph'>By using our website, you agree to the use of cookies as described in this Privacy Policy. If you do not agree to the use of cookies, you can disable them in your browser settings. Please note that if you disable cookies, some parts of our website may not function properly.</p>
                    <h3 className='header_4'>
                        How you can manage cookies?
                    </h3>
                    <p className='paragraph'>You can manage cookies by changing your browser settings. You can also delete cookies from your computer or mobile device at any time. For more information on how to manage cookies, please visit <a href='https://www.allaboutcookies.org/' className='default_anchor'>www.allaboutcookies.org</a>.</p>

                    <Divider />

                    <h2 className='heder_2 sec' id='changes-privacy'>
                        Changes to Our Privacy Policy
                    </h2>
                    <p className='paragraph'>When necessary, Visitpearl.com will update this privacy statement. Please be aware that we reserve the right to make changes to this privacy statement whenever we see fit. The most recent revision to this privacy statement will be noted, and it will go into effect as soon as it is released. Visitpearl.com will not limit your rights under this Privacy Statement without your express approval.</p>
                    <p className='paragraph'>Major updates will be announced clearly on Visitpearl.com (for instance, if there are changes pertaining to certain services, you will receive an email from us outlining those changes in the Privacy Statement). Thus, in order to stay up to date with our most recent policies, you should periodically examine this Privacy Statement.</p>
                </div>
            </div>

            <Drawer
                title="Basic Drawer"
                className='home__nav__drawer'
                rootClassName='home__nav__drawer_body'
                placement='bottom'
                closable={false}
                open={isNavDrawerOpen}
                onClose={() => setIsNavDraweOpen(false)}
                height='70vh'
                headerStyle={{ display: 'none' }}
            >
                <div className='terms__nav_drawe'>
                    {items.map((item) => (
                        <div
                            key={item.key}
                            className='terms__nav__container__items__item_drawer'
                            onClick={() => {
                                scrollToSection(item.link)
                                setIsNavDraweOpen(false)
                            }}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>
            </Drawer>
        </div>
    )
}

export default PrivacyPolicy