import { fetchAllDestinations } from '../../api/destinations.api';

import {
    FETCH_DESTINATIONS,
    FETCH_DESTINATIONS_SUCCESS,
    FETCH_DESTINATIONS_FAIL,
} from '../../types';

export const fetchDestinationsAC = (values) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_DESTINATIONS
        })
        try {

            const destinationsResponse = await fetchAllDestinations(values)

            dispatch({
                type: FETCH_DESTINATIONS_SUCCESS,
                payload: destinationsResponse.data
            })
        } catch (error) {
            dispatch({
                type: FETCH_DESTINATIONS_FAIL,
                payload: error.message
            })
        }
    }
}