const FAV_TOUR_PACKAGE = 'FAV_TOUR_PACKAGE';
const FAV_ACCOMMODATION = 'FAV_ACCOMMODATION';
const FAV_TRANSPORT = 'FAV_TRANSPORT';
const FAV_DESTINATION = 'FAV_DESTINATION';
const FAV_ATTRACTION = 'FAV_ATTRACTION';
const FAV_EVENT = 'FAV_EVENT';
const FAV_ACTIVITY = 'FAV_ACTIVITY';

export {
    FAV_TOUR_PACKAGE,
    FAV_ACCOMMODATION,
    FAV_TRANSPORT,
    FAV_DESTINATION,
    FAV_ATTRACTION,
    FAV_EVENT,
    FAV_ACTIVITY
}