import React, { useEffect, useRef, useState } from 'react'

import './styles.scss'
import { getItinerariesByUserTour, updateUserTourItinery } from '../../../../../../../redux/api/userTour.api'
import LoadingComp from '../../../../../../components/LoadingComp'
import { Button, Collapse, Divider, Empty, Form, Input, Modal, Select, Steps } from 'antd'
import ReactQuill from 'react-quill'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useNavigate } from 'react-router-dom'
import { capitalizeFisrtLetterEachWord, captilizeFirstLetter, trimHTML } from '../../../../../../helpers/nameRender'
import { DEFAULT_THUMBNAIL } from '../../../../../../constants/otherConstsnts'
import { CaretRightOutlined, CheckCircleFilled } from '@ant-design/icons'

const BookingItinery = ({ userTour, handleGetUserTour, setActiveKey, messageApi }) => {
    const [itinerary, setItinerary] = useState(null)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const [modal, contextHolder] = Modal.useModal();
    const [loadingSendRequest, setLoadingSendRequest] = useState(false)
    const formRef = useRef()
    const [loadingAgree, setLoadingAgree] = useState(false)

    useEffect(() => {
        handleGetItinerary()
    }, [])

    const handleGetItinerary = async () => {
        setLoading(true)
        try {
            const response = await getItinerariesByUserTour(userTour.id)
            setItinerary(response.data)
        } catch (error) {
            console.log('ERROR GETTING ITINERARY', error)
        }
        setLoading(false)
    }

    const handelAddRequest = async (values) => {
        setLoadingSendRequest(true)

        const newRequest = {
            day: values.day,
            changeRequest: values.changes,
            itineryChangeApproveStatus: 'pending',
            adminMessage: null,
            isClosed: false
        }

        const oldReqstsArray = userTour.itineryChangeRequests ? JSON.parse(userTour.itineryChangeRequests) : []

        const updatedReqstsArray = [...oldReqstsArray, newRequest]

        const data = {
            userTourId: userTour.id,
            itineryChangeRequests: JSON.stringify(updatedReqstsArray),
            isItinaryAdded: userTour.isItinaryAdded,
            isUserAgreedToTheItinery: userTour.isUserAgreedToTheItinery,
            copyOfItineries: userTour.copyOfItineries
        }

        try {
            const res = await updateUserTourItinery('custom', data)
            if (res.status === 200) {
                messageApi.success({
                    message: 'Request Added',
                    description: 'Your request has been added successfully. We will get back to you soon.'
                })
                formRef.current.resetFields()
                await handleGetUserTour()
                setActiveKey('5')
            } else {
                messageApi.error({
                    message: 'Error Adding Request',
                    description: 'There was an error adding your request. Please try again later.'
                })
            }
        } catch (error) {
            console.log('ERROR ADDING REQUEST', error)
            messageApi.error({
                message: 'Error Adding Request',
                description: 'There was an error adding your request. Please try again later.'
            })
        }

        setLoadingSendRequest(false)
    }

    const handleAgreeToItinerary = async () => {
        setLoadingAgree(true)

        const data = {
            userTourId: userTour.id,
            itineryChangeRequests: userTour.itineryChangeRequests,
            isItinaryAdded: userTour.isItinaryAdded,
            isUserAgreedToTheItinery: 'agreed',
            copyOfItineries: userTour.copyOfItineries
        }

        try {
            const res = await updateUserTourItinery('custom', data)
            if (res.status === 200) {
                messageApi.success({
                    message: 'Agreed to Itinerary',
                    description: 'You have agreed to the itinerary. You can not make any changes to the itinerary now.'
                })
                await handleGetUserTour()
                setActiveKey('5')
            } else {
                messageApi.error({
                    message: 'Error Agreeing to Itinerary',
                    description: 'There was an error agreeing to the itinerary. Please try again later.'
                })
            }
        } catch (error) {
            console.log('ERROR AGREEING TO ITINERARY', error)
            messageApi.error({
                message: 'Error Agreeing to Itinerary',
                description: 'There was an error agreeing to the itinerary. Please try again later.'
            })
        }

        setLoadingAgree(false)
    }

    return (
        <div className='tour_booking_itinerary'>
            {contextHolder}
            {
                loading ?
                    <div className='booking__loading_container'>
                        <LoadingComp />
                    </div>
                    :
                    <div className='booking_itinery'>
                        {userTour?.isItinaryAdded ?
                            <>
                                <h3 className='header_3' style={{ marginBottom: '10px' }}>
                                    Your Tour Itinerary
                                </h3>

                                {userTour?.isUserAgreedToTheItinery !== 'agreed' ?
                                    <div className='tour_agree_changes'>
                                        <p className='instruction'>
                                            This is the itinerary for your tour. If you want to make any changes to the itinerary, please send us a request.
                                        </p>
                                        <div className='agreee'>
                                            <Button
                                                loading={loadingAgree}
                                                onClick={async () => {
                                                    modal.confirm(
                                                        {
                                                            title: 'Agree to Itinerary',
                                                            content: (
                                                                <div>
                                                                    <p>
                                                                        I have read the itinerary and I am happy with it. I am ready to go on the tour.
                                                                        <br />
                                                                        You can't make any changes to the itinerary after you agree to it. <a
                                                                            className='default_anchor'
                                                                            href='/terms-and-conditions'
                                                                            target='_blank'
                                                                            rel='noreferrer'
                                                                        >
                                                                            Terms and Conditions
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                            ),
                                                            async onOk() {
                                                                await handleAgreeToItinerary()
                                                            }
                                                        }
                                                    );
                                                }}
                                            >
                                                <CheckCircleFilled />
                                                Agree
                                            </Button>
                                            <p>
                                                By clicking agree, you are confirming that you are happy with the itinerary and you are ready to go on the tour.
                                            </p>
                                        </div>
                                    </div>
                                    :
                                    <div className='tour_agree_changes'>
                                        <p className='instruction'>
                                            You have agreed to the itinerary. You can proceed with the tour.
                                        </p>
                                    </div>
                                }

                                <div className='changes'>
                                    {userTour?.isUserAgreedToTheItinery !== 'agreed' && <Form
                                        ref={formRef}
                                        layout='vertical'
                                        onFinish={handelAddRequest}
                                    >
                                        <h4 className='header_4'>Request Changes</h4>
                                        <Form.Item
                                            label='Day you need to change'
                                            name='day'
                                            rules={[{ required: true, message: 'Please select the day you want to change' }]}
                                        >
                                            <Select
                                                placeholder='Select the day you want to change'
                                            >
                                                {
                                                    itinerary?.map((iti, index) => (
                                                        <Select.Option value={iti.dayNumber} key={index}>
                                                            Day {iti.dayNumber}
                                                        </Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label='Changes you want to make'
                                            name='changes'
                                            rules={[{ required: true, message: 'Please enter the changes you want to make' }]}
                                        >
                                            <Input.TextArea
                                                placeholder='Enter the changes you want to make'
                                                autoSize={{ minRows: 3, maxRows: 5 }}
                                                maxLength={500}
                                                showCount
                                            />
                                        </Form.Item>

                                        <Button
                                            type='primary'
                                            htmlType='submit'
                                            loading={loadingSendRequest}
                                        >
                                            Send Request
                                        </Button>
                                    </Form>}

                                    {userTour?.itineryChangeRequests &&
                                        <div className={`reqests ${userTour.isUserAgreedToTheItinery === 'agreed' && 'user_agreed'}`}>
                                            {userTour?.isUserAgreedToTheItinery !== 'agreed' ?
                                                <>
                                                    {
                                                        userTour?.itineryChangeRequests &&
                                                        (() => {
                                                            let parsedRequests;
                                                            try {
                                                                parsedRequests = JSON.parse(userTour.itineryChangeRequests);
                                                            } catch (error) {
                                                                return null;
                                                            }

                                                            if (!Array.isArray(parsedRequests)) {
                                                                return null;
                                                            }

                                                            return parsedRequests.map((req, index) => (
                                                                <div key={index}
                                                                    className={`change_request ${userTour.isUserAgreedToTheItinery === 'agreed' && 'user_agreed'}`}
                                                                >
                                                                    <div className='header'>
                                                                        <h4>Change Request {index + 1} <span style={{ color: '#bb2124' }}>{req.isClosed && ' - Closed'}</span></h4>
                                                                    </div>
                                                                    <p>
                                                                        <strong>Date Need to Change: </strong> Day {req.day}
                                                                    </p>
                                                                    <p>
                                                                        <strong>Requested: </strong> {req.changeRequest}
                                                                    </p>
                                                                    <p>
                                                                        <strong>Approve Status: </strong> {req.itineryChangeApproveStatus && capitalizeFisrtLetterEachWord(req.itineryChangeApproveStatus)}
                                                                    </p>
                                                                    <p>
                                                                        <strong>Admin Message: </strong> {req.adminMessage || 'Not Replied Yet'}
                                                                    </p>
                                                                    <Divider />
                                                                </div>
                                                            ));
                                                        })()
                                                    }
                                                </>
                                                :
                                                <Collapse
                                                    expandIconPosition='right'
                                                    ghost
                                                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                                >
                                                    <Collapse.Panel
                                                        header='Your Previous Change Requests'
                                                        key='1'
                                                    >
                                                        {
                                                            userTour?.itineryChangeRequests &&
                                                            (() => {
                                                                let parsedRequests;
                                                                try {
                                                                    parsedRequests = JSON.parse(userTour.itineryChangeRequests);
                                                                } catch (error) {
                                                                    return null;
                                                                }

                                                                if (!Array.isArray(parsedRequests)) {
                                                                    return null;
                                                                }

                                                                return parsedRequests.map((req, index) => (
                                                                    <div key={index}
                                                                        className={`change_request ${userTour.isUserAgreedToTheItinery === 'agreed' && 'user_agreed'}`}
                                                                    >
                                                                        <div className='header'>
                                                                            <h4>Change Request {index + 1} <span style={{ color: '#bb2124' }}>{req.isClosed && ' - Closed'}</span></h4>
                                                                        </div>
                                                                        <p>
                                                                            <strong>Date Need to Change: </strong> Day {req.day}
                                                                        </p>
                                                                        <p>
                                                                            <strong>Requested: </strong> {req.changeRequest}
                                                                        </p>
                                                                        <p>
                                                                            <strong>Approve Status: </strong> {req.itineryChangeApproveStatus && capitalizeFisrtLetterEachWord(req.itineryChangeApproveStatus)}
                                                                        </p>
                                                                        <p>
                                                                            <strong>Admin Message: </strong> {req.adminMessage || 'Not Replied Yet'}
                                                                        </p>
                                                                        <Divider />
                                                                    </div>
                                                                ));
                                                            })()
                                                        }
                                                    </Collapse.Panel>
                                                </Collapse>
                                            }
                                        </div>}
                                </div>
                                {
                                    itinerary?.length > 0 ?
                                        <div className='itinerary'>
                                            <Steps
                                                direction="vertical"
                                                progressDot
                                                loading={loading}
                                            >
                                                {
                                                    itinerary?.map((itinerary, index) => (
                                                        <Steps.Step
                                                            className='itinerary_step'
                                                            key={index}
                                                            title={
                                                                <div>
                                                                    <Collapse
                                                                        bordered={false}
                                                                        expandIconPosition='right'
                                                                        ghost
                                                                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                                                    >
                                                                        <Collapse.Panel
                                                                            header={<span>
                                                                                <h2>Day {itinerary?.dayNumber}</h2>
                                                                                <p>{itinerary.dayTitle}</p>
                                                                            </span>}
                                                                            key={index}
                                                                            className='itinerary_collapse'
                                                                        >
                                                                            <ReactQuill
                                                                                className='day_description'
                                                                                value={itinerary.dayDescription}
                                                                                readOnly={true}
                                                                                theme='bubble'
                                                                            />
                                                                            <div className='main__destination'>
                                                                                <div className='left'>
                                                                                    <LazyLoadImage
                                                                                        alt='image'
                                                                                        effect="blur"
                                                                                        wrapperProps={{
                                                                                            style: { transitionDelay: "0.1s" },
                                                                                        }}
                                                                                        src={itinerary?.mainDestination?.thumbnailUrl}
                                                                                        onClick={() => navigate(`/destinations/${itinerary?.mainDestination?.id}`)}
                                                                                    />
                                                                                </div>
                                                                                <div className='right'>
                                                                                    <h2>Main Destination</h2>
                                                                                    <h3
                                                                                        onClick={() => navigate(`/destinations/${itinerary?.mainDestination?.id}`)}
                                                                                    >{itinerary?.mainDestination?.destinationName}</h3>
                                                                                    <ReactQuill
                                                                                        value={itinerary?.mainDestination?.destinationDescriptionShort ? trimHTML(itinerary?.mainDestination?.destinationDescriptionShort,
                                                                                            window.innerWidth > 1366 ? 120 : 60
                                                                                        ) : ''}
                                                                                        readOnly={true}
                                                                                        theme='bubble'
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <Divider />

                                                                            <div className='experience'>
                                                                                <h2>You can experience</h2>
                                                                                <div className='itemss'>
                                                                                    {
                                                                                        itinerary?.items?.sort((a, b) => a.position - b.position)?.map((item, index) => (
                                                                                            <div className='item_iti_dis'>
                                                                                                <div className='left'>
                                                                                                    <LazyLoadImage
                                                                                                        alt='image'
                                                                                                        effect="blur"
                                                                                                        wrapperProps={{
                                                                                                            style: { transitionDelay: "0.1s" },
                                                                                                        }}
                                                                                                        src={item?.thumbnailUrl || DEFAULT_THUMBNAIL}
                                                                                                    />
                                                                                                </div>

                                                                                                <div className='right'>
                                                                                                    <h4>{item?.eventName || item?.activityName || item?.attractionName || item?.destinationName || 'N/A'}</h4>
                                                                                                    <p>
                                                                                                        <strong>Type: </strong> {item?.type ? captilizeFirstLetter(item?.type) : 'N/A'}
                                                                                                    </p>
                                                                                                    <p>
                                                                                                        <strong>Short Description: </strong>
                                                                                                        <ReactQuill
                                                                                                            value={
                                                                                                                item?.eventDescriptionShort ?
                                                                                                                    trimHTML(item?.eventDescriptionShort, 120) :
                                                                                                                    item?.activityDescriptionShort ? trimHTML(item?.activityDescriptionShort, 120) :
                                                                                                                        item?.attractionDescriptionShort ? trimHTML(item?.attractionDescriptionShort, 120) :
                                                                                                                            item?.destinationDescriptionShort ? trimHTML(item?.destinationDescriptionShort, 120) :
                                                                                                                                'N/A'
                                                                                                            }
                                                                                                            readOnly={true}
                                                                                                            theme='bubble'
                                                                                                        />
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                            <Divider />

                                                                            <div className='experience'>
                                                                                <h2>You will Stay at</h2>
                                                                                <div className='itemss'>
                                                                                    <div className='item_iti_dis'>
                                                                                        {/* <div className='left'>
                                                                                            <LazyLoadImage
                                                                                                alt='image'
                                                                                                effect="blur"
                                                                                                wrapperProps={{
                                                                                                    style: { transitionDelay: "0.1s" },
                                                                                                }}
                                                                                                src={itinerary?.stay?.thumbnailUrl || DEFAULT_THUMBNAIL}
                                                                                                onClick={() => navigate(`/accommodations/${itinerary?.stay?.id}`)}
                                                                                            />
                                                                                        </div> */}

                                                                                        <div className='right'>
                                                                                            <h3
                                                                                                onClick={() => navigate(`/accommodations/${itinerary?.stay?.id}`)}
                                                                                            >{itinerary?.stay?.accommodationName} or Similar</h3>
                                                                                            {/* <p>
                                                                                                <strong>Rating: </strong> {itinerary?.stay?.starRating + ' Stars' || itinerary?.stay?.grade + ' Grade'}
                                                                                            </p>
                                                                                            <p>
                                                                                                <strong>Short Description: </strong>
                                                                                                <ReactQuill
                                                                                                    value={itinerary?.stay?.accommodationDescriptionShort && trimHTML(itinerary?.stay?.accommodationDescriptionShort, 120)}
                                                                                                    readOnly={true}
                                                                                                    theme='bubble'
                                                                                                />
                                                                                            </p> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </Collapse.Panel>
                                                                    </Collapse>
                                                                </div>
                                                            }
                                                        />
                                                    ))
                                                }
                                            </Steps>
                                        </div>
                                        :
                                        <div>
                                            <Empty>
                                                <p>
                                                    Our expert team is working on the itinerary for your tour. We'll update you with the details soon.
                                                    <br />
                                                    <strong>Note: </strong> Itinerary will be availabe after your tour is confirmed.
                                                </p>
                                            </Empty>
                                        </div>
                                }
                            </>
                            :
                            <div>
                                <Empty>
                                    <p>
                                        Our expert team is working on the itinerary for your tour. We'll update you with the details soon.
                                        <br />
                                        <strong>Note: </strong> Itinerary will be availabe after your tour is confirmed.
                                    </p>
                                </Empty>
                            </div>
                        }
                    </div>
            }
        </div>
    )
}

export default BookingItinery