import React from 'react'

import './styles.scss'

const LineLoader = () => {
    return (
        <div class="line_dot_loader">
            <div class="loader"></div>
        </div>
    )
}

export default LineLoader