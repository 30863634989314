import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import Flights from './pages/Flights';
import NotAllowed from './pages/NotAllowed';
import Destinations from './pages/Destinations';
import Destination from './pages/Destinations/Destination';
import Attraction from './pages/Destinations/Destination/Attraction';
import Activity from './pages/Destinations/Destination/Activity';
import Event from './pages/Destinations/Destination/Event';
import Tours from './pages/Tours';
import TourPackage from './pages/Tours/TourPackage';
import BookTourPackage from './pages/Tours/TourPackage/BookTourPackage';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Support from './pages/Support';
import Call from './pages/Support/Call';
import Email from './pages/Support/Email';
import FAQ from './pages/Support/FAQ';
import SupportTickets from './pages/Support/SupportTickets';
import CreateSupportTicket from './pages/Support/SupportTickets/CreateSupportTicket';
import Ticket from './pages/Support/SupportTickets/Ticket';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndCondtions from './pages/TermsAndCondtions';
import TrustAndSafety from './pages/TrustAndSafety';
import CustomTours from './pages/CustomTours';
import PlanTour from './pages/CustomTours/PlanTour';
import AboutUs from './pages/AboutUs';
import AboutSriLanka from './pages/AboutSriLanka';
import PlanEvent from './pages/CustomTours/PlanEvent';
import Blogs from './pages/Blogs';
import WriteABlog from './pages/Blogs/WriteABlog';
import Blog from './pages/Blogs/Blog';
import ServiceGuarantee from './pages/ServiceGuarantee';
import Accommodations from './pages/Accommodations';
import AccommodationPackage from './pages/Accommodations/AccommodationPackage';
import BookAccommodationPackage from './pages/Accommodations/AccommodationPackage/BookAccommodationPackage';

const RoutesJS = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />

            <Route path='/destinations' element={<Destinations />} />
            <Route path="/destinations/:id" element={<Destination />} />
            <Route path="/destinations/:id/attractions/:attractionId" element={<Attraction />} />
            <Route path="/destinations/:id/activities/:activityId" element={<Activity />} />
            <Route path="/destinations/:id/events/:eventId" element={<Event />} />

            <Route path="/tours" element={<Tours />} />
            <Route path='/tours/:id' element={<TourPackage />} />
            <Route path="/tours/:id/book" element={<BookTourPackage />} />

            <Route path='/custom-tours' element={<CustomTours />} />
            <Route path='/custom-tours/plan-tour' element={<ProtectedRoute element={PlanTour} />} />
            <Route path='/custom-tours/plan-event' element={<ProtectedRoute element={PlanEvent} />} />

            <Route path="/accommodations" element={<Accommodations />} />
            <Route path='/accommodations/:id' element={<AccommodationPackage />} />
            <Route path='/accommodations/:id/book' element={<BookAccommodationPackage />} />

            <Route path="/flights" element={<Flights />} />
            <Route path="/not-allowed" element={<NotAllowed />} />

            <Route path="/dashboard/*" element={<ProtectedRoute element={Dashboard} />} />

            <Route path='/support' element={<Support />} />
            <Route path='/support/call' element={<Call />} />
            <Route path='/support/email' element={<Email />} />
            <Route path='/support/faq' element={<FAQ />} />
            <Route path="/support/support-requests" element={<ProtectedRoute element={SupportTickets} />} />
            <Route path="/support/support-requests/new" element={<ProtectedRoute element={CreateSupportTicket} />} />
            <Route path="/support/support-requests/:id" element={<ProtectedRoute element={Ticket} />} />

            <Route path='/blogs' element={<Blogs />} />
            <Route path="/blogs/write" element={<ProtectedRoute element={WriteABlog} />} />
            <Route path="/blogs/:id" element={<Blog />} />

            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-and-conditions' element={<TermsAndCondtions />} />
            <Route path='/safety-and-trust' element={<TrustAndSafety />} />
            <Route path='/service-guarantee' element={<ServiceGuarantee />} />
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/about-sri-lanka' element={<AboutSriLanka />} />

            <Route path="*" element={<Home />} />
        </Routes>
    );
};

export default RoutesJS;
