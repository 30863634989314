import { CustomerServiceOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Col, Form, Input, notification, Row, Select, Spin, TimePicker, Upload } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useMyProfile from '../../../../hooks/useMyProfile'
import { SUPPORT_INCIDENT_TYPES } from '../../../../constants/itemTypes'
import ReactQuill from 'react-quill'

import './styles.scss'
import { handleImageUpload, SUPPORT_CONTAINER } from '../../../../azure/blob'
import { AUTH_USER } from '../../../../constants/roles'
import { createSupportRequest } from '../../../../../redux/api/support.api'

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const incidentOptions = Object.keys(SUPPORT_INCIDENT_TYPES).map(key => {
    return {
        label: getOptionName(SUPPORT_INCIDENT_TYPES[key]),
        value: key
    };
});

const quillModules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
        ["formula"],
        [{ size: ["small", false, "large", "huge"] }],
    ],
    clipboard: {
        matchVisual: false
    },
};

const CreateSupportTicket = () => {
    const navigate = useNavigate()
    const myProfile = useMyProfile();
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [api, contextHolder] = notification.useNotification();

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const handleSubmit = async (values) => {
        setLoading(true);
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

        try {
            let imageUrls = [];
            window.scrollTo(0, 0);

            const name = 'support_' + useMyProfile?.userProfile?.id + '_' + new Date().getTime();
            values.userType = AUTH_USER;
            values.userId = myProfile?.userProfile?.id;
            values.email = myProfile?.userProfile?.email;
            values.origin = 'SUPPORT_TICKET';
            values.preferedTimeToContact = values.preferedTimeToContact.map(time => time.format('HH:mm')).join(' - ');

            if (fileList.length > 0) {
                const imageUploadPromises = fileList.map((file, index) => {
                    return handleImageUpload(
                        SUPPORT_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${name}_${file.name}`
                    ).then(blobRes => {
                        imageUrls.push(blobRes.blockBlobClient.url);
                    });
                });
                await Promise.all(imageUploadPromises);
            }

            delete values.attachments;

            if (imageUrls.length > 0) {
                imageUrls = imageUrls.map(url => sanitizeBlobUrls(url));
                values.attachments = imageUrls.join(',');
            }

            const response = await createSupportRequest(values);
            if (response.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'Support ticket created successfully.'
                });

                setTimeout(() => {
                    navigate('/support/support-requests');
                }, 2000);

            } else {
                console.log(response);
                api.error({
                    message: 'Error',
                    description: 'An error occurred while creating the support ticket. Please try again later.'
                });
            }

        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: 'An error occurred while creating the support ticket. Please try again later.'
            });
        }

        setLoading(false);
    }

    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }

    return (
        <div className='support section_main support_ticket' style={{ marginBottom: '40px' }}>
            {contextHolder}
            <div className='top_padding_container'>
                <Breadcrumb
                    className='breadcrumb_pages'
                    style={{ marginBottom: '10px' }}
                    items={[
                        {
                            title: <div className='breadcrumb__item' onClick={() => navigate('/support')}><CustomerServiceOutlined /> Help & Support</div>,
                        },
                        {
                            title: <div className='breadcrumb__item' onClick={() => navigate('/support/support-requests')}>Support Tickets</div>,
                        },
                        {
                            title: <div className='breadcrumb__item last'>
                                Support Tickets
                            </div>,
                        },
                    ]}
                />

                <h1 className='header_1'>
                    Create Support Ticket
                </h1>
            </div>

            <div className='support__container'>
                <Spin spinning={loading} >
                    {!myProfile?.loading ?
                        <Form
                            layout='vertical'
                            onFinish={handleSubmit}
                        >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label='Email'
                                        name='email'
                                    >
                                        <Input defaultValue={myProfile?.userProfile?.email} disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label='Contact Number'
                                        name='contactNo'
                                        rules={[{
                                            // validate phone number
                                            pattern: new RegExp(/^[0-9]{10}$/),
                                            message: 'Please enter a valid phone number'
                                        }]}
                                    >
                                        <Input defaultValue={myProfile?.userProfile?.contactNumber} placeholder='Contact Number' type='tel' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label='Incident Category'
                                        name='supportType'
                                        rules={[{ required: true, message: 'Please select incident category' }]}
                                    >
                                        <Select
                                            options={incidentOptions}
                                            placeholder='Incident Category'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label='How this incident is impacting you?'
                                        name='impactLevel'
                                        rules={[{ required: true, message: 'Please select impact level' }]}
                                    >
                                        <Select
                                            options={[
                                                { label: 'High', value: 'high' },
                                                { label: 'Medium', value: 'medium' },
                                                { label: 'Low', value: 'low' },
                                            ]}
                                            placeholder='Select Impact Level'
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label='Prefered Contact Method'
                                        name='preferedContactMethod'
                                        rules={[{ required: true, message: 'Please select prefered contact method' }]}
                                    >
                                        <Select
                                            options={[
                                                { label: 'Call', value: 'CALL' },
                                                { label: 'Email', value: 'EMAIL' },
                                                { label: 'Support Ticket Chat', value: 'SUPPORT_TICKET_CHAT' },
                                            ]}
                                            placeholder='Select Prefered Contact Method'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label='Prefered Time to Contact (Local Time)'
                                        name='preferedTimeToContact'
                                        rules={[{ required: true, message: 'Please select prefered time to contact' }]}
                                    >
                                        <TimePicker.RangePicker
                                            style={{ width: '100%' }}
                                            format='HH:mm'
                                            placeholder='Select Time'
                                            showNow={false}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        label='Subject (Brief Summary)'
                                        name='subject'
                                        rules={[{ required: true, message: 'Please enter subject' }]}
                                    >
                                        <Input placeholder='Subject' maxLength={200} showCount />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        label='Message (Detailed Description)'
                                        name='message'
                                        rules={[{ required: true, message: 'Please enter message' }]}
                                    >
                                        <ReactQuill
                                            modules={quillModules}
                                            placeholder='Enter your message here...'
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        label='Upload Attachments (Max 3)'
                                        name='attachments'
                                    >
                                        <Upload
                                            listType="picture-card"
                                            fileList={fileList}
                                            onChange={handleChange}
                                            multiple={true}
                                            maxCount={10}
                                            beforeUpload={(file) => {
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/gif' || file.type === 'image/webp' || file.type === 'application/pdf';
                                                if (!isJpgOrPng) {
                                                    api.error({
                                                        message: 'Unsupported image format!',
                                                        description: 'Please upload a valid image format (jpeg, jpg, png, gif, webp, pdf)',
                                                    });
                                                }
                                                const isSizeValid = file.size / 1024 / 1024 < 2;
                                                if (!isSizeValid) {
                                                    api.error({
                                                        message: 'Image must be smaller than 2MB!',
                                                    });
                                                }

                                                const isVaid = isJpgOrPng && isSizeValid;

                                                return isVaid ? false : Upload.LIST_IGNORE;
                                            }}
                                        >
                                            {fileList.length <= 3 && '+ Upload'}
                                        </Upload>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    gap: '10px'
                                }}
                            >
                                <Button
                                    loading={loading}
                                    type='primary'
                                    htmlType='submit'
                                >
                                    Submit Ticket
                                </Button>

                                <Button
                                    type='default'
                                    loading={loading}
                                    onClick={() => navigate('/support/support-requests')}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                        :
                        <div>Loading...</div>
                    }
                </Spin>
            </  div>
        </div>
    )
}

export default CreateSupportTicket