import React, { useEffect, useRef } from 'react'
import { FREE_SERVICES } from '../../../../../constants/policies'
import { CheckCircleOutlined } from '@ant-design/icons'

import './styles.scss'
import { Button, Checkbox, DatePicker, Divider, Form, Input, Radio } from 'antd'
import { TAX_PERCENTAGE, TAX_RATE } from '../../../../../constants/finance'
import usePageSettings from '../../../../../hooks/usePageSettings'

const TourBookingConfirmation = ({
    userTourData,
    setUserTourData,
    tourPackage,
    messageApi,
    prev,
    next,
    calculateTotal,
    calculateSubTotal,
    calculateDiscount,
    calculateTax,
    current,
}) => {
    const formRef = useRef(null)
    const selectedCurrency = usePageSettings()?.selectedCurrency;

    useEffect(() => {
        window.scrollTo(0, 0)
        formRef?.current?.setFieldsValue({
            flightBooked: userTourData?.filghtDetails?.flightBooked,
            flightNumber: userTourData.filghtDetails?.flightNumber,
            departureAirport: userTourData.filghtDetails?.departureAirport,
            arrivalDateTime: userTourData.filghtDetails?.arrivalDateTime,
            confirmation: false,
        })

    }, [])

    const handleGoNext = (values) => {
        // check if all the fields are filled
        if (!userTourData.startDate) {
            messageApi.open({
                type: 'error',
                message: 'Start Date is required',
                description: 'Please select a start date for the tour.',
            })
            return
        }
        if (!userTourData.roomType) {
            messageApi.open({
                type: 'error',
                message: 'Room Type is required',
                description: 'Please select a room type for the tour.',
            })
            return
        }
        if (!userTourData.vehicleType) {
            messageApi.open({
                type: 'error',
                message: 'Vehicle Type is required',
                description: 'Please select a vehicle type for the tour.',
            })
            return
        }
        if (userTourData.adults === 0 && userTourData.children === 0 && userTourData.infants === 0) {
            messageApi.open({
                type: 'error',
                message: 'No members added',
                description: 'Please add at least one member to the tour.',
            })
            return
        }

        if (values.confirmation === false) {
            messageApi.open({
                type: 'error',
                message: 'Confirmation Required',
                description: 'Please confirm the details to proceed.',
            })
            return
        }

        if (userTourData.flightBooked) {
            if (!values.flightNumber) {
                messageApi.open({
                    type: 'error',
                    message: 'Flight Number is required',
                    description: 'Please provide your flight number.',
                })
                return
            }
            if (!values.departureAirport) {
                messageApi.open({
                    type: 'error',
                    message: 'Departure Airport is required',
                    description: 'Please provide your departure airport.',
                })
                return
            }
            if (!values.arrivalDateTime) {
                messageApi.open({
                    type: 'error',
                    message: 'Arrival Date & Time is required',
                    description: 'Please provide your arrival date & time.',
                })
                return
            }
        }

        setUserTourData({
            ...userTourData,
            filghtDetails: {
                flightBooked: values.flightBooked,
                flightNumber: values.flightNumber,
                departureAirport: values.departureAirport,
                arrivalDateTime: values.arrivalDateTime,
            }
        })

        next()
    }

    return (
        <>
            <Form
                className='booking_form_customer_data'
                onFinish={handleGoNext}
                scrollToFirstError
                layout='vertical'
                ref={formRef}
            >
                <div className='contents'>
                    <section className='booking_input_section'>
                        <h2 className='heder_2'>
                            {tourPackage?.packageName}
                        </h2>
                        <div className='service_wrapper'>
                            <div className='service'>
                                <CheckCircleOutlined />
                                <p><span>Start Date: </span>{userTourData?.startDate}</p>
                            </div>
                            <div className='service'>
                                <CheckCircleOutlined />
                                <p><span>Tour: </span>{tourPackage?.packageName}</p>
                            </div>
                            {userTourData?.adults > 0 && <div className='service'>
                                <CheckCircleOutlined />
                                <p><span>Adults: </span>{userTourData?.adults}</p>
                            </div>}
                            {userTourData?.children > 0 && <div className='service'>
                                <CheckCircleOutlined />
                                <p><span>Children: </span>{userTourData?.children}</p>
                            </div>}
                            {userTourData?.infants > 0 && <div className='service'>
                                <CheckCircleOutlined />
                                <p><span>Infants: </span>{userTourData?.infants}</p>
                            </div>}
                            <div className='service'>
                                <CheckCircleOutlined />
                                <p><span>Room Type: </span>{userTourData?.roomType || <span className='warn'>Please Select</span>}</p>
                            </div>
                            <div className='service'>
                                <CheckCircleOutlined />
                                <p><span>Vehicle Type: </span>{userTourData?.vehicleType || <span className='warn'>Please Select</span>}</p>
                            </div>
                        </div>
                    </section>

                    <section className='booking_input_section sim_services'>
                        <h2 className='heder_2'>
                            Traveler Details
                        </h2>
                        <p className='note'>
                            Please make sure the traveler details are correct. If there are any mistakes, please go back and correct them.
                        </p>
                        <div className='travellers'>
                            {
                                userTourData?.adults > 0 &&
                                <div className='traveller'>
                                    <Divider><h3>Adults</h3></Divider>
                                    {
                                        Array.from({ length: userTourData.adults }, (_, index) => index).map((_, index) => (
                                            <div key={index} className='traveller-details'>
                                                <h4>
                                                    Adult {index + 1}
                                                </h4>
                                                <p><strong>First Name:</strong> {userTourData?.userData[`adults[${index}].firstName`]}</p>
                                                <p><strong>Last Name:</strong> {userTourData?.userData[`adults[${index}].lastName`]}</p>
                                                <p><strong>Email:</strong> {userTourData?.userData[`adults[${index}].email`]}</p>
                                                <p><strong>Phone:</strong> {userTourData?.userData[`adults[${index}].phone`]}</p>
                                                <p><strong>Country:</strong> {userTourData?.userData[`adults[${index}].country`]}</p>
                                                <p><strong>Passport Number:</strong> {userTourData?.userData[`adults[${index}].passportNumber`]}</p>
                                                <p><strong>Allergies & Dietary Requirements:</strong> {userTourData?.userData[`adults[${index}].allergies`]}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            {
                                userTourData?.children > 0 &&
                                <div className='traveller'>
                                    <Divider><h3>Children</h3></Divider>
                                    {
                                        // user data is a int so we need to create an array of that length
                                        Array.from({ length: userTourData.children }, (_, index) => index).map((_, index) => (
                                            <div key={index} className='traveller-details'>
                                                <h4>
                                                    Child {index + 1}
                                                </h4>
                                                <p><strong>First Name:</strong> {userTourData?.userData[`children[${index}].firstName`]}</p>
                                                <p><strong>Last Name:</strong> {userTourData?.userData[`children[${index}].lastName`]}</p>
                                                <p><strong>Email:</strong> {userTourData?.userData[`children[${index}].email`]}</p>
                                                <p><strong>Phone:</strong> {userTourData?.userData[`children[${index}].phone`]}</p>
                                                <p><strong>Country:</strong> {userTourData?.userData[`children[${index}].country`]}</p>
                                                <p><strong>Passport Number:</strong> {userTourData?.userData[`children[${index}].passportNumber`]}</p>
                                                <p><strong>Allergies & Dietary Requirements:</strong> {userTourData?.userData[`children[${index}].allergies`]}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            {
                                userTourData?.infants > 0 &&
                                <div className='traveller'>
                                    <Divider><h3>Infants</h3></Divider>
                                    {
                                        // user data is a int so we need to create an array of that length
                                        Array.from({ length: userTourData.infants }, (_, index) => index).map((_, index) => (
                                            <div key={index} className='traveller-details'>
                                                <h4>
                                                    Infant {index + 1}
                                                </h4>
                                                <p><strong>First Name:</strong> {userTourData?.userData[`infants[${index}].firstName`]}</p>
                                                <p><strong>Last Name:</strong> {userTourData?.userData[`infants[${index}].lastName`]}</p>
                                                <p><strong>Email (Parent's):</strong> {userTourData?.userData[`infants[${index}].email`]}</p>
                                                <p><strong>Phone (Parent's):</strong> {userTourData?.userData[`infants[${index}].phone`]}</p>
                                                <p><strong>Country:</strong> {userTourData?.userData[`infants[${index}].country`]}</p>
                                                <p><strong>Passport Number (Parent's):</strong> {userTourData?.userData[`infants[${index}].passportNumber`]}</p>
                                                <p><strong>Allergies & Dietary Requirements:</strong> {userTourData?.userData[`infants[${index}].allergies`] || 'None'}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            <div className='traveller-details'>
                                <Divider><h3>Emergency Contact</h3></Divider>

                                <p><strong>First Name:</strong> {userTourData?.userData?.contactFirstName}</p>
                                <p><strong>Last Name:</strong> {userTourData?.userData?.contactGivenName}</p>
                                <p><strong>Email:</strong> {userTourData?.userData?.contactEmail}</p>
                                <p><strong>Phone:</strong> {userTourData?.userData?.contactPhone}</p>
                            </div>
                        </div>
                    </section>

                    <section className='booking_input_section sim_services'>
                        <h2 className='heder_2'>
                            Flight Details
                        </h2>
                        <p className='note'>
                            Please let us know your flight details. If you don't have the details yet, you can provide them later. We'll confirm your tour booking once we have the details.
                        </p>

                        <Form.Item
                            className='confirmation_form'
                            name='flightBooked'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select an option',
                                },
                            ]}
                            style={{ margin: '10px 0 0 0' }}

                        >
                            <Radio.Group
                                onChange={(e) => setUserTourData({ ...userTourData, flightBooked: e.target.value })}
                            >
                                <Radio value={true}>Yes, I have booked my flight</Radio>
                                <Radio value={false}>No, I haven't booked my flight yet</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {
                            userTourData.flightBooked &&
                            <>
                                <p style={{ margin: '10px 0' }}>
                                    <strong>Note: </strong> Please provide the correct details as in your flight ticket. We will use this information to arrange your airport pick-up and drop-off.
                                </p>
                                <Form.Item
                                    style={{ width: '100%', margin: '10px 0 0 0' }}
                                    className='confirmation_form'
                                    name='flightNumber'
                                    label='Flight Number'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your flight number',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Flight Number' />
                                </Form.Item>

                                <Form.Item
                                    style={{ width: '100%', margin: '10px 0 0 0' }}
                                    className='confirmation_form'
                                    name='departureAirport'
                                    label='Departure Airport'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your departure airport',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Departure Airport' />
                                </Form.Item>

                                <Form.Item
                                    style={{ width: '100%', margin: '10px 0 0 0' }}
                                    className='confirmation_form'
                                    name='arrivalDateTime'
                                    label='Arrival Date & Time'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your arrival date & time',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        showTime
                                        format='YYYY-MM-DD HH:mm:ss'
                                        placeholder='Arrival Date & Time'
                                        showNow={false}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </>
                        }
                    </section>

                    <section className='booking_input_section sim_services'>
                        <h2 className='heder_2'>
                            Confirmation
                        </h2>
                        <p className='note'>
                            Please make sure the traveler details are correct. If there are any mistakes, please go back and correct them. If everything is correct, please proceed to the payment.
                        </p>

                        <Form.Item
                            className='confirmation_form'
                            name='confirmation'
                            valuePropName='checked'
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (!value) {
                                            return Promise.reject('Please confirm the details')
                                        }
                                        return Promise.resolve()
                                    },
                                },
                            ]}
                        >
                            <Checkbox>
                                Above details are correct and I agree to the <a href='/terms-and-conditions' target='_blank' rel='noreferrer'>Terms and Conditions</a> and <a href='/privacy-policy' target='_blank' rel='noreferrer'>Privacy Policy</a>.
                            </Checkbox>
                        </Form.Item>
                    </section>
                </div>

                <div className='booking_pricing'>
                    <h2 className='heder_2'>Package Details</h2>

                    <div className='pricing_item date'>
                        <div><strong> Start Date: </strong>{userTourData?.startDate || <span style={{ color: '#F45713' }}>Please Select</span>}</div>
                        <div><strong>Room Type: </strong>{userTourData?.roomType || <span style={{ color: '#F45713' }}>Please Select</span>}</div>
                        <div><strong>Vehicle Type: </strong>{userTourData?.vehicleType || <span style={{ color: '#F45713' }}>Please Select</span>}</div>
                    </div>

                    <Divider />

                    <div className='pricing_item quentity'>
                        <div className='quentity'><h5>Adults: </h5> <p> &#215; {userTourData?.adults}</p></div>
                        <div className='quentity'><h5>Children: </h5> <p> &#215; {userTourData?.children}</p></div>
                        <div className='quentity'><h5>Infants: </h5> <p> &#215; {userTourData?.infants}</p></div>
                    </div>

                    <Divider />

                    <div className='total'>
                        <div>
                            <h4>
                                Sub Total:
                            </h4>
                            <p>{calculateSubTotal()} {selectedCurrency}</p>
                        </div>
                        {
                            tourPackage?.discount &&
                            <div>
                                <h4>
                                    Discount:
                                </h4>
                                <p>
                                    {tourPackage?.discount}%
                                </p>
                            </div>
                        }
                        <div>
                            <h4>
                                Service Charge:
                            </h4>
                            <p>{TAX_PERCENTAGE}%</p>
                        </div>

                        <Divider />

                        <div><h5>Total: </h5>
                            <h6>
                                {
                                    calculateTotal()
                                } {selectedCurrency}
                            </h6>
                        </div>
                    </div>

                    <div className='actions'>
                        {current < 5 - 1 && (
                            <Button type="primary" className='next' htmlType='submit'>
                                Next
                            </Button>
                        )}
                        {current === 5 - 1 && (
                            <Button type="primary" className='next'>
                                Done
                            </Button>
                        )}
                        {current > 0 && (
                            <Button onClick={() => prev()} className='prev'>
                                Previous
                            </Button>
                        )}
                    </div>
                </div>
            </Form>
        </>
    )
}

export default TourBookingConfirmation