import React, { useEffect, useState } from 'react'
import { getUserTour } from '../../../../../../redux/api/userTour.api'
import ReactQuill from 'react-quill'
import { notification, Tabs } from 'antd'

import './styles.scss'
import BookingDetails from './BookingDetails'
import TourDetails from './TourDetails'
import BookingAccommodations from './BookingAccommodations'
import BookingPayments from './BookingPayments'
import LoadingComp from '../../../../../components/LoadingComp'
import BookingItinery from './BookingItinery'

const TourBooking = () => {
    const url = window.location.pathname
    const tourId = url.split('/').pop()
    const [userTour, setUserTour] = useState(null)
    const [loading, setLoading] = useState(false)
    const [messageApi, contextHolder] = notification.useNotification();


    useEffect(() => {
        handleGetUserTour()
    }, [])

    const handleGetUserTour = async () => {
        setLoading(true)
        try {
            const response = await getUserTour(tourId)
            setUserTour(response.data)
        } catch (error) {
            console.log('ERROR GETTING USER TOUR', error)
        }
        setLoading(false)
    }


    const items = [
        {
            key: '1',
            label: 'Booking Details',
            children: <BookingDetails userTour={userTour} handleGetUserTour={handleGetUserTour} messageApi={messageApi} />
        },
        {
            key: '2',
            label: 'Tour Details',
            children: <TourDetails userTour={userTour} />
        },
        {
            key: '3',
            label: 'Tour Accommodation',
            children: <BookingAccommodations userTour={userTour} />
        },
        {
            key: '4',
            label: 'Payments',
            children: <BookingPayments userTour={userTour} />
        },
        {
            key: '5',
            label: 'Itinerary',
            children: <BookingItinery userTour={userTour} handleGetUserTour={handleGetUserTour} />
        },
        // {
        //     key: '5',
        //     label: 'Chat',
        //     children: <div>Heloo</div>
        // },
        // {
        //     key: '6',
        //     label: 'Reviews',
        //     children: <div>Heloo</div>
        // }
    ]

    return (
        <div className='personal_details tour_booking_details_container'>
            {contextHolder}
            {!loading ? <>
                <h1 className='heder_2'>
                    {userTour?.tourPackage?.packageName}
                </h1>
                <ReactQuill
                    value={userTour?.tourPackage?.shotDescription}
                    readOnly={true}
                    theme='bubble'
                    style={{ margin: '10px 0' }}
                />
                <div className='tour_booking_details'>
                    <Tabs
                        defaultActiveKey="1"
                        items={items}
                    />
                </div>
            </>
                :
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '50vh'
                    }}
                >
                    <LoadingComp />
                </div>
            }
        </div>
    )
}

export default TourBooking