import { Button, Form, Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { FacebookFilled, InstagramFilled, RobotOutlined, SmileOutlined, YoutubeFilled } from '@ant-design/icons';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import './styles.scss';
import LineLoader from '../LineLoader';

import sltda_logo from '../../Assets/icons/sltda_logo.png';

const Footer = () => {
    const formRef = useRef();

    const MAILCHIMP_URL = process.env.REACT_APP_MAILCHIMP_URL;

    // Custom form component for MailchimpSubscribe
    const CustomForm = ({ status, message, onValidated }) => {
        const [email, setEmail] = useState("");

        const handleSubmit = () => {
            if (email && email.indexOf("@") > -1) {
                onValidated({ EMAIL: email });
            }
        };

        useEffect(() => {
            if (status === "success") {
                setEmail("");
                formRef.current.resetFields();
            }
        }, [status]);

        return (
            <Form
                hideRequiredMark
                onFinish={handleSubmit}
                ref={formRef}
            >
                <div className='input'>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                            },
                            {
                                type: 'email',
                                message: 'Please enter a valid email address!',
                            },
                            {
                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email format!',
                            }
                        ]}
                        style={{ width: '100%' }}
                    >
                        <Input
                            placeholder="Enter your email address"
                            style={{ width: '100%' }}
                            size='large'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Item>
                    <Button
                        className='custom_secondery_btn'
                        htmlType='submit'
                        size='large'
                        type="primary"
                        loading={status === "sending"}
                    >
                        {
                            status === "sending" ? 'Subscribing...' : 'Subscribe'
                        }
                    </Button>
                </div>

                {status === "sending" && <div className='subscribing_loader'><LineLoader /> </div>}
                {status === "error" && (
                    <div className='subscribing_error'>
                        <RobotOutlined />
                        {message}
                    </div>
                )}
                {status === "success" && (
                    <div className='subscribing_success'>
                        <SmileOutlined />
                        Thank you for subscribing!
                    </div>
                )}
            </Form>
        );
    };

    return (
        <div className='footer'>
            <div className='footer__top'>
                <div className='description'>
                    <img src='https://visitpearlsharedblob.blob.core.windows.net/appassets/weblogo.webp' alt='logo' />
                    <p>
                        Pearl Travel is a travel agency that offers the best deals on flights, hotels, and car rentals. We also provide travel guides and tips to help you plan your next trip.
                    </p>

                    <div className='sltda_registration'>
                        <img src={sltda_logo} alt='sltda_logo' />
                        <div>
                            <p>Sri Lanka Tourism Development Authority Registration No</p>
                            <span>SLTDA/SQA/PRO/TA/00948</span>
                        </div>
                    </div>
                </div>

                <div className='footer__links'>
                    <div className='footer__links__column'>
                        <h1>Explore</h1>
                        <ul>
                            <li>
                                <a href='/about-sri-lanka' target='_blank' rel='noreferrer'>Explore Sri Lanka</a>
                            </li>
                            <li>
                                <a href='/support' target='_blank' rel='noreferrer'>Help Centre</a>
                            </li>
                            <li>
                                <a href='/safety-and-trust' target='_blank' rel='noreferrer'>Service Guarantee</a>
                            </li>
                            <li>
                                <a href='/blogs' target='_blank' rel='noreferrer'>Blog</a>
                            </li>
                            <li>
                                <a href='/blogs' target='_blank' rel='noreferrer'>Travel Articles</a>
                            </li>
                            <li>
                                <a href='/dashboard/my-reviews' target='_blank' rel='noreferrer'>Write a Review</a>
                            </li>
                        </ul>
                    </div>
                    <div className='footer__links__column'>
                        <h1>Useful Links</h1>
                        <ul>
                            <li>
                                <a href='/destinations' target='_blank' rel='noreferrer'>Destinations</a>
                            </li>
                            <li>
                                <a href='/tours' target='_blank' rel='noreferrer'>Tour Packages</a>
                            </li>
                            <li>
                                <a href='/custom-tours' target='_blank' rel='noreferrer'>Custom Tours</a>
                            </li>
                            <li>
                                <a href='/custom-tours/plan-event' target='_blank' rel='noreferrer'>Plan My Event</a>
                            </li>
                            <li>
                                <a href='/dashboard/my-bookings' target='_blank' rel='noreferrer'>Your Bookings</a>
                            </li>
                            <li>
                                <a href='/support/faq' target='_blank' rel='noreferrer'>FAQ</a>
                            </li>
                        </ul>
                    </div>
                    <div className='footer__links__column'>
                        <h1>More</h1>
                        <ul>
                            <li>
                                <a href='/custom-tours/plan-event' target='_blank' rel='noreferrer'>About Us</a>
                            </li>
                            <li>
                                <a href='/terms-and-conditions/#terms-of-use' target='_blank' rel='noreferrer'>Terms of Use</a>
                            </li>
                            <li>
                                <a href='/privacy-policy' target='_blank' rel='noreferrer'>Privacy Policy</a>
                            </li>
                            <li>
                                <a href='/safety-and-trust' target='_blank' rel='noreferrer'>Safety & Trust</a>
                            </li>
                            <li>
                                <a href='/privacy-policy/#cookies' target='_blank' rel='noreferrer'>Cookie consent</a>
                            </li>
                            <li>
                                <a href='/support' target='_blank' rel='noreferrer'>Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='newsletter'>
                    <h1>Subscribe to our newsletter</h1>

                    <MailchimpSubscribe
                        url={MAILCHIMP_URL}
                        render={({ subscribe, status, message }) => (
                            <CustomForm
                                status={status}
                                message={message}
                                onValidated={formData => subscribe(formData)}
                            />
                        )}
                    />

                    <p>
                        Get the latest travel news, tips, and special offers delivered straight to your inbox.
                    </p>

                    <div className='social__links'>
                        <a href='https://www.facebook.com/profile.php?id=61560704055422' target='_blank' rel='noreferrer'><FacebookFilled /></a>
                        <a href='https://www.instagram.com/visit.pearl' target='_blank' rel='noreferrer'><InstagramFilled /></a>
                        <a href='https://www.youtube.com/@VisitPearl' target='_blank' rel='noreferrer'><YoutubeFilled /></a>
                    </div>
                </div>

            </div>

            <div className='footer__bottom'>
                <p>© 2024 <a href='/'>Visit Pearl</a>. All Rights Reserved.</p>

                <p>
                    Designed & Developed by <a
                        href='https://www.isuruariyarathna.me/'
                        target='_blank'
                        rel='noreferrer'
                    >Isuru Ariyarathna</a>
                </p>
            </div>
        </div>
    );
}

export default Footer;