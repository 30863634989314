import axios from 'axios';
import { getAccessToken } from '../../App/azure/auth';
import { msalInstance } from '../../App/azure/authConfig';
import { API_BASE_URL } from "../../App/azure/api";

export const createUserTour = async (values) => {
    const ipAddress = localStorage.getItem('vp-ip');
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/createUserTour?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        return error;
    }
}

export const getAllTourBookingsByUser = async (userId) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getAllTourBookingsByUser?userId=${userId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const editUserTour = async (values) => {
    const ipAddress = localStorage.getItem('vp-ip');
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/editUserTour?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        return error;
    }
}

export const editUserTourCustomer = async (values) => {
    const ipAddress = localStorage.getItem('vp-ip');
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/editUserTourCustomer?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        return error;
    }
}

export const getUserTour = async (tourId) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getUserTour?id=${tourId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}


export const getHotelsByUserTour = async (id) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getHotelsByUserTour?id=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const addPayment = async (values) => {
    const ipAddress = localStorage.getItem('vp-ip');
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/addPayment?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        return error;
    }
}

export const addUserTourFlightDetails = async (values) => {
    const ipAddress = localStorage.getItem('vp-ip');
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/addUserTourFlightDetails?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        return error;
    }
}

// Tour Itinerary
export const getItinerariesByUserTour = async (id) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getItinerariesByUserTour?userTourId=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}



// ------------------------------------------------------------------
// Custom Tour
// ------------------------------------------------------------------

export const createCustomTour = async (values) => {
    const ipAddress = localStorage.getItem('vp-ip');
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/createCustomTour?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        return error;
    }
}

export const getCustomToursByUser = async (userId) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getCustomToursByUser?id=${userId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getCustomTourById = async (tourId) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getCustomTourById?id=${tourId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const editCustomTourCutsomer = async (values) => {
    const ipAddress = localStorage.getItem('vp-ip');
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/editCustomTourCutsomer?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        return error;
    }
}

export const getCustomTourChatMessages = async (id) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getCustomTourChatMessages?id=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const addCustomTourMessage = async (values) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/addCustomTourMessage`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const deleteCustomTourChatMessage = async (id) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/deleteCustomTourChatMessage?clientIp=${ipAddress}&id=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}


// ------------------------------------------------------------------
// Custom Event
// ------------------------------------------------------------------

export const createCustomEvent = async (values) => {
    const ipAddress = localStorage.getItem('vp-ip');
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/createCustomEvent?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        return error;
    }
}

export const getCustomEventsByUser = async (userId) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getCustomEventsByUser?id=${userId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}








export const updateUserTourItinery = async (tourType, values) => {
    const token = await getAccessToken(msalInstance);
    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/updateUserTourItinery?tourType=${tourType}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}
