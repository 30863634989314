import {
    FETCH_PROFILE,
    FETCH_PROFILE_SUCCESS,
    FETCH_PROFILE_FAIL
} from '../../types';

import { fetchProfile } from '../../api/userProfile.api';

export const fetchProfileAC = () => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_PROFILE
        });

        try {
            const profileResponse = await fetchProfile();

            dispatch({
                type: FETCH_PROFILE_SUCCESS,
                payload: profileResponse.data
            });
        } catch (error) {
            console.error('Profile Fetch Error', error);
            dispatch({
                type: FETCH_PROFILE_FAIL,
                payload: error
            });
        }
    }
}