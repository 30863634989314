import React, { useEffect, useState } from 'react'
import useMyProfile from '../../../../hooks/useMyProfile';
import { Button, Checkbox, Divider, Form, Input, notification, Select, Spin, Upload } from 'antd';

import './styles.scss'
import { useDispatch } from 'react-redux';
import { fetchProfileAC } from '../../../../../redux/actions/myProfile/myProfile.ac';
import { editUserProfile } from '../../../../../redux/api/userProfile.api';
import { countryList } from '../../../../constants/itemTypes';
import { BLOB_BASE_URL, handleImageUpload, USER_PROFILE_CONTAINER } from '../../../../azure/blob';

const PersonalDetails = () => {
    const myProfile = useMyProfile();
    const dispatch = useDispatch();
    const [fileList, setFileList] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [isProfilePicChanged, setIsProfilePicChanged] = useState(false);

    useEffect(() => {
        if (myProfile?.userProfile?.profilePicUrl) {
            setFileList([{
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: myProfile?.userProfile?.profilePicUrl,
            }]);
        }
    }, [myProfile]);

    const handleUpdateProfile = async (values) => {
        setLoading(true);

        try {
            const res = await editUserProfile(values);
            if (res.status === 200) {
                api.success({
                    message: 'Profile updated successfully',
                    description: 'Your profile has been updated successfully',
                });
                setEditIndex(null);
                await fetchProfile();
            } else {
                api.error({
                    message: 'Profile update failed',
                    description: 'Failed to update your profile',
                });
            }
        } catch (error) {
            console.log('ERROR UPDATING PROFILE', error);
            api.error({
                message: 'Profile update failed',
                description: 'Failed to update your profile',
            });
        }
        setLoading(false);
    }

    const fetchProfile = async () => {
        try {
            dispatch(fetchProfileAC());
        } catch (error) {
            console.log('ERROR FETCHING PROFILE', error);
        }
    }

    const handleProfilePicChange = async () => {
        setEditIndex(null);
        setImageLoading(true);
        let profilePicUrl = myProfile?.userProfile?.profilePicUrl;
        try {
            if (fileList.length > 0) {
                const blobRes = await handleImageUpload(
                    USER_PROFILE_CONTAINER,
                    fileList[0].originFileObj,
                    fileList[0].size,
                    `${myProfile?.id}.jpg`
                )
                if (blobRes?.blockBlobClient.url) {
                    profilePicUrl = `${BLOB_BASE_URL}/${USER_PROFILE_CONTAINER}/${myProfile?.id}.jpg`
                    const res = await editUserProfile({ profilePicUrl: profilePicUrl });

                    if (res.status === 200) {
                        api.success({
                            message: 'Profile picture updated successfully',
                            description: 'Your profile picture has been updated successfully',
                        });
                        await fetchProfile();
                    } else {
                        api.error({
                            message: 'Profile picture update failed',
                            description: 'Failed to update your profile picture',
                        });
                    }
                } else {
                    api.error({
                        message: 'Profile picture update failed',
                        description: 'Failed to update your profile picture',
                    });
                }
            } else {
                const res = await editUserProfile({ profilePicUrl: null });
                setFileList([]);

                if (res.status === 200) {
                    api.success({
                        message: 'Profile picture updated successfully',
                        description: 'Your profile picture has been updated successfully',
                    });
                    setInterval(() => {
                        window.location.reload();
                    }, 500);
                } else {
                    api.error({
                        message: 'Profile picture update failed',
                        description: 'Failed to update your profile picture',
                    });
                }
            }
        } catch (error) {
            console.log('ERROR UPDATING PROFILE PIC', error);
            api.error({
                message: 'Profile picture update failed',
                description: 'Failed to update your profile picture',
            });
        }
        setImageLoading(false);
    }

    return (
        <div className='personal_details'>
            {contextHolder}
            <h1 className='heder_2'>Personal Details</h1>
            <p className='about'>
                Below are your personal details. You can edit them by clicking the edit button.
            </p>

            <div className='personal_details__container'>
                <Divider />

                <div className='personal_details__item profile_pic'>
                    <Spin spinning={imageLoading}>
                        <Upload
                            listType="picture-card"
                            fileList={fileList}
                            onChange={({ fileList }) => {
                                setFileList(fileList)
                                setIsProfilePicChanged(true);
                            }}
                            multiple={false}
                            beforeUpload={(file) => {
                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/gif' || file.type === 'image/webp';
                                if (!isJpgOrPng) {
                                    api.error({
                                        message: 'You can only upload image files!',
                                    });
                                }
                                const isSizeValid = file.size / 1024 / 1024 < 2;
                                if (!isSizeValid) {
                                    api.error({
                                        message: 'Image must be smaller than 2MB!',
                                    });
                                }

                                const isVaid = isJpgOrPng && isSizeValid;

                                return isVaid ? false : Upload.LIST_IGNORE;
                            }}
                        >
                            {fileList.length < 1 && '+ Upload'}
                        </Upload>
                    </Spin>
                    {isProfilePicChanged && <Button type='primary' onClick={handleProfilePicChange} loading={imageLoading}>Save</Button>}
                </div>

                <Divider />

                <div className='personal_details__item'>
                    {editIndex === 0 ? (
                        <Form
                            layout='vertical'
                            name='basic'
                            onFinish={(values) => handleUpdateProfile(values)}
                            initialValues={{
                                firstName: myProfile?.userProfile?.firstName,
                                lastName: myProfile?.userProfile?.lastName,
                            }}
                            loading={loading}
                        >
                            <div className='feilds'>
                                <Form.Item
                                    label='First Name'
                                    name='firstName'
                                    rules={[{ required: true, message: 'Please input your first name!' }]}
                                >
                                    <Input placeholder='First Name' maxLength={50} />
                                </Form.Item>

                                <Form.Item
                                    label='Last Name'
                                    name='lastName'
                                    rules={[{ required: true, message: 'Please input your last name!' }]}
                                >
                                    <Input placeholder='Last Name' maxLength={50} />
                                </Form.Item>
                            </div>
                            <div className='actions'>
                                <Button type='link' onClick={() => setEditIndex(null)}>Cancel</Button>
                                <Button type='primary' loading={loading} htmlType='submit'>Save</Button>
                            </div>
                        </Form>
                    ) : (
                        <>
                            <div className='left'>
                                <h2 className='label'>Name</h2>
                                <h3 className='value'>{myProfile?.userProfile?.firstName || <span>Add your first name</span>} {myProfile?.userProfile?.lastName || <span>Add your last name</span>}</h3>
                            </div>
                            <Button className='desktopEdit' type='link' onClick={() => setEditIndex(0)}>Edit</Button>
                        </>
                    )}
                </div>

                <Divider />

                <div className='personal_details__item'>
                    {editIndex === 1 ? (
                        <Form
                            layout='vertical'
                            name='basic'
                            onFinish={(values) => handleUpdateProfile(values)}
                            initialValues={{
                                userName: myProfile?.userProfile?.userName,
                            }}
                            loading={loading}
                        >
                            <div className='feilds'>
                                <Form.Item
                                    label='Display Name'
                                    name='userName'
                                    rules={[{ required: true, message: 'Please input your display name!' }]}
                                >
                                    <Input placeholder='Display Name' maxLength={50} />
                                </Form.Item>
                            </div>
                            <div className='actions'>
                                <Button type='link' onClick={() => setEditIndex(null)}>Cancel</Button>
                                <Button type='primary' loading={loading} htmlType='submit'>Save</Button>
                            </div>
                        </Form>
                    ) : (
                        <>
                            <div className='left'>
                                <h2 className='label'>Display Name</h2>
                                <h3 className='value'>{myProfile?.userProfile?.userName || <span>Add your display name</span>}</h3>
                            </div>
                            <Button className='desktopEdit' type='link' onClick={() => setEditIndex(1)}>Edit</Button>
                        </>
                    )}
                </div>

                <Divider />

                <div className='personal_details__item'>
                    <div className='left'>
                        <h2 className='label'>Email</h2>
                        <h3 className='value'>{myProfile?.userProfile?.email}</h3>
                    </div>
                </div>

                <Divider />

                <div className='personal_details__item'>
                    {editIndex === 2 ? (
                        <Form
                            layout='vertical'
                            name='basic'
                            onFinish={(values) => handleUpdateProfile(values)}
                            initialValues={{
                                contactNumber: myProfile?.userProfile?.contactNumber,
                            }}
                            loading={loading}
                        >
                            <div className='feilds'>
                                <Form.Item
                                    label='Phone Number'
                                    name='contactNumber'
                                    rules={[
                                        { required: true, message: 'Please input your phone number!' },
                                        { pattern: new RegExp(/^[0-9]+$/), message: 'Please input valid phone number!' }
                                    ]}
                                >
                                    <Input placeholder='Phone Number' type='tel' />
                                </Form.Item>
                            </div>
                            <div className='actions'>
                                <Button type='link' onClick={() => setEditIndex(null)}>Cancel</Button>
                                <Button type='primary' loading={loading} htmlType='submit'>Save</Button>
                            </div>
                        </Form>
                    ) : (
                        <>
                            <div className='left'>
                                <h2 className='label'>Phone Number</h2>
                                <h3 className='value'>{myProfile?.userProfile?.contactNumber || <span>Add your phone number</span>}</h3>
                            </div>
                            <Button className='desktopEdit' type='link' onClick={() => setEditIndex(2)}>Edit</Button>
                        </>
                    )}
                </div>

                <Divider />

                <div className='personal_details__item'>
                    {editIndex === 3 ? (
                        <Form
                            layout='vertical'
                            name='basic'
                            onFinish={(values) => handleUpdateProfile(values)}
                            initialValues={{
                                dateOfBirth: myProfile?.userProfile?.dateOfBirth,
                            }}
                            loading={loading}
                        >
                            <div className='feilds'>
                                <Form.Item
                                    label='Date of Birth'
                                    name='dateOfBirth'
                                    rules={[
                                        { required: true, message: 'Please input your date of birth!' },
                                    ]}
                                >
                                    <Input type='date' />
                                </Form.Item>
                            </div>
                            <div className='actions'>
                                <Button type='link' onClick={() => setEditIndex(null)}>Cancel</Button>
                                <Button type='primary' loading={loading} htmlType='submit'>Save</Button>
                            </div>
                        </Form>
                    ) : (
                        <>
                            <div className='left'>
                                <h2 className='label'>Date of Birth</h2>
                                <h3 className='value'>{myProfile?.userProfile?.dateOfBirth || <span>Add your birth day</span>}</h3>
                            </div>
                            <Button className='desktopEdit' type='link' onClick={() => setEditIndex(3)}>Edit</Button>
                        </>
                    )}
                </div>

                <Divider />

                <div className='personal_details__item'>
                    <div className='left'>
                        <h2 className='label'>Joined At</h2>
                        <h3 className='value'>{myProfile?.userProfile?.createdAt}</h3>
                    </div>
                </div>

                <Divider />

                <div className='personal_details__item'>
                    {editIndex === 4 ? (
                        <Form
                            layout='vertical'
                            name='basic'
                            onFinish={(values) => handleUpdateProfile(values)}
                            initialValues={{
                                dateOfBirth: myProfile?.userProfile?.dateOfBirth,
                            }}
                            loading={loading}
                        >
                            <div className='feilds'>
                                <Form.Item
                                    label='Nationality'
                                    name='nationality'
                                    rules={[
                                        { required: true, message: 'Please input your nationality!' },
                                    ]}
                                >
                                    <Select
                                        placeholder='Select country'
                                        allowClear
                                        showSearch
                                    >
                                        {countryList?.map((country) => (
                                            <Select.Option key={country} value={country}>{country}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className='actions'>
                                <Button type='link' onClick={() => setEditIndex(null)}>Cancel</Button>
                                <Button type='primary' loading={loading} htmlType='submit'>Save</Button>
                            </div>
                        </Form>
                    ) : (
                        <>
                            <div className='left'>
                                <h2 className='label'>Nationality</h2>
                                <h3 className='value'>{myProfile?.userProfile?.nationality || <span>Add your nationality</span>}</h3>
                            </div>
                            <Button className='desktopEdit' type='link' onClick={() => setEditIndex(4)}>Edit</Button>
                        </>
                    )}
                </div>

                <Divider />

                <div className='personal_details__item'>
                    {editIndex === 5 ? (
                        <Form
                            layout='vertical'
                            name='basic'
                            onFinish={(values) => handleUpdateProfile(values)}
                            initialValues={{
                                gender: myProfile?.userProfile?.gender,
                            }}
                            loading={loading}
                        >
                            <div className='feilds'>
                                <Form.Item
                                    label='Gender'
                                    name='gender'
                                    rules={[
                                        { required: true, message: 'Please input your gender!' },
                                    ]}
                                >
                                    <Select
                                        placeholder='Select yout gender'
                                        allowClear
                                    >
                                        <Select.Option value='Male'>Male</Select.Option>
                                        <Select.Option value='Female'>Female</Select.Option>
                                        <Select.Option value='Other'>Other</Select.Option>
                                        <Select.Option value='NotSay'>Prefer not to say</Select.Option>

                                    </Select>
                                </Form.Item>
                            </div>
                            <div className='actions'>
                                <Button type='link' onClick={() => setEditIndex(null)}>Cancel</Button>
                                <Button type='primary' loading={loading} htmlType='submit'>Save</Button>
                            </div>
                        </Form>
                    ) : (
                        <>
                            <div className='left'>
                                <h2 className='label'>Gender</h2>
                                <h3 className='value'>{myProfile?.userProfile?.gender || <span>Add your gender</span>}</h3>
                            </div>
                            <Button className='desktopEdit' type='link' onClick={() => setEditIndex(5)}>Edit</Button>
                        </>
                    )}
                </div>

                <Divider />

                <div className='personal_details__item'>
                    {editIndex === 6 ? (
                        <Form
                            layout='vertical'
                            name='basic'
                            onFinish={(values) => handleUpdateProfile(values)}
                            initialValues={{
                                addressRegion: myProfile?.userProfile?.addressRegion,
                                address: myProfile?.userProfile?.address,
                                addressCity: myProfile?.userProfile?.addressCity,
                                addressZip: myProfile?.userProfile?.addressZip,
                            }}
                            loading={loading}
                        >
                            <div className='feilds address'>
                                <div className='address_top'>
                                    <Form.Item
                                        label='Country / Region'
                                        name='addressRegion'
                                        rules={[{ required: true, message: 'Please input your country / region!' }]}
                                    >
                                        <Select
                                            placeholder='Select your country / region you live'
                                            allowClear
                                            showSearch
                                        >
                                            {countryList?.map((country) => (
                                                <Select.Option key={country} value={country}>{country}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label='Address'
                                        name='address'
                                        rules={[{ required: true, message: 'Please input your address!' }]}
                                    >
                                        <Input placeholder='Address' maxLength={200} />
                                    </Form.Item>
                                </div>
                                <div className='address_bottom'>
                                    <Form.Item
                                        label='Town / City'
                                        name='addressCity'
                                        rules={[{ required: true, message: 'Please input your city / town!' }]}
                                    >
                                        <Input placeholder='Town / City' maxLength={50} />
                                    </Form.Item>

                                    <Form.Item
                                        label='Zip Code'
                                        name='addressZip'
                                        rules={[{ required: true, message: 'Please input your zip code!' }]}
                                    >
                                        <Input placeholder='Zip Code' maxLength={5} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='actions'>
                                <Button type='link' onClick={() => setEditIndex(null)}>Cancel</Button>
                                <Button type='primary' loading={loading} htmlType='submit'>Save</Button>
                            </div>
                        </Form>
                    ) : (
                        <>
                            <div className='left'>
                                <h2 className='label'>Address</h2>
                                <h3 className='value address_text'>
                                    {myProfile?.userProfile?.address ? myProfile?.userProfile?.address + ', ' : <span>Add your address,</span>}
                                    {myProfile?.userProfile?.addressCity ? myProfile?.userProfile?.addressCity + ', ' : <span>Add your city,</span>}
                                    {myProfile?.userProfile?.addressRegion ? myProfile?.userProfile?.addressRegion + ', ' : <span>Add your country,</span>}
                                    {myProfile?.userProfile?.addressZip ? myProfile?.userProfile?.addressZip + '.' : <span>Add your zip code.</span>}
                                </h3>
                            </div>
                            <Button className='desktopEdit' type='link' onClick={() => setEditIndex(6)}>Edit</Button>
                        </>
                    )}
                </div>

                <Divider />

                <div className='personal_details__item'>
                    {editIndex === 7 ? (
                        <Form
                            layout='vertical'
                            name='basic'
                            onFinish={(values) => {
                                const { passportCheck, ...rest } = values;
                                handleUpdateProfile(rest);
                            }}
                            initialValues={{
                                passportFName: myProfile?.userProfile?.passportFName,
                                passportLName: myProfile?.userProfile?.passportLName,
                                passportIssueingCountry: myProfile?.userProfile?.passportIssueingCountry,
                                passportNumber: myProfile?.userProfile?.passportNumber,
                                passportExp: myProfile?.userProfile?.passportExp,
                            }}
                            loading={loading}
                        >
                            <div className='feilds passport'>
                                <div className='passport_row'>
                                    <Form.Item
                                        label='First Name'
                                        name='passportFName'
                                        rules={[{ required: true, message: 'Please input your first name!' }]}
                                    >
                                        <Input placeholder='First Name in passport' maxLength={50} />
                                    </Form.Item>

                                    <Form.Item
                                        label='Last Name'
                                        name='passportLName'
                                        rules={[{ required: true, message: 'Please input your last name!' }]}
                                    >
                                        <Input placeholder='Last Name in passport' maxLength={50} />
                                    </Form.Item>
                                </div>
                                <div className='passport_row'>
                                    <Form.Item
                                        label='Passport Issuing Country'
                                        name='passportIssueingCountry'
                                        rules={[{ required: true, message: 'Please input your passport issuing country!' }]}
                                    >
                                        <Select
                                            placeholder='Select country'
                                            allowClear
                                            showSearch
                                        >
                                            {countryList?.map((country) => (
                                                <Select.Option key={country} value={country}>{country}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label='Passport Number'
                                        name='passportNumber'
                                        rules={[
                                            { required: true, message: 'Please input your passport number!' },
                                        ]}
                                    >
                                        <Input placeholder='Passport Number' maxLength={50} />
                                    </Form.Item>
                                </div>
                                <div className='passport_row'>
                                    <Form.Item
                                        label='Passport Expiry Date'
                                        name='passportExp'
                                        rules={[
                                            { required: true, message: 'Please input your passport expiry date!' },
                                            {
                                                validator: (rule, value) => {
                                                    const selectedDate = new Date(value);
                                                    const today = new Date();
                                                    today.setHours(0, 0, 0, 0);
                                                    if (selectedDate <= today) {
                                                        return Promise.reject('Date must be greater than today');
                                                    }
                                                    return Promise.resolve();
                                                },
                                                message: 'Date must be greater than today',
                                            }
                                        ]}
                                    >
                                        <Input type='date' />
                                    </Form.Item>
                                </div>

                                <div className='passport_row'>
                                    <Form.Item
                                        name='passportCheck'
                                        valuePropName='checked'
                                        rules={[
                                            { validator: (_, value) => value ? Promise.resolve() : Promise.reject('Please check the box to proceed!') }
                                        ]}
                                    >
                                        <div className='passport_check'>
                                            <Checkbox />
                                            <p> I consent to visitperal.com.au using and storing my passport information in accordance with the <a href='/privacy'>Privacy Policy</a> and <a href='/terms'>Terms of Use.</a></p>
                                        </div>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='actions'>
                                <Button type='link' onClick={() => setEditIndex(null)}>Cancel</Button>
                                <Button type='primary' loading={loading} htmlType='submit'>Save</Button>
                            </div>
                        </Form>
                    ) : (
                        <>
                            <div className='left passport_left'>
                                <h2 className='label'>Passport</h2>
                                <h3 className='value passport'>
                                    <h5> <h6>First Name: </h6> {myProfile?.userProfile?.passportFName || <span>Add your first name</span>}</h5>
                                    <h5><h6>Last Name: </h6>{myProfile?.userProfile?.passportLName || <span>Add your last name</span>}</h5>
                                    <h5><h6>Issuing Country: </h6>{myProfile?.userProfile?.passportIssueingCountry || <span>Add passport issuing country</span>}</h5>
                                    <h5><h6>Passport Number:</h6>{myProfile?.userProfile?.passportNumber || <span>Add passport number</span>}</h5>
                                    <h5><h6>Expiry Date:</h6>{myProfile?.userProfile?.passportExp || <span>Add passport expiry date</span>}</h5>
                                </h3>
                            </div>
                            <div className='actions desktopEdit'>
                                {(
                                    myProfile?.userProfile?.passportFName ||
                                    myProfile?.userProfile?.passportLName ||
                                    myProfile?.userProfile?.passportIssueingCountry ||
                                    myProfile?.userProfile?.passportNumber ||
                                    myProfile?.userProfile?.passportExp
                                ) &&
                                    <Button
                                        type='link'
                                        danger
                                        loading={loading}
                                        onClick={() => {
                                            handleUpdateProfile({
                                                passportFName: null,
                                                passportLName: null,
                                                passportIssueingCountry: null,
                                                passportNumber: null,
                                                passportExp: null,
                                            });
                                        }}
                                    >
                                        Delete
                                    </Button>}
                                <Button type='link' onClick={() => setEditIndex(7)}>Edit</Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PersonalDetails