import React, { useEffect } from 'react'

import './styles.scss'
import SecContactUs from '../../Home/SecContactUs'
import { Breadcrumb } from 'antd'
import { useNavigate } from 'react-router-dom'
import { CustomerServiceOutlined } from '@ant-design/icons'

const Email = () => {
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='support section_main' style={{ marginBottom: '60px' }}>
            <div className='top_padding_container'>
                <Breadcrumb
                    className='breadcrumb_pages'
                    style={{ marginBottom: '10px' }}
                    items={[
                        {
                            title: <div className='breadcrumb__item' onClick={() => navigate('/support')}><CustomerServiceOutlined /> Help & Support</div>,
                        },
                        {
                            title: <div className='breadcrumb__item last'>Email</div>,
                        },
                    ]}
                />
                <h1 className='header_1'>
                    Send Us an Email
                </h1>
            </div>
            <div className='support__container'>
                <SecContactUs />
            </div>
        </div>
    )
}

export default Email