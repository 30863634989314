import { HomeOutlined, LoadingOutlined, MenuUnfoldOutlined, SearchOutlined } from '@ant-design/icons'
import { AutoComplete, Breadcrumb, Button, Divider, Drawer, Empty, Form, Input, Pagination, Radio, Skeleton, Slider, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import './styles.scss'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Row } from 'react-bootstrap'
import { TbCactus } from 'react-icons/tb'
import { useDispatch } from 'react-redux'
import useTourPackages from '../../hooks/useTourPackages'
import { fetchTourPackagesAC } from '../../../redux/actions/tourPackages/tourPackages.ac'
import { DEFAULT_THUMBNAIL } from '../../constants/otherConstsnts'
import { searchTourPackages } from '../../../redux/api/tourPackages.api'
import { tourTypes } from '../../constants/itemTypes'
import ReactQuill from 'react-quill'
import { captilizeFirstLetter, resizeString } from '../../helpers/nameRender'
import SecTripPlanner from '../Home/SecTripPlanner'
import useCurrencyRates from '../../hooks/useCurrencyRates'
import usePageSettings from '../../hooks/usePageSettings'
import { convertCurrency } from '../../helpers/currencyConverter'

const Tours = () => {
    const [searchResults, setSearchResults] = useState([])
    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)
    const tourPackages = useTourPackages();
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [showAllTypes, setShowAllTypes] = useState(false);

    const currencyRates = useCurrencyRates()?.currencyRates
    const selectedCurrency = usePageSettings()?.selectedCurrency;

    const [page, setPage] = useState(1)
    const [minAge, setMinAge] = useState(null)
    const [maxAge, setMaxAge] = useState(null)
    const [minPrice, setMinPrice] = useState(null)
    const [maxPrice, setMaxPrice] = useState(null)
    const [durationDays, setDurationDays] = useState(5)
    const [tourType, setTourType] = useState(null)
    const [form] = Form.useForm();

    useEffect(() => {
        window.scrollTo(0, 0)
        handleFetchTourPackages({ page: 1 })
    }, [])

    const onChangeSearch = async (value) => {
        if (value?.length > 2) {
            await handleSearch(value)
        } else {
            setSearchResults([])
        }

        if (value?.length === 0) {
            setSearchResults([])
        }
    }

    const handleSearch = async (keyword) => {
        setIsSearchLoading(true)

        const response = await searchTourPackages(keyword)
        if (response && response.data) {
            addSearchResults(response.data)
        }

        setIsSearchLoading(false)
    }


    const handleFetchTourPackages = (values) => {
        dispatch(fetchTourPackagesAC(values))
    }

    const handleFetchFilteredTourPackages = (page) => {
        const data = {
            page,
            minAge: minAge || null,
            maxAge: maxAge || null,
            minPrice: minPrice || null,
            maxPrice: maxPrice || null,
            durationDays: durationDays < 7 ? null : durationDays,
            tourType: tourType || null,
        }

        handleFetchTourPackages(data)
    }

    const handleOnFilterFinish = async (values) => {
        values = {
            page: 1,
            minAge: minAge || null,
            maxAge: maxAge || null,
            minPrice: minPrice || null,
            maxPrice: maxPrice || null,
            durationDays: durationDays < 7 ? null : durationDays,
            tourType: tourType || null,
        }

        await handleFetchTourPackages(values)

        setIsFilterDrawerOpen(false)
    }

    const addSearchResults = (items) => {
        const results = items.map(item => ({
            value: item.id,
            label:
                <div className='result-row-activities'
                    onClick={() => navigate(`/tours/${item.id}`)}
                >
                    <div className='left'>
                        <LazyLoadImage
                            alt={item.packageName}
                            effect="blur"
                            wrapperProps={{
                                style: { transitionDelay: "0.1s" },
                            }}
                            src={item.thumbnailUrl || DEFAULT_THUMBNAIL}
                        />
                    </div>
                    <div className='right'>
                        <h3>{item.packageName}</h3>
                        <p>{item.durationDays} Days / {item.durationNights} Nights</p>
                    </div>
                </div>
        }))

        if (results?.length === 0) {
            results.push({
                value: 'no-results',
                label: <div className='result-row no_result'>
                    <div className='empty'>
                        <TbCactus /> <p>No Results Found</p>
                    </div>
                </div>
            })
        }

        setSearchResults(results)
    }


    const handleRenderTourTypes = () => {
        let types = Object.values(tourTypes);

        if (!showAllTypes) {
            types = types.slice(0, 5);
        }

        return types.map((type, index) => (
            <Radio key={index} value={type}>
                {type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}
            </Radio>
        ));
    }

    const clearTourTypes = () => {
        form.resetFields(['tourType']);
        setTourType(null);
    };

    const clearDurationDays = () => {
        form.resetFields(['durationDays']);
        setDurationDays(7);
    }

    const clearPrice = () => {
        setMinPrice(null);
        setMaxPrice(null);
        form.resetFields(['price']);
    }

    const clearAge = () => {
        setMinAge(null);
        setMaxAge(null);
        form.resetFields(['age']);
    }

    return (
        <div className='main__contaier tours_main'>
            <div className='hero section_main'>
                <div className='hero__background__overlay'></div>
                <div className='hero__background'>
                    <LazyLoadImage
                        alt='background-image'
                        effect="blur"
                        wrapperProps={{
                            style: { transitionDelay: "0.1s" },
                        }}
                        src='https://visitpearlsharedblob.blob.core.windows.net/appassets/toursBg.webp'
                    />
                </div>

                <div className='hero__content'>
                    <h1 data-aos="fade-up" data-aos-delay="300" className='section_title'>
                        Tour Packages
                    </h1>

                    <div className='hero__search__button' data-aos="fade-up" data-aos-delay="600">
                        <AutoComplete
                            popupClassName='certain-category-search-popup'
                            options={searchResults}
                        >
                            <Input
                                className='search__input'
                                prefix={isSearchLoading ? <LoadingOutlined /> : <SearchOutlined />}
                                placeholder='Search for tours'
                                onChange={(e) => onChangeSearch(e.target.value)}
                            />
                        </AutoComplete>
                        <Button
                            type='primary'
                            onClick={() => handleSearch()}
                            className='serach_btn'
                            loading={isSearchLoading}
                            icon={<SearchOutlined />}
                        >
                            Search
                        </Button>
                    </div>
                </div>
            </div>

            <div className="custom-shape-divider-top-1724082403">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                </svg>
            </div>
            <div className='destinations__content section_main'>
                <div className='breadcrumb__section'>
                    <Breadcrumb
                        className='breadcrumb_pages'
                        items={[
                            {
                                title: <div className='breadcrumb__item' onClick={() => navigate('/')}><HomeOutlined /> Home</div>,
                            },
                            {
                                title: <div className='breadcrumb__item last'>Tour Packages</div>,
                            },
                        ]}
                    />

                    <Button
                        type='primary'
                        className='filter__btn'
                        onClick={() => setIsFilterDrawerOpen(true)}
                    >
                        <MenuUnfoldOutlined />
                    </Button>
                </div>

                <div className='main_items__list'>
                    <div className='filter__section'>
                        <Form
                            layout='vertical'
                            form={form}
                            onFinish={handleOnFilterFinish}
                        >
                            <div className='header'>
                                <h2>Filters</h2>
                                <Button type='primary' htmlType='submit'>Apply</Button>
                            </div>

                            <Form.Item
                                label={
                                    <div className='form_label'>
                                        <p>Tour Type</p>
                                        <Button type='link' onClick={clearTourTypes} >Clear</Button>
                                    </div>
                                }
                                name='tourType'
                            >
                                <Radio.Group
                                    value={tourType}
                                    onChange={(e) => {
                                        setTourType(e.target.value);
                                        form.setFieldsValue({ destinationLocation: e.target.value });
                                    }}
                                >
                                    {handleRenderTourTypes()}
                                </Radio.Group>
                                <Button className='show_all_btn' type='link' onClick={() => setShowAllTypes(!showAllTypes)}>
                                    {showAllTypes ? 'Hide' : 'Show All'}
                                </Button>
                            </Form.Item>

                            <Divider />

                            <Form.Item
                                label={
                                    <div className='form_label'>
                                        <p>Tour Duration</p>
                                        <Button type='link' onClick={clearDurationDays} >Clear</Button>
                                    </div>
                                }
                                name='durationDays'
                            >
                                <div className='days__slider'>
                                    <Slider
                                        className='slider'
                                        min={7}
                                        max={40}
                                        onChange={(value) => setDurationDays(value)}
                                        value={durationDays}
                                    />
                                    {durationDays && <span>{durationDays || '_'} Days</span>}
                                </div>
                            </Form.Item>

                            <Divider />

                            <Form.Item
                                label={
                                    <div className='form_label'>
                                        <p>Price</p>
                                        <Button type='link' onClick={clearPrice} >Clear</Button>
                                    </div>
                                }
                                name='price'
                            >
                                <Slider
                                    className='slider'
                                    min={500}
                                    max={8000}
                                    range
                                    onChange={(value) => {
                                        setMinPrice(value[0]);
                                        setMaxPrice(value[1]);
                                    }}
                                    value={[minPrice, maxPrice]}
                                    defaultValue={[500, 8000]}
                                />
                                <p>Price Range: {minPrice || 500} AUD - {maxPrice || 8000} AUD</p>
                            </Form.Item>

                            {/* <Form.Item
                                label={
                                    <div className='form_label'>
                                        <p>Age</p>
                                        <Button type='link' onClick={clearAge} >Clear</Button>
                                    </div>
                                }
                                name='price'
                            >
                                <Slider
                                    className='slider'
                                    min={1}
                                    max={120}
                                    range
                                    onChange={(value) => {
                                        setMinAge(value[0]);
                                        setMaxAge(value[1]);
                                    }}
                                    value={[minAge, maxAge]}
                                    defaultValue={[1, 120]}
                                />
                                <p>Age Range: {minAge || 1} Y - {maxAge || 120} Y</p>
                            </Form.Item> */}
                        </Form>
                    </div>

                    <div className='items__list'>
                        <div className='added_filters'>
                        </div>
                        <div className='section__all__destinations__content'>
                            <Row xs={1} md={1} lg={1} xl={1} className="g-4">
                                {!tourPackages?.loading && tourPackages?.tourPackages?.map((item, idx) => (
                                    <Col key={idx}>
                                        <Card
                                            className='tour__packages__main_card'
                                            data-aos="fade-up"
                                            data-aos-delay={`${idx * 50}`}
                                            onClick={() => navigate(`/tours/${item?.id}`)}
                                        >
                                            <Card.Body
                                                className='destination__card__content'
                                            >
                                                <div className='left'>
                                                    <LazyLoadImage
                                                        alt={item?.packageName}
                                                        effect="blur"
                                                        wrapperProps={{
                                                            style: { transitionDelay: "0.1s" },
                                                        }}
                                                        src={item?.thumbnailUrl || DEFAULT_THUMBNAIL}
                                                    />
                                                </div>

                                                <div className='right'>
                                                    <h1>
                                                        {item?.packageName}
                                                    </h1>

                                                    <ReactQuill
                                                        value={item && resizeString(item?.shotDescription, 200)}
                                                        readOnly={true}
                                                        theme='bubble'
                                                    />
                                                    <div className='tags'>
                                                        {
                                                            item?.tags && item?.tags?.split(',')?.map((tag, index) => (
                                                                <Tag key={index}>{tag}</Tag>
                                                            ))
                                                        }
                                                    </div>

                                                    <div className='card_footer'>
                                                        <div className='details'>
                                                            <div className='item'>
                                                                <h3>Duration</h3>
                                                                <p>{item?.durationDays} Days / {item?.durationNights} Nights</p>
                                                            </div>

                                                            <div className='item'>
                                                                <h3>Tour Type</h3>
                                                                <p>{captilizeFirstLetter(item?.tourType)}</p>
                                                            </div>

                                                            <div className='item'>
                                                                <h3>Age Range</h3>
                                                                <p>
                                                                    {item?.minAge} - {item?.maxAge} Years
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='price'>
                                                            <h2>
                                                                <span>From </span>
                                                                {item?.startingPrice ? convertCurrency(item?.startingPrice, selectedCurrency, currencyRates) : 'N/A'} {selectedCurrency}
                                                            </h2>

                                                            <Button
                                                                type='primary'
                                                                onClick={() => navigate(`/tours/${item?.id}`)}
                                                            >
                                                                View Tour
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}

                                {
                                    tourPackages?.loading && <>
                                        {Array.from({ length: 4 }).map((_, idx) => (
                                            <Col key={idx}>
                                                <Card className='tour__card_loading'>
                                                    <Card.Body
                                                        className='card_body'
                                                    >
                                                        <div className='left'>
                                                            <Skeleton.Image active />
                                                        </div>
                                                        <div className='right'>
                                                            <Skeleton.Button active />
                                                            <Skeleton active />
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))}
                                    </>
                                }
                            </Row>
                            {
                                tourPackages?.loading === false && tourPackages?.tourPackages?.length === 0 && (
                                    <div className='empty_cards'>
                                        <Empty
                                            description={<span>No found</span>}
                                        />
                                    </div>
                                )
                            }
                            {(!tourPackages?.loading && tourPackages?.tourPackages?.length !== 0) &&
                                <Pagination
                                    className='pagination'
                                    defaultCurrent={page}
                                    total={tourPackages?.tourPackages[0]?.filteredTourPackagesCount}
                                    onChange={(page) => {
                                        setPage(page);
                                        handleFetchFilteredTourPackages(page);
                                    }}
                                    showSizeChanger={false}
                                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                />}
                        </div>
                    </div>
                </div>

                <div className="trip_planner">
                    <SecTripPlanner />
                </div>
            </div>

            <Drawer
                title="Basic Drawer"
                className='filter__drawer'
                placement='bottom'
                closable={false}
                open={isFilterDrawerOpen}
                onClose={() => setIsFilterDrawerOpen(false)}
                height='70vh'
                headerStyle={{ display: 'none' }}
            >
                <Form
                    layout='vertical'
                    form={form}
                    onFinish={handleOnFilterFinish}
                >
                    <div className='header'>
                        <h2>Filters</h2>
                        <div className='actions'>
                            <Button className='btnn-close' type='link' onClick={() => setIsFilterDrawerOpen(false)}>Close</Button>
                            <Button className='btn-apply' type='primary' htmlType='submit'>Apply</Button>
                        </div>
                    </div>

                    <Form.Item
                        label={
                            <div className='form_label'>
                                <p>Tour Type</p>
                                <Button type='link' onClick={clearTourTypes} >Clear</Button>
                            </div>
                        }
                        name='tourType'
                    >
                        <Radio.Group
                            value={tourType}
                            onChange={(e) => {
                                setTourType(e.target.value);
                                form.setFieldsValue({ destinationLocation: e.target.value });
                            }}
                        >
                            {handleRenderTourTypes()}
                        </Radio.Group>
                        <Button className='show_all_btn' type='link' onClick={() => setShowAllTypes(!showAllTypes)}>
                            {showAllTypes ? 'Hide' : 'Show All'}
                        </Button>
                    </Form.Item>

                    <Divider />

                    <Form.Item
                        label={
                            <div className='form_label'>
                                <p>Tour Duration</p>
                                <Button type='link' onClick={clearDurationDays} >Clear</Button>
                            </div>
                        }
                        name='durationDays'
                    >
                        <div className='days__slider'>
                            <Slider
                                className='slider'
                                min={7}
                                max={40}
                                onChange={(value) => setDurationDays(value)}
                                value={durationDays}
                            />
                            {durationDays && <span>{durationDays || '_'} Days</span>}
                        </div>
                    </Form.Item>

                    <Divider />

                    <Form.Item
                        label={
                            <div className='form_label'>
                                <p>Price</p>
                                <Button type='link' onClick={clearPrice} >Clear</Button>
                            </div>
                        }
                        name='price'
                    >
                        <Slider
                            className='slider'
                            min={500}
                            max={8000}
                            range
                            onChange={(value) => {
                                setMinPrice(value[0]);
                                setMaxPrice(value[1]);
                            }}
                            value={[minPrice, maxPrice]}
                            defaultValue={[500, 8000]}
                        />
                        <p>Price Range: {minPrice || 500} AUD - {maxPrice || 8000} AUD</p>
                    </Form.Item>

                    <Form.Item
                        label={
                            <div className='form_label'>
                                <p>Age</p>
                                <Button type='link' onClick={clearAge} >Clear</Button>
                            </div>
                        }
                        name='price'
                    >
                        <Slider
                            className='slider'
                            min={1}
                            max={120}
                            range
                            onChange={(value) => {
                                setMinAge(value[0]);
                                setMaxAge(value[1]);
                            }}
                            value={[minAge, maxAge]}
                            defaultValue={[1, 120]}
                        />
                        <p>Age Range: {minAge || 1} Y - {maxAge || 120} Y</p>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    )
}

export default Tours