import React, { useEffect, useState } from 'react'
import useMyProfile from '../../../../hooks/useMyProfile';

import './styles.scss'
import { Button, Card, Col, Divider, Drawer, Empty, Form, Input, notification, Popconfirm, Row, Skeleton, Spin, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { acceptGroupInvitation, addUserToGroup, createUserGroup, deleteUserGroup, getGroupByUserId, getGroupMembers, removeMemberFromGroup } from '../../../../../redux/api/userProfile.api';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DEFAULT_PROFILE_IMAGE } from '../../../../constants/otherConstsnts';
import { captilizeEachWord } from '../../../../helpers/nameRender';

const DashboardGroups = () => {
    const myProfile = useMyProfile();
    const [openCreate, setOpenCreate] = useState(false);
    const [createGroupLoading, setCreateGroupLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [myGroups, setMyGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [viewGropOpen, setViewGroupOpen] = useState(false);
    const [groupMembers, setGroupMembers] = useState([]);
    const [groupMembersLoading, setGroupMembersLoading] = useState(false);
    const [deleteMemberLoading, setDeleteMemberLoading] = useState(false);
    const [loadingInvite, setLoadingInvite] = useState(false);
    const [loadingAccept, setLoadingAccept] = useState(false);
    const [loadingDecline, setLoadingDecline] = useState(false);
    const [ladingDeleteGroup, setLoadingDeleteGroup] = useState(false);

    useEffect(() => {
        if (myProfile?.userProfile) {
            handleGetGroups();
        }
    }, [myProfile]);

    useEffect(() => {
        if (selectedGroup) {
            handleGetGroupMembers();
        }
    }, [selectedGroup]);

    const handleGetGroups = async () => {
        setLoading(true);
        try {
            const res = await getGroupByUserId(myProfile?.userProfile?.id);
            setMyGroups(res.data);
        } catch (error) {
            console.log('ERROR FETCHING GROUPS', error);
        }
        setLoading(false);
    }

    const handleGetGroupMembers = async () => {
        setGroupMembersLoading(true);
        try {
            const res = await getGroupMembers(selectedGroup.id);
            setGroupMembers(res.data);
        } catch (error) {
            console.log('ERROR FETCHING GROUP MEMBERS', error);
        }
        setGroupMembersLoading(false);
    }


    const onFinish = async (values) => {
        setCreateGroupLoading(true);
        try {
            values.groupType = 'TOUR';
            console.log('VALUES', values);
            const res = await createUserGroup(values);
            if (res.status === 201) {
                api.success({
                    message: 'Group created successfully',
                    description: 'You have successfully created a new group.',
                });
                setOpenCreate(false);
                handleGetGroups();
            }
            else {
                if (res?.response?.status === 400) {
                    api.error({
                        message: 'Limit reached',
                        description: 'You have reached the limit of groups you can create. Please delete an existing group to create a new one.',
                    });
                } else {
                    console.log('ERROR CREATING GROUP', res);
                    api.error({
                        message: 'Error creating group',
                        description: 'There was an error creating the group. Please try again later.',
                    });
                }
            }

        } catch (error) {
            console.log('ERROR CREATING GROUP', error);
            api.error({
                message: 'Error creating group',
                description: 'There was an error creating the group. Please try again later.',
            });
        }
        setCreateGroupLoading(false);
    }

    const handleInviteMember = async (values) => {
        setLoadingInvite(true);
        try {
            const res = await addUserToGroup(selectedGroup.id, values?.email);
            if (res.status === 200) {
                api.success({
                    message: 'Member invited successfully',
                    description: 'You have successfully invited the member to the group. An in-app notification will be sent to the user.',
                });
                handleGetGroupMembers();
            }
            else {
                if (res?.response?.data === 'User not found!') {
                    api.error({
                        message: 'User Not Found',
                        description: 'The user you are trying to invite is not registered with us. Please ask them to register first.',
                    });
                } else {
                    console.log('ERROR INVITING MEMBER', res);
                    api.error({
                        message: 'Error inviting member',
                        description: 'There was an error inviting the member. Please try again later.',
                    });
                }
            }
        } catch (error) {
            console.log('ERROR INVITING MEMBER', error);
            api.error({
                message: 'Error inviting member',
                description: 'There was an error inviting the member. Please try again later.',
            });
        }
        setLoadingInvite(false);
    }

    const handleDeleteGroup = async () => {
        setLoadingDeleteGroup(true);
        try {
            const res = await deleteUserGroup(selectedGroup.id);
            if (res.status === 200) {
                api.success({
                    message: 'Group deleted successfully',
                    description: 'You have successfully deleted the group.',
                });
                handleGetGroups();
                setViewGroupOpen(false);
                setSelectedGroup(null);
                setGroupMembers([]);
            }
        } catch (error) {
            console.log('ERROR DELETING GROUP', error);
            api.error({
                message: 'Error deleting group',
                description: 'There was an error deleting the group. Please try again later.',
            });
        }
        setLoadingDeleteGroup(false);
    }

    return (
        <div className='personal_details'>
            {contextHolder}
            <h1 className='heder_2'>
                My Travel Groups
            </h1>
            <p className='about'>
                Your travel groups will appear here. You can create a new group or join an existing group.
            </p>

            <div className='actions_main'>
                <Button className='btn_primary' onClick={() => setOpenCreate(true)}>
                    <PlusOutlined />
                    Create Group
                </Button>
            </div>

            <div className='personal_details__container'>
                {!loading ?
                    <>
                        {myGroups.length > 0 ? (
                            <div className='groups_container'>
                                {myGroups.map((group) => (
                                    <div
                                        key={group.id}
                                        className='group_card'
                                        onClick={() => {
                                            setSelectedGroup(group);
                                            setViewGroupOpen(true);
                                        }}
                                    >
                                        <div className='group_card__header'>
                                            <h3>{group.groupName}</h3>
                                            <p>{group.groupDescription}</p>
                                        </div>
                                        <div className='group_card__footer'>
                                            <Button type='link'>View Group</Button>
                                        </div>
                                    </div>
                                ))}
                            </div>)
                            :
                            <>{!loading && <Empty />}</>
                        }
                    </>
                    :
                    <>
                        {Array.from({ length: 3 }).map((_, idx) => (
                            <Col key={idx}>
                                <Card style={{
                                    height: '100%',
                                    marginBottom: '10px',
                                }}>
                                    <Skeleton active />
                                </Card>
                            </Col>
                        ))}
                    </>
                }
            </div>

            <Drawer
                title="Create a new group"
                width={720}
                onClose={() => setOpenCreate(false)}
                open={openCreate}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <Spin spinning={createGroupLoading}>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="groupName"
                                    label="Group Name"
                                    rules={[{ required: true, message: 'Please enter group name' }]}
                                >
                                    <Input placeholder="Please enter group name" maxLength={50} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="maxMenberCount"
                                    label="Max Member Count"
                                    rules={[
                                        { required: true, message: 'Please enter group description' },
                                    ]}
                                >
                                    <Input type='number' placeholder="Please enter max member count" min={1} max={30} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="groupDescription"
                                    label="Group Description"
                                    rules={[{ required: true, message: 'Please enter group description' }]}
                                >
                                    <Input.TextArea rows={4} placeholder="Please enter group description" maxLength={200} showCount />
                                </Form.Item>
                            </Col>
                        </Row>

                        <div className='actions'>
                            <Button onClick={() => setOpenCreate(false)} style={{ marginRight: 8 }} >
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit" loading={createGroupLoading}>
                                Create Group
                            </Button>
                        </div>
                    </Form>
                </Spin>
            </Drawer>

            <Drawer
                rootClassName='view_group_drawer'
                title="Group Details"
                width={720}
                onClose={() => {
                    setViewGroupOpen(false);
                    setSelectedGroup(null);
                    setGroupMembers([]);
                }}
                open={viewGropOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <div className='goup_view_header'>
                    <div className='header'>
                        <h1>{selectedGroup?.groupName}</h1>
                        {selectedGroup?.createdBy === myProfile?.userProfile?.id &&
                            <Popconfirm
                                title="Are you sure you want to delete this group?"
                                onConfirm={handleDeleteGroup}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button danger>Delete Group</Button>
                            </Popconfirm>
                        }
                    </div>
                    <p>{selectedGroup?.groupDescription}</p>
                    {myProfile?.userProfile?.id === selectedGroup?.createdBy &&
                        <Form
                            onFinish={handleInviteMember}
                        >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Invitee's Email"
                                        name="email"
                                        rules={[{ required: true, message: 'Please enter invitee\'s email' }]}
                                    >
                                        <Input placeholder="Please enter invitee's email" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Button type="primary" htmlType="submit" loading={loadingInvite}>
                                        Invite
                                    </Button>
                                </Col>
                            </Row>
                        </Form>}
                </div>
                <div className='group_vew_body'>
                    {!groupMembersLoading ?
                        <>
                            {groupMembers?.length > 0 ? (
                                <div className='group_members_container'>
                                    <Divider />
                                    {groupMembers?.map((member) => (
                                        <>
                                            <div
                                                key={member.id}
                                                className='group_member_card'
                                            >
                                                <div className='group_member_card__header'>
                                                    <div className='left'>
                                                        <LazyLoadImage
                                                            src={member.user?.profilePicUrl || DEFAULT_PROFILE_IMAGE}
                                                            alt='profile'
                                                            effect='blur'
                                                        />
                                                    </div>
                                                    <div className='right'>
                                                        <h3>{member.user?.userName}<span> {member?.userId === selectedGroup?.createdBy && <Tag
                                                            color='blue'
                                                            style={{
                                                                marginLeft: '10px',
                                                                padding: '5px 10px',
                                                            }}
                                                        >Admin</Tag>}</span></h3>

                                                        <p><span>User Email: </span>{member.user?.email}</p>
                                                        <p
                                                            style={{
                                                                color: member?.memberStatus === 'accept' ||
                                                                    member?.memberStatus === 'active'
                                                                    ? 'green' : 'red'
                                                            }}
                                                        >
                                                            {member?.memberStatus && captilizeEachWord(member?.memberStatus)
                                                            }
                                                        </p>
                                                        {
                                                            member?.memberStatus === 'invited' && member?.userId === myProfile?.userProfile?.id &&
                                                            <div className='actions'>
                                                                <Button onClick={async () => {
                                                                    setLoadingAccept(true);
                                                                    try {
                                                                        const res = await acceptGroupInvitation(
                                                                            myProfile?.userProfile?.id,
                                                                            member.id,
                                                                            selectedGroup?.id,
                                                                            'accept'
                                                                        ); if (res.status === 200) {
                                                                            api.success({
                                                                                message: 'Invitation accepted successfully',
                                                                                description: 'You have successfully accepted the invitation.',
                                                                            });
                                                                            handleGetGroupMembers();
                                                                        }
                                                                    }
                                                                    catch (error) {
                                                                        console.log('ERROR ACCEPTING INVITATION', error);
                                                                        api.error({
                                                                            message: 'Error accepting invitation',
                                                                            description: 'There was an error accepting the invitation. Please try again later.',
                                                                        });
                                                                    }
                                                                    setLoadingAccept(false);
                                                                }
                                                                }
                                                                    className='accept'
                                                                    loading={loadingAccept}
                                                                >
                                                                    Accept
                                                                </Button>

                                                                <Button danger onClick={async () => {
                                                                    setLoadingDecline(true);
                                                                    try {
                                                                        const res = await acceptGroupInvitation(
                                                                            myProfile?.userProfile?.id,
                                                                            member.id,
                                                                            selectedGroup?.id,
                                                                            'decline'
                                                                        );
                                                                        if (res.status === 200) {
                                                                            api.success({
                                                                                message: 'Invitation removed successfully',
                                                                                description: 'You have declined the invitation. You will no longer be a part of this group.',
                                                                            });
                                                                            handleGetGroupMembers();
                                                                        }
                                                                    } catch (error) {
                                                                        console.log('ERROR REJECTING INVITATION', error);
                                                                        api.error({
                                                                            message: 'Error removing invitation',
                                                                            description: 'There was an error removing the invitation. Please try again later.',
                                                                        });
                                                                    }
                                                                    setLoadingDecline(false);
                                                                }
                                                                }
                                                                    className='decline'
                                                                    loading={loadingDecline}
                                                                >
                                                                    Decline
                                                                </Button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='group_member_card__footer'>
                                                    {
                                                        myProfile?.userProfile?.id === selectedGroup?.createdBy
                                                        &&
                                                        member?.userId !== selectedGroup?.createdBy &&
                                                        <Popconfirm
                                                            title="Are you sure you want to remove this member?"
                                                            onConfirm={async () => {
                                                                setDeleteMemberLoading(true);
                                                                try {
                                                                    const res = await removeMemberFromGroup(selectedGroup?.id, member.userId);
                                                                    if (res.status === 200) {
                                                                        api.success({
                                                                            message: 'Member removed successfully',
                                                                            description: 'You have successfully removed the member from the group.',
                                                                        });
                                                                        handleGetGroupMembers();
                                                                    }
                                                                } catch (error) {
                                                                    console.log('ERROR REMOVING MEMBER', error);
                                                                    api.error({
                                                                        message: 'Error removing member',
                                                                        description: 'There was an error removing the member. Please try again later.',
                                                                    });
                                                                }
                                                                setDeleteMemberLoading(false);
                                                            }}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Button loading={deleteMemberLoading} type='link' danger>Remove Member</Button>
                                                        </Popconfirm>
                                                    }
                                                    {
                                                        myProfile?.userProfile?.id === member?.userId && member?.userId !== selectedGroup?.createdBy &&
                                                        <Popconfirm
                                                            title="Are you sure you want to leave this group?"
                                                            onConfirm={async () => {
                                                                setDeleteMemberLoading(true);
                                                                try {
                                                                    const res = await removeMemberFromGroup(selectedGroup?.id, member.userId);
                                                                    if (res.status === 200) {
                                                                        api.success({
                                                                            message: 'You have left the group',
                                                                            description: 'You have successfully left the group.',
                                                                        });
                                                                        handleGetGroupMembers();
                                                                    }
                                                                } catch (error) {
                                                                    console.log('ERROR REMOVING MEMBER', error);
                                                                    api.error({
                                                                        message: 'Error leaving group',
                                                                        description: 'There was an error leaving the group. Please try again later.',
                                                                    });
                                                                }
                                                                setDeleteMemberLoading(false);
                                                            }}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Button loading={deleteMemberLoading} type='link' danger>
                                                                Leave Group
                                                            </Button>
                                                        </Popconfirm>
                                                    }
                                                </div>
                                            </div>
                                            <Divider />
                                        </>
                                    ))}
                                </div>)
                                :
                                <>{!groupMembersLoading && <Empty />}</>
                            }
                        </>
                        :
                        <>
                            {Array.from({ length: 3 }).map((_, idx) => (
                                <Col key={idx}>
                                    <Card style={{
                                        height: '100%',
                                        marginBottom: '10px',
                                    }}>
                                        <Skeleton active />
                                    </Card>
                                </Col>
                            ))}
                        </>
                    }
                </div>
            </Drawer>
        </div>
    )
}

export default DashboardGroups