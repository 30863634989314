export const convertCurrency = (amount, toCurrency, currencyRates) => {
    if (!amount || !toCurrency || !currencyRates) {
        return
    }
    const rates = currencyRates?.currencyList;

    const toRate = rates[toCurrency];

    if (!toRate) {
        console.log('INVALID CURRENCY')
        return
    }

    const convertedAmount = (amount * toRate).toFixed(2);

    return parseFloat(convertedAmount);
};
