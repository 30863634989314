import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CookieIcon } from 'lucide-react';
import './styles.scss';

const CookieConsentPopup = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consentGiven = localStorage.getItem('cookieConsent');
        if (!consentGiven) {
            setIsVisible(true);
        }
    }, []);

    const handleConsent = () => {
        localStorage.setItem('cookieConsent', 'true');
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.3 }}
                className="cookie-consent-wrapper"
            >
                <div className="cookie-consent-card">
                    <div className="cookie-consent-icon">
                        <CookieIcon />
                    </div>
                    <div className="cookie-consent-content">
                        <p>By using this website you automatically accept that we use cookies. <a href="/privacy-policy/#cookies" target="_blank" rel="noreferrer">
                            What for?
                        </a>
                        </p>
                        <button
                            onClick={handleConsent}
                            className="cookie-consent-button"
                        >
                            Understood
                        </button>
                    </div>
                </div>
            </motion.div>
        </AnimatePresence>
    );
};

export default CookieConsentPopup;