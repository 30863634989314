const PENDING_PAYMENT = 'PENDING_PAYMENT';
const PENDING_CONFIRMATION = 'PENDING_CONFIRMATION';
const CONFIRMED = 'CONFIRMED';
const CANCELLED = 'CANCELLED';
const COMPLETED = 'COMPLETED';
const ONGOING = 'ONGOING';
const PENDING_APPROVAL = 'PENDING_APPROVAL';

export {
    PENDING_PAYMENT,
    PENDING_CONFIRMATION,
    CONFIRMED,
    CANCELLED,
    COMPLETED,
    ONGOING,
    PENDING_APPROVAL
};