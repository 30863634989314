import { notification, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { getAccommodationBookingById } from '../../../../../../redux/api/accommodations.api'
import LoadingComp from '../../../../../components/LoadingComp'
import AccommodationBookingDetails from './AccommodationBookingDetails'
import AccommodationPayment from './AccommodationPayment'

const AccommodationBooking = () => {
    const url = window.location.pathname
    const bookingId = url.split('/').pop()
    const [booking, setBooking] = useState(null)
    const [loading, setLoading] = useState(false)
    const [messageApi, contextHolder] = notification.useNotification();

    useEffect(() => {
        handleGetBooking()
    }, [])

    const handleGetBooking = async () => {
        setLoading(true)
        try {
            const response = await getAccommodationBookingById(bookingId)
            setBooking(response.data)
        } catch (error) {
            console.log('ERROR GETTING USER TOUR', error)
        }
        setLoading(false)
    }

    const items = [
        {
            key: '1',
            label: 'Booking Details',
            children: <AccommodationBookingDetails booking={booking} />
        },
        {
            key: '2',
            label: 'Payment Details',
            children: <AccommodationPayment booking={booking} handleGetBooking={handleGetBooking} messageApi={messageApi} />
        }
    ]

    return (
        <div className='personal_details tour_booking_details_container'>
            {contextHolder}
            {!loading ?
                <>
                    <h1 className='heder_2'>
                        {"Booking to " + booking?.accommodation?.accommodationName + " on " + booking?.checkIn}
                    </h1>
                    <div className='tour_booking_details'>
                        <Tabs
                            defaultActiveKey="1"
                            items={items}
                        />
                    </div>
                </>
                :
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '50vh'
                    }}
                >
                    <LoadingComp />
                </div>
            }
        </div>
    )
}

export default AccommodationBooking