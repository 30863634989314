import { EnvironmentOutlined, MailFilled, PhoneFilled } from '@ant-design/icons'
import { Button, Col, Form, Input, notification, Radio, Row } from 'antd'
import React, { useRef, useState } from 'react'

import { supporRequestTypes } from '../../../constants/itemTypes'
import { AUTH_USER, UN_AUTH_USER } from '../../../constants/roles'
import { createSupportRequest } from '../../../../redux/api/support.api'

import './styles.scss'
import useMyProfile from '../../../hooks/useMyProfile'

const SecContactUs = () => {
    const [loading, setLoading] = useState(false)
    const formRef = useRef()
    const [api, contextHolder] = notification.useNotification();
    const myProfile = useMyProfile();

    const handleFormSubmit = async (values) => {
        setLoading(true)

        values.userType = myProfile?.userProfile?.id ? AUTH_USER : UN_AUTH_USER;
        values.userId = myProfile?.userProfile?.id || null;
        values.origin = 'HOME_PAGE'
        values.preferedContactMethod = 'EMAIL'

        try {
            const response = await createSupportRequest(values)
            if (response.status === 200) {
                api.success({
                    message: 'Message Sent',
                    description: 'Thank you for contacting us. We will get back to you soon.'
                })
                formRef.current.resetFields()
            } else {
                api.error({
                    message: 'Failed to send message',
                    description: 'Please try again later.'
                })
            }
        }
        catch (error) {
            console.error(error)
            api.error({
                message: 'Failed to send message',
                description: 'Please try again later.'
            })
        }

        setLoading(false)
    }

    return (
        <div className='section_main contact_section'>
            {contextHolder}
            <div className='inner_contect'
                data-aos="zoom-in"
            >
                <div className='left'
                    style={{
                        backgroundImage: 'url(https://visitpearlsharedblob.blob.core.windows.net/appassets/contact_us_bg.webp)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}
                >
                    <div>
                        <h1
                            data-aos="fade-up"
                            data-aos-delay="100"
                        >
                            Contact Information
                        </h1>
                        <h3
                            data-aos="fade-up"
                            data-aos-delay="200"
                        >
                            We are here to help you with any questions you may have.
                        </h3>
                    </div>

                    <section>
                        <p
                            data-aos="fade-up"
                            data-aos-delay="300"
                        >
                            <PhoneFilled />
                            <a href='tel:+61448551279'>+61 448 551 279</a>
                        </p>
                        <p
                            data-aos="fade-up"
                            data-aos-delay="400"
                        >
                            <MailFilled />
                            <a href='mailto:info@visitpearl.com.au'>info@visitpearl.com.au</a>
                        </p>
                        <p
                            data-aos="fade-up"
                            data-aos-delay="500"
                        >
                            <EnvironmentOutlined />
                            VISITPEARL.COM, Toowoomba, QLD.
                        </p>
                    </section>
                </div>

                <div className='right'>
                    <Form
                        name='contact-us'
                        layout='vertical'
                        requiredMark={false}
                        onFinish={handleFormSubmit}
                        ref={formRef}
                        data-aos="fade-down"
                        data-aos-delay="300"
                    >
                        <div className='row'>
                            <div className='col'>
                                <Form.Item
                                    name='firstName'
                                    label='First Name'
                                    rules={[{ required: true, message: 'Please input your first name!' }]}
                                >
                                    <Input maxLength={20} />
                                </Form.Item>
                            </div>
                            <div className='col'>
                                <Form.Item
                                    name='lastName'
                                    label='Last Name'
                                    rules={[{ required: true, message: 'Please input your last name!' }]}
                                >
                                    <Input maxLength={20} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <Form.Item
                                    name='email'
                                    label='Email'
                                    rules={
                                        [
                                            { required: true, message: 'Please input your email!' },
                                            { type: 'email', message: 'Please input a valid email!' }
                                        ]
                                    }
                                >
                                    <Input maxLength={100} />
                                </Form.Item>
                            </div>
                            <div className='col'>
                                <Form.Item
                                    name='contactNo'
                                    label='Phone Number'
                                    rules={
                                        [
                                            { required: true, message: 'Please input your phone number!' },
                                            { pattern: /^[0-9]+$/, message: 'Please input a valid phone number!' }
                                        ]
                                    }
                                >
                                    <Input maxLength={20} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='row subject'>
                            <div className='col'>
                                <h3>Select Subject</h3>
                                <Form.Item
                                    name='subject'
                                    rules={[{ required: true, message: 'Please select a subject!' }]}
                                >
                                    <Radio.Group>
                                        <Radio value={supporRequestTypes.GENERAL}>General Inquiry</Radio>
                                        <Radio value={supporRequestTypes.BOOKING}>Booking Inquiry</Radio>
                                        <Radio value={supporRequestTypes.FEEDBACK}>Feedback</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </div>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name='message'
                                    label='Message'
                                    rules={[{ required: true, message: 'Please input your message!' }]}
                                >
                                    <Input.TextArea maxLength={500} placeholder='Wite your message...' />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item className='submit_btn'>
                            <Button
                                loading={loading}
                                className='custom_primary_btn'
                                htmlType='submit'
                            >
                                {
                                    loading ? 'Sending...'
                                        : 'Send Message'
                                }
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default SecContactUs