import axios from 'axios';
import { API_BASE_URL, API_BASE_URL_OPEN } from '../../App/azure/api';
import { getAccessToken } from '../../App/azure/auth';
import { msalInstance } from '../../App/azure/authConfig';

export const searchAccommodationPackage = async (searchTerm) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL_OPEN}/searchAccommodationPackage?keyword=${searchTerm}`,
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getAllAccommodationPackages = async (values) => {
    const params = new URLSearchParams();

    if (values.page !== undefined && values.page !== null) params.append('page', values.page);
    if (values.pageSize !== undefined && values.pageSize !== null) {
        params.append('pageSize', values.pageSize);
    } else {
        params.append('pageSize', 10);
    }
    if (values.accommodationType) params.append('accommodationType', values.accommodationType);
    if (values.accommodationCity) params.append('accommodationCity', values.accommodationCity);
    if (values.accommodationRating) params.append('accommodationRating', values.accommodationRating);
    if (values.accommodationTags) params.append('accommodationTags', values.accommodationTags);
    if (values.minPrice) params.append('minPrice', values.minPrice);
    if (values.maxPrice) params.append('maxPrice', values.maxPrice);
    if (values.packageRoomType) params.append('packageRoomType', values.packageRoomType);

    const url = `${API_BASE_URL_OPEN}/getAllAccommodationPackages?${params.toString()}`;

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: url,
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getAccommodationPackageById = async (id) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL_OPEN}/getAccommodationPackageById?id=${id}`,
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const createAccommodationBooking = async (values) => {
    const ipAddress = localStorage.getItem('vp-ip');
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/addAccommodationsBooking?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        return error;
    }
}

export const getAllAccommodationBookingsByUser = async (userId) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getAllAccommodationBookingsByUser?userId=${userId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getAccommodationBookingById = async (id) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getAccommodationBookingById?id=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const editAccommodationBookingCustomer = async (values) => {
    const ipAddress = localStorage.getItem('vp-ip');
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/editAccommodationBookingCustomer?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        return error;
    }
}