import React, { useEffect, useState } from 'react'

import './styles.scss'
import { Button, Divider, Drawer } from 'antd'
import { MenuUnfoldOutlined } from '@ant-design/icons'

const items = [
    {
        key: '1',
        label: 'About Us & Contact',
        link: '#about-us',
    },
    {
        key: '2',
        label: 'About Terms and Conditions',
        link: '#about-terms',
    },
    {
        key: '3',
        label: 'Website Rules',
        link: '#website-rules',
    },
    {
        key: '4',
        label: 'Booking Terms',
        link: '#booking',
    },
    {
        key: '5',
        label: 'Flight Terms',
        link: '#flight',
    },
    {
        key: '6',
        label: 'Hotel Terms',
        link: '#hotel',
    },
    {
        key: '7',
        label: 'Tours & Tickets',
        link: '#tours',
    },
    {
        key: '8',
        label: 'Personal Data',
        link: '#personal-data',
    },
    {
        key: '9',
        label: 'Liability',
        link: '#liability',
    },
    {
        key: '10',
        label: 'Complaints',
        link: '#complaints',
    },
    {
        key: '11',
        label: 'Country Laws',
        link: '#country-laws',
    },
    {
        key: '12',
        label: 'Language',
        link: '#language',
    },
    {
        key: '13',
        label: 'General',
        link: '#general',
    },
]

const TermsAndCondtions = () => {
    const [isNavDrawerOpen, setIsNavDraweOpen] = useState(false);

    useEffect(() => {
        const url = window.location.href;
        const section = url.split('#')[1];
        if (section) {
            scrollToSection(`#${section}`);
        }
    }, []);

    const scrollToSection = (id) => {
        const section = document.querySelector(id);
        if (section) {
            const offset = window.innerWidth > 768 ? 100 : 60;
            const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - offset;

            window.scrollTo({
                top: sectionPosition,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div className='termsandcondition section_main' style={{ marginBottom: '60px' }}>
            <div className='top_padding_container'>
                <h1 className='header_1'>
                    Terms and Conditions
                </h1>
                <p>
                    Version 1.0.0 - Last updated: 2024-10-06
                </p>
            </div>

            <div className='mobile_nav'>
                <Button onClick={() => setIsNavDraweOpen(true)}><MenuUnfoldOutlined /> Open Menu</Button>
            </div>

            <div className='body'>
                <div className='terms__nav'>
                    {items.map((item) => (
                        <div
                            key={item.key}
                            className='terms__nav__container__items__item'
                            onClick={() => scrollToSection(item.link)}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>

                <div className='terms'>
                    <h3 className='heder_2' id='about-us'>
                        About Us & How to Contact Us
                    </h3>
                    <p className='paragraph'>
                        Visitpearl.com is a website and mobile application (<strong>“Website”</strong>) operated by visitpearl.com pvt. Ltd., a limited liability company based at <strong>478, Gonawala, Kelaniya, Sri Lanka</strong> (”we”, “our” or “us”). We are a global online travel agency (SLTDA registration here).
                    </p>
                    <p className='paragraph'>
                        To contact us, please email or telephone our customer service team (for our email address and helpline number, please see our <a href='/support' target='_blank' rel='noreferrer' className='default_anchor'>contact us</a> page.) Live chat for customer support is currently in progress, which is to be published at the next update.
                    </p>

                    <h3 className='heder_2 sec' id='about-terms'>
                        About these Terms and Conditions
                    </h3>
                    <p className='paragraph'>
                        You can find out the availability of travel-related goods and services as well as travel-related information by using our website. Additionally, it enables you to reserve tickets for flights, lodging, cars (with or without drivers), airport transportation, and attraction passes (collectively, "Travel Products") offered by independent vendors (referred to as "Suppliers"). For reservations placed on our website, we also offer client assistance. These booking services are subject to these terms.
                    </p>
                    <p className='paragraph'>The terms and conditions of the applicable Supplier, such as the terms and conditions of the airline or hotel, also apply when you book a travel product. These constitute a distinct legal agreement between you and the applicable Supplier, so it is crucial that you read them. Each of the sections below on travel products has more details on this. These agreements take precedence over the Supplier's terms and conditions in the event of any discrepancies.
                        Certain Travel Products are subject to other terms and conditions (such as rights to amend or cancel), which will be communicated to you during the booking process and are a part of these terms. Before making a reservation, carefully read them.</p>
                    <p className='paragraph'>These conditions also outline the guidelines for using our website, including the content standards that apply when you link to, upload content to, or otherwise interact with it.</p>
                    <p className='paragraph'>You attest to your acceptance of these terms and any applicable terms and conditions from the supplier by using our website and/or completing a reservation with us. It is advised that you carefully read these terms and print a copy for your records. Please use the information in section 1 to get in touch with us if there is anything in these terms that you do not understand. You may not use our website or make a reservation with us if you disagree with these conditions.</p>
                    <p className='paragraph'>You represent and warrant that you will only use our Website to make authorized reservations and that you are of legal age to enter into a legally binding agreement in your country.</p>
                    <p className='paragraph'>If you are making reservations for Travel Products on behalf of multiple people, you will be held accountable for the following: (i) giving true information about the other people; (ii) accepting these terms and making sure the other people abide by them; (iii) double-checking the information in the booking confirmation and getting in touch with us right away if there are any errors; and (iv) paying the full cost of the reservation and any additional fees associated with it.</p>
                    <p className='paragraph'>These terms could change at any time. Any changes will become effective immediately upon posting, and we will record the most recent modification date at the top of these terms. Please review these terms each time you want to use our website and/or make a reservation with us to make sure you are aware of the terms that are in effect at that moment.</p>
                    <p className='paragraph'>When this website is mentioned, derivatives are understood to include references to it, such as connected websites and applications that can be accessed on a tablet, smartphone, or other device.</p>


                    <Divider />


                    <h3 className='heder_2 sec' id='website-rules'>
                        Rules for Using Our Website
                    </h3>
                    <h3 className='header_4'>
                        We may make changes to our website
                    </h3>
                    <p className='paragraph'>
                        We may update and change our Website from time to time to reflect changes to our services, our users’ needs and our business priorities. We will try to give you reasonable notice of any major changes.
                    </p>
                    <h3 className='header_4'>
                        We may suspend or withdraw our website
                    </h3>
                    <p className='paragraph'>
                        Our Website is made available free of charge.
                    </p>
                    <p className='paragraph'>
                        We make no promises regarding the availability or continuity of our website or any of the content on it. For administrative and business purposes, we reserve the right to discontinue, remove, or limit access to all or any portion of our website. We shall make an effort to notify you in a timely manner of any suspension or withdrawal.
                    </p>
                    <p className='paragraph'>
                        Our website's operation depends on devices and the internet. It is acknowledged and agreed upon by you that we shall not be held responsible for any damages you may incur as a result of our Website being unavailable due to uncontrollable events, circumstances, or causes, including but not limited to internet, system or device instability, computer viruses, and hacker attacks.
                    </p>
                    <p className='paragraph'>
                        You are in charge of making sure that everyone who visits our website through your internet connection is aware of these terms and any other applicable terms and conditions and complies with them, as well as providing the required hardware, which may include but is not limited to an appropriate device and an internet connection.
                    </p>
                    <h3 className='header_4'>
                        Your account details
                    </h3>
                    <p className='paragraph'>You guarantee that all of the account information you have provided is true, accurate, up to date, and comprehensive.</p>
                    <p className='paragraph'>As part of our security protocols, you must regard any information that you choose or are given a user ID, password, or other piece of information as confidential. It must not be revealed to any outside parties. You recognize and accept that failure on your part to treat such information as confidential will result in no losses for which we will be responsible.</p>
                    <p className='paragraph'>If, in our reasonable opinion, you have broken any of these rules, we reserve the right to disable any password or user identification code that you have chosen or that we have assigned to you.</p>
                    <p className='paragraph'>You must notify us right away using the contact information in section 1 of these rules if you know or think that someone else is in possession of your password or user ID. To lessen potential losses in this case, you will also get in touch with your payment account provider if you have stored your payment information to your account.</p>
                    <p className='paragraph'>You consent to assist us with any required anti-fraud or anti-money laundering investigations.</p>
                    <p className='paragraph'>It is your responsibility to keep your personal account's password and related information secure. Please get in touch with our Customer Service right once if you believe that a fraudulent or unauthorized reservation was made through us using your payment instrument. We take no liability for such instances.</p>
                    <h3 className='header_4'>
                        How you may use material on our website
                    </h3>
                    <p className='paragraph'>All patents, copyrights, trade names, business names, domain names, design rights, computer software rights, database rights, and other intellectual property rights whether registered or unregistered found on this website are either owned by us or are under license. These kinds of rights are all reserved.</p>
                    <p className='paragraph'>You are free to download and print portions of any page(s) from our website for personal use. You may also use this to alert others to content that has been put on our website.</p>
                    <p className='paragraph'>You are not permitted to make any changes to the paper or digital copies of any materials you have downloaded or printed off, nor are you permitted to use any images, photos, video, or audio clips apart from the accompanying text.</p>
                    <p className='paragraph'>We must always provide credit where credit is due for any content on our website, including to any known contributors.</p>
                    <p className='paragraph'>You are not allowed to utilize any portion of the content on our website for profit-making without first obtaining our express consent or the consent of those who are already authorized to do so.</p>
                    <p className='paragraph'>Your right to use our website will immediately terminate and you will be required to either return or destroy any copies of the contents you have made, at our discretion, if you print off, copy, or download any portion of it in violation of these terms.</p>
                    <h3 className='header_4'>
                        We are not responsible for websites we link to
                    </h3>
                    <p className='paragraph'>The links on our website that lead to external websites and resources offered by third parties are provided solely for your information. These links should not be seen as an endorsement by us of the linked sites or any information you may find there.</p>
                    <p className='paragraph'>The information on those websites and other resources is beyond our control.</p>
                    <h3 className='header_4'>
                        User-generated content is not approved by us
                    </h3>
                    <p className='paragraph'>This website might contain content that other users have posted, such as review services, discussion forums, and bulletin boards. We have confirmed and authorized these items and information before they are published, provided they don't go against our guidelines for content. Please be aware that the opinions shared by other users on our website do not always reflect our opinions or ideals. We hereby explicitly disclaim any duty for any loss or harm resulting from a user's usage of any interactive section in violation of the following content guidelines.</p>
                    <p className='paragraph'>Please use the information in section 1 of these conditions to get in touch with us if you have any complaints regarding content and information that other users have contributed.</p>
                    <h3 className='header_4'>
                        Prohibited uses
                    </h3>
                    <p className='paragraph'>You may only use our Website for lawful purposes. By way of example, and not as a limitation, you may not use our Website or services:</p>
                    <ul>
                        <li>In any way that breaches any applicable local, national or international law or regulation.</li>
                        <li>In any way that is unlawful, fraudulent, unauthorised, or abusive, or has any unlawful, fraudulent, unauthorised, or abusive purpose or effect.</li>
                        <li>For the purpose of harming or attempting to harm minors in any way.</li>
                        <li>To send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards below.</li>
                        <li>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).</li>
                        <li>To knowingly transmit any data, send or upload any material that contains viruses, trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</li>
                    </ul>
                    <p className='paragraph'>You also agree:</p>
                    <ul>
                        <li>Not to reproduce, duplicate, copy or re-sell any part of our Website in contravention with these terms.</li>
                        <li>Not to access without authority, interfere with, damage or disrupt:
                            <ul>
                                <li>Any part of our Website;</li>
                                <li>Any equipment or network on which our Website is stored;</li>
                                <li>Any software used in the provision of our Website; or</li>
                                <li>Any equipment or network or software owned or used by any third party.</li>
                            </ul>
                        </li>
                        <li>Not interfere or attempt to interfere with the normal operation of our Website or any activity that is conducted on our Website.</li>
                        <li>Not to take any action that would result in an unreasonably large data load on our Website’s network.</li>
                    </ul>
                    <h3 className='header_4'>
                        Content standards
                    </h3>
                    <p className='paragraph'>These content standards apply to any and all material which you contribute to our Website <strong>(“Contribution”)</strong>.</p>
                    <p className='paragraph'>The content standards must be complied with in spirit as well as to the letter. The standards apply to each part of any Contribution as well as to its whole.</p>
                    <p className='paragraph'>We will determine, at our discretion, whether a Contribution breaches the content standards.</p>
                    <p className='paragraph'>A Contribution must:</p>
                    <ul>
                        <li>Be relevant to your own travel (accommodation, restaurant, airlines, transportation, location or general travel experiences).</li>
                        <li>Be accurate (where it states facts).</li>
                        <li>Be genuinely held (where it states opinions).</li>
                        <li>Comply with the law applicable in any country from which it is posted.</li>
                    </ul>
                    <p className='paragraph'>A Contribution must not:</p>
                    <ul>
                        <li>Be defamatory of any person.</li>
                        <li>Be obscene, offensive, hateful or inflammatory.</li>
                        <li>Promote sexually explicit material.</li>
                        <li>Promote violence.</li>
                        <li>Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.</li>
                        <li>Infringe any copyright, database right, trade mark or any other intellectual property right of any other person.</li>
                        <li>Be likely to deceive any person.</li>
                        <li>Breach any legal duty owed to a third party, such as a contractual duty or a duty of confidence.</li>
                        <li>Breach any applicable minor protection laws and regulations.</li>
                        <li>Promote any illegal activity.</li>
                        <li>Be in contempt of court.</li>
                        <li>Be threatening, abuse or invade another’s privacy, or cause annoyance, inconvenience or needless anxiety.</li>
                        <li>Be likely to harass, upset, embarrass, alarm or annoy any other person.</li>
                        <li>Impersonate any person, or misrepresent your identity or affiliation with any person.</li>
                        <li>Give the impression that the Contribution emanates from us, if this is not the case.</li>
                        <li>Advocate, promote, incite any party to commit, or assist any unlawful or criminal act such as (by way of e.g. only) copyright infringement or computer misuse.</li>
                        <li>Contain a statement which you know or believe, or have reasonable grounds for believing, that members of the public to whom the statement is, or is to be, published are likely to understand as a direct or indirect encouragement or other inducement to the commission, preparation or instigation of acts of terrorism.</li>
                        <li>Contain any advertising or promote any services or web links to other sites.</li>
                        <li>Endanger national security or leak state secrets.</li>
                    </ul>
                    <p className='paragraph'>We have no obligation to post your Contributions, and we reserve the right in our absolute discretion to determine which Contributions are published on our Website.</p>
                    <h3 className='header_4'>
                        Rights you are giving us to use meterial you upload
                    </h3>
                    <p className='paragraph'>In connection with the services offered by the Website and across various media without notice, you grant us a perpetual, worldwide, non-exclusive, irrevocable, royalty-free, transferable licence to use, reproduce, distribute, modify, adapt, publish, translate, prepare derivative works of, and display such content with or without attribution to you. This licence also extends to the use of such content for Website and service promotion. Additionally, you give us the authority to file a lawsuit for any infringement on our or your intellectual property rights in the content. You acknowledge that you have no objections to the publication, use, alteration, deletion, or exploitation of your content, and you hereby expressly waive any and all "moral rights" (including rights of attribution or integrity) that may exist in your content, to the extent that such rights may exist. To be clear, we reserve the right, at our sole discretion and without prior notice to you, to remove any content.</p>
                    <p className='paragraph'>If you believe that any of the content of our Website breaches your intellectual property rights, please contact us using the details in section 1 of these terms.</p>
                    <h3 className='header_4'>
                        We are not responsible for viruses and you must not introduce them
                    </h3>
                    <p className='paragraph'>We have adopted the technology that our service providers have given us to reduce cyber threats for both our own and your safety.</p>
                    <p className='paragraph'>Nevertheless, we cannot guarantee that our website will be virus-free or totally safe from bugs.</p>
                    <p className='paragraph'>To view our website, you must configure your computer software, hardware, and information technology. Use the virus prevention software that you own.</p>
                    <p className='paragraph'>It is strictly forbidden for you to intentionally introduce dangerous or technologically damaging content onto our website, such as viruses, trojans, worms, logic bombs, or other threats. It is forbidden for you to try to access our website, the server that houses it, or any other server, computer, or database that is connected to our website without authorization. You are not permitted to use distributed denial-of-service or denial-of-service attacks on our website.</p>
                    <h3 className='header_4'>
                        Rules about linking to our website
                    </h3>
                    <p className='paragraph'>You are free to link to our home page as long as you follow the law, treat us fairly, and don't exploit or harm our name in any way.</p>
                    <p className='paragraph'>You may not create a link in a way that implies an affiliation, endorsement, or approval of ours when none exists.</p>
                    <p className='paragraph'>It is prohibited for you to create a link to our website from any website that you do not own.</p>
                    <p className='paragraph'>It is prohibited to frame our website on another website or to link to any other page on our website except the main page.</p>
                    <p className='paragraph'>Without prior warning, we retain the right to revoke permission to link.</p>


                    <Divider />

                    <h3 className='heder_2 sec' id='booking'>
                        General Booking Terms (Applicable to all travel products)
                    </h3>
                    <h3 className='header_4'>
                        Details & Prices
                    </h3>
                    <p className='paragraph'>You attest to the accuracy and completeness of all the information you submitted when making your reservation, including your name, contact information, ID information, payment information, and dates. We will assist you in attempting to amend the details on the booking if you notify us of any inaccurate information you unintentionally submitted; however, we cannot guarantee that the Supplier will allow this, especially in cases where the alteration could amount to a change in identity.</p>
                    <p className='paragraph'>We promote a lot of travel-related products, and while we work hard to make sure the price is always correct, mistakes can happen from time to time. We reserve the right to modify the pricing or cancel the reservation if the fault should have been fairly obvious to you. We will get in touch with you if this occurs.</p>
                    <h3 className='header_4'>
                        Booking Process
                    </h3>
                    <p className='paragraph'>We will send you an email confirming your reservation as soon as it's finished. You pledge to verify the information on the booking confirmation and to get in touch with us right away if you find any information is off. The pertinent sections below have more details about confirmations of hotel and flight reservations.</p>
                    <p className='paragraph'>Your reservation will be given a Visitpearl.com booking number. Whenever you contact us regarding your reservation, it would be helpful if you could let us know about the Visitpearl.com booking.</p>
                    <p className='paragraph'>When using our booking services you shall not:</p>
                    <ul>
                        <li>Use an invalid bank card or account, or a bank card or account that you are not authorised to use.</li>
                        <li>Impersonate any person, or misrepresent your identity or affiliation with any person.</li>
                        <li>Violate any laws and regulations or regulatory requirements.</li>
                    </ul>
                    <p className='paragraph'>The availability of travel products at the time of booking applies to all reservations. While we make every effort to maintain our website current, we cannot guarantee that any of the travel products will be offered when you go to make a reservation. If for any reason the travel product you wanted to book with us is not available, we will let you know as soon as we can after you make a reservation.</p>
                    <p className='paragraph'>In order to verify or validate your reservation, we reserve the right to request further information or confirmations. In the event that we determine the information you have supplied to be inadequate or that you have not submitted the necessary information, your booking request will be deemed incomplete and will be cancelled. It is crucial to remember that a product could become unavailable while we are checking, in which case the reservation might not be possible. You understand and agree that under any such circumstances, we won't be held responsible for any loss or expense.</p>
                    <h3 className='header_4'>
                        Payment & Currency
                    </h3>
                    <p className='paragraph'>The conditions of payment for a Travel Product will be made evident to you at the time of booking. Online prepayment is required for all reservations. Your booking may be instantly cancelled if any amount is not paid in full in compliance with the terms stated in the booking. This covers circumstances where a booking or a user habit indicates fraud, or where the payment method might not be charged at all.</p>
                    <p className='paragraph'>We only accept payments in Australian dollars ($AUD) or US dollars ($USD). If you make payments using a currency other than $USD or $AUD, our payment gateway might direct you to the account provider. Our exchange rate computation service provider calculates exchange rates several times a day. When making an online prepayment, you will use either $USD or $AUD to pay the precise amount displayed on our payment page. It is your responsibility to confirm if the account provider uses an alternate currency rate if you are paying with a different currency. You can be assessed a fee by your account provider for making foreign payments.</p>
                    <p className='paragraph'>Please do not submit the money again if you run into trouble when submitting it. By contacting us with the information provided in section 1 of these terms, you can find out if your reservation was made successfully.</p>
                    <p className='paragraph'>Unless we specify otherwise, refunds will be made using the original payment method. The provider of your account will determine when any refunds are credited to your account. We have strong security procedures in place to guarantee the safety of your personal information, and we take the protection of your payment information very seriously.</p>
                    <h3 className='header_4'>
                        Travel Preparation
                    </h3>
                    <p className='paragraph'>It is your duty to make sure you have the right insurance and the necessary travel documentation for your journey. This covers (but is not limited to) your visas, medical records (such as vaccination records), and passport or national ID card.</p>
                    <p className='paragraph'>We do not guarantee or advise that you can travel to and/or through a certain location without risk by providing Travel Products to or in that location. Prior to making your reservation and departing, it is your responsibility to verify if there are any travel advisories or restrictions in the area you will be visiting.</p>
                    <p className='paragraph'>It is your responsibility to make sure you have any vaccines needed for your travel and to heed any advice from medical professionals.</p>
                    <h3 className='header_4'>
                        Service Guarantee
                    </h3>
                    <p className='paragraph'>Please visit <a href='/service-guarantee' target='_blank' rel='noreferrer' className='default_anchor'>Service Guarantee page</a> for information on our Service Guarantee.</p>


                    <Divider />

                    <h3 className='heder_2 sec' id='flight'>
                        Flight Terms
                    </h3>
                    <h3 className='header_4'>
                        Airline's Terms & Conditions
                    </h3>
                    <p className='paragraph'>Your reservation is also subject to the terms and conditions of the applicable Supplier, in this case the airline, as stated under "About these terms." Most of these are available on the airline's website. These terms shall apply in the event that there are any discrepancies between the airline's terms and conditions and these terms, for as when you choose a less expensive ticket that is non-refundable or changeable. Please get in touch with the provider directly if you have any queries about the terms and conditions that relate to your flight ticket, such as the regulations for changes, cancellations, and baggage allowance.</p>
                    <h3 className='header_4'>
                        Changing or Cancelling your Flight Bookings
                    </h3>
                    <p className='paragraph'>In this instance, please ensure that you contact the airline directly for making any changes to your flight. If the booking needs to be cancelled and refunded, please contact us using the details in section 1 of these terms.</p>
                    <h3 className='header_4'>
                        Important Flight Information
                    </h3>
                    <p className='paragraph'>Where possible the airline will provide you with information on:</p>
                    <ul>
                        <li>check-in and boarding times,</li>
                        <li>seat selection</li>
                        <li>baggage allowances and restrictions</li>
                        <li>whether it is possible to book infant/child tickets online</li>
                    </ul>
                    <p className='paragraph'>applicable to your flight ticket during your booking process, however you should always check the relevant airline’s website for details.</p>
                    <p className='paragraph'>For information on:</p>
                    <ul>
                        <li>suitability of passengers for flying (for e.g., elderly, pregnant or infant passengers)</li>
                        <li>rules on passengers carrying lithium batteries, dangerous goods and liquids</li>
                        <li>applications for special meals and/or services before your journey</li>
                    </ul>
                    <p className='paragraph'>you should check the relevant airline’s website for details, as different airlines may have different policies or rules.</p>
                    <p className='paragraph'>If you would like to order special meals and/or services via us, please contact us using the details in section 1 of these terms. You can also contact us if you have any questions on how to book infant/child tickets.</p>
                    <p className='paragraph'>Once you reach the appropriate airport terminal, it is your responsibility to make sure you give yourself enough time for security checks, passport control, and check-in. Before leaving, make sure you have the necessary travel documents ready, including your passport or national ID card and any necessary visas.</p>
                    <p className='paragraph'>Please be advised that in order for the relevant airline to permit you to check in or board the aircraft, you must utilize your flight tickets in the order specified in your trip schedule. For example, the airline may refuse to let you use your return ticket if you do not utilize your outward ticket. For specifics, you should visit the website of the applicable airline, as several airlines might have different guidelines or restrictions.</p>


                    <Divider />

                    <h3 className='heder_2 sec' id='hotel'>
                        Hotel Terms
                    </h3>
                    <h3 className='header_4'>
                        Hotel's Terms & Conditions
                    </h3>
                    <p className='paragraph'>Your reservation is also subject to the conditions of the applicable Supplier, which in this case is the hotel you are staying at, as stated under "About these terms." Most of these are available on the hotel's website. These terms will apply if there are any discrepancies between them and the terms and conditions of the hotel, for as if you have chosen a less expensive room that cannot be altered or returned. As part of our customer support services, you can get in touch with us directly (using the information in section 1 of these terms) if you have any questions about the terms and conditions that relate to your hotel reservation.</p>
                    <h3 className='header_4'>
                        Booking Process
                    </h3>
                    <p className='paragraph'>We will make every effort to provide you a confirmation of your hotel reservation within the window of time that we informed you of when you made your reservation. Please use the information in section 1 of these terms to get in touch with us if you haven't received your hotel booking confirmation within this time range. Before informing you, the confirmation for each of our rooms is manually inspected and authorized. We will email you to let you know as soon as your reservation is confirmed, and at that moment on, your accommodation is reserved. In the event that we are unable to verify your reservation or make comparable changes, you will receive a complete refund.</p>
                    <p className='paragraph'>Please do not panic if, after your reservation has been confirmed, you get in touch with the hotel directly and they are unable to find your reservation. Although the hotel might not obtain your personal information until closer to the booking date, your reservation is still guaranteed. In the event that the hotel is unable to fulfill your reservation, you will be arranged to stay at a comparable location that is as near to the original one as feasible (in terms of amenities, star rating, etc.). Please use the information in section 1 of these terms to contact us with any questions or concerns.</p>
                    <h3 className='header_4'>
                        Changing, Cancelling, or Refunding your Hotel Bookings
                    </h3>
                    <p className='paragraph'>During the booking process, you will have received information about your rights to modify or cancel your hotel reservation (as well as any related costs); it's possible that you choose a less expensive room that is not amendable or refundable. Read this information carefully before completing your booking, since it is included in these terms. Please contact us using the information in section 1 of these conditions if you have any questions about whether your hotel reservation allows for adjustments and/or reimbursements in the event of a cancellation.</p>
                    <p className='paragraph'>All of the alternatives on our website and/or alternative rooms at that hotel might not be available to you when your hotel booking enables changes. The hotel and/or rooms that you can change to could be limited by the hotel or the third-party hotel inventory supplier. When we receive your request to modify your hotel reservation, we will give you details on the options that are available.
                        Generally, the terms and conditions of the applicable hotel will apply if it changes or cancels your reservation. Please contact us directly (using the details in section 1 of these terms) and we will help as part of our customer support services.</p>
                    <h3 className='header_4'>
                        Hotel Ratings
                    </h3>
                    <p className='paragraph'>Verified user reviews serve as the foundation for our guest ratings. Our star ratings are derived from a variety of sources, including information from the hotels themselves (including, where relevant, their star rating as determined by the Sri Lanka Tourism Development Authority, or SLTDA), amenities, images, reviews from previous visitors, and pricing. This will provide you the clearest picture of the hotel's caliber. You do, however, understand and agree that before making a reservation, you will check the details on all the hotels listed on our website (as well as on the hotel's website or websites).</p>


                    <Divider />

                    <h3 className='heder_2 sec' id='tours'>
                        Tours & Tickets (Other Products on our Website such as Airport Transfers, Car Rentals, and Attraction Passes etc.)
                    </h3>
                    <h3 className='header_4'>
                        Supplier's Terms & Conditions
                    </h3>
                    <p className='paragraph'>Your reservation is also subject to the conditions of the applicable Supplier (in this case, the hotel or airline operator), as stated under "About these terms." Typically, the Supplier's own website has these. Whenever feasible, during the booking process' checkout phase, we will have sent you a link to these terms.</p>
                    <h3 className='header_4'>
                        Changing, Cancelling, or Upgrading your Tour Bookings
                    </h3>
                    <p className='paragraph'>For details on your ability to modify or cancel your reservation, please refer to the conditions of the applicable Supplier. Please take note that during the booking procedure, we might have also given you some information about your rights; this information is included in these conditions.
                        Generally, the terms and conditions of the applicable Supplier will apply if they amend or cancel your reservation. Please contact us directly (using the details in section 1 of these terms) and we will help as part of our customer support services.</p>


                    <Divider />

                    <h3 className='heder_2 sec' id='personal-data'>
                        How we use your personal information
                    </h3>
                    <p className='paragraph'>
                        We only use your personal information in accordance with our Privacy Policy. Please take the time to read our Privacy Policy, as it includes important information and details about how we handle your personal information. Visit <a href='/privacy-policy' target='_blank' rel='noreferrer' className='default_anchor'>Privacy Policy</a> to learn more.
                    </p>


                    <Divider />

                    <h3 className='heder_2 sec' id='liability'>
                        Liability
                    </h3>
                    <h3 className='header_4'>
                        Our Responsibility for Loss or Damage Suffered by You
                    </h3>
                    <p className='paragraph'>A significant portion of the content on our website comes from pertinent vendors and/or website users who have left reviews of the travel-related goods. Although we will make every effort to ensure that the information is correct and comprehensive, to the extent that the law permits, we disclaim all liability for any loss or harm that you may suffer as a result of the information's incompleteness or inaccuracy. Regarding any information you obtain from our website, we make no representations, guarantees, or conditions, and we disclaim all liability with regard to such information, unless it is legally prohibited to do so. To the extent allowed by law, we won't be responsible for any losses or harms you sustain because of:</p>
                    <ul>
                        <li>Incorrect details (such as name, id details, nationality, contact details, payment details, dates and times) submitted by you when making your booking. this includes, but is not limited to, loss or damage as a result of</li>
                        <ul>
                            <li>Not being able to contact you with important information about your booking, where you have provided incorrect contact details.</li>
                            <li>You being unable to use a travel product (for e.g., board a plane or train, check-in to a hotel, hire a car, use an airport transfer service or use an attraction ticket) due to the id details (including nationality) provided by you during your booking not matching those on your id.</li>
                        </ul>
                        <li>Your failure to obtain the correct documentation, such as a passport, visa or proof of vaccinations, required for your travel product.</li>
                        <li>Your failure to obtain appropriate insurance for your trip.</li>
                        <li>You being unable to use a travel product (for e.g., board a plane or train, check-in to a hotel, hire a car, use an airport transfer service or use an attraction ticket) due to:</li>
                        <ul>
                            <li>Your failure to comply with the relevant supplier’s terms and conditions.</li>
                            <li>Your failure to arrive on time or allow sufficient time for check-in, boarding or (where relvant) passport control and security checks.</li>
                            <li>Your behaviour being deemed as unacceptable by the relevant supplier.</li>
                        </ul>
                        <li>Where your journey requires a flight transfer or change of train, bus or ferry, your failure to make the next flight,train, bus or ferry due to your own acts or ommisions.</li>
                        <li>Any bookings, including duplicate bookings, made by you in error.</li>
                        <li>Any fees charged by your account provider for international payments, alternative exchange rates used by your account provider when paying in a different currency or any changes in the exchange rate between the time of booking and the time of payment.</li>
                        <li>Any direct communications between you and the relevant supplier.</li>
                        <li>Any inaccurate guest or star rating.</li>
                        <li>False, inaccurate, outdated or incomplete payment information provided by you.</li>
                        <li>The use of any interactive area by a user of our website in contravention of our content standards set out in these terms.</li>
                        <li>You travelling to and/or around an area with a travel warning or travel restrictions in place.</li>
                        <li>Events outside of our control, including but not limited to:</li>
                        <ul>
                            <li>Acts of god, flood, drought, earthquake or other natural disaster</li>
                            <li>Epidemic or pandemic</li>
                            <li>Terrorist attack, war, commotion or riots, threat of or preparation for war, armed conflict, imposition of sanctions, embargo, or breaking off of diplomatic relations;
                                nuclear, chemical or biological contamination or sonic boom;
                                any law or any action taken by a government or public authority; and
                                internet, system or device instability, computer viruses and hacker attacks.</li>
                        </ul>
                    </ul>
                    <p className='paragraph'>please note that we only provide our website for domestic and private use. you agree not to use our website for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>
                    <h3 className='header_4'>
                        Breach of these terms by you.
                    </h3>
                    <p className='paragraph'>Failure to comply with these terms or failure to comply with applicable laws or regulations may result in our taking all or any of the following actions anytime without prior notice:</p>
                    <ul>
                        <li>Reject or cancel any bookings associated with your name, email address or account, including on behalf of the Suppliers</li>
                        <li>Cancel or revoke any Trip Coins, coupons, incentives, promotional rewards or other benefits that you may earn or have previously earned.</li>
                        <li>Immediate, temporary or permanent withdrawal of your right to use our Website and/or our booking services.</li>
                        <li>Immediate, temporary or permanent removal of any Contribution uploaded by you to our Website.</li>
                        <li>Issue of a warning to you.</li>
                        <li>Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach.</li>
                        <li>Further legal action against you.</li>
                        <li>Disclosure of such information to law enforcement authorities as we reasonably feel is necessary or as required by law.</li>
                        <li>Any other measures as deemed appropriate by us at our sole discretion.</li>
                    </ul>
                    <p className='paragraph'>You acknowledge that if you don't follow these terms, we won't be responsible for any losses or damages. You agree to hold us harmless for any losses we incur from your use of our website and/or booking services in a way that violates these terms.</p>
                    <p className='paragraph'>If we cancel your reservation, we might not give you a reason if, in our sole judgment, doing so would (a) break any applicable laws or (b) make it more difficult to detect or stop fraud or other criminal activity.</p>
                    <p className='paragraph'>You can not be eligible for a refund if we have to cancel your reservation. This could happen if you have broken any of our terms or the laws that apply to you, if the terms of the Supplier prohibit refunds, or if we decide—at our sole discretion—that you are not entitled for a refund because of possible fraud or abuse. You agree not to try to use our website or services under a different name or through another user in the event that your access to our service is refused. Please contact our customer support staff if you think your reservation was canceled unfairly or if you should be receiving a refund. You understand and accept that under these circumstances, we won't be held accountable for any expenses or losses.</p>


                    <Divider />

                    <h3 className='heder_2 sec' id='complaints'>
                        Complaints
                    </h3>
                    <p className='paragraph'>If you have any questions or complaints about our Website or booking services, please contact us using the details in section 1 of these terms.</p>


                    <Divider />

                    <h3 className='heder_2 sec' id='country-laws'>
                        Which Country's Laws Apply to Any Disputes?
                    </h3>
                    <p className='paragraph'>The laws of Australia will govern and interpret these terms as well as any disputes or claims (including non-contractual disputes or claims) arising out of them, their subject matter, or their formation. However, this will not deprive you of any mandatory consumer rights under the laws of the country in which you currently reside.</p>
                    <p className='paragraph'>Both you and we agree that any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with these terms, their subject matter, or their formation will be heard in Australian courts; however, this will not obligate you to choose to have any such dispute or claim resolved in the courts of your home country.</p>


                    <Divider />

                    <h3 className='heder_2 sec' id='language'>
                        Language
                    </h3>
                    <p className='paragraph'>Where the original English version of these terms has been translated into other languages, the English version shall prevail in the event of any inconsistencies between the versions.</p>


                    <Divider />

                    <h3 className='heder_2 sec' id='general'>
                        General
                    </h3>
                    <p className='paragraph'>These terms are between you and us. No other person shall have any rights to enforce any of these terms.
                        If any court or relevant authority finds that part of these terms are illegal, the rest will continue in full force and effect.
                        Even if we delay enforcing these terms, we may still take steps against you at a later date.</p>
                </div>
            </div>

            <Drawer
                title="Basic Drawer"
                className='home__nav__drawer'
                rootClassName='home__nav__drawer_body'
                placement='bottom'
                closable={false}
                open={isNavDrawerOpen}
                onClose={() => setIsNavDraweOpen(false)}
                height='70vh'
                headerStyle={{ display: 'none' }}
            >
                <div className='terms__nav_drawe'>
                    {items.map((item) => (
                        <div
                            key={item.key}
                            className='terms__nav__container__items__item_drawer'
                            onClick={() => {
                                scrollToSection(item.link)
                                setIsNavDraweOpen(false)
                            }}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>
            </Drawer>
        </div>
    )
}

export default TermsAndCondtions