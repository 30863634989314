import React, { useEffect, useState } from 'react'

import { CloseCircleOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Button, Drawer, Spin } from 'antd'
import { Route, Routes, useNavigate } from 'react-router-dom'

import './styles.scss'
import useMyProfile from '../../../hooks/useMyProfile'
import { BiCake, BiExtension, BiHotel, BiSolidPlaneAlt, BiTrip } from 'react-icons/bi'
import TourBookings from './TourBookings'
import TourBooking from './TourBookings/TourBooking'
import FlightBooking from './FlightBooking'
import CustomTours from './CustomTours'
import CustomTour from './CustomTours/CustomTour'
import CustomEvents from './CustomEvents'
import CustomEvent from './CustomEvents/CustomEvent'
import AccommodationsBookings from './AccommodationsBookings'
import AccommodationBooking from './AccommodationsBookings/AccommodationBooking'

const items = [
    {
        key: '1',
        label: 'Tour Bookings',
        icon: <BiTrip style={{ fontSize: '20px' }} />,
        link: '/tours',
    },
    {
        key: '2',
        label: 'Custom Tours',
        icon: <BiExtension style={{ fontSize: '20px' }} />,
        link: '/custom-tours',
    },
    {
        key: '5',
        label: 'Custom Events',
        icon: <BiCake style={{ fontSize: '20px' }} />,
        link: '/custom-events',
    },
    {
        key: '3',
        label: 'Accommodations Booking',
        icon: <BiHotel style={{ fontSize: '20px' }} />,
        link: '/accommodations',
    },
    {
        key: '4',
        label: 'Flights',
        icon: <BiSolidPlaneAlt style={{ fontSize: '20px' }} />,
        link: '/flights',
    },
]

const MyBookings = () => {
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState('1');
    const [isNavDrawerOpen, setIsNavDraweOpen] = useState(false);
    const myProfile = useMyProfile();
    const url = window.location.pathname

    useEffect(() => {
        const splitUrl = url.split('/');
        if (splitUrl.includes('tours')) {
            setActiveItem('1');
        } else if (splitUrl.includes('custom-tours')) {
            setActiveItem('2');
        } else if (splitUrl.includes('accommodations')) {
            setActiveItem('3');
        } else if (splitUrl.includes('flights')) {
            setActiveItem('4');
        } else if (splitUrl.includes('custom-events')) {
            setActiveItem('5');
        } else {
            setActiveItem('1');
            navigate('/dashboard/my-bookings/tours');
        }
    }, []);


    return (
        <div className='dashboard__Home'>
            <div className='dash_home_nav'>
                {items.map((item) => (
                    <div
                        key={item.key}
                        className={`dash_home_nav__container__items__item ${activeItem === item.key ? 'active' : ''}`}
                        onClick={() => {
                            navigate(`/dashboard/my-bookings${item.link}`);
                            setActiveItem(item.key);
                        }}
                    >
                        {item.icon}
                        {item.label}
                    </div>
                ))}
            </div>

            <div className='mobile_nav'>
                <Button onClick={() => setIsNavDraweOpen(true)}><MenuUnfoldOutlined /> Open Menu</Button>
            </div>

            <div className='dash_home_content'>
                <Spin spinning={myProfile.loading}>
                    <Routes>
                        <Route path='/tours' element={<TourBookings />} />
                        <Route path='/tours/:id' element={<TourBooking />} />

                        <Route path='/custom-tours' element={<CustomTours />} />
                        <Route path='/custom-tours/:id' element={<CustomTour />} />

                        <Route path='/custom-events' element={<CustomEvents />} />
                        <Route path='/custom-events/:id' element={<CustomEvent />} />

                        <Route path='/accommodations' element={<AccommodationsBookings />} />
                        <Route path='/accommodations/:id' element={<AccommodationBooking />} />

                        <Route path='/flights' element={<FlightBooking />} />
                    </Routes>
                </Spin>
            </div>

            <Drawer
                title="Basic Drawer"
                className='home__nav__drawer'
                rootClassName='home__nav__drawer_body'
                placement='bottom'
                closable={false}
                open={isNavDrawerOpen}
                onClose={() => setIsNavDraweOpen(false)}
                height='70vh'
                headerStyle={{ display: 'none' }}
            >
                <div className='drawer__content_home_nav'>
                    {items.map((item) => (
                        <div
                            key={item.key}
                            className={`drawer__content__items ${activeItem === item.key ? 'active' : ''}`}
                            onClick={() => {
                                navigate(`/dashboard/my-bookings${item.link}`);
                                setActiveItem(item.key);
                                setIsNavDraweOpen(false);
                            }}
                        >
                            {item.icon}
                            {item.label}
                        </div>
                    ))}
                    <div className='drawer__content__items close' onClick={() => setIsNavDraweOpen(false)}>
                        <CloseCircleOutlined />
                        Close
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default MyBookings