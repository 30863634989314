import React, { useEffect, useState } from 'react';
import LoadingComp from '../../../../components/LoadingComp';
import { useNavigate, useLocation } from 'react-router-dom';
import { getTourPackageById } from '../../../../../redux/api/tourPackages.api';
import { notification, Steps } from 'antd';
import DateAndQuentity from './DateAndQuentity';

import './styles.scss';
import { TAX_RATE } from '../../../../constants/finance';
import OtherServices from './OtherServices';
import TravelerDetils from './TravelerDetils';
import { useMsal } from '@azure/msal-react';
import { LOGIN_REQUEST } from '../../../../azure/authConfig';
import TourBookingConfirmation from './TourBookingConfirmation';
import TourBookingPayment from './TourBookingPayment';
import usePageSettings from '../../../../hooks/usePageSettings';
import useCurrencyRates from '../../../../hooks/useCurrencyRates';
import { convertCurrency } from '../../../../helpers/currencyConverter';

const usePrompt = (message, when) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isPrompting, setIsPrompting] = useState(false);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (when) {
                e.preventDefault();
                e.returnValue = message;
            }
        };

        const handleBlockNavigation = (e) => {
            if (when && !window.confirm(message)) {
                e.preventDefault();
                return;
            }
            setIsPrompting(false);
        };

        if (when) {
            window.addEventListener('beforeunload', handleBeforeUnload);
            window.history.pushState(null, null, location.pathname);
            window.addEventListener('popstate', handleBlockNavigation);
        }

        return () => {
            if (when) {
                window.removeEventListener('beforeunload', handleBeforeUnload);
                window.removeEventListener('popstate', handleBlockNavigation);
            }
        };
    }, [when, message, location.pathname]);

    useEffect(() => {
        if (isPrompting) {
            navigate(location.pathname);
        }
    }, [isPrompting, navigate, location.pathname]);
};

const BookTourPackage = () => {
    const url = window.location.pathname;
    const tourPackageId = url.split('/')[2];
    const [loading, setLoading] = useState(true);
    const [api, contextHolderNoti] = notification.useNotification();
    const [tourPackage, setTourPackage] = useState(null);
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [current, setCurrent] = useState(0);
    const navigate = useNavigate();

    const [userTourData, setUserTourData] = useState({});
    const currencyRates = useCurrencyRates()?.currencyRates
    const selectedCurrency = usePageSettings()?.selectedCurrency;

    useEffect(() => {
        if (!activeAccount) {
            handleLoginRedirect();
        }
    }, []);

    useEffect(() => {
        if (!tourPackageId) {
            navigate('/tours');
        }
        handleGetTourPackage(tourPackageId);

        setUserTourData({
            startDate: '',
            adults: 1,
            children: 0,
            infants: 0,
            roomType: 'Single',
            vehicleType: 'Hatchback',
            tax: 0,
            finalPayment: 0,
            selectedVehicalPrice: 0,
            userData: {},
            paymentOption: null,
            instalmentPlan: null,
            termsAndConditions: false,
            filghtDetails: {
                flightBooked: false,
                flightNumber: '',
                arrivalDateTime: '',
                departureAirport: ''
            },
        });

        // return () => {
        //     setIsPrompting(false);
        // };
    }, [tourPackageId, navigate]);

    const handleLoginRedirect = () => {
        instance.handleRedirectPromise()
            .then((response) => {
                if (response) {
                    instance.setActiveAccount(response.account);
                } else {
                    instance.loginRedirect(LOGIN_REQUEST)
                        .catch((e) => {
                            console.error('User Login Error', e);
                        });
                }
            })
            .catch((error) => {
                console.error('Redirect Handling Error', error);
            });
    }

    // usePrompt('Are you sure you want to leave this page? Any unsaved changes will be lost.', isPrompting);

    const handleGetTourPackage = async (id) => {
        setLoading(true);
        try {
            const tour = await getTourPackageById(id);
            setTourPackage(tour.data[0]);
        } catch (error) {
            console.error('Error fetching destination', error);
        }
        setLoading(false);
    };

    const getVehiclePrice = (type) => {
        switch (type) {
            case 'Hatchback':
                return tourPackage?.vehicleHatchback;
            case 'Sedan':
                return tourPackage?.vehicleSedan;
            case 'Suv':
                return tourPackage?.vehicleSuv;
            case 'Van':
                return tourPackage?.vehicleVan;
            case 'Coster':
                return tourPackage?.VehicleCoster;
            default:
                return 0;
        }
    }

    const calculateTotal = () => {
        const packagePrice = tourPackage?.pricePackage;
        const guidePrice = tourPackage?.priceGuide;
        const selectedRoomTypePrice = tourPackage?.[`room${userTourData?.roomType}`];
        const selectedVehicalPrice = getVehiclePrice(userTourData?.vehicleType);
        const totalMembers = userTourData?.adults + userTourData?.children + userTourData?.infants;

        const pricePerPerson = packagePrice + guidePrice + selectedRoomTypePrice;
        const total = (pricePerPerson * totalMembers) + selectedVehicalPrice;
        const discount = tourPackage?.discount ?
            total * (tourPackage?.discount / 100) : 0;

        const totalAfterDiscount = total - discount;
        const tax = totalAfterDiscount * TAX_RATE;
        let grandTotal = totalAfterDiscount + tax;
        grandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;
        grandTotal = grandTotal ? convertCurrency(grandTotal, selectedCurrency, currencyRates) : 0

        return grandTotal;
    }

    const calculateSubTotal = () => {
        const packagePrice = tourPackage?.pricePackage;
        const guidePrice = tourPackage?.priceGuide;
        const selectedRoomTypePrice = tourPackage?.[`room${userTourData?.roomType}`];
        const selectedVehicalPrice = getVehiclePrice(userTourData?.vehicleType);
        const totalMembers = userTourData?.adults + userTourData?.children + userTourData?.infants;

        const pricePerPerson = packagePrice + guidePrice + selectedRoomTypePrice;
        let total = (pricePerPerson * totalMembers) + selectedVehicalPrice;
        total = Math.round((total + Number.EPSILON) * 100) / 100;
        total = total ? convertCurrency(total, selectedCurrency, currencyRates) : 0
        return total;
    }

    const calculateTax = () => {
        const packagePrice = tourPackage?.pricePackage;
        const guidePrice = tourPackage?.priceGuide;
        const selectedRoomTypePrice = tourPackage?.[`room${userTourData?.roomType}`];
        const selectedVehicalPrice = getVehiclePrice(userTourData?.vehicleType);
        const totalMembers = userTourData?.adults + userTourData?.children + userTourData?.infants;

        const pricePerPerson = packagePrice + guidePrice + selectedRoomTypePrice;
        const total = (pricePerPerson * totalMembers) + selectedVehicalPrice;
        const discount = tourPackage?.discount ?
            total * (tourPackage?.discount / 100) : 0;

        const totalAfterDiscount = total - discount;
        let tax = totalAfterDiscount * TAX_RATE;
        tax = Math.round((tax + Number.EPSILON) * 100) / 100;
        tax = tax ? convertCurrency(tax, selectedCurrency, currencyRates) : 0
        return tax;
    }

    const calculateDiscount = () => {
        const packagePrice = tourPackage?.pricePackage;
        const guidePrice = tourPackage?.priceGuide;
        const selectedRoomTypePrice = tourPackage?.[`room${userTourData?.roomType}`];
        const selectedVehicalPrice = getVehiclePrice(userTourData?.vehicleType);
        const totalMembers = userTourData?.adults + userTourData?.children + userTourData?.infants;

        const pricePerPerson = packagePrice + guidePrice + selectedRoomTypePrice;
        const total = (pricePerPerson * totalMembers) + selectedVehicalPrice;
        let discount = tourPackage?.discount ?
            total * (tourPackage?.discount / 100) : 0;
        discount = Math.round((discount + Number.EPSILON) * 100) / 100;
        discount = discount ? convertCurrency(discount, selectedCurrency, currencyRates) : 0
        return discount;
    }

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const steps = [
        {
            title: 'Date & Quantity',
            content: <DateAndQuentity
                tourPackage={tourPackage}
                userTourData={userTourData}
                setUserTourData={setUserTourData}
                messageApi={api}
                prev={prev}
                next={next}
                calculateTotal={calculateTotal}
                calculateSubTotal={calculateSubTotal}
                calculateTax={calculateTax}
                calculateDiscount={calculateDiscount}
                current={current}
            />,
        },
        {
            title: 'Other Services',
            content: <OtherServices
                tourPackage={tourPackage}
                userTourData={userTourData}
                setUserTourData={setUserTourData}
                messageApi={api}
                prev={prev}
                next={next}
                calculateTotal={calculateTotal}
                calculateSubTotal={calculateSubTotal}
                calculateTax={calculateTax}
                calculateDiscount={calculateDiscount}
                current={current}
            />,
        },
        {
            title: 'Traveler Information',
            content: <TravelerDetils
                tourPackage={tourPackage}
                userTourData={userTourData}
                setUserTourData={setUserTourData}
                messageApi={api}
                prev={prev}
                next={next}
                calculateTotal={calculateTotal}
                calculateSubTotal={calculateSubTotal}
                calculateTax={calculateTax}
                calculateDiscount={calculateDiscount}
                current={current}
            />,
        },
        {
            title: 'Confirmation',
            content: <TourBookingConfirmation
                tourPackage={tourPackage}
                userTourData={userTourData}
                setUserTourData={setUserTourData}
                messageApi={api}
                prev={prev}
                next={next}
                calculateTotal={calculateTotal}
                calculateSubTotal={calculateSubTotal}
                calculateTax={calculateTax}
                calculateDiscount={calculateDiscount}
                current={current}
            />,
        },
        {
            title: 'Payment',
            content: <TourBookingPayment
                tourPackage={tourPackage}
                userTourData={userTourData}
                setUserTourData={setUserTourData}
                messageApi={api}
                prev={prev}
                next={next}
                calculateTotal={calculateTotal}
                calculateSubTotal={calculateSubTotal}
                calculateTax={calculateTax}
                calculateDiscount={calculateDiscount}
                current={current}
            />,
        }
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    return (
        <>
            {!loading ? (
                <div className='main__contaier bookTour section_main'>
                    {contextHolderNoti}
                    <div className='hero top_margin_main'>
                        <Steps
                            current={current}
                            items={items}
                            progressDot={window.innerWidth > 768 ? false : true}
                        />
                        <div className='content_body_main'>
                            {steps[current].content}
                        </div>
                    </div>
                </div>
            ) : (
                <div className='loading_page'>
                    <LoadingComp />
                </div>
            )}
        </>
    );
};

export default BookTourPackage;
