import { useMsal } from '@azure/msal-react';
import { Button, Checkbox, Col, DatePicker, Descriptions, Form, Input, InputNumber, Modal, notification, Radio, Rate, Spin, Steps, Tag } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { LOGIN_REQUEST } from '../../../../azure/authConfig';
import { createAccommodationBooking, getAccommodationPackageById } from '../../../../../redux/api/accommodations.api';

import './styles.scss';
import usePageSettings from '../../../../hooks/usePageSettings';
import { capitalizeFisrtLetterEachWord } from '../../../../helpers/nameRender';
import LoadingComp from '../../../../components/LoadingComp';
import useMyProfile from '../../../../hooks/useMyProfile';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const BookAccommodationPackage = () => {
    const url = window.location.pathname;
    const accommodationPackageId = url.split('/')[2];
    const [loading, setLoading] = useState(true);
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const navigate = useNavigate();
    const [accommodationPackage, setAccommodationPackage] = useState(null);
    const selectedCurrency = usePageSettings()?.selectedCurrency;
    const [form] = Form.useForm();
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [api, contextHolderNoti] = notification.useNotification();
    const [isModelOpen, setIsModelOpen] = useState(false);
    const myProfile = useMyProfile().userProfile;

    useEffect(() => {
        if (!activeAccount) {
            handleLoginRedirect();
        }
    }, []);

    useEffect(() => {
        if (!accommodationPackageId) {
            navigate('/accommodations');
        }
        handleGetAccommodationPackage(accommodationPackageId);
    }, [accommodationPackageId]);

    const handleGetAccommodationPackage = async (id) => {
        setLoading(true);
        try {
            const pack = await getAccommodationPackageById(id);
            setAccommodationPackage(pack.data);
        } catch (error) {
            console.error('Error fetching destination', error);
        }
        setLoading(false);
    };


    const onFinish = async (values) => {
        setLoadingSubmit(true);
        const formattedValues = {
            ...values,
            checkIn: values.dates[0].format('YYYY-MM-DD'),
            checkOut: values.dates[1].format('YYYY-MM-DD'),
            mealsInfo: {
                breakfast: values.breakfast || false,
                lunch: values.lunch || false,
                dinner: values.dinner || false,
                preferences: values.mealPreference
            },
            initailCostPerPerson: accommodationPackage?.accommodationPackage?.packagePrice,
            givenPricePerPerson: accommodationPackage?.accommodationPackage?.packagePrice,
            bookingStatus: 'requested',
            paymentStatus: 'pending',
            accommodationId: accommodationPackageId,
            userId: myProfile.id
        };

        delete formattedValues.dates;
        delete formattedValues.breakfast;
        delete formattedValues.lunch;
        delete formattedValues.dinner;
        delete formattedValues.mealPreference;

        try {
            const response = await createAccommodationBooking(formattedValues)
            if (response?.status === 200) {
                api.open({
                    type: 'success',
                    message: 'Booking Confirmed',
                    description: 'Your booking is confirmed. Our team will contact you shortly with best available rates and other details.',
                })
                setIsModelOpen(true)
            } else {
                console.log('ERROR SAVING ACC BOOING', response)
                api.open({
                    type: 'error',
                    message: 'Error',
                    description: 'An error occurred while saving the booking. Please try again.',
                })
            }
        } catch (error) {
            console.log('Error submitting booking request', error);
            api.open({
                type: 'error',
                message: 'Error Submitting Booking Request',
                description: 'Please try again later'
            });
        }

        setLoadingSubmit(false);
    };

    const handleLoginRedirect = () => {
        instance.handleRedirectPromise()
            .then((response) => {
                if (response) {
                    instance.setActiveAccount(response.account);
                } else {
                    instance.loginRedirect(LOGIN_REQUEST)
                        .catch((e) => {
                            console.error('User Login Error', e);
                        });
                }
            })
            .catch((error) => {
                console.error('Redirect Handling Error', error);
            });
    }

    return (
        <div className='main__contaier bookAccommodation section_main'>
            {contextHolderNoti}
            <div className='header'>
                <h1 className='header_3'>Book Accommodation Package</h1>
            </div>

            {
                loading ?
                    <div className='loading__container'>
                        <LoadingComp />
                    </div>
                    :
                    <>
                        <div className='bookAccommodation__container'>
                            <Descriptions bordered column={2}>
                                <Descriptions.Item label="Accommodation">{accommodationPackage?.accommodation?.accommodationName}</Descriptions.Item>
                                <Descriptions.Item label="City">{accommodationPackage?.accommodation?.accommodationCity}</Descriptions.Item>
                                <Descriptions.Item label="Package Name">{accommodationPackage?.accommodationPackage?.packageName}</Descriptions.Item>
                                <Descriptions.Item label="Price">{accommodationPackage?.accommodationPackage?.packagePrice} {selectedCurrency} Per Person Per Night</Descriptions.Item>
                                <Descriptions.Item label="Rating">
                                    {accommodationPackage?.accommodationPackage?.accommodationRating && <Rate disabled defaultValue={accommodationPackage?.accommodationPackage?.accommodationRating} />}
                                </Descriptions.Item>
                                <Descriptions.Item label="Hotel Type">{accommodationPackage?.accommodationPackage?.accommodationType && capitalizeFisrtLetterEachWord(accommodationPackage?.accommodationPackage?.accommodationType)}</Descriptions.Item>
                                <Descriptions.Item label="Meals">{accommodationPackage?.accommodationPackage?.packageMeals
                                    && accommodationPackage?.accommodationPackage?.packageMeals?.split(',').map((meal, index) => (
                                        <Tag key={index} bordered={false} color='blue'>{capitalizeFisrtLetterEachWord(meal)}</Tag>
                                    ))}
                                </Descriptions.Item>
                                <Descriptions.Item label="Room Type">{accommodationPackage?.accommodationPackage?.packageRooms && capitalizeFisrtLetterEachWord(accommodationPackage?.accommodationPackage?.packageRooms)}</Descriptions.Item>
                                <Descriptions.Item label="Package Faclities">{accommodationPackage?.accommodationPackage?.packageFacilities &&
                                    accommodationPackage?.accommodationPackage?.packageFacilities?.split(',').map((facility, index) => (
                                        <Tag key={index} bordered={false} color='cyan' style={{ marginBottom: '10px' }}>{capitalizeFisrtLetterEachWord(facility)}</Tag>
                                    ))}
                                </Descriptions.Item>
                            </Descriptions>
                        </div>

                        <div className='bookAccommodation__container'>
                            <h2 className='header_4'>
                                How to Book
                            </h2>
                            <p className='paragraph'>
                                Please fill the form below to book the accommodation package. Our team will contact you shortly with best available rates and other details.
                            </p>

                            <Spin spinning={loadingSubmit} tip="Submitting your request...">
                                <Form
                                    form={form}
                                    layout="vertical"
                                    onFinish={onFinish}
                                    className="booking-form"
                                >
                                    <Form.Item
                                        label="Check-in & Check-out Dates"
                                        name="dates"
                                        rules={[{ required: true, message: 'Please select your dates!' }]}
                                    >
                                        <RangePicker className="date-picker" />
                                    </Form.Item>

                                    <div className="form-row">
                                        <Form.Item
                                            label="Adults"
                                            name="adults"
                                            initialValue={1}
                                            rules={[{ required: true }]}
                                        >
                                            <InputNumber min={1} max={10} />
                                        </Form.Item>

                                        <Form.Item
                                            label="Children"
                                            name="childrens"
                                            initialValue={0}
                                        >
                                            <InputNumber min={0} max={10} />
                                        </Form.Item>
                                    </div>

                                    <div className="meals-section">
                                        <h4>Meal Preferences</h4>
                                        <div className="form-row-in">
                                            <Form.Item name="breakfast" valuePropName="checked">
                                                <Checkbox>Breakfast</Checkbox>
                                            </Form.Item>
                                            <Form.Item name="lunch" valuePropName="checked">
                                                <Checkbox>Lunch</Checkbox>
                                            </Form.Item>
                                            <Form.Item name="dinner" valuePropName="checked">
                                                <Checkbox>Dinner</Checkbox>
                                            </Form.Item>
                                        </div>

                                        <Form.Item name="mealPreference">
                                            <Radio.Group>
                                                <Radio value="vegetarian">Vegetarian</Radio>
                                                <Radio value="non-vegetarian">Non Vegetarian</Radio>
                                                <Radio value="vegan">Vegan</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>

                                    <Form.Item
                                        label="Special Requirements"
                                        name="specialRequirements"
                                    >
                                        <TextArea rows={4} />
                                    </Form.Item>

                                    <div className="form-row">
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[
                                                { required: true, message: 'Please input your email!' },
                                                { type: 'email', message: 'Please enter a valid email!' }
                                            ]}
                                            className="form-col"
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item
                                            label="Contact Number"
                                            name="phone"
                                            rules={[
                                                { required: true, message: 'Please input your contact number!' },
                                                { pattern: /^[0-9+-]+$/, message: 'Please enter a valid phone number!' }
                                            ]}
                                            className="form-col"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" size="large" loading={loadingSubmit}>
                                            Submit Booking Request
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Spin>
                        </div>
                    </>
            }

            <Modal
                title="Confirm Booking"
                rootClassName='tour_booking_modal'
                open={isModelOpen}
                closable={false}
                onOk={() => setIsModelOpen(false)}
                footer={[
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => {
                            setIsModelOpen(false)
                            navigate('/dashboard/my-bookings')
                        }}
                    >
                        Ok
                    </Button>,
                ]}
            >
                <p>
                    Your booking is accommodation booking is recived successfully. Our team will contact you shortly with best available rates and other details.
                </p>

                <Steps
                    direction="vertical"
                    current={0}
                    items={[
                        {
                            title: 'Booking Request',
                            description: 'Your booking request is received successfully.',
                        },
                        {
                            title: 'Our Team Contact',
                            description: 'Our team will contact you shortly with best available rates and other details.',
                        },
                        {
                            title: 'Payment',
                            description: 'You can pay for the booking through the payment link provided by our team.',
                        },
                        {
                            title: 'Booking Confirmed',
                            description: 'Your booking is confirmed and you can view it in your booking history.',
                        }
                    ]}
                />
            </Modal>
        </div>
    )
}

export default BookAccommodationPackage